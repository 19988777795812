
export const he = {
  language_code: `he`,
  language_name: `עברית`,
  app_name: `קצב״ת`,

  global_search: `חפש`,
  global_new_client: `לקוח חדש`,
  global_save: `שמור`,
  global_save_changes: `שמור שינויים`,
  global_add_new: `הוסף חדש`,
  global_status: `סטטוס`,
  global_created: `נוצר בתאריך`,
  global_id: `מספר זיהוי`,
  global_contact_us: `צור קשר`,
  global_contact_us_description: `ניתן ליצור קשר על ידי מילוי ושליחת הטופס הבא או לחלופין ניתן לשלוח לנו הודעה בוואטסאפ שמספרו 054-7831008`,
  global_send: `שלח`,
  global_yes: `כן`,
  global_no: `לא`,
  global_number: `מס`,
  global_click_to_view: `לחץ לצפייה`,
  global_open_gallary: `פתח גלריה`,
  global_id_number: `תעודת זהות`,
  global_id_number_short: `ת.ז.`,
  global_send_request: `שלח בקשה`,
  global_phone: `טלפון`,
  global_country_code: `קוד מדינה`,
  global_email: `דואר אלקטרוני`,
  global_actions: `פעולות`,
  global_delete: `מחק`,
  global_cancel: `בטל`,
  global_cancel_and_close: `בטל וסגור`,
  global_download_picture: `הורד תמונה`,
  global_download_app_scan_qr_code: `סרוק להורדת האפליקציה לנייד`,
  global_loading_data: `טוען נתונים...`,
  global_address: `כתובת`,
  global_try_again: `נסה שוב`,
  global_edit: `ערוך פרטים`,
  global_no_data: `אין נתונים`,
  global_update_data: `עדכן פרטים`,
  global_all: `הכל`,
  global_access_denied_title: `אין לך הרשאה`,
  global_access_denied_subtitle: `אין לך הרשאה לצפות בדף זה`,
  global_access_denied_subtitle_missing_payments: `אין לך הרשאה לצפות בדף זה, עליך לחדש את המנוי על מנת להמשיך להשתמש במערכת!`,
  global_phone_number: `מספר טלפון`,
  global_verify_code: `אימות קוד`,
  global_verification_code: `קוד אימות שהתקבל`,
  global_full_name: `שם מלא`,
  global_driver_license_number: `מספר רישיון נהיגה`,
  global_other: `אחר`,
  global_remove: `הסר`,
  global_downloading: `הורדת קבצים`,
  global_downloading_file_message: `נא להמתין בזמן שהקובץ נטען`,
  global_filter: `סנן`,
  global_select_all: `בחר הכל`,
  global_download: `להורדה`,
  global_options: `אפשרויות`,
  global_clear: `נקה`,
  global_date: `תאריך`,
  global_rows_per_page: `שורות לעמוד`,
  global_feature_not_available_title: `מודל זה אינו זמין למנוי זה!`,
  global_feature_not_available: `ניתן להוסיף מודל זה על ידי שינוי סוג המנוי, פנה למנהל המערכת למידע נוסף.`,
  global_select: `בחר`,
  global_upload: `העלה`,
  global_remove_all: `הסר הכל`,
  global_today: `היום`,
  global_name: `שם`,
  global_saving_changes: `שומר שינויים...`,
  global_unknown: `לא ידוע`,
  global_view: `הצג`,
  global_onboarding: `רישום למערכת`,
  global_preview: `תצוגה מקדימה`,
  global_close: `סגור`,

  global_unit_km: `ק"מ`,

  // Messages:
  message_are_you_sure_delete: `האם אתה בטוח שברצונך למחוק?`,

  // Login / Logout:
  login_title: `כניסה למערכת`,
  login_email: `דואר אלקטרוני`,
  login_password: `סיסמא`,
  login_remember_me: `זכור אותי`,
  login_forgot_password: `שכחת סיסמא?`,
  login_forgot_password_message: `אנא הכנס את כתובת האימייל שלך ונשלח לך קישור לאיפוס הסיסמא.`,
  login_reset_pssword: `איפוס סיסמא`,
  login_button: `התחבר`,
  login_error: `דואר אלקטרוני או סיסמא שגויים`,
  logout_title: `התנתקות מהמערכת`,
  logout_message: `האם אתה בטוח שברצונך להתנתק?`,
  forgot_password_return_to_sign_in: `חזור להתחברות`,
  password_request_sent_title: `בדוק את תיבת המייל שלך!`,
  password_request_sent_message: `אם כתובת האימייל שלך קיימת במערכת, קישור לאיפוס הסיסמא נשלח אליך.`,

  // Register:
  register_title: `הרשמה למערכת`,

  // Errors:
  'Firebase: Error (auth/user-not-found).': `אימייל לא קיים במערכת`,
  'Firebase: Error (auth/wrong-password).': `סיסמא שגויה`,
  'auth/invalid-phone-number': `מספר טלפון לא תקין`,
  'auth/invalid-verification-code': `קוד אימות לא תקין`,
  error_client_list_empty_title: `רשימת הלקוחות שלך ריקה`,
  error_client_list_empty_subtitle: `נסה לרענן את הדף או לחץ על הכפתור להוספת לקוח`,
  error_client_add_title: `לא ניתן להוסיף לקוח, בדוק האם מספר החברה כבר בשימוש`,
  error_vehicles_list_empty_title: `רשימת הרכבים שלך ריקה`,
  error_vehicles_list_empty_subtitle: `נסה לרענן את הדף או לחץ על הכפתור להוספת רכב`,
  error_vehicle_add_title: `לא ניתן להוסיף או לערוך רכב`,
  error_vehicle_document_add_title: `לא ניתן להוסיף או לערוך מסמך`,
  error_driver_add_title: `לא ניתן להוסיף או לערוך נהג`,
  error_driver_phone_number: `מספר טלפון לא תקין`,
  error_driver_empty_phone: `מספר טלפון הוא שדה חובה`,
  error_vehicle_add_subtitle: `ארעה שגיאה בעת הוספת הרכב`,
  error_vehicle_reports_list_is_empty_title: `רשימת הדוחות של הרכב ריקה`,
  error_vehicle_reports_list_is_empty_subtitle: `לרכב זה אין דוחות עדיין, תוכל ליצור דוח חדש דרך האפליקציה בנייד`,
  error_drivers_list_empty_title: `רשימת הנהגים שלך ריקה`,
  error_drivers_list_not_found_title: `לא נמצאו נהגים`,
  error_drivers_list_not_found_description: `לא נמצאו נהגים, נסה לשנות את החיפוש`,
  error_drivers_list_empty_subtitle: `לחץ על הכפתור להוספת נהג`,
  error_no_drivers_found_title: `לא נמצאו נהגים, עליך להוסיף נהג חדש ללקוח זה תחילה`,
  error_global_title: `ארעה שגיאה`,
  error_global_subtitle: `אופס, משהו לא עובד כמתוכנן, נסה שוב`,
  error_global_empty: `אין נתונים להצגה`,
  error_driver_not_selected_title: `לא נבחר נהג`,
  error_linked_drivers_list_empty_title: `אין נהגים קבועים המקושרים לרכב זה`,
  error_linked_drivers_list_empty_subtitle: `לחץ על הכפתור לקישור נהג`,
  error_update_user_data: `אירעה שגיאה בעת עדכון הפרטים`,
  error_client_no_vehicles_for_type: `ללקוח זה אין רכבים מסוג זה`,


  contact_found_problem_feature_request: `מצאת בעיה? יש לך הצעה לשיפור?`,

  language_title: `עברית`,
  nav_subheader_general: `כללי`,
  nav_subheader_management: `ניהול`,
  nav_app: `לוח ראשי`,
  nav_clients: `לקוחות`,
  nav_clients_add: `הוספת לקוח`,
  nav_clients_vehicle: `רכבים`,
  nav_clients_driver: `נהגים`,
  nav_clients_course: `הדרכות`,
  nav_clients_reports: `דוחות`,
  nav_subheader_system: `הגדרות מערכת`,
  nav_settings: `הגדרות`,
  nav_settings_signature: `פרטים וחתימה`,
  nav_settings_vehicle_report: `דוח בדיקת רכב`,

  // Components:
  component_file_dropzone_title: 'גרור או בחר קבצים להעלאה',
  component_file_dropzone_part_1: 'גרור קבצים או ',
  component_file_dropzone_part_2: 'לחץ כאן',
  component_file_dropzone_part_3: 'לבחירה מתקיה',

  // Dashboard:
  dashboard_title: `לוח ראשי`,
  dashboard_total_clients: `לקוחות`,
  dashboard_total_vehicles: `רכבים`,
  dashboard_total_drivers: `נהגים`,
  dashboard_total_reports: `דוחות`,
  dashboard_total_expired_vehicles: `רכבים לטיפול`,

  // Clients List:
  clients_title: `לקוחות`,
  clients_add_client: `הוספת לקוח`,
  clients_edit_title: `עריכת לקוח`,
  clients_list_table_title_name: `שם הלקוח`,
  clients_list_table_title_id: `מספר ח.פ.`,
  clients_list_table_title_total_vehicles: `סה"כ רכבים`,
  clients_list_table_title_total_reports: `סה"כ דוחות`,
  clients_list_table_title_status: `סטטוס`,
  clients_list_table_title_created: `נוצר בתאריך`,
  clients_list_table_title_address: `כתובת`,
  clients_list_table_title_vehicles_number: `רכבים`,
  clients_list_table_title_drivers_number: `נהגים`,
  client_list_status_clear: `הכל תקין`,
  client_list_status_missing: `חסרים פרטים`,
  client_list_status_problem: `יש בעיה`,
  client_singature: `חתימה/חותמת הלקוח (לא חובה)`,
  client_singature_upload: `העלאת חתימה`,
  client_singature_upload_description: `באפשרותך להעלות את חתימת/חותמת הלקוח למערכת על מנת לצרף אותה לדוחות ומסמכים, עליך לחתום על דף לבן, לצלם את החתימה ולהעלות את התמונה למערכת, לדוגמא:`,

  // Client:
  client_page_title: `תיק לקוח`,
  client_page_about: `אודות`,
  client_page_tab_profile: `פרופיל`,
  client_page_tab_vehicles: `רכבים`,
  client_page_tab_alerts: `התראות`,
  client_page_tab_drivers: `נהגים`,
  client_page_tab_courses: `הדרכות`,
  client_page_tab_notifications_settings: `התראות`,
  client_page_news_updates: `חדשות ועדכונים`,
  client_page_news_updates_empty: `אין חדשות ועדכונים`,
  client_page_search_vehicles: `חיפוש רכבים`,
  client_page_search_drivers: `חיפוש נהגים`,
  client_page_delete_client: `מחיקת לקוח`,
  client_page_show_archive: `הצג רכבים מהארכיון בלבד`,

  // Client Vehicle:
  client_page_vehicle_id: `מספר רכב`,
  client_page_vehicle_make: `יצרן`,
  client_page_vehicle_model: `דגם`,
  client_page_vehicle_year: `שנת ייצור`,
  client_page_vehicle_vin: `מספר שלדה`,
  client_page_vehicle_type: `סוג רכב`,
  client_page_vehicle_last_odometer: `קילומטראז' אחרון`,
  client_page_vehicle_color: `צבע`,
  client_page_vehicle_owner_type: `סוג בעלות`,
  client_page_vehicle_internal_id: `מזהה פנימי`,

  client_page_vehicle_add: `הוספת רכב`,
  client_page_vehicle_edit: `עריכת רכב`,
  client_page_vehicle_details: `פרטי הרכב`,
  client_page_vehicle_form_validation_id: `מספר רכב הוא שדה חובה`,
  client_page_vehicle_form_validation_id_numbers_only: `מספר רכב חייב להכיל מספרים בלבד`,
  client_page_vehicle_form_validation_make: `יצרן הוא שדה חובה`,
  client_page_vehicle_form_validation_year: `שנת ייצור הוא שדה חובה`,
  client_page_vehicle_filter_by_type: `סנן לפי סוג רכב`,

  // Client Driver:
  client_page_driver_add: `הוספת נהג`,
  client_page_driver_subtitle: `הוספת נהג חדש ללקוח, לא כל הפרטים חובה, באפשרותך לבחור לשלוח הודעה לנהג בכדי להשלים את הפרטים, להעלות צילום של הרישיון ולחתום על הצהרת הנהג.`,
  client_page_driver_edit: `עריכת נהג`,
  client_page_driver_subtitle_edit: `עריכת נהג קיים.`,
  client_page_driver_link: `קישור נהג`,
  client_page_driver_details: `פרטי הנהג`,
  client_page_driver_name: `שם הנהג`,
  client_page_driver_type: `סוג נהג`,
  client_page_driver_license: `מספר רישיון`,
  client_page_driver_license_expiration: `תאריך תפוגה רישיון`,
  client_page_driver_birthday: `תאריך לידה`,
  client_page_driver_notify: `שלח הודעה לנהג לצורך השלמת תהליך הרישום וחתימה דיגיטלית`,
  client_page_driver_notify_success: `הודעה נשלחה בהצלחה`,
  client_page_driver_notify_failed: `שגיאה בשליחת הודעה`,
  client_page_driver_license_issue_date: `תאריך הנפקה רישיון`,
  client_page_driver_form_validation_name: `שם הנהג הוא שדה חובה`,
  client_page_driver_form_validation_license: `מספר רישיון הוא שדה חובה`,
  client_page_driver_form_validation_license_numbers_only: `מספר רישיון חייב להכיל מספרים בלבד`,
  client_page_driver_form_validation_license_expiration: `תאריך תפוגה הוא שדה חובה`,
  client_page_driver_form_validation_license_issue_date: `תאריך הנפקה הוא שדה חובה`,
  client_page_driver_details_work: `פרטי עבודה`,
  client_page_driver_work_start_date: `תאריך תחילת עבודה`,
  client_page_driver_work_branch: `סניף עבודה`,
  client_page_driver_work_vehicle_type: `סוג רכב`,
  client_page_driver_work_job_title: `תפקיד`,
  client_page_driver_work_job_devision: `מחלקה`,

  // Client Settings:
  client_page_settings_alert_emails: `קבלת התראות מערכת`,
  client_page_settings_alert_emails_description: `ניתן להוסיף כתובות מייל אחרות לקבלת התראות מהמערכת, בחר סוג התראה:`,
  client_page_settings_add: `הוסף כתובת`,
  client_page_settings_search_email: `חיפוש כתובת`,
  error_client_settings_list_empty_title: `רשימת הכתובות ריקה`,
  error_client_settings_list_empty_subtitle: `ללקוח זה אין כתובות עדיין, ניתן להוסיף כתובות דואר אלקטרוני בכדי שעוד בעלי עניין יקבלו התראות`,
  client_page_settings_add_address: `הוסף כתובת`,
  client_settings_page_title_add_alert: `הוספת התראה`,
  client_page_settings_send_alert_vehicle_to_drivers: `שלח התראות רכב לנהגים מקושרים`,
  client_page_settings_send_alert_driver_docs: `שלח התראות מסמכים לנהגים`,
  client_page_settings_send_alert_client: `שלח התראות ללקוח`,
  client_page_settings_send_alert_disable: `בטל את שליחת התראות`,

  client_settings_alert_type_title: `סוג התראה`,
  client_settings_alert_type_vehicle_document_expired: `מסמך רכב פג תוקף`,
  client_settings_alert_type_vehicle_generic_alert: `התראה כללית על רכב`,
  client_settings_alert_type_vehicle_report_alert: `התראה על דוח רכב`,
  client_settings_alert_type_driver_document_expired: `מסמך נהג פג תוקף`,
  client_settings_alert_type_driver_generic_alert: `התראה כללית על נהג`,
  client_settings_alert_type_user_account_alert: `התראה על חשבון משתמש`,
  client_settings_alert_type_generic_alert: `התראה כללית`,

  // Vehicle Page:
  vehicle_page_title: `תיק רכב`,
  vehicle_page_license_number: `מספר רכב`,
  vehicle_page_tab_reports: `דוחות`,
  vehicle_page_tab_incidents: `אירועים`,
  vehicle_page_tab_tachographs: `טכוגרף`,
  vehicle_page_tab_info: `פרטים`,
  vehicle_page_tab_documents: `מסמכים`,
  vehicle_page_tab_invoices: `חשבוניות`,
  vehicle_page_search_reports: `חיפוש דוחות`,
  vehicle_page_move_to_archive: `העבר לארכיון`,
  vehicle_page_move_to_archive_description: `האם אתה בטוח שברצונך להעביר את הרכב לארכיון? פעולה זו לא תמחק את המידע על הרכב.`,
  vehicle_page_move_out_from_archive: `הוצא מארכיון`,
  vehicle_page_move_out_from_archive_description: `האם אתה בטוח שברצונך להוציא את הרכב מהארכיון?`,
  vehicle_page_related_drivers: `נהגים קבועים`,
  vehicle_page_related_drivers_add: `הוספת נהג קבוע`,
  vehicle_page_related_drivers_add_description: `הוסף נהגים הקשורים ללקוח זה`,
  vehicle_page_related_drivers_empty: `לא נמצאו עוד נהגים להוספה`,
  vehicle_page_related_drivers_empty_description: `ראשית עליך להוסיף את הנהג ללקוח`,

  // Vehicle Type:
  vehicle_type_car: `רכב פרטי`,
  vehicle_type_commercial: `רכב מסחרי`,
  vehicle_type_truck: `משאית`,
  vehicle_type_truck_small: `משאית קטנה`,
  vehicle_type_bus: `אוטובוס`,
  vehicle_type_minibus: `מיניבוס`,
  vehicle_type_teayoulit: `טיולית`,
  vehicle_type_taxi: `מונית`,
  vehicle_type_motorcycle: `אופנוע`,
  vehicle_type_tractor: `טרקטור`,
  vehicle_type_trailer: `נגרר`,
  vehicle_type_fork_lift: `מלגזה`,
  vehicle_type_motor_home: `קראוון`,
  vehicle_type_other: `אחר`,

  // Vehicle Owner Types:
  vehicle_owner_type_company: `חברה`,
  vehicle_owner_type_private: `פרטי`,
  vehicle_owner_type_leasing: `ליסינג`,
  vehicle_owner_type_rental: `השכרה`,
  vehicle_owner_type_other: `אחר`,

  // Vehicle Documents:
  vehicle_documents_page_title_is_expired: `פג תוקף`,
  vehicle_documents_page_title_document_title: `כותרת`,
  vehicle_documents_page_title_document_type: `סוג המסמך`,
  vehicle_documents_page_title_expiration_date: `תאריך תפוגה`,
  vehicle_documents_page_title_download_file: `הורד קובץ`,
  vehicle_documents_page_title_update_date: `עדכן תאריך`,
  vehicle_documents_page_title_documents_list: `רשימת מסמכים`,
  vehicle_documents_page_title_search_documents: `חיפוש מסמכים`,
  vehicle_documents_page_title_no_title: `ללא כותרת`,
  vehicle_documents_page_title_actions: `פעולות`,
  vehicle_documents_page_title_download: `צפיה/הורדת מסמך`,
  vehicle_documents_page_title_add_document: `הוספת מסמך`,
  vehicle_documents_page_title_edit_document: `עריכת מסמך`,
  vehicle_documents_page_title_document_file: `קובץ מסמך`,
  vehicle_documents_page_title_upload_file: `לחץ כאן לבחירת קובץ להעלאה`,
  vehicle_documents_page_title_file_selected: `קובץ נבחר`,
  vehicle_documents_page_title_clear_file: `הסר קובץ`,
  vehicle_documents_page_error_adding_document: `אירעה שגיאה בעת הוספת המסמך`,
  vehicle_documents_page_error_updating_expiration_date: `אירעה שגיאה בעת עדכון תאריך התפוגה`,
  error_vehicle_document_add_no_file: `יש לבחור מסמך להעלאה`,
  error_vehicle_documents_page_title_empty: `לא נמצאו מסמכים`,
  error_vehicle_documents_page_subtitle_empty: `לרכב זה אין מסמכים עדיין, תוכל להוסיף מסמכים גם דרך האפליקציה בנייד`,
  vehicle_document_type_vehicle_license: 'רישיון רכב',
  vehicle_document_type_vehicle_license_appendix: 'נספח רישיון רכב',
  vehicle_document_type_compulsory_insurance: 'ביטוח חובה',
  vehicle_document_type_brakes_certificate: 'אישור מערכת בלמים',
  vehicle_document_type_winter_examination: 'בדיקת חורף',
  vehicle_document_type_operating_license: 'רישיון הפעלה',
  vehicle_document_type_bus_transport_certificate: 'רישיון הסעה',
  vehicle_document_type_bus_children_forgetting_certificate: 'תקינות מערכת שכחת ילדים',
  vehicle_document_type_tachograph_certificate: 'אישור כיול טכוגרף',
  vehicle_document_type_truck_lift_ramp_certificate: 'אישור תקינות דופן הידראולי',
  vehicle_document_type_truck_transforter_certificate: 'רישיון מוביל',
  vehicle_document_type_cooling_box_certificate: 'אישור כיול ארגז קירור',
  vehicle_document_type_seasonal_check: 'טיפול תקופתי',
  vehicle_document_type_other: 'אחר',
  vehicle_document_type_undefined: 'לא מוגדר',

  // Vehicle Invoices:
  vehicle_invoices_page_title_invoices_list: `חשבוניות רכב`,
  vehicle_invoices_page_title_add_invoice: `הוספת חשבונית`,
  vehicle_invoices_page_title_edit_invoice: `עריכת חשבונית`,
  vehicle_invoices_page_title_search_invoices: `חיפוש חשבוניות`,
  error_vehicle_invoices_page_title_empty: `לא נמצאו חשבוניות`,
  error_vehicle_invoices_page_subtitle_empty: `לרכב זה אין חשבוניות עדיין, תוכל להוסיף חשבוניות גם דרך האפליקציה בנייד`,
  vehicle_invoices_page_title_invoice_issuedBy: `הופקה על ידי`,
  vehicle_invoices_page_title_upload_file: `לחץ כאן לבחירת קובץ להעלאה`,
  vehicle_invoices_page_title_invoice_number: `מספר חשבונית`,
  vehicle_invoices_page_title_invoice_amount: `סכום חשבונית`,
  vehicle_invoices_page_title_invoice_details: `פרטי חשבונית`,
  vehicle_invoices_page_title_invoice_issueDate: `תאריך הנפקה`,
  vehicle_invoices_page_title_due_date: `לתשלום עד`,
  vehicle_invoices_page_title_file_selected: `קובץ נבחר`,
  vehicle_invoices_page_title_download: `לחץ להורדת צילום החשבונית`,
  vehicle_invoices_page_title_no_file: `אין סריקה לחשבונית זאת`,
  error_vehicle_invoice_add_title: `לא ניתן להוסיף חשבונית`,
  vehicle_invoices_page_title_invoice_title: `כותרת חשבונית`,
  vehicle_invoices_page_error: `אירעה שגיאה בעת הוספת החשבונית`,

  vehicle_exipred_tests_list_title: `רכבים לטיפול ⚠️`,
  vehicle_exipred_tests_list_subtitle: `לידיעתך, ברשימה זו מופיעים רכבים שיש לבצע עבורם בדיקת בטיחות חודשית חדשה דרך האפליקציה בנייד.`,
  vehicle_exipred_tests_last_date: `תאריך בדיקה אחרונה`,
  vehicle_exipred_tests_filter_company_name: `סנן לפי שם חברה`,
  vehicle_exipred_tests_no_vehicle: `רשימת הרכבים ריקה.`,

  // Report Page:
  report_page_title: `דוח בדיקת רכב`,
  report_page_title_more_details: `פרטים נוספים`,
  report_page_title_download_report: `הורדת הדוח`,
  report_page_title_send_report: `שליחת הדוח`,
  report_page_title_details: `פרטי דוח`,
  report_page_title_report_number: `מספר דוח`,
  report_page_title_car_details: `פרטי הרכב`,
  report_page_title_car_id: `מספר רכב`,
  report_page_title_car_make: `יצרן`,
  report_page_title_car_model: `דגם`,
  report_page_title_car_type: `סוג רכב`,
  report_page_title_car_manufacturer_date: `שנת ייצור`,
  report_page_title_odometer: `קילומטראז`,
  report_page_title_client_name: `שם הלקוח`,
  report_page_title_date: `תאריך`,
  report_page_title_driver_details: `פרטי הנהג`,
  report_page_title_driver_permenant: `נהג קבוע`,
  report_page_title_driver_id: `תעודת זהות נהג`,
  report_page_title_driver_name_full: `שם מלא`,
  report_page_title_driver_license_expiration: `תוקף רישיון נהיגה`,
  report_page_title_results: `תוצאות הבדיקה`,
  report_page_title_search_tests: `חיפוש בדיקות`,
  report_page_title_test_name: `שם הבדיקה`,
  report_page_title_test_result: `תוצאה`,
  report_page_title_test_notes: `הערות`,
  report_page_title_test_attachments: `תמונות`,
  report_page_title_test_pass: `נמצא תקין`,
  report_page_title_test_fail: `נמצאו ליקויים`,
  report_page_title_test_no_tested: `לא בוצע`,
  report_page_title_notes_exists: `יש הערות`,
  report_page_title_notes_not_exists: `אין הערות`,
  report_page_title_attachments_exists: `יש תמונות`,
  report_page_title_test_location: `מיקום הבדיקה`,
  report_page_title_test_type: `סוג הבדיקה`,
  report_page_title_vin: `מספר שלדה`,
  report_page_title_download_report_description: `הורדת הדוח ישרות למחשב שלך`,
  report_page_title_email_report_description: `שלח את הדוח ישירות לתיבת הדואר של הלקוח`,
  report_page_title_generate_report_include_photos: `כלול תמונות`,
  report_page_title_generate_report_include_notes: `כלול הערות`,
  report_page_title_generate_report_include_driver: `כלול פרטי נהג`,
  report_page_title_generate_report_include_options: `כלול אופציות`,
  report_page_title_report_status: `סטטוס הדוח`,
  report_page_title_report_status_open: `פתוח`,
  report_page_title_report_status_closed: `סגור`,
  report_page_title_close_report: `סגור דוח`,
  report_page_title_close_report_description: `סגור את הדוח ואל תאפשר עריכה נוספת`,

  // Notifications:
  notifications_title: `התראות`,
  notifications_not_read_placeholder: `יש לך % התראות שלא נקראו`,

  // Form Validation:
  form_validation_global: `שדה חובה`,
  form_validation_name: `שם הוא שדה חובה`,
  form_validation_email: `דואר אלקטרוני הוא שדה חובה`,
  form_validation_password: `סיסמה היא שדה חובה`,
  form_validation_email_message: `כתובת דואר אלקטרוני אינה תקינה`,
  form_validation_phone_number: `מספר טלפון הוא שדה חובה`,
  form_validation_address: `כתובת היא שדה חובה`,
  form_validation_city: `עיר היא שדה חובה`,
  form_validation_zip_code: `מיקוד הוא שדה חובה`,
  form_validation_company_name: `שם החברה הוא שדה חובה`,
  form_validation_company_id: `מספר ח.פ הוא שדה חובה`,
  form_validation_numbers_only: `שדה זה יכול להכיל רק מספרים`,
  form_validation_text_only: `שדה זה יכול להכיל רק אותיות`,
  form_input_has_permenent_driver: `נהג קבוע`,
  form_input_has_permenent_driver_select: `בחר נהג קבוע`,
  form_validation_type_error_date: `תאריך לא תקין`,


  // From Inputs:
  form_input_name: `שם`,
  form_input_email: `דואר אלקטרוני`,
  form_input_phone_number: `מספר טלפון`,
  form_input_street: `רחוב`,
  form_input_house_number: `מספר בית`,
  form_input_city: `עיר`,
  form_input_zip_code: `מיקוד`,
  form_input_company_name: `שם החברה`,
  form_input_company_id: `מספר ח.פ`,
  form_input_save_changes: `שמור שינויים`,
  form_input_create_client: `צור לקוח`,
  form_input_has_contact_info: `הוספת פרטי איש קשר`,
  form_input_title_company_info: `פרטי הלקוח`,
  form_input_title_contact_info: `פרטי איש קשר`,
  form_how_can_we_help: `איך אפשר לעזור?`,

  form_message_success_message: `פרטי הלקוח נשמרו בהצלחה!`,
  form_message_error_message: `אירעה שגיאה בעת שמירת הפרטים`,
  form_message_update_success_message: `הפרטים עודכנו בהצלחה`,
  form_message_already_exists: `המידע שהזנת כבר קיים במערכת`,


  // Settings:
  settings_title: `הגדרות`,
  settings_user_details: `פרטי משתמש`,
  settings_singature: `חתימה`,
  settings_singature_upload_description: `העלה את החתימה שלך לשימוש בדוחות\n\nהקפד להשתמש ברקע לבן ועט כהה, הנה דוגמה:`,
  settings_singature_upload: `העלה חתימה`,

  settings_vehicle_report_selection: `הגדרות דוח בדיקת רכב`,
  settings_vehicle_type_selection: `בחר סוג רכב`,
  settings_vehicle_type_report_selection: `בחר סוג דוח`,
  settings_vehicle_report_doc_input_title: `פרטים נוספים`,
  settings_vehicle_report_doc_input_table_field: `שדה`,
  settings_vehicle_report_doc_input_table_included: `נכלל בבדיקה`,
  settings_vehicle_report_doc_input_table_mendatory: `שדה חובה`,
  settings_vehicle_report_question_title: `בדיקות הנכללות בדוח`,
  settings_days_before_alert_label: `ימים לפני קבלת התראה`,
  settings_days_before_alert_title: `בחר כמה ימים לפני תרצה לקבל התראות`,

  // Alerts:
  alert_type_vehicle_document_expired: `מסמך רכב פג תוקף`,
  alert_type_vehicle_generic_alert: `בעיה ברכב`,
  alert_type_vehicle_report_alert: `דוח בדיקת רכב`,
  alert_type_driver_document_expired: `מסמך נהג פג תוקף`,
  alert_type_driver_generic_alert: `התראה על נהג`,
  alert_type_user_account_alert: `בעיה בחשבון משתמש`,
  alert_type_generic_alert: `בעיה כללית`,

  alert_vehicle_document_expired_title: `מסמך`,
  alert_vehicle_document_expired_with_name_title: `בשם`,
  alert_vehicle_document_expired_for_vehicle: `עבור רכב שמספרו`,
  alert_vehicle_document_expired_expired_at: `פג תוקף בתאריך`,

  alert_vehicle_test_expired_title: `התראת בדיקת בטיחות עבור רכב מספר`,
  alert_vehicle_test_expired_expire_date: `תאריך הבדיקה האחרונה`,

  alert_driver_document_expired_title: `מסמך`,
  alert_driver_document_expired_with_name_title: `בשם`,
  alert_driver_document_expired_for_driver: `עבור נהג בשם`,
  alert_driver_document_expired_expired_at: `פג תוקף בתאריך`,

  alert_driver_license_expired_title: `רישיון הנהיגה של`,
  alert_driver_license_expired_expired_at: `פג תוקף בתאריך`,


  // Country codes:
  country_lable_IL: `ישראל`,
  country_lable_DE: `גרמניה`,

  // Driver types:
  driver_type_truck: `משאית`,
  driver_type_bus: `אוטובוס`,
  driver_type_taxi: `מונית`,
  driver_type_commercial: `מסחרי`,
  driver_type_private: `פרטי`,
  driver_type_other: `אחר`,

  // Driver Pages:
  driver_page_login_title: `התחברות נהג`,
  driver_page_login_by_phone_help_text: `הכנס את מספר הטלפון שלך ונשלח אליך קוד להתחברות`,
  driver_page_login_copyright_text: `קצב״ת כל הזכויות שמורות`,
  driver_page_login_send_code_button_text: `שלח קוד לאימות`,
  driver_page_login_send_code_error: `אופס, אירעה שגיאה, נא לבדוק את הפרטים ולנסות שוב`,
  driver_page_login_error_enter_phone_number: `יש להכניס מספר טלפון`,
  driver_page_onboarding_error_save_data_first: `עליך ללחוץ על כפתור ״שמור שינויים״ בראש העמוד.`,
  driver_page_onboarding_error_missing_license_photo: `עליך להוסיף תמונה של רישיון הנהיגה.`,
  driver_page_onboarding_complete: `הושלם`,
  driver_page_onboarding_not_complete: `לא הושלם`,
  driver_page_onboarding_title: `טופס קליטת נהג למערכת`,
  driver_page_onboarding_subtitle: `לצורך סיום תהליך הרישום במערכת יש לוודא ו/או להשלים את הפרטים הבאים:`,
  driver_page_onboarding_confirm: `לחץ כאן לסיום וחתימה דיגיטלית`,
  driver_page_onboarding_signature_title: `נא לחתום על המסך בתוך המסגרת הכחולה:`,
  driver_page_onboarding_signature_clear: `נקה חתימה`,
  driver_page_onboarding_license_photo_title: `צילום רישיון הנהיגה שלך`,
  driver_page_onboarding_license_photo_subtitle: `צלם את רישיון הנהיגה משני הצדדים`,
  driver_page_onboarding_license_photo_front: `לחץ כאן להוספת צילום הרישיון מצידו הקידמי`,
  driver_page_onboarding_license_photo_back: `לחץ כאן להוספת צילום הרישיון מצידו האחורי`,
  driver_page_onboarding_fines_convert_agreement: `אני מאשר כי הפרטים שמסרתי לרבות חתימתי הדיגיטלית ישמשו לצורך הפקת מסמכי הצהרת נהג להסבת קנסות ודוחות תנועה שבוצעו על ידיי`,
  driver_page_onboarding_fines_convert_agreement_confirm: `אני מאשר בזאת שימוש בחתימתי`,
  driver_page_onboarding_fines_convert_agreement_cancel: `לא מאשר`,
  driver_page_onboarding_no_data_title: `אין נתונים להצגה`,
  driver_page_onboarding_no_data_subtitle: `אין נתונים להצגה, נסה שוב מאוחר יותר`,
  driver_page_onboarding_completed_title: `הרישום הושלם בהצלחה`,
  driver_page_onboarding_completed_subtitle: `ניתן לסגור את החלון`,
  driver_page_delete_success_message: `נהג נמחק בהצלחה`,
  driver_page_delete_error_message: `אירעה שגיאה בעת מחיקת הנהג`,
  
  
  driver_page_courses_title: `הדרכות לנהג`,
  driver_page_courses_subtitle: `במסך זה תוכל לקרוא ולהשלים הדרכות בנושאים שונים. יש לבחור הדרכה מתוך הרשימה:`,
  driver_page_courses_no_courses_title: `אין הדרכות`,
  driver_page_courses_no_courses_subtitle: `לא נמצאו הדרכות פתוחות כרגע`,
  driver_page_courses_instruction_to_driver_title: `נהג/ת יקר/ה,`,
  driver_page_courses_instruction_to_driver_description: `אנא הקדש/י זמן לקרוא בעיון ולעבור על כל המסמכים והסרטונים המצורפים. בסיום, יש לאשר כי עברת על חומרי ההדרכה והבנת אותם.`,
  driver_page_courses_legal_agreement: `בלחיצה על הכפתור אני מאשר/ת כי עברתי על חומרי ההדרכה והבנתי אותם`,
  driver_page_courses_confirm: `אני מאשר/ת`,
  driver_page_courses_click_to_view: `לחץ כאן לצפייה`,
  driver_page_courses_download: `לחץ כאן להורדה`,
  driver_page_courses_no_documents_title: `אין מסמכים`,
  driver_page_courses_no_documents_subtitle: `לא נמצאו מסמכים להצגה`,
  driver_page_courses_completed_title: `ההדרכה הושלמה בהצלחה.`,

  // Driver Details:
  driver_page_details_title: `פרטי נהג`,
  driver_page_tab_info: `פרטים`,
  driver_page_tab_documents: `מסמכים`,
  driver_page_tab_course: `הדרכות`,
  global_back_to_client: `חזרה ללקוח`,
  driver_page_details_onboarding_completed: `רישום ע״י הנהג הושלם`,
  driver_page_details_onboarding_not_completed: `רישום ע״י הנהג טרם הושלם`,
  driver_page_details_driving_license: `פרטי רישיון נהיגה`,
  driver_page_details_driving_license_copy_front: `צילום רישיון נהיגה מצד קדמי`,
  driver_page_details_driving_license_copy_back: `צילום רישיון נהיגה מצד אחורי`,
  driver_page_details_driving_license_download: `הורד צילום רישיון נהיגה`,
  driver_page_details_driving_license_photos: `צילום רישיון נהיגה`,
  driver_page_details_download_click: `לחץ כאן להורדה`,
  driver_page_driver_license_details: `פרטי רישיון`,
  driver_page_driver_address: `פרטי כתובת`,
  
  driver_page_driver_license_levels: `דרגות רישיון`,
  driver_page_driver_license_levels_one: `1`,
  driver_page_driver_license_levels_a1: `A1`,
  driver_page_driver_license_levels_a2: `A2`,
  driver_page_driver_license_levels_a: `A`,
  driver_page_driver_license_levels_b: `B`,
  driver_page_driver_license_levels_c1: `C1`,
  driver_page_driver_license_levels_c: `C`,
  driver_page_driver_license_levels_ce: `CE`,
  driver_page_driver_license_levels_d: `D`,
  driver_page_driver_license_levels_d1: `D1`,
  driver_page_driver_license_levels_d2: `D2`,
  driver_page_driver_license_levels_d3: `D3`,
  driver_page_driver_license_levels_e: `E`,

  driver_page_driver_license_certificates: `הסמכות נהיגה`,
  driver_page_driver_license_certificate_crain: `מנוף`,
  driver_page_driver_license_certificate_forklift: `מלגזה`,
  driver_page_driver_license_certificate_materials: `חומרים מסוכנים`,

  // Driver Documents:
  driver_documents_generate_title: `הפקת מסמך חדש לנהג`,
  driver_documents_generate_date: `תאריך המסמך`,
  driver_documents_generate_ֿֿsend_to_title: `הוספת פרטי נמען`,
  driver_documents_generate_ֿֿsend_to_subtitle: `ניתן להוסיף את פרטי הנמען למסמך, חלק זה אינו חובה.`,
  driver_documents_subtitle_completed: `הפקת מסמכים לנהג, בחר את סוג המסמך ולאחר מכן מלא את הפרטים הנחוצים, פרטים שלא ימולאו יוחלפו בפס שניתן יהיה למלא ידנית. לחץ על כפתור ״תצוגה מקדימה״ על מנת לצפות בפורמט המסמך לפי היצירה שלו. `,
  driver_documents_generate_title_type: `בחירת סוג מסמך`,
  driver_documents_generate_title_vehicle: `קישור מסמך לרכב`,
  driver_documents_generate_use_driver_signature: `הוסף חתימה דיגיטלית של הנהג`,
  driver_documents_generate_new_document: `הפקת מסמך חדש`,
  driver_documents_upload_new_document: `העלאת מסמך קיים`,
  driver_documents_upload_title: `העלאת מסמך קיים`,
  driver_documents_upload_subtitle: `ניתן להעלות מסמך קיים למערכת ולשייך אותו לנהג`,
  driver_documents_upload_type: `סוג מסמך`,
  driver_documents_upload_name_title: `שם המסמך`,
  driver_documents_expire_date_title: `תאריך תפוגה`,
  driver_documents_page_title_upload_file: `לחץ כאן לבחירת קובץ להעלאה`,
  driver_documents_page_title_file_selected: `קובץ נבחר`,
  error_driver_document_add_no_file: `יש לבחור מסמך להעלאה`,
  driver_documents_hide_file_title: `הסתרת מסמך (לא יוצג למי שאין לו הרשאה)`,
  driver_documents_page_error_adding_document: `אירעה שגיאה בעת הוספת המסמך`,
  driver_document_expired_title: `פג תוקף`,

  driver_document_upload_title_driver_law_info: `מידע על נהג`,
  driver_document_upload_title_other: `אחר`,

  driver_documents_list_title: `רשימת מסמכים`,
  driver_documents_list_empty_title: `לא נמצאו מסמכים`,
  driver_documents_list_empty_subtitle: ``,
  driver_document_title_accident_report: `דוח אירוע`,
  driver_document_title_fine_change_parking: `שינוי דו״ח חניה`,
  driver_document_title_fine_change_court: `שינוי כתב אישום`,
  driver_document_title_fine_change_traffic: `שינוי דו״ח תנועה`,
  driver_document_title_fine_change_generic: `שינוי דו״ח כללי`,
  driver_document_title_test_drive: `נסיעת מבחן`,
  driver_document_title_driver_declaration: `הצהרת נהג לרכב`,
  driver_document_title_driver_declaration_generic: `הצהרת נהג כללית`,
  driver_document_title_law_168: `שעות נהיגה – תקנה 168`,
  driver_document_title_other: `אחר`,

  driver_documents_generate_fine_number: `מספר דו״ח`,
  driver_documents_generate_fine_recived_date: `תאריך קבלת הדו״ח`,
  driver_documents_generate_violation_date: `תאריך ביצוע העבירה`,
  driver_documents_generate_judge_name: `שם השופט`,
  driver_documents_generate_court_location: `מקום הדיון`,
  driver_documents_generate_upload_success_message: `המסמך נוצר בהצלחה`,
  driver_documents_generate_upload_error_message: `אירעה שגיאה בעת הפקת המסמך`,
  driver_documents_generate_send_to_address: `סמן להוספת כתובת נמען`,

  driver_documents_search_documents: `חיפוש מסמכים`,
  driver_documents_type: `סוג מסמך`,
  driver_document_create_date: `תאריך מסמך / תוקף`,
  driver_document_fineViolationDate: `תאריך העבירה`,
  driver_document_fineReceivedDate: `תאריך קבלת הדו״ח`,
  driver_document_license_number: `מספר רכב`,
  driver_documents_sent_to: `נשלח אל`,

  driver_documents_generate_send_to_name: `שלח אל`,
  driver_documents_generate_send_to_address_street: `רחוב`,
  driver_documents_generate_send_to_address_city: `עיר`,
  driver_documents_generate_send_to_address_zipCode: `מיקוד`,
  driver_documents_generate_send_to_address_houseNumber: `מספר בית`,
  driver_documents_generate_send_to_address_additionalAddress: `כתובת נוספת`,
  driver_documents_preivew_title: `תצוגה מקדימה למסמך, (לא כוללת את הפרטים והחתימה)`,

  // Invoces:
  invoice_title: `חשבוניות`,
  invoice_title_start_date: `תאריך התחלה`,
  invoice_title_end_date: `תאריך סיום`,
  invoice_title_search: `חיפוש חשבוניות`,
  invoice_title_found: `נמצאו`,

  invoice_analytics_title_total: `סה"כ`,
  invoice_analytics_title_paid: `שולם`,
  invoice_analytics_title_unpaid: `לא שולם`,

  invoice_status_title: `סטטוס`,
  invoice_status_title_all: `הכל`,
  invoice_status_title_paid: `שולם`,
  invoice_status_title_not_paid: `לא שולם`,
  invoice_status_title_refunded: `החזרו`,
  invoice_status_title_canceled: `בוטל`,

  invoice_type_title: `סוג חשבונית`,
  invoice_type_title_service: `מוסך, טיפול, תיקון`,
  invoice_type_title_parking: `חניה`,
  invoice_type_title_recharge_electric: `טעינת חשמל`,
  invoice_type_title_fuel_card: `כרטיס דלק`,
  invoice_type_title_test: `טסט`,
  invoice_type_title_insurence: `ביטוח`,
  invoice_type_title_rental: `השכרה`,
  invoice_type_title_leasing: `ליסינג`,
  invoice_type_title_loan: `הלוואה`,
  invoice_type_title_road_6: `כביש 6`,
  invoice_type_title_road_6_north: `כביש 6 צפון`,
  invoice_type_title_road_carmel: `מנהרות הכרמל`,
  invoice_type_title_others: `אחר`,

  invoice_table_head_title_number: `מספר חשבונית`,
  invoice_table_head_title_issued_date: `תאריך הנפקה`,
  invoice_table_head_title_due_date: `תאריך לתשלום`,
  invoice_table_head_title_amount: `סכום`,
  invoice_table_head_title_from_name: `ספק`,
  invoice_table_head_title_status: `סטטוס`,

  invoice_details_title: `פרטי חשבונית`,
  invoice_details_title_number: `מספר חשבונית`,
  invoice_details_title_description: `תיאור`,
  invoice_details_title_notes: `הערות`,
  invoice_details_title_subtotal: `סכום לפני מע"מ`,
  invoice_details_title_tax: `מע"מ`,
  invoice_details_title_total: `סה"כ`,
  invoice_details_title_shipping: `משלוח`,
  invoice_details_title_discount: `הנחה`,
  invoice_details_title_issue_date: `תאריך הנפקה`,
  invoice_details_title_due_date: `תאריך לתשלום`,

  invoice_add_title: `הוסף חשבונית`,
  inovice_add_update: `עדכן חשבונית`,
  invoice_add_item: `הוסף פריט`,
  invoice_add_item_title: `שם הפריט`,
  invoice_add_item_description: `תיאור`,
  invoice_add_item_price: `מחיר`,

  invoice_page_create_title_from: `ספק או נותן שירות`,
  invoice_page_create_title_issuedBy: `הונפקה על ידי`,
  invoice_page_create_title_address: `כתובת`,
  invoice_page_create_title_phone: `טלפון`,
  invoice_page_create_title_invoice_number: `מספר חשבונית`,

  rich_table_filter_title_found: `נמצאו`,
  rich_table_filter_type_title: `סוג`,
  rich_table_filter_start_date: `תאריך התחלה`,
  rich_table_filter_end_date: `תאריך סיום`,
  rich_table_filter_title_search: `חיפוש`,
  rich_table_filter_status_title: `סטטוס`,

  // Courses:
  courses_page_tab_info: `פרטים`,
  courses_page_tab_documents: `מסמכים`,
  courses_page_title_courses_list: `רשימת הדרכות`,
  courses_page_title_create_course: `הוספת הדרכה`,
  courses_page_title_download_report: `הורד סיכום כל ההדרכות`,
  courses_table_head_title_name: `שם`,
  courses_table_head_title_type: `סוג`,
  courses_table_head_title_status: `סטטוס`,
  courses_table_head_title_started_at: `תאריך התחלה`,
  courses_table_head_title_ended_at: `תאריך סיום`,
  courses_table_head_title_total_registered: `סה"כ רשומים`,

  courses_documents_add_new_document: `הוספת מסמך חדש`,
  courses_documents_table_title_name: `שם`,
  courses_documents_table_title_description: `תיאור`,
  courses_documents_table_title_show_to_attendees: `מוצג למשתתפים`,
  courses_documents_table_title_type: `סוג`,

  courses_documents_type_preset_material: `מסמכי הדרכה מוכנים מראש`,
  courses_documents_type_atendee_material: `חומר לימוד למשתתף`,
  courses_documents_type_external_material: `חומר לימוד חיצוני`,
  courses_documents_type_internal: `מסמך פנימי`,
  courses_documents_type_other: `אחר`,

  courses_documents_add_dialog_title: `הוספת או עריכת מסמך`,
  courses_documents_add_dialog_error: `ארעה שגיאה בעת הוספת המסמך`,
  courses_documents_add_field_title: `שם המסמך`,
  courses_documents_add_field_description: `תיאור`,
  courses_documents_add_field_show_to_attendees: `מוצג למשתתפים`,
  courses_documents_add_field_type: `סוג`,
  courses_documents_add_title_upload_file: `לחץ כאן לבחירת קובץ להעלאה`,
  courses_documents_add_title_file_selected: `קובץ נבחר`,
  courses_documents_add_no_file_error: `קובץ או קישור חובה`,
  courses_documents_add_field_external_url: `קישור למסמך חיצוני`,
  courses_documents_add_external_url_disclaimer: `* אני מאשר כי קישור זה אינו מפר זכויות יוצרים של צד שלישי.`,
  

  course_type_title_summer: `הדרכת קיץ`,
  course_type_title_winter: `הדרכת חורף`,
  course_type_title_quarterly: `רבעוני`,
  course_type_title_driving: `מבחן נהיגה`,
  course_type_title_other: `אחר`,

  course_status_title_all: `הכל`,
  course_status_title_new: `חדש`,
  course_status_title_active: `פעיל`,
  course_status_title_completed: `הושלם`,
  course_status_title_canceled: `בוטל`,
  course_status_title_pending: `ממתין`,

  client_page_course_add: `הוספת הדרכה`,
  client_page_course_edit: `עריכת הדרכה`,
  client_page_course_subtitle: `הוסף או ערוך הדרכת נהגים ללקוח, ניתן לצרף מסמכים להדרכה מלשונית ״מסמכים״ במסך פרטי ההדרכה`,
  client_page_course_details: `פרטי ההדרכה`,
  client_page_course_name: `שם ההדרכה`,
  client_page_course_type: `סוג ההדרכה`,
  client_page_course_startedAt: `תאריך התחלה`,
  client_page_course_endedAt: `תאריך סיום`,
  client_page_course_ateendees_title: `בחר משתתפים`,
  client_page_course_add_all_ateendees: `הוסף את כל הנהגים`,
  error_course_add_title: `ארעה שגיאה בעת הוספת ההדרכה`,

  course_page_title: `פרטי הדרכה`,
  client_page_course_select_type: `יש לבחור את סוג ההדרכה`,
  course_details_page_title: `פרטי הדרכה`,
  course_details_page_title_name: `שם ההדרכה`,
  course_details_page_title_status: `סטטוס`,
  course_details_page_title_startedAt: `תאריך התחלה`,
  course_details_page_title_endedAt: `תאריך סיום`,
  course_details_page_title_totalRegistered: `סה"כ רשומים`,
  course_details_page_title_totalPassed: `סה"כ עברו`,
  course_details_page_title_totalFailed: `סה"כ נכשלו`,

  course_details_page_atendee_name: `שם`,
  course_details_page_atendee_status: `סטטוס`,
  course_details_page_atendee_singed: `הנהג חתם`,
  course_details_page_atendees: `רשימת משתתפים`,
  course_details_page_atendee_search: `חיפוש משתתפים`,
  course_details_page_atendee_remider: `שלח תזכורת למשתתפים`,
  course_details_page_atendee_list_empty_title: `לא נמצאו משתתפים`,
  course_details_page_edit_data: `עריכת פרטים`,
  course_details_page_atendee_notify_title: `שליחת תזכורת למשתתפים`,
  course_details_page_atendee_notify_description: `אנחנו נשלח הודעת תזכורת לכל המשתתפים שטרם קראו וחתמו על ההדרכה, האם אתה בטוח?`,
  course_details_page_atendee_notify_button: `שלח תזכורת`,
  course_details_page_atendee_remider_sent: `התזכורות נשלחו בהצלחה`,
  course_details_page_atendee_remider_error: `ארעה שגיאה בעת שליחת התזכורות`,

  course_atendee_status_title_pending: `ממתין`,
  course_atendee_status_title_registered: `רשום | ממתין`,
  course_atendee_status_title_passed: `חתם | עבר`,
  course_atendee_status_title_failed: `נכשל`,
  course_atendee_status_title_removed: `הוסר`,
  course_atendee_status_title_unknown: `לא ידוע`,
  course_details_page_download_pdf: `הורד סיכום`,
  

  // Incidents:
  vehicle_page_incidents_title: `רשימת אירועים`,
  vehicle_page_incidents_table_head_title_date_time: `תאריך ושעה`,
  vehicle_page_incidents_table_head_title: `שם האירוע`,
  vehicle_page_incidents_table_head_title_address: `כתובת`,
  vehicle_page_incidents_table_head_title_related_driver: `נהג מעורב`,
  vehicle_page_incidents_title_create_new: `הוסף תיעוד אירוע`,
  vehicle_page_incidents_list_title_download_report: `הורד סיכום`,

  incident_page_tab_info: `פרטים`,
  incident_page_title: `פרטי אירוע`,

  incident_details_page_title: `פרטי האירוע`,
  incident_details_page_title_name: `שם האירוע`,
  incident_details_page_title_date_time: `תאריך ושעה`,
  incident_details_page_title_address: `כתובת`,
  incident_details_page_title_relatedVehicleLicensePlate: `רכב מעורב`,
  incident_details_page_title_description: `תיאור האירוע`,
  incident_details_page_title_reason: `סיבת האירוע`,
  incident_details_page_title_conclusion: `מסקנות`,
  incident_details_page_title_policeNotes: `מעורבות משטרה`,
  incident_details_page_title_notes: `הערות`,
  incident_details_page_title_details: `פרטים נוספים`,
  incident_details_page_title_images: `תמונות`,

  incident_details_page_edit_data: `עריכת פרטים`,
  incident_details_page_download_pdf: `הורד דוח אירוע`,

  incident_details_involved_parties_title: `מעורבים`,
  incident_details_involved_parties_table_head_person_name: `שם`,
  incident_details_involved_parties_table_head_person_gov_id: `ת.ז`,
  incident_details_involved_parties_table_head_person_phone_number: `טלפון`,
  incident_details_involved_parties_table_head_type: `סוג`,
  incident_details_involved_parties_table_action_more_details: `פרטים נוספים`,

  incident_details_involved_parties_type_driver: `נהג`,
  incident_details_involved_parties_type_witness: `עד`,
  incident_details_involved_parties_type_passenger: `נוסע`,
  incident_details_involved_parties_type_pedestrian: `הולך רגל`,
  incident_details_involved_parties_type_other: `אחר`,

  incident_details_involved_parties_table_vehicle_info: `פרטי רכב`,
  incident_details_involved_parties_table_license_number: `מספר רישוי`,
  incident_details_involved_parties_table_manufacturer: `יצרן`,
  incident_details_involved_parties_table_model: `דגם`,
  incident_details_involved_parties_table_manufacturer_date: `שנת יצור`,
  incident_details_involved_parties_table_vehicle_type: `סוג רכב`,
  incident_details_involved_parties_table_vehicle_owner_name: `שם בעל הרכב`,
  incident_details_involved_parties_table_vehicle_owner_id: `ת.ז בעל הרכב`,
  incident_details_involved_parties_table_vehicle_owner_phone_number: `טלפון בעל הרכב`,
  incident_details_involved_parties_table_vehicle_owner_address: `כתובת בעל הרכב`,

  incident_details_involved_parties_table_insurance_info: `פרטי ביטוח`,
  incident_details_involved_parties_table_insurance_company: `חברת ביטוח`,
  incident_details_involved_parties_table_insurance_policy_number: `מספר פוליסה`,
  incident_details_involved_parties_table_insurance_agent_name: `שם סוכן`,

  vehicle_incident_edit: `עריכת פרטי אירוע`,
  vehicle_incident_add: `תיעוד אירוע`,
  error_incident_add_title: `ארעה שגיאה בעת הוספת האירוע`,
  vehicle_incident_add_edit_details: `פרטי האירוע`,
  vehicle_incident_add_edit_title: `כותרת`,
  vehicle_incident_add_edit_date_time: `תאריך ושעה`,
  vehicle_incident_add_edit_related_driver_id: `נהג מעורב`,
  vehicle_incident_add_edit_address: `כתובת`,
  vehicle_incident_add_edit_more_details: `פרטים נוספים`,
  vehicle_incident_add_edit_description: `תיאור`,
  vehicle_incident_add_edit_reason: `סיבה`,
  vehicle_incident_add_edit_conclusion: `מסקנות`,
  vehicle_incident_add_edit_police_notes: `מעורבות משטרה`,
  vehicle_incident_add_edit_notes: `הערות`,
  vehicle_incident_add_edit_photos: `תמונות`,
  vehicle_incident_add_edit_details_add: `הוסף`,
  vehicle_incident_add_edit_details_edit: `ערוך`,

  vehicle_incident_add_edit_involved_parties: `מעורבים`,
  vehicle_incident_add_edit_involved_parties_add: `הוספת מעורב חדש`,
  vehicle_incident_add_edit_involved_parties_vehicle_details: `פרטי רכב`,
  vehicle_incident_add_edit_involved_party_person_details: `פרטי אדם מעורב`,
  vehicle_incident_add_edit_involved_party_type: `סוג מעורב`,
  vehicle_incident_add_edit_involved_party_person_name: `שם`,
  vehicle_incident_add_edit_involved_party_person_gov_id: `ת.ז`,
  vehicle_incident_add_edit_involved_party_person_license_number: `מספר רישיון נהיגה`,
  vehicle_incident_add_edit_involved_party_person_email: `אימייל`,
  vehicle_incident_add_edit_involved_party_person_phone_number: `טלפון`,
  vehicle_incident_add_edit_involved_party_person_address: `כתובת`,
  vehicle_incident_add_edit_involved_parties_use_person_details_for_vehicle_owner: `השתמש בפרטי אדם מעורב כבעל רכב`,
  vehicle_incident_add_edit_involved_parties_vehicle_owner_name: `שם בעל הרכב`,
  vehicle_incident_add_edit_involved_parties_vehicle_owner_gov_id: `ת.ז בעל הרכב`,
  vehicle_incident_add_edit_involved_parties_vehicle_owner_phone_number: `טלפון בעל הרכב`,
  vehicle_incident_add_edit_involved_parties_vehicle_owner_address: `כתובת בעל הרכב`,
  vehicle_incident_add_edit_involved_parties_insurance_details: `פרטי ביטוח`,
  vehicle_incident_add_edit_involved_parties_insurance_company: `חברת ביטוח`,
  vehicle_incident_add_edit_involved_parties_insurance_policy_number: `מספר פוליסה`,
  vehicle_incident_add_edit_involved_parties_insurance_agent_name: `שם סוכן`,

  vehicle_tachograph_page_title: `רשומות טכוגרף`,
  vehicle_tachograph_page_title_add_new: `הוספת תיעוד טכוגרף`,
  vehicle_tachograph_page_title_download_summary: `הורד סיכום`,
  vehicle_tachograph_page_calendar_title_year: `בחר שנה`,
  vehicle_tachograph_page_calendar_title_month: `בחר חודש`,
  vehicle_tachograph_page_calendar_select_driver: `בחר נהג`,
  vehicle_tachograph_page_calendar_all_drivers: `כל הנהגים`,
  vehicle_tachograph_edit: `עריכת פרטים`,
  vehicle_tachograph_add: `הוספת תיעוד`,
  vehicle_tachograph_add_edit_title_start_date: `תאריך התחלה`,
  vehicle_tachograph_add_edit_title_end_date: `תאריך סיום`,
  error_tachograph_add_title: `ארעה שגיאה בעת הוספת הרשומה`,
  vehicle_tachograph_add_edit_title_startDateTime: `זמן תחילת נסיעה`,
  vehicle_tachograph_add_edit_title_endDateTime: `זמן סיום נסיעה`,
  vehicle_tachograph_add_edit_title_odometerStart: `מד אוץ תחילת נסיעה`,
  vehicle_tachograph_add_edit_title_odometerEnd: `מד אוץ סיום נסיעה`,
  vehicle_tachograph_add_edit_title_isOverspeeding: `חריגת מהירות`,
  vehicle_tachograph_add_edit_title_maxSpeed: `מהירות מרבית`,
  vehicle_tachograph_add_edit_title_isOvertime: `חריגת שעות`,
  vehicle_tachograph_add_edit_title_totalHours: `סה"כ שעות`,
  vehicle_tachograph_add_edit_title_relatedDriverId: `בחירת נהג`,
  vehicle_tachograph_add_edit_title_notes: `הערות`,

};

export default he;
