import { useNavigate } from "react-router-dom";

// @mui
import { Grid, Stack, Dialog } from "@mui/material";
//
import { useState } from "react";
import ProfileAbout from "./ProfileAbout";
import ProfileVheicleDriversSummery from "./ProfileVheicleDriversSummery";
import { Client, ExpiredVehicleHeader } from "../../../../api";
import DeleteConfimDialog from "../../common/views/DeleteConfimDialog";
import ClientsListNewEditForm from "../../clientsList/ClientsListNewEditForm";
import LoadingView from "../../../components/loading-view/LoadingView";
import { useAnalyticsContext } from "../../../../app/firebase/useAnalyticsContext";
import ClientVehiclesExpiredTests from "../../vehicles/list/ClientVehiclesExpiredTests";

// ----------------------------------------------------------------------

type Props = {
  translate: Function;
  data: Client | null;
  expiredTestVehicleHeaders: ExpiredVehicleHeader[];
  isLoadingExpiredTests: boolean;
  onDataChanged?: Function;
  onDeleteClicked?: Function;
};

export default function ClientPageProfile({
  translate,
  data,
  expiredTestVehicleHeaders,
  isLoadingExpiredTests,
  onDataChanged,
  onDeleteClicked,
}: Props) {
  const { logEvent } = useAnalyticsContext();
  const navigate = useNavigate();

  // ------------------- Delete Client Dialog -------------------
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  // const handleOpenDeleteConfirm = () => {
  //   setOpenDeleteConfirm(true);
  // };

  const handleCloseDeleteConfirm = () => {
    setOpenDeleteConfirm(false);
  };

  const handleDeleteClick = () => {
    if (onDeleteClicked) {
      onDeleteClicked();
    }
    handleCloseDeleteConfirm();
  };

  // ------------------- Edit Client Dialog -------------------
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const handleEditClicked = () => {
    setIsEditDialogOpen(true);
    logEvent("client_page_edit_clicked");
  };

  const handleEditClose = () => {
    setIsEditDialogOpen(false);
  };

  const handleOnEdit = () => {
    if (onDataChanged) {
      onDataChanged();
    }
    handleEditClose();
  };

  return data ? (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Stack spacing={3}>
            <ProfileVheicleDriversSummery
              translate={translate}
              drivers={data?.totalDrivers || 0}
              vehicles={data?.totalVehicles || 0}
            />

            <ProfileAbout
              onEditClick={handleEditClicked}
              translate={translate}
              data={{
                email: data.email || "",
                phoneNumber: data.phoneNumber,
                address: data.address,
              }}
            />

            {data.contacts && data.contacts.length > 0 && (
              <Stack direction="row">
                {data.contacts.map((contact) => (
                  <ProfileAbout
                    key={contact.name}
                    translate={translate}
                    data={{
                      name: contact.name,
                      email: contact.email,
                      phoneNumber: contact.phone,
                      address: contact.address,
                    }}
                  />
                ))}
              </Stack>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12} md={8}>
          {expiredTestVehicleHeaders &&
            expiredTestVehicleHeaders.length > 0 && (
              <ClientVehiclesExpiredTests
                currentClientId={data.id}
                showRelatedClient={false}
                vehiclesData={expiredTestVehicleHeaders}
                isLoading={isLoadingExpiredTests}
                translate={translate}
                navigate={navigate}
              />
            )}
        </Grid>
      </Grid>

      <DeleteConfimDialog
        translate={translate}
        isOpen={openDeleteConfirm}
        onClose={handleCloseDeleteConfirm}
        onAction={handleDeleteClick}
      />

      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isEditDialogOpen}
        onClose={handleEditClose}
      >
        <ClientsListNewEditForm
          isEdit
          currentClient={data}
          translate={translate}
          onComplete={handleOnEdit}
        />
      </Dialog>
    </>
  ) : (
    <LoadingView />
  );
}
