import { NavigateFunction, useNavigate } from "react-router-dom";

// @mui
import {
  Box,
  Stack,
  Card,
  Typography,
  CardHeader,
  IconButton,
  ListItem,
  List,
  ListItemButton,
} from "@mui/material";
import { Circle, DeleteOutline } from "@mui/icons-material";
// utils
import {
  getAlertColor,
  getAlertVehicleDocumentDescription,
  getAlertTitle,
  getAlertDriverDocumentDescription,
  getAlertDriverLicenseDescription,
  getAlertVehicleTestExpiredDescription,
} from "../../../utils/models/alertsUtils";
import {
  fDate,
  fDateFromSeconds,
  fToNow,
} from "../../../utils/format/formatTime";
import {
  Alert,
  AlertDriverDocumentPayload,
  AlertDriverLicenseExpiredPayload,
  AlertType,
  AlertVehicleDocumentPayload,
  AlertVehicleExpiredTestPayload,
} from "../../../../api";
import GenericMessageView from "../../common/views/GenericMessageView";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { VEHICLE_PAGE_TABS_IDS } from "../../vehicles/VehiclePage";
import { DRIVER_PAGE_TABS_IDS } from "../../drivers/DriverPage";
import LoadingView from "../../../components/loading-view/LoadingView";

// ----------------------------------------------------------------------

function AlertItem({
  alertData,
  title,
  description,
  expairedTitle,
  isLast,
  markAlertAsRead,
  handleRowClick,
}: {
  alertData: Alert;
  title: string;
  description?: string;
  expairedTitle?: string;
  isLast?: boolean;
  markAlertAsRead: (_alertId: string) => void;
  handleRowClick?: (_alertData: Alert) => void;
}) {

  return (
    <ListItem
      key={alertData.id}
      sx={{ cursor: "pointer" }}
      secondaryAction={
        <IconButton
          color={"default"}
          onClick={() => {
            markAlertAsRead(alertData.id);
          }}
        >
          <DeleteOutline />
        </IconButton>
      }
      divider={!isLast}
      disablePadding
    >
      <ListItemButton
        onClick={() => {
          if (handleRowClick) {
            handleRowClick(alertData);
          }
        }}
      >
        <Stack spacing={0}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Circle
              sx={{
                width: 12,
                height: 12,
                borderRadius: 1.5,
                flexShrink: 0,
                color: getAlertColor(alertData.severity),
              }}
            />

            <Box>
              <Typography color="inherit" variant="subtitle2">
                {title}
              </Typography>
            </Box>
          </Stack>
          <Box
            sx={{
              paddingInlineStart: 2.6,
            }}
          >
            {description && (
              <Typography color="inherit" variant="body2">
                {description}
              </Typography>
            )}

            {expairedTitle && (
              <Typography variant="subtitle2" sx={{ color: "red" }}>
                {expairedTitle}
              </Typography>
            )}
          </Box>
        </Stack>
      </ListItemButton>
    </ListItem>
  );
}

// ----------------------------------------------------------------------

function AlertItemVehicle({
  translate,
  clientId,
  alert,
  isLast,
  markAlertAsRead,
  navigate,
}: {
  translate: Function;
  clientId?: string;
  alert: Alert;
  isLast?: boolean;
  markAlertAsRead: (_alertId: string) => void;
  navigate: NavigateFunction;
}) {
  let alertVehicleDocument: AlertVehicleDocumentPayload | undefined;
  if (alert.payloadJson) {
    alertVehicleDocument = JSON.parse(alert.payloadJson);
  }

  const onAlertItemClick = (_alertData: Alert) => {
    if (clientId && alertVehicleDocument?.vehicleId) {
      navigate(
        PATH_DASHBOARD.management.clients.vheicle.path(
          clientId,
          alertVehicleDocument?.vehicleId,
          VEHICLE_PAGE_TABS_IDS.documents
        )
      );
    }
  };

  return (
    <AlertItem
      alertData={alert}
      title={getAlertTitle(alert, translate)}
      description={getAlertVehicleDocumentDescription(
        alertVehicleDocument,
        translate
      )}
      expairedTitle={`${translate("alert_vehicle_document_expired_expired_at")} - ${fDate(alertVehicleDocument?.vehicleDocument?.expirationDate ?? "")}`}
      markAlertAsRead={markAlertAsRead}
      handleRowClick={onAlertItemClick}
      isLast={isLast}
    />
  );
}

// ----------------------------------------------------------------------

function AlertItemVehicleExpire({
  translate,
  clientId,
  alert,
  isLast,
  markAlertAsRead,
  navigate,
}: {
  translate: Function;
  clientId?: string;
  alert: Alert;
  isLast?: boolean;
  markAlertAsRead: (_alertId: string) => void;
  navigate: NavigateFunction;
}) {
  let alertVehicle: AlertVehicleExpiredTestPayload | undefined;
  if (alert.payloadJson) {
    alertVehicle = JSON.parse(alert.payloadJson);
  }

  const onAlertItemClick = () => {
    if (clientId && alertVehicle?.vehicleId) {
      navigate(
        PATH_DASHBOARD.management.clients.vheicle.path(
          clientId,
          alertVehicle?.vehicleId,
          VEHICLE_PAGE_TABS_IDS.reports
        )
      );
    }
  };

  return (
    <AlertItem
      alertData={alert}
      title={getAlertTitle(alert, translate)}
      description={getAlertVehicleTestExpiredDescription(
        alertVehicle,
        translate
      )}
      expairedTitle={`${translate("alert_vehicle_test_expired_expire_date")} - ${fDateFromSeconds(Number(alertVehicle?.lastReportId))} (${fToNow(Number(alertVehicle?.lastReportId) * 1000)})`}
      markAlertAsRead={markAlertAsRead}
      handleRowClick={onAlertItemClick}
      isLast={isLast}
    />
  );
}

// ----------------------------------------------------------------------

function AlertItemDriver({
  translate,
  clientId,
  alert,
  isLast,
  markAlertAsRead,
  navigate,
}: {
  translate: Function;
  clientId?: string;
  alert: Alert;
  isLast?: boolean;
  markAlertAsRead: (_alertId: string) => void;
  navigate: NavigateFunction;
}) {
  let alertDriverPayload: AlertDriverDocumentPayload | undefined;
  if (alert.payloadJson) {
    alertDriverPayload = JSON.parse(alert.payloadJson);
  }

  const onAlertItemClick = () => {
    if (clientId && alertDriverPayload?.driverId) {
      navigate(
        PATH_DASHBOARD.management.clients.driver.path(
          clientId,
          alertDriverPayload?.driverId,
          DRIVER_PAGE_TABS_IDS.documents
        )
      );
    }
  };

  return (
    <AlertItem
      alertData={alert}
      title={getAlertTitle(alert, translate)}
      description={getAlertDriverDocumentDescription(
        alertDriverPayload,
        translate
      )}
      expairedTitle={`${translate("alert_driver_document_expired_expired_at")} - ${fDate(alertDriverPayload?.driverDocument?.expirationDate ?? "")}`}
      markAlertAsRead={markAlertAsRead}
      handleRowClick={onAlertItemClick}
      isLast={isLast}
    />
  );
}

function AlertItemDriverLicense({
  translate,
  clientId,
  alert,
  isLast,
  markAlertAsRead,
  navigate,
}: {
  translate: Function;
  clientId?: string;
  alert: Alert;
  isLast?: boolean;
  markAlertAsRead: (_alertId: string) => void;
  navigate: NavigateFunction;
}) {
  let alertDriverPayload: AlertDriverLicenseExpiredPayload | undefined;
  if (alert.payloadJson) {
    alertDriverPayload = JSON.parse(alert.payloadJson);
  }

  const onAlertItemClick = () => {
    if (clientId && alertDriverPayload?.driverId) {
      navigate(
        PATH_DASHBOARD.management.clients.driver.path(
          clientId,
          alertDriverPayload?.driverId,
          DRIVER_PAGE_TABS_IDS.info
        )
      );
    }
  };

  return (
    <AlertItem
      alertData={alert}
      title={getAlertTitle(alert, translate)}
      description={getAlertDriverLicenseDescription(
        alertDriverPayload,
        translate
      )}
      expairedTitle={`${translate("alert_driver_license_expired_expired_at")} - ${fDate(alertDriverPayload?.licenseExpireDate ?? "")}`}
      markAlertAsRead={markAlertAsRead}
      handleRowClick={onAlertItemClick}
      isLast={isLast}
    />
  );
}

// ----------------------------------------------------------------------

interface Props {
  translate: Function;
  clientId?: string;
  list: Alert[];
  isLoading: boolean;
  markAlertAsRead: (_alertId: string) => void;
}

export default function ClientAlertsUpdate({
  translate,
  clientId,
  list,
  isLoading,
  markAlertAsRead,
  ...other
}: Props) {
  const navigate = useNavigate();

  const getAlertItemViewByType = (alert: Alert, isLast: boolean) => {
    switch (alert.type) {
      case AlertType.VEHICLE_DOCUMENT_EXPIRED:
        return (
          <AlertItemVehicle
            key={alert.id}
            clientId={clientId}
            alert={alert}
            translate={translate}
            markAlertAsRead={markAlertAsRead}
            navigate={navigate}
            isLast={isLast}
          />
        );
      case AlertType.VEHICLE_REPORT_ALERT:
        return (
          <AlertItemVehicleExpire
            key={alert.id}
            clientId={clientId}
            alert={alert}
            translate={translate}
            markAlertAsRead={markAlertAsRead}
            navigate={navigate}
            isLast={isLast}
          />
        );
      case AlertType.DRIVER_DOCUMENT_EXPIRED:
        return (
          <AlertItemDriver
            key={alert.id}
            clientId={clientId}
            alert={alert}
            translate={translate}
            markAlertAsRead={markAlertAsRead}
            navigate={navigate}
            isLast={isLast}
          />
        );
      case AlertType.DRIVER_GENERIC_ALERT:
        return (
          <AlertItemDriverLicense
            key={alert.id}
            clientId={clientId}
            alert={alert}
            translate={translate}
            markAlertAsRead={markAlertAsRead}
            navigate={navigate}
            isLast={isLast}
          />
        );
      default:
        return (
          <AlertItem
            key={alert.id}
            alertData={alert}
            title={getAlertTitle(alert, translate)}
            markAlertAsRead={markAlertAsRead}
            isLast={isLast}
          />
        );
    }
  };

  return (
    <Card {...other}>
      <CardHeader title={`${translate("client_page_news_updates")}`} />

      {isLoading ? (
        <LoadingView />
      ) : list.length > 0 ? (
        <List>
          {list.map((alert, index) =>
            getAlertItemViewByType(alert, index === list.length - 1)
          )}
        </List>
      ) : (
        <GenericMessageView
          subtitle={`${translate("client_page_news_updates_empty")}`}
          title={""}
        />
      )}
    </Card>
  );
}
