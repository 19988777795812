import * as Yup from "yup";
import { useEffect, useMemo, useState } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { LoadingButton } from "@mui/lab";
import { Card, Stack, Box, Typography, Grid } from "@mui/material";
import { parseISO } from "date-fns";
import { countries } from "../../assets/data/countries";
import Iconify from "../../../global/components/iconify";
import { useSnackbar } from "../../../global/components/snackbar";
import { getAddressInputFields } from "../../utils/formsUtils";
import { Address, DriverLicenseLevel, DriverType, DriverUser } from "../../../api";

import FormProvider, {
  RHFCheckbox,
  RHFDatePicker,
  RHFTextField,
  RHFAutocomplete,
  RHFMultiSelect,
  RHFMultiSelectOption,
} from "../../../global/components/hook-form";
import RepositoryDriver from "../../repositories/RepositoryDriver";
import { useAnalyticsContext } from "../../../app/firebase/useAnalyticsContext";
import { DriverLicenseCertificate } from '../../../api/api';
import {
  emailSchema,
  genericRequiredStringSchema,
  numberOnlyStringSchema,
  numberOnlyStringSchemaRequired,
} from "../../../global/utils/formValidators";

export type ClientDriversAddEditProps = {
  clientId: string;
  isEdit?: boolean;
  currentDriver?: DriverUser;
  translate: Function;
  onComplete?: Function;
};

export default function ClientDriversAddEdit({
  clientId,
  isEdit = false,
  currentDriver,
  translate,
  onComplete,
}: ClientDriversAddEditProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { logEvent } = useAnalyticsContext();

  // Remove germany from countries if in production mode:
  const isProd = process.env.NODE_ENV === "production";
  const filteredCountries = isProd
    ? countries.filter((country) => country.code !== "DE")
    : countries;

  const NewDriverSchema = Yup.object().shape({
    driverName: genericRequiredStringSchema(translate),
    govId: numberOnlyStringSchemaRequired(translate),
    email: emailSchema(translate),
    phoneNumber: numberOnlyStringSchemaRequired(translate),
    phoneCountryCode: numberOnlyStringSchemaRequired(translate),
    driverLicenseNumber: numberOnlyStringSchema(translate),
  });

  const defaultValues = useMemo(
    () => ({
      driverName: currentDriver?.fullName || "",
      govId: currentDriver?.govId || "",
      email: currentDriver?.email || "",
      driverType: currentDriver?.driverType || DriverType.OTHER,
      driverLicenseNumber: currentDriver?.licenseNumber || "",
      driverLicenseIssueDate: currentDriver?.licenseIssueDate
        ? parseISO(currentDriver?.licenseIssueDate)
        : null,
      driverLicenseExpireDate: currentDriver?.licenseExpireDate
        ? parseISO(currentDriver?.licenseExpireDate)
        : null,
      birthday: currentDriver?.birthday
        ? parseISO(currentDriver?.birthday)
        : null,
      shouldNotifyDriver: false,
      phoneNumber: currentDriver?.phoneNumber || "",
      phoneCountryCode: currentDriver?.phoneCountryCode || "972",
      street: currentDriver?.address?.street || "",
      houseNumber: currentDriver?.address?.houseNumber || "",
      city: currentDriver?.address?.city || "",
      zipCode: currentDriver?.address?.zipCode || "",
      workStartDate: currentDriver?.workStartDate
        ? parseISO(currentDriver?.workStartDate)
        : null,
      workBranch: currentDriver?.workBranch || "",
      workVehicleType: currentDriver?.workVehicleType || "",
      workJobTitle: currentDriver?.workJobTitle || "",
      workJobDevision: currentDriver?.workJobDevision || "",
      driverLicenseLevels: currentDriver?.licenseLevels || [],
      driverLicenseCertificate: currentDriver?.licenseCertificates || []
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentDriver]
  );

  const [driverLicenseLevels, setDriverLicenseLevels] = useState<RHFMultiSelectOption<DriverLicenseLevel>[]>([]);
  useEffect(() => {
    setDriverLicenseLevels(
      Object.values(DriverLicenseLevel).map((level) => ({
        id: level.toString(),
        label: `${translate(`driver_page_driver_license_levels_${level.toLowerCase()}`)}`,
        value: level,
      }))
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDriver]);

  const [driverLicenseCertificate, setDriverLicenseCertificate] = useState<RHFMultiSelectOption<DriverLicenseCertificate>[]>([]);
  useEffect(() => {
    setDriverLicenseCertificate(
      Object.values(DriverLicenseCertificate).map((level) => ({
        id: level.toString(),
        label: `${translate(`driver_page_driver_license_certificate_${level.toLowerCase()}`)}`,
        value: level,
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDriver]);

  const methods = useForm({
    resolver: yupResolver(NewDriverSchema),
    defaultValues,
  });

  const {
    // reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: any) => {
    try {
      const driver: DriverUser = {
        fullName: data.driverName,
        govId: data.govId,
        email: data.email,
        driverType: data.driverType,
        licenseNumber: data.driverLicenseNumber,
        licenseIssueDate:
          data.driverLicenseIssueDate?.toISOString() || undefined,
        licenseExpireDate:
          data.driverLicenseExpireDate?.toISOString() || undefined,
        birthday: data.birthday?.toISOString() || undefined,
        phoneNumber: data.phoneNumber,
        phoneCountryCode: data.phoneCountryCode,
        address:
          data.street || data.houseNumber || data.city || data.zipCode
            ? ({
              street: data.street,
              houseNumber: data.houseNumber,
              city: data.city,
              zipCode: data.zipCode,
            } as Address)
            : undefined,
        workStartDate: data.workStartDate
          ? new Date(data.workStartDate).toISOString()
          : undefined,
        workBranch: data.workBranch,
        workVehicleType: data.workVehicleType,
        workJobTitle: data.workJobTitle,
        workJobDevision: data.workJobDevision,
        licenseLevels: data.driverLicenseLevels,
        licenseCertificates: data.driverLicenseCertificate
      };

      let result;
      if (!isEdit) {
        result = await RepositoryDriver().addDriver(
          clientId,
          data.shouldNotifyDriver === true,
          driver
        );
      } else if (currentDriver?.id) {
        result = await RepositoryDriver().updateDriver(
          currentDriver?.id,
          driver
        );
      } else {
        result = undefined;
      }

      if (result) {
        if (result === 204) {
          enqueueSnackbar(`${translate("form_message_already_exists")}`, {
            variant: "warning",
          });
        } else {
          enqueueSnackbar(
            !isEdit
              ? `${translate("form_message_success_message")}`
              : `${translate("form_message_update_success_message")}`
          );

          // Wait for 1 second before closing the dialog
          await new Promise((resolve) => setTimeout(resolve, 500));
          if (onComplete) {
            onComplete();
          }
        }
      } else {
        enqueueSnackbar(`${translate("error_driver_add_title")}`, {
          variant: "error",
        });
      }

      logEvent("client_driver_add_edit", {
        clientId,
        driverId: currentDriver?.id,
        isEdit,
        result:
          result === 204 ? "already_exists" : result ? "success" : "error",
      });
    } catch (error) {
      enqueueSnackbar(`${translate("error_driver_add_title")}`, {
        variant: "error",
      });
    }
  };

  function ClientDriversAddEditForm() {
    return (
      <Stack>
        <Stack direction={'row'} justifyContent="space-between">
          <Stack>
            <Typography variant="h4">{`${translate(
              isEdit ? "client_page_driver_edit" : "client_page_driver_add"
            )}`}</Typography>
            <Typography variant="body2">{`${translate(
              isEdit
                ? "client_page_driver_subtitle_edit"
                : "client_page_driver_subtitle"
            )}`}</Typography>
          </Stack>


          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ minWidth: 120 }}
          >
            {!isEdit
              ? `${translate("global_add_new")}`
              : `${translate("global_save")}`}
          </LoadingButton>
        </Stack>


        <Grid container spacing={3}>
          {/* Driver Details */}

          <Grid item md={6}>
            {/** Driver Details */}
            <Stack>
              {/** Details */}
              <Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>
                {`${translate("client_page_driver_details")}`}
              </Typography>
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: "repeat(2, 1fr)",
                  sm: "repeat(2, 1fr)",
                }}
              >
                <RHFTextField
                  name="driverName"
                  label={`${translate("client_page_driver_name")}`}
                />
                <RHFTextField
                  disabled={isEdit}
                  name="govId"
                  label={`${translate("global_id_number")}`}
                />

                <RHFAutocomplete
                  name="phoneCountryCode"
                  label={`${translate("global_country_code")}`}
                  options={filteredCountries.map((country) => country.phone)}
                  placeholder={`${translate("global_country_code")}`}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => {
                    const { code, label, phone } = filteredCountries.filter(
                      (country) => country.phone === option
                    )[0];

                    if (!phone) {
                      return null;
                    }

                    return (
                      <li {...props} key={phone}>
                        <Iconify
                          key={label}
                          icon={`circle-flags:${code.toLowerCase()}`}
                          width={28}
                          sx={{ mr: 1 }}
                        />
                        {`${translate(`country_lable_${code}`)} ${phone}+`}
                      </li>
                    );
                  }}
                />

                <RHFTextField
                  name="phoneNumber"
                  label={`${translate("global_phone")}`}
                />

                <RHFAutocomplete
                  name="driverType"
                  label={`${translate("client_page_driver_type")}`}
                  options={Object.values(DriverType)}
                  placeholder={`${translate("client_page_driver_type")}`}
                  getOptionLabel={(option) =>
                    `${translate(`driver_type_${option.toLowerCase()}`)}`
                  }
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => (
                    <li {...props}>
                      {`${translate(`driver_type_${option.toLowerCase()}`)}`}
                    </li>
                  )}
                />

                <RHFDatePicker
                  name="birthday"
                  label={`${translate("client_page_driver_birthday")}`}
                />

                <RHFTextField
                  name="email"
                  label={`${translate("global_email")}`}
                />
              </Box>

              {/** Address */}
              <Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>
                {`${translate("driver_page_driver_address")}`}
              </Typography>
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: "repeat(2, 1fr)",
                  sm: "repeat(2, 1fr)",
                }}
              >
                {getAddressInputFields.map((field) => (
                  <RHFTextField
                    key={field.name}
                    name={field.name}
                    label={`${translate(field.lablel_key)}`}
                  />
                ))}


              </Box>
            </Stack>
          </Grid>

          {/* Work & License Details */}
          <Grid item md={6}>
            <Stack justifyContent="space-between">
              {/** License Details */}
              <Stack>
                <Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>
                  {`${translate("driver_page_driver_license_details")}`}
                </Typography>
                <Box
                  rowGap={3}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: "repeat(2, 1fr)",
                    sm: "repeat(2, 1fr)",
                  }}
                >
                  <RHFTextField
                    name="driverLicenseNumber"
                    label={`${translate("client_page_driver_license")}`}
                  />

                  <RHFDatePicker
                    name="driverLicenseIssueDate"
                    label={`${translate("client_page_driver_license_issue_date")}`}
                  />

                  <RHFDatePicker
                    name="driverLicenseExpireDate"
                    label={`${translate("client_page_driver_license_expiration")}`}
                  />

                  <RHFMultiSelect
                    checkbox
                    name="driverLicenseLevels"
                    label={`${translate("driver_page_driver_license_levels")}`}
                    options={driverLicenseLevels}
                  />

                  {
                    driverLicenseCertificate.length > 0 && (<RHFMultiSelect
                      checkbox
                      name="driverLicenseCertificate"
                      label={`${translate("driver_page_driver_license_certificates")}`}
                      options={driverLicenseCertificate}
                    />)
                  }

                </Box>
              </Stack>

              {/* Work Details */}
              <Stack>
                {/** Driver Work Related */}
                <Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>
                  {`${translate("client_page_driver_details_work")}`}
                </Typography>
                <Box
                  rowGap={3}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                  }}
                >
                  <RHFDatePicker
                    name="workStartDate"
                    label={`${translate("client_page_driver_work_start_date")}`}
                  />

                  <RHFTextField
                    name="workBranch"
                    label={`${translate("client_page_driver_work_branch")}`}
                  />

                  <RHFTextField
                    name="workVehicleType"
                    label={`${translate("client_page_driver_work_vehicle_type")}`}
                  />

                  <RHFTextField
                    name="workJobTitle"
                    label={`${translate("client_page_driver_work_job_title")}`}
                  />

                  <RHFTextField
                    name="workJobDevision"
                    label={`${translate("client_page_driver_work_job_devision")}`}
                  />
                </Box>

                {!isEdit && (
                  <RHFCheckbox
                    sx={{ marginTop: 3 }}
                    name="shouldNotifyDriver"
                    label={`${translate("client_page_driver_notify")}`}
                  />
                )}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    );
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ p: 3 }}>
        <ClientDriversAddEditForm />


      </Card>
    </FormProvider>
  );
}
