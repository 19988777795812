import * as Yup from "yup";

import { useEffect, useState, useMemo } from "react";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box, Stack, Typography } from "@mui/material";
import { parseISO } from "date-fns";
import { LoadingButton } from "@mui/lab";

import FormProvider, {
  RHFDatePicker,
  RHFMultiSelect,
  RHFMultiSelectOption,
  RHFTextField,
} from "../../../global/components/hook-form";

import { useLocales } from "../../../global/locales";

import {
  emailSchema,
  genericRequiredAnySchema,
  genericRequiredDateSchema,
  numberOnlyStringSchemaRequired,
  textOnlySchemaRequired,
} from "../../../global/utils/formValidators";
import {
  Address,
  CompleteDriverData,
  DriverLicenseCertificate,
  DriverLicenseLevel,
} from "../../../api/api";
import { getAddressInputFields } from "../../../dashboard/utils/formsUtils";

export default function DriverOnboardingDetailsPage({
  driverUser,
  onSavedDriverDetails,
}: {
  driverUser: CompleteDriverData;
  onSavedDriverDetails: (_driver: CompleteDriverData) => void;
}) {
  const { translate } = useLocales();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSaved, setDataSaved] = useState(false);

  const DriverDetailsSchema = Yup.object().shape({
    driverLicenseNumber: numberOnlyStringSchemaRequired(translate),
    email: emailSchema(translate),
    driverLicenseIssueDate: genericRequiredDateSchema(translate),
    driverLicenseExpireDate: genericRequiredDateSchema(translate),
    birthday: genericRequiredDateSchema(translate),
    city: textOnlySchemaRequired(translate),
    driverLicenseLevels: genericRequiredAnySchema(translate),
  });

  const defaultValues = useMemo(
    () => ({
      email: driverUser?.email || "",
      driverLicenseNumber: driverUser?.licenseNumber || "",
      driverLicenseIssueDate: parseISO(driverUser?.licenseIssueDate || ""),
      driverLicenseExpireDate: parseISO(driverUser?.licenseExpireDate || ""),
      birthday: parseISO(driverUser?.birthday || ""),
      street: driverUser?.address?.street || "",
      houseNumber: driverUser?.address?.houseNumber || "",
      city: driverUser?.address?.city || "",
      zipCode: driverUser?.address?.zipCode || "",
      driverLicenseLevels: driverUser?.licenseLevels || [],
      driverLicenseCertificate: driverUser?.licenseCertificates || [],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [driverUser]
  );

  const [driverLicenseLevels, setDriverLicenseLevels] = useState<
    RHFMultiSelectOption<DriverLicenseLevel>[]
  >([]);
  useEffect(() => {
    setDriverLicenseLevels(
      Object.values(DriverLicenseLevel).map((level) => ({
        id: level.toString(),
        label: `${translate(`driver_page_driver_license_levels_${level.toLowerCase()}`)}`,
        value: level,
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverUser]);

  const [driverLicenseCertificate, setDriverLicenseCertificate] = useState<
    RHFMultiSelectOption<DriverLicenseCertificate>[]
  >([]);
  useEffect(() => {
    setDriverLicenseCertificate(
      Object.values(DriverLicenseCertificate).map((level) => ({
        id: level.toString(),
        label: `${translate(`driver_page_driver_license_certificate_${level.toLowerCase()}`)}`,
        value: level,
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverUser]);

  const methods = useForm({
    resolver: yupResolver(DriverDetailsSchema),
    defaultValues,
  });

  const handleOnSubmit = async (data: any) => {
    setIsLoading(true);
    setDataSaved(false);

    const updatedDriverUser: CompleteDriverData = {
      ...driverUser,
      email: data.email,
      licenseNumber: data.driverLicenseNumber,
      licenseIssueDate: data.driverLicenseIssueDate?.toISOString() || undefined,
      licenseExpireDate:
        data.driverLicenseExpireDate?.toISOString() || undefined,
      birthday: data.birthday?.toISOString() || undefined,
      address:
        data.street || data.houseNumber || data.city || data.zipCode
          ? ({
              street: data.street,
              houseNumber: data.houseNumber,
              city: data.city,
              zipCode: data.zipCode,
            } as Address)
          : undefined,
      licenseLevels: data.driverLicenseLevels,
      licenseCertificates: data.driverLicenseCertificate,
    };

    onSavedDriverDetails(updatedDriverUser);

    // delay for 1 sec:
    setTimeout(() => {
      setIsLoading(false);
      setDataSaved(true);
    }, 1500);
  };

  const {
    // reset,
    handleSubmit,
  } = methods;

  function DriverDetailsFormTitle({ text }: { text: string }) {
    return (
      <Typography variant="h5" sx={{ pt: 2 }} color={"text"}>
        {text}
      </Typography>
    );
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(handleOnSubmit)}>
      <Stack spacing={2}>
        {/** Personal Details */}
        <DriverDetailsFormTitle
          text={`${translate("client_page_driver_details")}`}
        />

        <RHFTextField
          name="email"
          type="email"
          label={`${translate("global_email")}`}
        />

        <RHFDatePicker
          name="birthday"
          label={`${translate("client_page_driver_birthday")}`}
        />

        {/** Address */}
        <DriverDetailsFormTitle
          text={`${translate("driver_page_driver_address")}`}
        />
        {getAddressInputFields.map((field) => (
          <RHFTextField
            key={field.name}
            name={field.name}
            label={`${translate(field.lablel_key)}`}
          />
        ))}

        {/** License Details */}
        <DriverDetailsFormTitle
          text={`${translate("driver_page_driver_license_details")}`}
        />

        <RHFTextField
          name="driverLicenseNumber"
          type="number"
          label={`${translate("client_page_driver_license")}`}
        />

        <RHFDatePicker
          name="driverLicenseIssueDate"
          label={`${translate("client_page_driver_license_issue_date")}`}
        />

        <RHFDatePicker
          name="driverLicenseExpireDate"
          label={`${translate("client_page_driver_license_expiration")}`}
        />

        <RHFMultiSelect
          checkbox
          name="driverLicenseLevels"
          label={`${translate("driver_page_driver_license_levels")}`}
          options={driverLicenseLevels}
        />

        {driverLicenseCertificate.length > 0 && (
          <RHFMultiSelect
            checkbox
            name="driverLicenseCertificate"
            label={`${translate("driver_page_driver_license_certificates")}`}
            options={driverLicenseCertificate}
          />
        )}

        {/**
         * Space
         */}
        <Box sx={{ height: 1 }} />

        <LoadingButton
          type="submit"
          loading={isLoading}
          variant="contained"
          color={dataSaved ? "success" : "primary"}
        >
          {dataSaved
            ? `${translate("form_message_update_success_message")}`
            : `${translate("global_save_changes")}`}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
