import { Helmet } from "react-helmet-async";
import { Container, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { InsertDriveFile, Pages, School } from "@mui/icons-material";
import ConfirmDialog from "../../components/confirm-dialog/ConfirmDialog";
import {
  useDashboardAppDispatch,
  useDashboardAppSelector,
} from "../../hooks/useRedux";
import { useLocales } from "../../../global/locales";

import { DashboardRootState } from "../../app/dashboardAppStore";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import {
  DriverDocumentTableItem,
  deleteDriverFromApi,
  downloadDriverDocument,
  downloadDriverUploadedDocument,
  getDriverData,
  getDriverLicenseData,
  resetDriverData,
  uploadDriverLicense,
} from "./driverPageSlice";
import CommonLayout from "../common/layout/CommonLayout";
import LoadingView from "../../components/loading-view/LoadingView";
import { CommonLayoutPageCover, CommonLayoutTab } from "../common/layout/types";
import DriverDetailsPage from "./details/DriverDetailsPage";
import { PATH_DASHBOARD } from "../../routes/paths";
import {
  UploadDriverLicenseReq,
  DriverDocumentUpload,
  WebAppFeature,
} from "../../../api";
import { getClientVehiclesFromApi } from "../clientPage/main/clientPageSlice";
import { useSnackbar } from "../../../global/components/snackbar";
import DriverDocumentsPage from "./documents/DriverDocumentsPage";
import { CLIENT_PAGE_TABS_IDS } from "../clientPage/main/ClientPage";
import DashboardAppFeaturesGuard from "../../app/featuresConfig/DashboardAppFeaturesGuard";
import DriverCoursesListPage from "./courses/DriverCoursesListPage";

export const DRIVER_PAGE_TABS_IDS = {
  info: "info",
  documents: "documents",
  courses: "courses",
};

export default function DriverPage() {
  const { enqueueSnackbar } = useSnackbar();

  const { clientId, driverId } = useParams<string>();
  const dispatch = useDashboardAppDispatch();

  const { translate } = useLocales();

  const isLoadingData = useDashboardAppSelector(
    (state: DashboardRootState) => state.driverPageSlice.isLoadingData
  );

  const isDownloadingFile = useDashboardAppSelector(
    (state: DashboardRootState) => state.driverPageSlice.isDownloadingFile
  );

  const driverData = useDashboardAppSelector(
    (state: DashboardRootState) => state.driverPageSlice.data
  );

  const driverLicenseRes = useDashboardAppSelector(
    (state: DashboardRootState) => state.driverPageSlice.driverLicense
  );

  const clientVehiclesHeaders = useDashboardAppSelector(
    (state: DashboardRootState) => state.clientPageSlice.vehicles
  );

  const getData = () => {
    if (clientId && driverId) {
      dispatch(getDriverData({ driverId }));
      dispatch(getDriverLicenseData({ driverId }));
      if (clientVehiclesHeaders.length === 0) {
        dispatch(getClientVehiclesFromApi({ clientId, fromArchive: false }));
      }
    }
  };

  const onDeleteDriver = () => {
    if (driverId) {
      dispatch(
        deleteDriverFromApi({
          driverId,
          onCompleted: (isSuccess: boolean) => {
            if (isSuccess) {
              enqueueSnackbar(
                `${translate("driver_page_delete_success_message")}`,
                {
                  variant: "success",
                }
              );
              if (clientId) {
                setTimeout(() => {
                  window.location.href = `${PATH_DASHBOARD.management.clients.client(
                    clientId,
                    CLIENT_PAGE_TABS_IDS.drivers
                  )}`;
                }, 2000);
              }
            } else {
              enqueueSnackbar(
                `${translate("driver_page_delete_error_message")}`,
                {
                  variant: "error",
                }
              );
            }
          },
        })
      );
    }
  };

  const onDriverDataChanged = () => {
    getData();
  };

  const uploadDriverData = (req: UploadDriverLicenseReq) => {
    dispatch(uploadDriverLicense({ ploadDriverLicenseReq: req }));
  };

  const handleDownloadDoc = (documentItem: DriverDocumentTableItem) => {
    if (driverId) {
      if (!documentItem.isUploaded) {
        dispatch(
          downloadDriverDocument({ driverId, documentId: documentItem.id })
        );
      } else if (
        (documentItem.raw as DriverDocumentUpload).documentType !== undefined
      ) {
        dispatch(
          downloadDriverUploadedDocument({
            driverUserId: driverId,
            documentType: (documentItem.raw as DriverDocumentUpload)
              .documentType!,
            documentId: documentItem.id,
            onRespose: (responseVehicleDocument: DriverDocumentUpload) => {
              if (responseVehicleDocument.fileUrl != null) {
                window.open(responseVehicleDocument.fileUrl, "_blank");
              }
            },
          })
        );
      }
    }
  };

  // Get the data:
  useEffect(() => {
    getData();
    return () => {
      dispatch(resetDriverData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverId]);

  const TABS: CommonLayoutTab[] = [
    {
      value: DRIVER_PAGE_TABS_IDS.info,
      label: `${translate("driver_page_tab_info")}`,
      icon: <Pages />,
      component: (
        <>
          {driverData && clientId ? (
            <DriverDetailsPage
              clientId={clientId}
              driver={driverData}
              driverLicenseRes={driverLicenseRes}
              translate={translate}
              onDataChanged={onDriverDataChanged}
              onDeleteDriver={onDeleteDriver}
              uploadDriverData={uploadDriverData}
            />
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      value: DRIVER_PAGE_TABS_IDS.documents,
      label: `${translate("driver_page_tab_documents")}`,
      icon: <InsertDriveFile />,
      component: (
        <DriverDocumentsPage
          isLoading={isLoadingData.driverDocuments}
          clientId={clientId}
          driver={driverData}
          translate={translate}
          onDocListRowClicked={handleDownloadDoc}
        />
      ),
    },
    {
      value: DRIVER_PAGE_TABS_IDS.courses,
      label: `${translate("driver_page_tab_course")}`,
      icon: <School />,
      component: (
        <DashboardAppFeaturesGuard
          showError
          appFeature={WebAppFeature.COURSES}
        >
          <DriverCoursesListPage
            clientId={clientId}
            driverId={driverData?.id}
            translate={translate}
          />
        </DashboardAppFeaturesGuard>
      ),
    },
  ];

  const coverData: CommonLayoutPageCover = {
    title: `${driverData?.fullName || ""}`,
    subtitle: `${driverData?.govId}`,
  };

  return driverData && clientId ? (
    <>
      <Helmet>
        <title>
          {`${translate("driver_page_details_title")}`} |{" "}
          {`${driverData.fullName || ""}`}
        </title>
      </Helmet>

      <Container maxWidth="xl">
        <CustomBreadcrumbs
          heading={`${translate("driver_page_details_title")}`}
          links={[
            {
              name: `${translate("global_back_to_client")}`,
              href: `${PATH_DASHBOARD.management.clients.client(
                clientId,
                CLIENT_PAGE_TABS_IDS.drivers
              )}`,
            },
            {
              name: `${driverData.fullName}`,
            },
          ]}
        />
        <CommonLayout tabs={TABS} converData={coverData} />
      </Container>

      {isDownloadingFile && (
        <ConfirmDialog
          open={isDownloadingFile}
          onClose={() => {}}
          title={`${translate("global_downloading")}`}
          content={
            <Stack spacing={2}>
              <Typography variant="subtitle1" align="center">
                {`${translate("global_downloading_file_message")}`}
              </Typography>
              <LoadingView showLogo={false} />
            </Stack>
          }
          action={<div />}
        />
      )}
    </>
  ) : (
    <LoadingView showLogo showTruck />
  );
}
