// @mui
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import { Button, IconButton, Stack } from "@mui/material";
import { Delete, Download, RemoveRedEye } from "@mui/icons-material";

import {
  CourseDocumentBase,
  CourseDocumentCategory,
  DocumentConfigOption,
} from "../../../../api";
import Label from "../../../../global/components/label";

// ----------------------------------------------------------------------

type Props = {
  translate: Function;
  row: CourseDocumentBase;
  onViewRow: VoidFunction;
  onDeleteClick: VoidFunction;
  courseConfigOptions?: DocumentConfigOption[];
};

export default function CourseDocumentsListViewRowItem({
  translate,
  row,
  onViewRow,
  onDeleteClick,
  courseConfigOptions,
}: Props) {
  const {
    title,
    description,
    showToAttendees,
    documentType,
    documentCategory,
  } = row;

  const documentTitle = courseConfigOptions?.find(
    (opt) => opt.type === documentType
  )?.label;

  const globalNoData = `${translate("global_no_data")}`;

  return (
    <>
      <TableRow>
        {/** title */}
        <TableCell>
          <ListItemText
            primary={<Typography variant="body2">{title}</Typography>}
          />
        </TableCell>

        {/** description */}
        <TableCell>
          <ListItemText
            primary={
              <Typography variant="body2">
                {description && description.length > 1
                  ? description
                  : globalNoData}
              </Typography>
            }
          />
        </TableCell>

        {/** showToAttendees */}
        <TableCell>
          <ListItemText
            primary={
              <Typography variant="body2">
                {showToAttendees
                  ? translate("global_yes")
                  : translate("global_no")}
              </Typography>
            }
          />
        </TableCell>

        {/** documentType */}
        <TableCell>
          <Label variant="soft">{documentTitle ?? globalNoData}</Label>
        </TableCell>

        {/** deleteIcon */}
        <TableCell>
          <Stack spacing={1} direction={"row"} justifyContent={"space-between"}>
            {/** deleteIcon */}
            <Button
              onClick={onViewRow}
              color={"primary"}
              variant="contained"
              startIcon={
                row.documentType !==
                CourseDocumentCategory.EXTERNAL_MATERIAL ? (
                  <Download />
                ) : (
                  <RemoveRedEye />
                )
              }
            >
              {`${translate(`${row.documentType === CourseDocumentCategory.EXTERNAL_MATERIAL ? "global_view" : "global_download"}`)}`}
            </Button>

            {/** deleteIcon */}
            {documentCategory !== CourseDocumentCategory.PRESET_MATERIAL && (
              <IconButton
                onClick={(_e) => {
                  onDeleteClick();
                }}
              >
                <Delete color="error" />
              </IconButton>
            )}
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
}
