import * as katzbatApi from '../../api';
import { API_URL_BASE, genericAxiosPost, getAxionsInstance } from '../../global/repositories/RepositoryBase';

import { DocumentConfig, DocumentConfigOption, DocumentUploadImgRequestBody, DriverDocumentCreateReqV1, DriverDocumentHeader, DriverDocumentUpload, DriverDocumentUploadType } from '../../api';


export type AddDriverDocumentParams = {
  driverUserId: string;
  isHidden?: boolean;
  documentType: DriverDocumentUploadType;
  expirationDate: string | null;
  title: string;
  docuemnt: File;
};


export interface IRepositoryDriverDocuments {
  addDriverDocument: (_params: AddDriverDocumentParams) => Promise<boolean>;
  getDriverDocuments: (_params: { driverUserId: string }) => Promise<DriverDocumentUpload[] | undefined>;
  getDriverDocuemntById: (_params: { driverUserId: string, documentType: DriverDocumentUploadType, documentId: string }) => Promise<DriverDocumentUpload>;
  deleteDriverDocument: (_params: { driverUserId: string, documentType: DriverDocumentUploadType, documentId: string }) => Promise<boolean>;
  
  getDriverAvailableDocumentConfigOptions: () => Promise<DocumentConfigOption[] | null>;
  getDriverAvailableUploadDocumentsOptions: () => Promise<DocumentConfigOption[] | null>;
  
  getDriverDocumentConfig: (_params: { documentType: string, clientId: string }) => Promise<DocumentConfig | null>;
  addNewDriverDocument: (_request: DriverDocumentCreateReqV1) => Promise<DriverDocumentHeader>;
  
  getAllDriverDocumentsHeaders: (_params: { driverUserId: string }) => Promise<DriverDocumentHeader[] | null>;
  getGeneratedDriverDocumentPdf: (_params: { driverUserId: string, documentId: string }) => Promise<any | null>;
  getGeneratedDriverDocumentHtmlPreview: (_params: { driverUserId: string, documentType: string }) => Promise<string | null>;
}

export default function RepositoryDriverDocuments(): IRepositoryDriverDocuments {

  const apiDriverDocuments = (clientId?: string) => katzbatApi.DriverDocumentsApiFactory(undefined, API_URL_BASE, getAxionsInstance(clientId));

  const addDriverDocumentFromPdf = async ({
    driverUserId,
    isHidden,
    documentType,
    expirationDate,
    title,
    docuemnt
  }: AddDriverDocumentParams): Promise<boolean> => {
    const formData = new FormData();

    formData.append('file', docuemnt);

    const response = await genericAxiosPost<FormData>(
      '/driver/documents/upload_pdf',
      formData,
      {
        driverUserId,
        title,
        documentType,
        expirationDate,
        isHidden: isHidden ? 'true' : 'false',
      },
      'json'
    );

    return response.status === 200;
  }

  const convertFileToBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  })

  const addDriverDocumentFromImage = async ({
    driverUserId,
    isHidden,
    documentType,
    expirationDate,
    title,
    docuemnt
  }: AddDriverDocumentParams): Promise<boolean> => {
    // convert image to base64:
    const fileAsBase64 = await convertFileToBase64(docuemnt);
    const prefixToRemove = 'data:image/png;base64,';
    const fileAsBase64WithoutPrefix = fileAsBase64.substring(prefixToRemove.length);

    const requestBody: DocumentUploadImgRequestBody = {
      pagesDataBase64: [fileAsBase64WithoutPrefix]
    }
    const response = await genericAxiosPost<DocumentUploadImgRequestBody>(
      '/driver/documents/upload_images',
      requestBody,
      {
        driverUserId,
        title,
        documentType,
        expirationDate,
        isHidden: isHidden ? 'true' : 'false',
      },
      'json'
    );

    return response.status === 200;
  }


  // === POST: Add driver document ===
  const addDriverDocument = async ({
    driverUserId,
    isHidden,
    documentType,
    expirationDate,
    title,
    docuemnt
  }: AddDriverDocumentParams): Promise<boolean> => {
    // get the file extension:
    const fileExtension = docuemnt.name.split('.').pop();

    let result = false;
    if (fileExtension === 'pdf') {
      result = await addDriverDocumentFromPdf({
        driverUserId,
        isHidden,
        documentType,
        expirationDate,
        title,
        docuemnt
      })
    } else {
      result = await addDriverDocumentFromImage({
        driverUserId,
        isHidden,
        documentType,
        expirationDate,
        title,
        docuemnt
      })
    }

    return result;
  }

  // === GET: Get driver documents ===
  const getDriverDocuments = async ({
    driverUserId,
  }: {
    driverUserId: string,
  }): Promise<DriverDocumentUpload[] | undefined> => {
    const resutls = await apiDriverDocuments(driverUserId).driverDocumentsGetAllGet(driverUserId);
    return resutls.data;
  }

  // === GET: Get driver document by ID ===
  const getDriverDocuemntById = async ({
    driverUserId,
    documentType,
    documentId
  }: {
    driverUserId: string,
    documentType: DriverDocumentUploadType,
    documentId: string
  }) => {
    const resutls = await apiDriverDocuments().driverDocumentsGetGet(
      driverUserId,
      documentType,
      documentId
    );
    return resutls.data;
  }

  // === DELETE: Remove driver document ===
  const deleteDriverDocument = async ({
    driverUserId,
    documentType,
    documentId
  }: {
    driverUserId: string,
    documentType: DriverDocumentUploadType,
    documentId: string
  }): Promise<boolean> => {
    const resutls = await apiDriverDocuments().driverDocumentsDeleteDelete(
      driverUserId,
      documentType,
      documentId
    );
    return resutls.data;
  }

  // === GET: Get driver document config options ===
  const getDriverAvailableDocumentConfigOptions = async (): Promise<DocumentConfigOption[] | null> => {
    const resutls = await apiDriverDocuments().driverDocumentsGetDocsConfigOptionsGet();
    return resutls.data;
  }

  // === GET: Get driver document config options ===
  const getDriverAvailableUploadDocumentsOptions = async (): Promise<DocumentConfigOption[] | null> => {
    const resutls = await apiDriverDocuments().driverDocumentsGetUploadedDocsConfigOptionsGet();
    return resutls.data;
  }

  // === GET: Get driver document config ===
  const getDriverDocumentConfig = async ({
    documentType,
    clientId
  }: {
    documentType: string,
    clientId: string
  }): Promise<DocumentConfig | null> => {
    const resutls = await apiDriverDocuments(clientId).driverDocumentsGetDocConfigGet(documentType, clientId);
    return resutls.data;
  }

  // === POST: Add new driver document ===
  const addNewDriverDocument = async (request: DriverDocumentCreateReqV1): Promise<DriverDocumentHeader> => {
    const resutls = await apiDriverDocuments().driverDocumentsAddNewDriverDocumentPost(request);
    return resutls.data;
  }

  // === GET: Get all driver documents headers ===
  const getAllDriverDocumentsHeaders = async ({
    driverUserId
  }: {
    driverUserId: string
  }): Promise<DriverDocumentHeader[] | null> => {
    const resutls = await apiDriverDocuments().driverDocumentsGetAllHeadersGet(driverUserId);
    return resutls.data;
  }

  // === GET: Get generated driver document PDF ===
  const getGeneratedDriverDocumentPdf = async ({
    driverUserId,
    documentId
  }: {
    driverUserId: string,
    documentId: string
  }): Promise<any | null> => {
    const resutls = await apiDriverDocuments().driverDocumentsGetDriverDocumentPdfGet(
      driverUserId, 
      documentId,
      {
        responseType: 'arraybuffer'
      });
    return resutls.data;
  }

  // === GET: Get generated driver document HTML preview ===
  const getGeneratedDriverDocumentHtmlPreview = async ({
    driverUserId,
    documentType
  }: {
    driverUserId: string,
    documentType: string
  }): Promise<string | null> => {
    const resutls = await apiDriverDocuments().driverDocumentsGetDocPreviewGet(
      driverUserId, 
      documentType
    );
    return resutls.data;
  }


  return {
    addDriverDocument,
    getDriverDocuments,
    getDriverDocuemntById,
    deleteDriverDocument,

    getDriverAvailableDocumentConfigOptions,
    getDriverAvailableUploadDocumentsOptions,
    getDriverDocumentConfig,
    addNewDriverDocument,
    getAllDriverDocumentsHeaders,
    getGeneratedDriverDocumentPdf,
    getGeneratedDriverDocumentHtmlPreview
  };
}
