import { useState, useEffect } from "react";

import { Button, Stack } from "@mui/material";
import { Add } from "@mui/icons-material";

import { CourseDocumentBase, DocumentConfigOption } from "../../../../api";

import RichTableView from "../../common/views/richTableView/rich-table-view";
import { TableHeadItem } from "../../../components/table/TableHeadCustom";
import { RichTableFilters } from "../../common/views/richTableView/rich-table-filters-result";

import CourseDocumentsListViewRowItem from "./CourseDocumentsListViewRowItem";

// ----------------------------------------------------------------------

export type CourseDocumentsListViewProps = {
  translate: Function;
  isLoadingData: boolean;
  titleKey?: string;

  documentsHeaders: CourseDocumentBase[];
  courseConfigOptions?: DocumentConfigOption[];

  onRowClicked: (_document: CourseDocumentBase) => void;
  onDeleteClicked?: (_document: CourseDocumentBase) => void;

  // Action Button:
  onAddButtonClicked?: () => void;
};

const applyFilter = (documents: CourseDocumentBase[], filters: RichTableFilters): CourseDocumentBase[] => {
  const { globalSearch } = filters;
  
  let filteredData: CourseDocumentBase[] = [...documents];

  if (globalSearch !== "") {
    filteredData = documents.filter((itemObject: any) =>
      Object.keys(itemObject).some((key) => {
        const value = itemObject[key];
        return (
          value &&
          typeof value === "string" &&
          value.toLowerCase().includes(globalSearch.toLowerCase())
        );
      })
    );
  }
  return filteredData;
};


export default function CourseDocumentsListView({
  translate,
  isLoadingData,
  titleKey,
  documentsHeaders,
  onRowClicked,
  onDeleteClicked,
  onAddButtonClicked,
  courseConfigOptions
}: CourseDocumentsListViewProps) {
  const [filteredTableData, _setFilteredTableData] = useState(documentsHeaders);

  useEffect(() => {
    _setFilteredTableData(documentsHeaders);
  }, [documentsHeaders]);

  const handleOnTableRowClicked = (document: CourseDocumentBase) => {
    onRowClicked(document);
  };

  const TABLE_HEAD: TableHeadItem[] = [
    {
      id: "title",
      label: `${translate("courses_documents_table_title_name")}`,
    },
    {
      id: "description",
      label: `${translate("courses_documents_table_title_description")}`,
    },
    {
      id: "showToAttendees",
      label: `${translate("courses_documents_table_title_show_to_attendees")}`,
    },
    {
      id: "documentType",
      label: `${translate("courses_documents_table_title_type")}`,
    },
    {
      id: "actions",
      label: `${translate("global_actions")}`,
    }
  ];


  const handleOnApplyFilters = (filters: RichTableFilters, _dateError: boolean) => {
    _setFilteredTableData(applyFilter(documentsHeaders, filters));
  };

  const handleOnFileClicked = (document: CourseDocumentBase) => {
    if (onDeleteClicked) {
      onDeleteClicked(document);
    }
  };

  return (
    <RichTableView
      translate={translate}
      isLoadingData={isLoadingData}
      titleKey={titleKey}
      filteredTableData={filteredTableData}
      tableHeads={TABLE_HEAD}
      showEndDate={false}
      onApplyFilters={handleOnApplyFilters}
      randerRow={(item: CourseDocumentBase) => (
        <CourseDocumentsListViewRowItem
          translate={translate}
          key={item.id || ""}
          row={item}
          onViewRow={() => handleOnTableRowClicked(item)}
          onDeleteClick={() => handleOnFileClicked(item)}
          courseConfigOptions={courseConfigOptions}
        />
      )}
      onActionButtonClicked={onAddButtonClicked}
      overrideActionButtons={
        <>
        <Stack spacing={1} direction={'row'}>
            <Button
              onClick={onAddButtonClicked}
              variant="contained"
              color="success"
              startIcon={<Add />}
            >
              {`${translate("courses_documents_add_new_document")}`}
            </Button>
        </Stack>
      </>
      }
    />
  );
}
