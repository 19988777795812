import { InputLabel, MenuItem, Select } from "@mui/material";
import { DocumentConfigOption } from "../../../../api";

export const DocumentTypeSelect = ({
  translate,
  id,
  labelKey,
  currentValue,
  options,
  onSelect,
  isDisabled
}: {
  translate: Function;
  id: string;
  labelKey: string;
  currentValue: string;
  options: DocumentConfigOption[];
  onSelect: (_value: string) => void;
  isDisabled?: boolean;
}) => (
  <>
    <InputLabel id={labelKey}>{`${translate(labelKey)}`}</InputLabel>
    <Select
      disabled={isDisabled}
      fullWidth
      labelId={labelKey}
      id={id}
      value={currentValue}
      label={`${translate(labelKey)}`}
      onChange={(event) => {
        onSelect(event.target.value as string);
      }}
    >
      {options?.map((option) => (
        <MenuItem key={option.type} value={option.type}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  </>
);
