import { useEffect, useState } from "react";

import { Box, Dialog } from "@mui/material";

import {
  useDashboardAppDispatch,
  useDashboardAppSelector,
} from "../../../hooks/useRedux";
import { DriverCourseHeader } from "../../../../api";

import { DashboardRootState } from "../../../app/dashboardAppStore";
import DriverCoursesListView from "./DriverCoursesListView";
import {
  downloadCourseDocument,
  getAvailableCourseConfigOptions,
  getDriverCoursesHeaders,
} from "../driverPageSlice";
import LoadingView from "../../../components/loading-view";

type Props = {
  clientId?: string;
  driverId?: string;
  translate: Function;
};

export default function DriverCoursesListPage({
  clientId,
  driverId,
  translate,
}: Props) {
  const dispatch = useDashboardAppDispatch();

  const courseConfigOptions = useDashboardAppSelector(
    (state: DashboardRootState) => state.driverPageSlice.courseConfigOptions
  );

  const isLoadingData = useDashboardAppSelector(
    (state: DashboardRootState) => state.driverPageSlice.isLoadingData
  );

  const coursesHeadersData = useDashboardAppSelector(
    (state: DashboardRootState) => state.driverPageSlice.driverCoursesHeaders
  );

  // Get the data:
  useEffect(() => {
    if (clientId && driverId) {
      dispatch(getAvailableCourseConfigOptions());
      dispatch(getDriverCoursesHeaders({
        driverUserId: driverId,
        includeCompletedCourses: true,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, driverId]);

  const [showDownloadDialog, setShowDownloadDialog] = useState(false);
  const handleOnRowClicked = (course: DriverCourseHeader) => {
    if (driverId && clientId) {
      setShowDownloadDialog(true);
      dispatch(
        downloadCourseDocument({
          clientId,
          courseId: course.id,
          driverId,
          onComplete: () => {
            setShowDownloadDialog(false);
          },
        })
      );
    }
  };

  return (
    <>
      <DriverCoursesListView
        translate={translate}
        titleKey="courses_page_title_courses_list"
        courseConfigOptions={courseConfigOptions || []}
        coursesHeaders={coursesHeadersData || []}
        onRowClicked={handleOnRowClicked}
        isLoadingData={isLoadingData.coursesHeaders}
      />

      <Dialog open={showDownloadDialog}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <LoadingView showTruck />
        </Box>
      </Dialog>
    </>
  );
}
