import { Box, Card, Stack, Tab, Tabs } from "@mui/material";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CommonLayoutCover from "./CommonLayoutCover";
import { CommonLayoutProps, CommonLayoutTab } from "./types";
import { useAnalyticsContext } from "../../../../app/firebase/useAnalyticsContext";

export const COMMON_LAYOUT_COVER_SPECS = {
  height: 180,
  avatarSize: 100,
  iconSize: 128,
};

export default function CommonLayout({
  converData,
  tabs,
  urlHashTabId,
}: CommonLayoutProps) {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const { logEvent } = useAnalyticsContext();

  const [currentTab, setCurrentTab] = useState(tabs[0].value);

  useEffect(() => {
    if (hash) {
      const firstTag = hash.split("#").at(1);
      if (
        firstTag &&
        Object.values(tabs.map((tab) => tab.value)).includes(firstTag)
      ) {
        setCurrentTab(firstTag);
      }
    }
  }, [hash, tabs]);

  useEffect(() => {
    if (urlHashTabId) {
      setCurrentTab(urlHashTabId);
    }
  }, [urlHashTabId]);

  function TabIcon({ tab }: { tab: CommonLayoutTab }) {
    return (
      <Box
        sx={{
          pt: 1,
          position: "relative",
          width: 38, height: 25
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1, // Lower z-index
          }}
        >
          {tab.icon}
        </Box>

        {tab.alertsCount && tab.alertsCount > 0 && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              bgcolor: "error.main",
              color: "common.white",
              borderRadius: "50%",
              width: 18,
              height: 18,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 10,
              zIndex: 2,
            }}
          >
            {tab.alertsCount}
          </Box>
        )}
      </Box>
    );
  }

  return (
    <>
      <Stack
        sx={{
          position: "relative",
        }}
      >
        <Card
          sx={{
            mb: 1,
            height: COMMON_LAYOUT_COVER_SPECS.height,
            position: "relative",
          }}
        >
          <CommonLayoutCover data={converData} />
        </Card>

        <Tabs
          value={currentTab}
          onChange={(_event, newValue) => {
            setCurrentTab(newValue);
            if (navigate) {
              navigate(`#${newValue}`);
            }
            logEvent("tab_changed", { tab: newValue });
          }}
          sx={{
            width: 1,
            zIndex: 9,
            position: "relative",
            bgcolor: "background.paper",
            "& .MuiTabs-flexContainer": {
              pr: { md: 3 },
              justifyContent: {
                sm: "center",
                md: "flex-end",
              },
            },
          }}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              icon={<TabIcon tab={tab} />}
              label={tab.label}
              sx={{
                marginLeft: -2,
                pr:1,
                "&.Mui-selected": {
                  color: "primary.dark",
                },
              }}
            />
          ))}
        </Tabs>

        {tabs.map(
          (tab) =>
            tab.value === currentTab && (
              <Box key={tab.value} paddingTop={1.5}>
                {" "}
                {tab.component}{" "}
              </Box>
            )
        )}
      </Stack>
    </>
  );
}
