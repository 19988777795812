import Lottie from "lottie-react";
import lottieLoadingCarLight from "../../../dashboard/assets/anim/lot_loading_car_light.json";

export function LoadingTruckAnim() {
  const style = {
    // height: 200,
    width: 240,
  };

  return <Lottie loop style={style} animationData={lottieLoadingCarLight} />;
}
