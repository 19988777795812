/* tslint:disable */
/* eslint-disable */
/**
 * Katzbat API
 * Katzbat API
 *
 * The version of the OpenAPI document: 5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AddCourseDocumentQueryProps
 */
export interface AddCourseDocumentQueryProps {
    /**
     * 
     * @type {string}
     * @memberof AddCourseDocumentQueryProps
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof AddCourseDocumentQueryProps
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof AddCourseDocumentQueryProps
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AddCourseDocumentQueryProps
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddCourseDocumentQueryProps
     */
    'showToAttendees': string;
    /**
     * 
     * @type {CourseDocumentCategory}
     * @memberof AddCourseDocumentQueryProps
     */
    'documentCategory': CourseDocumentCategory;
    /**
     * 
     * @type {string}
     * @memberof AddCourseDocumentQueryProps
     */
    'externalUrl'?: string | null;
}


/**
 * 
 * @export
 * @interface AddVehicleIncidentImgsRequestBody
 */
export interface AddVehicleIncidentImgsRequestBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof AddVehicleIncidentImgsRequestBody
     */
    'imagesDataBase64'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AddVehicleInvoiceReqBody
 */
export interface AddVehicleInvoiceReqBody {
    /**
     * 
     * @type {KInvoice}
     * @memberof AddVehicleInvoiceReqBody
     */
    'invoiceData'?: KInvoice;
    /**
     * 
     * @type {AddVehicleInvoiceReqBodyScannedDocs}
     * @memberof AddVehicleInvoiceReqBody
     */
    'scannedDocs'?: AddVehicleInvoiceReqBodyScannedDocs;
}
/**
 * @type AddVehicleInvoiceReqBodyScannedDocs
 * @export
 */
export type AddVehicleInvoiceReqBodyScannedDocs = DocumentUploadImgRequestBody | File;

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'houseNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'additionalAddress'?: string;
}
/**
 * 
 * @export
 * @interface Alert
 */
export interface Alert {
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'id': string;
    /**
     * 
     * @type {AlertType}
     * @memberof Alert
     */
    'type': AlertType;
    /**
     * 
     * @type {AlertSeverity}
     * @memberof Alert
     */
    'severity': AlertSeverity;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'date': string;
    /**
     * 
     * @type {boolean}
     * @memberof Alert
     */
    'isRead': boolean;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'payloadJson'?: string;
}


/**
 * 
 * @export
 * @interface AlertAddEmailContactProps
 */
export interface AlertAddEmailContactProps {
    /**
     * 
     * @type {string}
     * @memberof AlertAddEmailContactProps
     */
    'clientId': string;
    /**
     * 
     * @type {AlertType}
     * @memberof AlertAddEmailContactProps
     */
    'alertType': AlertType;
    /**
     * 
     * @type {AlertTypeEmailContact}
     * @memberof AlertAddEmailContactProps
     */
    'contactInfo': AlertTypeEmailContact;
}


/**
 * 
 * @export
 * @interface AlertDashbaord
 */
export interface AlertDashbaord {
    /**
     * 
     * @type {string}
     * @memberof AlertDashbaord
     */
    'relatedUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDashbaord
     */
    'clientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDashbaord
     */
    'clientName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDashbaord
     */
    'id': string;
    /**
     * 
     * @type {AlertType}
     * @memberof AlertDashbaord
     */
    'type': AlertType;
    /**
     * 
     * @type {AlertSeverity}
     * @memberof AlertDashbaord
     */
    'severity': AlertSeverity;
    /**
     * 
     * @type {string}
     * @memberof AlertDashbaord
     */
    'date': string;
    /**
     * 
     * @type {boolean}
     * @memberof AlertDashbaord
     */
    'isRead': boolean;
    /**
     * 
     * @type {string}
     * @memberof AlertDashbaord
     */
    'payloadJson'?: string;
}


/**
 * 
 * @export
 * @interface AlertDriverDocumentPayload
 */
export interface AlertDriverDocumentPayload {
    /**
     * 
     * @type {string}
     * @memberof AlertDriverDocumentPayload
     */
    'driverId': string;
    /**
     * 
     * @type {string}
     * @memberof AlertDriverDocumentPayload
     */
    'driverName': string;
    /**
     * 
     * @type {DriverDocumentUpload}
     * @memberof AlertDriverDocumentPayload
     */
    'driverDocument': DriverDocumentUpload;
}
/**
 * 
 * @export
 * @interface AlertDriverLicenseExpiredPayload
 */
export interface AlertDriverLicenseExpiredPayload {
    /**
     * 
     * @type {string}
     * @memberof AlertDriverLicenseExpiredPayload
     */
    'driverId': string;
    /**
     * 
     * @type {string}
     * @memberof AlertDriverLicenseExpiredPayload
     */
    'driverName': string;
    /**
     * 
     * @type {string}
     * @memberof AlertDriverLicenseExpiredPayload
     */
    'licenseExpireDate': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AlertSeverity = {
    LOW: 'LOW',
    MEDIUM: 'MEDIUM',
    HIGH: 'HIGH',
    CRITICAL: 'CRITICAL'
} as const;

export type AlertSeverity = typeof AlertSeverity[keyof typeof AlertSeverity];


/**
 * 
 * @export
 * @enum {string}
 */

export const AlertType = {
    VEHICLE_DOCUMENT_EXPIRED: 'VEHICLE_DOCUMENT_EXPIRED',
    VEHICLE_GENERIC_ALERT: 'VEHICLE_GENERIC_ALERT',
    VEHICLE_REPORT_ALERT: 'VEHICLE_REPORT_ALERT',
    DRIVER_DOCUMENT_EXPIRED: 'DRIVER_DOCUMENT_EXPIRED',
    DRIVER_GENERIC_ALERT: 'DRIVER_GENERIC_ALERT',
    USER_ACCOUNT_ALERT: 'USER_ACCOUNT_ALERT',
    GENERIC_ALERT: 'GENERIC_ALERT'
} as const;

export type AlertType = typeof AlertType[keyof typeof AlertType];


/**
 * 
 * @export
 * @interface AlertTypeEmailContact
 */
export interface AlertTypeEmailContact {
    /**
     * The name of the contact.
     * @type {string}
     * @memberof AlertTypeEmailContact
     */
    'name': string;
    /**
     * The email address of the contact.
     * @type {string}
     * @memberof AlertTypeEmailContact
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface AlertVehicleDocumentPayload
 */
export interface AlertVehicleDocumentPayload {
    /**
     * 
     * @type {string}
     * @memberof AlertVehicleDocumentPayload
     */
    'vehicleId': string;
    /**
     * 
     * @type {VehicleDocument}
     * @memberof AlertVehicleDocumentPayload
     */
    'vehicleDocument': VehicleDocument;
}
/**
 * 
 * @export
 * @interface AlertVehicleExpiredTestPayload
 */
export interface AlertVehicleExpiredTestPayload {
    /**
     * 
     * @type {string}
     * @memberof AlertVehicleExpiredTestPayload
     */
    'vehicleId': string;
    /**
     * 
     * @type {string}
     * @memberof AlertVehicleExpiredTestPayload
     */
    'lastReportId': string;
}
/**
 * 
 * @export
 * @interface AppOfflineData
 */
export interface AppOfflineData {
    /**
     * 
     * @type {Array<ClientHeader>}
     * @memberof AppOfflineData
     */
    'clientHeaders'?: Array<ClientHeader>;
    /**
     * 
     * @type {{ [key: string]: Array<VehicleHeader>; }}
     * @memberof AppOfflineData
     */
    'clientIdToVehiclesHeadersMap'?: { [key: string]: Array<VehicleHeader>; };
    /**
     * 
     * @type {{ [key: string]: Array<VehicleReportHeader>; }}
     * @memberof AppOfflineData
     */
    'vehicleIdToReportsHeadersMap'?: { [key: string]: Array<VehicleReportHeader>; };
    /**
     * 
     * @type {{ [key: string]: VehicleReportResponse; }}
     * @memberof AppOfflineData
     */
    'lastReportsData'?: { [key: string]: VehicleReportResponse; };
    /**
     * 
     * @type {{ [key: string]: Array<DocumentCategory>; }}
     * @memberof AppOfflineData
     */
    'staticDocumentCategories'?: { [key: string]: Array<DocumentCategory>; };
    /**
     * 
     * @type {{ [key: string]: Array<DocumentConfigOption>; }}
     * @memberof AppOfflineData
     */
    'availableTestsConfig'?: { [key: string]: Array<DocumentConfigOption>; };
    /**
     * 
     * @type {{ [key: string]: DocumentConfig; }}
     * @memberof AppOfflineData
     */
    'userVehicleDocConfig'?: { [key: string]: DocumentConfig; };
}
/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'attachmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'fileType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface Client
 */
export interface Client {
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'companyName': string;
    /**
     * 
     * @type {number}
     * @memberof Client
     */
    'totalVehicles'?: number;
    /**
     * 
     * @type {number}
     * @memberof Client
     */
    'totalDrivers'?: number;
    /**
     * 
     * @type {Address}
     * @memberof Client
     */
    'address': Address;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'signaturePicUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'singatureBase64'?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'email'?: string | null;
    /**
     * 
     * @type {Array<ContactInfo>}
     * @memberof Client
     */
    'contacts'?: Array<ContactInfo>;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'relatedUserId'?: string;
}
/**
 * 
 * @export
 * @interface ClientHeader
 */
export interface ClientHeader {
    /**
     * 
     * @type {string}
     * @memberof ClientHeader
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ClientHeader
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof ClientHeader
     */
    'phoneNumber': string;
    /**
     * 
     * @type {Address}
     * @memberof ClientHeader
     */
    'address': Address;
    /**
     * 
     * @type {string}
     * @memberof ClientHeader
     */
    'email'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClientHeader
     */
    'totalVehicles'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClientHeader
     */
    'totalDrivers'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClientHeader
     */
    'totalReports'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientHeader
     */
    'relatedUserId'?: string;
}
/**
 * 
 * @export
 * @interface ClientWebAppConfig
 */
export interface ClientWebAppConfig {
    /**
     * 
     * @type {{ [key: string]: Array<AlertTypeEmailContact>; }}
     * @memberof ClientWebAppConfig
     */
    'alertEmailsByType'?: { [key: string]: Array<AlertTypeEmailContact>; } | null;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ClientWebAppConfig
     */
    'alertsConfig'?: { [key: string]: boolean; } | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ClientWebAppConfigAlertConfigType = {
    VEHICLE_DOCS_TO_LINKED_DRIVERS: 'VEHICLE_DOCS_TO_LINKED_DRIVERS',
    DRIVER_DOCS_TO_DRIVERS: 'DRIVER_DOCS_TO_DRIVERS',
    ALERT_CLIENT: 'ALERT_CLIENT',
    DISABLE_ALERTS: 'DISABLE_ALERTS'
} as const;

export type ClientWebAppConfigAlertConfigType = typeof ClientWebAppConfigAlertConfigType[keyof typeof ClientWebAppConfigAlertConfigType];


/**
 * 
 * @export
 * @interface CompleteDriverData
 */
export interface CompleteDriverData {
    /**
     * 
     * @type {string}
     * @memberof CompleteDriverData
     */
    'licenseNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CompleteDriverData
     */
    'licenseExpireDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompleteDriverData
     */
    'licenseIssueDate'?: string;
    /**
     * 
     * @type {Array<DriverLicenseLevel>}
     * @memberof CompleteDriverData
     */
    'licenseLevels'?: Array<DriverLicenseLevel>;
    /**
     * 
     * @type {Array<DriverLicenseCertificate>}
     * @memberof CompleteDriverData
     */
    'licenseCertificates'?: Array<DriverLicenseCertificate>;
    /**
     * 
     * @type {string}
     * @memberof CompleteDriverData
     */
    'birthday'?: string;
    /**
     * 
     * @type {Address}
     * @memberof CompleteDriverData
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof CompleteDriverData
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface CompleteDriverOnboardingReq
 */
export interface CompleteDriverOnboardingReq {
    /**
     * 
     * @type {string}
     * @memberof CompleteDriverOnboardingReq
     */
    'drivingLicenseFrontBase64': string;
    /**
     * 
     * @type {string}
     * @memberof CompleteDriverOnboardingReq
     */
    'drivingLicenseBackBase64': string;
    /**
     * 
     * @type {string}
     * @memberof CompleteDriverOnboardingReq
     */
    'signatureBase64': string;
    /**
     * 
     * @type {CompleteDriverData}
     * @memberof CompleteDriverOnboardingReq
     */
    'updatedDriverUser': CompleteDriverData;
}
/**
 * 
 * @export
 * @interface ContactInfo
 */
export interface ContactInfo {
    /**
     * 
     * @type {string}
     * @memberof ContactInfo
     */
    'name'?: string;
    /**
     * 
     * @type {Address}
     * @memberof ContactInfo
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof ContactInfo
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfo
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface CourseAtendee
 */
export interface CourseAtendee {
    /**
     * 
     * @type {string}
     * @memberof CourseAtendee
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CourseAtendee
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CourseAtendee
     */
    'govId': string;
    /**
     * 
     * @type {string}
     * @memberof CourseAtendee
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CourseAtendee
     */
    'phoneNumner': string;
    /**
     * 
     * @type {CourseAtendeeStatus}
     * @memberof CourseAtendee
     */
    'status': CourseAtendeeStatus;
    /**
     * 
     * @type {boolean}
     * @memberof CourseAtendee
     */
    'singedComplete': boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseAtendee
     */
    'singedCompletedAt'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CourseAtendeeStatus = {
    REGISTERED: 'REGISTERED',
    PASSED: 'PASSED',
    FAILED: 'FAILED',
    UNKNOWN: 'UNKNOWN'
} as const;

export type CourseAtendeeStatus = typeof CourseAtendeeStatus[keyof typeof CourseAtendeeStatus];


/**
 * 
 * @export
 * @interface CourseData
 */
export interface CourseData {
    /**
     * 
     * @type {Array<CourseAtendee>}
     * @memberof CourseData
     */
    'atendees'?: Array<CourseAtendee>;
    /**
     * 
     * @type {string}
     * @memberof CourseData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CourseData
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CourseData
     */
    'courseType': string;
    /**
     * 
     * @type {CourseStatus}
     * @memberof CourseData
     */
    'status': CourseStatus;
    /**
     * 
     * @type {number}
     * @memberof CourseData
     */
    'totalRegistered'?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseData
     */
    'totalPassed'?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseData
     */
    'totalFailed'?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseData
     */
    'startedAt': string;
    /**
     * 
     * @type {Array<DocumentInputResult>}
     * @memberof CourseData
     */
    'documentInputResults'?: Array<DocumentInputResult>;
}


/**
 * 
 * @export
 * @interface CourseDocumentBase
 */
export interface CourseDocumentBase {
    /**
     * 
     * @type {string}
     * @memberof CourseDocumentBase
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDocumentBase
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CourseDocumentBase
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CourseDocumentBase
     */
    'showToAttendees': boolean;
    /**
     * 
     * @type {CourseDocumentCategory}
     * @memberof CourseDocumentBase
     */
    'documentCategory': CourseDocumentCategory;
    /**
     * 
     * @type {string}
     * @memberof CourseDocumentBase
     */
    'externalUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CourseDocumentBase
     */
    'documentType'?: string;
    /**
     * 
     * @type {Array<DocumentInputResult>}
     * @memberof CourseDocumentBase
     */
    'documentInputResults'?: Array<DocumentInputResult>;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CourseDocumentCategory = {
    PRESET_MATERIAL: 'PRESET_MATERIAL',
    ATENDEE_MATERIAL: 'ATENDEE_MATERIAL',
    EXTERNAL_MATERIAL: 'EXTERNAL_MATERIAL',
    INTERNAL: 'INTERNAL',
    OTHER: 'OTHER'
} as const;

export type CourseDocumentCategory = typeof CourseDocumentCategory[keyof typeof CourseDocumentCategory];


/**
 * 
 * @export
 * @enum {string}
 */

export const CourseDocumentInputOptionKeys = {
    COURSE_TITLE: 'courseTitle',
    COURSE_DATE: 'courseDate',
    COURSE_STATUS: 'courseStatus',
    INCLUDE_DIGITAL_SIGNATURE: 'includeDigitalSignature'
} as const;

export type CourseDocumentInputOptionKeys = typeof CourseDocumentInputOptionKeys[keyof typeof CourseDocumentInputOptionKeys];


/**
 * 
 * @export
 * @interface CourseHeader
 */
export interface CourseHeader {
    /**
     * 
     * @type {string}
     * @memberof CourseHeader
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CourseHeader
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CourseHeader
     */
    'courseType': string;
    /**
     * 
     * @type {CourseStatus}
     * @memberof CourseHeader
     */
    'status': CourseStatus;
    /**
     * 
     * @type {number}
     * @memberof CourseHeader
     */
    'totalRegistered'?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseHeader
     */
    'totalPassed'?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseHeader
     */
    'totalFailed'?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseHeader
     */
    'startedAt': string;
    /**
     * 
     * @type {Array<DocumentInputResult>}
     * @memberof CourseHeader
     */
    'documentInputResults'?: Array<DocumentInputResult>;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CourseStatus = {
    ACTIVE: 'ACTIVE',
    COMPLETED: 'COMPLETED'
} as const;

export type CourseStatus = typeof CourseStatus[keyof typeof CourseStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const CourseTypes = {
    SIDE_ROAD_STOP: 'SIDE_ROAD_STOP',
    TRIP_MALFUNCTION: 'TRIP_MALFUNCTION',
    CRANE_SELF_LOADING: 'CRANE_SELF_LOADING',
    BETON_TRUCK: 'BETON_TRUCK',
    MOVING_OPEN_BOX_CURTAIN: 'MOVING_OPEN_BOX_CURTAIN',
    OTHER: 'OTHER'
} as const;

export type CourseTypes = typeof CourseTypes[keyof typeof CourseTypes];


/**
 * 
 * @export
 * @interface CreateNewCheckoutSessionReq
 */
export interface CreateNewCheckoutSessionReq {
    /**
     * 
     * @type {string}
     * @memberof CreateNewCheckoutSessionReq
     */
    'stripeProductId': string;
    /**
     * 
     * @type {StripePaymentMethod}
     * @memberof CreateNewCheckoutSessionReq
     */
    'paymentMethod': StripePaymentMethod;
}


/**
 * 
 * @export
 * @interface CreateNewCourseBody
 */
export interface CreateNewCourseBody {
    /**
     * 
     * @type {string}
     * @memberof CreateNewCourseBody
     */
    'courseType': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateNewCourseBody
     */
    'includeAllAtendees'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateNewCourseBody
     */
    'atendeesIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<DocumentInputResult>}
     * @memberof CreateNewCourseBody
     */
    'documentInputResults'?: Array<DocumentInputResult>;
}
/**
 * 
 * @export
 * @interface CreateOrUpdateVehicleIncident
 */
export interface CreateOrUpdateVehicleIncident {
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateVehicleIncident
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateVehicleIncident
     */
    'dateTime'?: string;
    /**
     * 
     * @type {Address}
     * @memberof CreateOrUpdateVehicleIncident
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateVehicleIncident
     */
    'relatedVehicleLicensePlate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateVehicleIncident
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateVehicleIncident
     */
    'reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateVehicleIncident
     */
    'conclusion'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateVehicleIncident
     */
    'policeNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateVehicleIncident
     */
    'notes'?: string;
    /**
     * 
     * @type {Array<IncidentInvolvedParty>}
     * @memberof CreateOrUpdateVehicleIncident
     */
    'involvedParties'?: Array<IncidentInvolvedParty>;
}
/**
 * 
 * @export
 * @interface CreateVehicleTachograph
 */
export interface CreateVehicleTachograph {
    /**
     * 
     * @type {number}
     * @memberof CreateVehicleTachograph
     */
    'odometerStart'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateVehicleTachograph
     */
    'odometerEnd'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateVehicleTachograph
     */
    'isOverspeeding'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateVehicleTachograph
     */
    'maxSpeed'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateVehicleTachograph
     */
    'isOvertime'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateVehicleTachograph
     */
    'totalHours'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateVehicleTachograph
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVehicleTachograph
     */
    'startDateTime': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVehicleTachograph
     */
    'endDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVehicleTachograph
     */
    'photoDataBase64'?: string;
}
/**
 * 
 * @export
 * @interface DashboardData
 */
export interface DashboardData {
    /**
     * 
     * @type {number}
     * @memberof DashboardData
     */
    'totalClients': number;
    /**
     * 
     * @type {number}
     * @memberof DashboardData
     */
    'totalVehicles': number;
    /**
     * 
     * @type {number}
     * @memberof DashboardData
     */
    'totalReports': number;
    /**
     * 
     * @type {number}
     * @memberof DashboardData
     */
    'totalDrivers': number;
}
/**
 * 
 * @export
 * @interface DocumentCategory
 */
export interface DocumentCategory {
    /**
     * 
     * @type {string}
     * @memberof DocumentCategory
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentCategory
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentCategory
     */
    'description'?: string;
    /**
     * 
     * @type {Array<DocumentItem>}
     * @memberof DocumentCategory
     */
    'children': Array<DocumentItem>;
}
/**
 * 
 * @export
 * @interface DocumentConfig
 */
export interface DocumentConfig {
    /**
     * 
     * @type {string}
     * @memberof DocumentConfig
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentConfig
     */
    'title': string;
    /**
     * 
     * @type {Array<DocumentInput>}
     * @memberof DocumentConfig
     */
    'documentInputs': Array<DocumentInput>;
    /**
     * 
     * @type {Array<DocumentCategory>}
     * @memberof DocumentConfig
     */
    'categoriesList': Array<DocumentCategory>;
}
/**
 * 
 * @export
 * @interface DocumentConfigOption
 */
export interface DocumentConfigOption {
    /**
     * 
     * @type {string}
     * @memberof DocumentConfigOption
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentConfigOption
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface DocumentInput
 */
export interface DocumentInput {
    /**
     * 
     * @type {string}
     * @memberof DocumentInput
     */
    'dbKey': string;
    /**
     * 
     * @type {DocumentInputType}
     * @memberof DocumentInput
     */
    'type': DocumentInputType;
    /**
     * 
     * @type {string}
     * @memberof DocumentInput
     */
    'label': string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentInput
     */
    'isMandatory'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DocumentInput
     */
    'order'?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentInput
     */
    'hint'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentInput
     */
    'dbKeyRelation'?: string;
    /**
     * 
     * @type {Array<DocumentInputOption>}
     * @memberof DocumentInput
     */
    'options'?: Array<DocumentInputOption>;
}


/**
 * 
 * @export
 * @interface DocumentInputConfig
 */
export interface DocumentInputConfig {
    /**
     * 
     * @type {string}
     * @memberof DocumentInputConfig
     */
    'dbKey': string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentInputConfig
     */
    'isMandatory': boolean;
    /**
     * 
     * @type {number}
     * @memberof DocumentInputConfig
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface DocumentInputOption
 */
export interface DocumentInputOption {
    /**
     * 
     * @type {string}
     * @memberof DocumentInputOption
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentInputOption
     */
    'value': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DocumentInputOptionKeys = {
    CURRENT_ODOMETER: 'currentOdometer',
    TEST_LOCATION: 'testLocation',
    DRIVER_ID: 'driverId'
} as const;

export type DocumentInputOptionKeys = typeof DocumentInputOptionKeys[keyof typeof DocumentInputOptionKeys];


/**
 * 
 * @export
 * @interface DocumentInputResult
 */
export interface DocumentInputResult {
    /**
     * 
     * @type {string}
     * @memberof DocumentInputResult
     */
    'dbKey': string;
    /**
     * 
     * @type {object}
     * @memberof DocumentInputResult
     */
    'value': object;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DocumentInputType = {
    LABEL: 'LABEL',
    TEXT: 'TEXT',
    NUMBER: 'NUMBER',
    DATE: 'DATE',
    TIME: 'TIME',
    SELECT: 'SELECT',
    CHECKBOX: 'CHECKBOX',
    RADIO: 'RADIO',
    FILE: 'FILE',
    IMAGE: 'IMAGE',
    SIGNATURE: 'SIGNATURE',
    LOCATION: 'LOCATION',
    DRIVER: 'DRIVER'
} as const;

export type DocumentInputType = typeof DocumentInputType[keyof typeof DocumentInputType];


/**
 * 
 * @export
 * @interface DocumentItem
 */
export interface DocumentItem {
    /**
     * 
     * @type {string}
     * @memberof DocumentItem
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentItem
     */
    'isMandatory': boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentItem
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentItem
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentItem
     */
    'amount'?: number;
}
/**
 * 
 * @export
 * @interface DocumentUploadImgRequestBody
 */
export interface DocumentUploadImgRequestBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentUploadImgRequestBody
     */
    'pagesDataBase64': Array<string>;
}
/**
 * 
 * @export
 * @interface DriverCourseData
 */
export interface DriverCourseData {
    /**
     * 
     * @type {string}
     * @memberof DriverCourseData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DriverCourseData
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof DriverCourseData
     */
    'courseType': string;
    /**
     * 
     * @type {string}
     * @memberof DriverCourseData
     */
    'courseTypeLabel': string;
    /**
     * 
     * @type {string}
     * @memberof DriverCourseData
     */
    'startedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof DriverCourseData
     */
    'singedComplete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DriverCourseData
     */
    'singedCompletedAt'?: string;
    /**
     * 
     * @type {CourseAtendeeStatus}
     * @memberof DriverCourseData
     */
    'status'?: CourseAtendeeStatus;
    /**
     * 
     * @type {Array<DriverCourseDocument>}
     * @memberof DriverCourseData
     */
    'documents'?: Array<DriverCourseDocument>;
}


/**
 * 
 * @export
 * @interface DriverCourseDocument
 */
export interface DriverCourseDocument {
    /**
     * 
     * @type {string}
     * @memberof DriverCourseDocument
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DriverCourseDocument
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof DriverCourseDocument
     */
    'description'?: string | null;
    /**
     * 
     * @type {CourseDocumentCategory}
     * @memberof DriverCourseDocument
     */
    'documentCategory'?: CourseDocumentCategory;
}


/**
 * 
 * @export
 * @interface DriverCourseHeader
 */
export interface DriverCourseHeader {
    /**
     * 
     * @type {string}
     * @memberof DriverCourseHeader
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DriverCourseHeader
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof DriverCourseHeader
     */
    'courseType': string;
    /**
     * 
     * @type {string}
     * @memberof DriverCourseHeader
     */
    'courseTypeLabel': string;
    /**
     * 
     * @type {string}
     * @memberof DriverCourseHeader
     */
    'startedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof DriverCourseHeader
     */
    'singedComplete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DriverCourseHeader
     */
    'singedCompletedAt'?: string;
    /**
     * 
     * @type {CourseAtendeeStatus}
     * @memberof DriverCourseHeader
     */
    'status'?: CourseAtendeeStatus;
}


/**
 * 
 * @export
 * @interface DriverDocumentCreateReq
 */
export interface DriverDocumentCreateReq {
    /**
     * 
     * @type {string}
     * @memberof DriverDocumentCreateReq
     */
    'driverId': string;
    /**
     * 
     * @type {string}
     * @memberof DriverDocumentCreateReq
     */
    'relatedClientId': string;
    /**
     * 
     * @type {string}
     * @memberof DriverDocumentCreateReq
     */
    'relatedVehicleLicenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverDocumentCreateReq
     */
    'documentDate'?: string;
    /**
     * 
     * @type {DriverDocumentsTypes}
     * @memberof DriverDocumentCreateReq
     */
    'documentType': DriverDocumentsTypes;
    /**
     * 
     * @type {DriverDocumentFineData}
     * @memberof DriverDocumentCreateReq
     */
    'extraFineData'?: DriverDocumentFineData;
    /**
     * 
     * @type {boolean}
     * @memberof DriverDocumentCreateReq
     */
    'includeDigitalSignature': boolean;
    /**
     * 
     * @type {string}
     * @memberof DriverDocumentCreateReq
     */
    'sendToName'?: string;
    /**
     * 
     * @type {Address}
     * @memberof DriverDocumentCreateReq
     */
    'sendToAddress'?: Address;
}


/**
 * 
 * @export
 * @interface DriverDocumentCreateReqV1
 */
export interface DriverDocumentCreateReqV1 {
    /**
     * 
     * @type {string}
     * @memberof DriverDocumentCreateReqV1
     */
    'documentType': string;
    /**
     * 
     * @type {string}
     * @memberof DriverDocumentCreateReqV1
     */
    'driverId': string;
    /**
     * 
     * @type {string}
     * @memberof DriverDocumentCreateReqV1
     */
    'relatedClientId': string;
    /**
     * 
     * @type {Array<DocumentInputResult>}
     * @memberof DriverDocumentCreateReqV1
     */
    'documentInputResults': Array<DocumentInputResult>;
    /**
     * 
     * @type {Address}
     * @memberof DriverDocumentCreateReqV1
     */
    'sendToAddress'?: Address;
}
/**
 * 
 * @export
 * @interface DriverDocumentFineData
 */
export interface DriverDocumentFineData {
    /**
     * 
     * @type {string}
     * @memberof DriverDocumentFineData
     */
    'fineNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverDocumentFineData
     */
    'fineReceivedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverDocumentFineData
     */
    'fineViolationDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof DriverDocumentFineData
     */
    'fineAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof DriverDocumentFineData
     */
    'judgeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverDocumentFineData
     */
    'courtName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverDocumentFineData
     */
    'courtAddress'?: string;
}
/**
 * 
 * @export
 * @interface DriverDocumentHeader
 */
export interface DriverDocumentHeader {
    /**
     * 
     * @type {string}
     * @memberof DriverDocumentHeader
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverDocumentHeader
     */
    'relatedVehicleLicenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverDocumentHeader
     */
    'documentType'?: string;
    /**
     * 
     * @type {DriverDocumentFineData}
     * @memberof DriverDocumentHeader
     */
    'extraFineData'?: DriverDocumentFineData;
    /**
     * 
     * @type {string}
     * @memberof DriverDocumentHeader
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverDocumentHeader
     */
    'sendToName'?: string;
    /**
     * 
     * @type {Address}
     * @memberof DriverDocumentHeader
     */
    'sendToAddress'?: Address;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DriverDocumentInputOptionKeys = {
    INCLUDE_DIGITAL_SIGNATURE: 'includeDigitalSignature',
    DOCUMENT_DATE: 'documentDate',
    RELATED_VEHICLE_LICENSE_NUMBER: 'relatedVehicleLicenseNumber',
    SEND_TO_NAME: 'sendToName',
    INCLUDE_SEND_TO_ADDRESS: 'includeSendToAddress',
    SEND_TO_ADDRESS: 'sendToAddress',
    SEND_TO_ADDRESS_NAME: 'sendToAddressName',
    FINE_NUMBER: 'fineNumber',
    FINE_RECEIVED_DATE: 'fineReceivedDate',
    FINE_VIOLATION_DATE: 'fineViolationDate',
    FINE_AMOUNT: 'fineAmount',
    JUDGE_NAME: 'judgeName',
    COURT_NAME: 'courtName',
    COURT_LOCATION: 'courtLocation'
} as const;

export type DriverDocumentInputOptionKeys = typeof DriverDocumentInputOptionKeys[keyof typeof DriverDocumentInputOptionKeys];


/**
 * 
 * @export
 * @interface DriverDocumentUpload
 */
export interface DriverDocumentUpload {
    /**
     * 
     * @type {string}
     * @memberof DriverDocumentUpload
     */
    'id': string;
    /**
     * 
     * @type {DriverDocumentUploadType}
     * @memberof DriverDocumentUpload
     */
    'documentType'?: DriverDocumentUploadType;
    /**
     * 
     * @type {string}
     * @memberof DriverDocumentUpload
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverDocumentUpload
     */
    'expirationDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof DriverDocumentUpload
     */
    'expirationDateTimestamp'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DriverDocumentUpload
     */
    'isExpired'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DriverDocumentUpload
     */
    'isHidden'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DriverDocumentUpload
     */
    'fileUrl'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const DriverDocumentUploadType = {
    DRIVER_LAW_INFO: 'DRIVER_LAW_INFO',
    OTHER: 'OTHER'
} as const;

export type DriverDocumentUploadType = typeof DriverDocumentUploadType[keyof typeof DriverDocumentUploadType];


/**
 * 
 * @export
 * @enum {string}
 */

export const DriverDocumentsTypes = {
    FINE_CHANGE_PARKING: 'FINE_CHANGE_PARKING',
    FINE_CHANGE_TRAFFIC: 'FINE_CHANGE_TRAFFIC',
    FINE_CHANGE_COURT: 'FINE_CHANGE_COURT',
    FINE_CHANGE_GENERIC: 'FINE_CHANGE_GENERIC',
    DRIVER_DECLARATION: 'DRIVER_DECLARATION',
    DRIVER_DECLARATION_GENERIC: 'DRIVER_DECLARATION_GENERIC',
    LAW_168: 'LAW_168'
} as const;

export type DriverDocumentsTypes = typeof DriverDocumentsTypes[keyof typeof DriverDocumentsTypes];


/**
 * 
 * @export
 * @enum {string}
 */

export const DriverLicenseCertificate = {
    CRAIN: 'Crain',
    FORKLIFT: 'Forklift',
    MATERIALS: 'Materials'
} as const;

export type DriverLicenseCertificate = typeof DriverLicenseCertificate[keyof typeof DriverLicenseCertificate];


/**
 * 
 * @export
 * @enum {string}
 */

export const DriverLicenseLevel = {
    A1: 'A1',
    A2: 'A2',
    A: 'A',
    B: 'B',
    C1: 'C1',
    C: 'C',
    D3: 'D3',
    D2: 'D2',
    D1: 'D1',
    D: 'D',
    CE: 'CE',
    ONE: 'ONE'
} as const;

export type DriverLicenseLevel = typeof DriverLicenseLevel[keyof typeof DriverLicenseLevel];


/**
 * 
 * @export
 * @interface DriverLicenseRes
 */
export interface DriverLicenseRes {
    /**
     * 
     * @type {string}
     * @memberof DriverLicenseRes
     */
    'driverId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverLicenseRes
     */
    'driverGovId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverLicenseRes
     */
    'driverLicenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverLicenseRes
     */
    'frontLicenseImageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverLicenseRes
     */
    'backLicenseImageUrl'?: string;
}
/**
 * 
 * @export
 * @interface DriverOnboardingData
 */
export interface DriverOnboardingData {
    /**
     * 
     * @type {string}
     * @memberof DriverOnboardingData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DriverOnboardingData
     */
    'govId': string;
    /**
     * 
     * @type {string}
     * @memberof DriverOnboardingData
     */
    'fullName': string;
    /**
     * 
     * @type {string}
     * @memberof DriverOnboardingData
     */
    'licenseNumber': string;
    /**
     * 
     * @type {boolean}
     * @memberof DriverOnboardingData
     */
    'isOnboardingCompleted': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DriverType = {
    TRUCK: 'TRUCK',
    BUS: 'BUS',
    TAXI: 'TAXI',
    COMMERCIAL: 'COMMERCIAL',
    PRIVATE: 'PRIVATE',
    OTHER: 'OTHER'
} as const;

export type DriverType = typeof DriverType[keyof typeof DriverType];


/**
 * 
 * @export
 * @interface DriverUser
 */
export interface DriverUser {
    /**
     * 
     * @type {string}
     * @memberof DriverUser
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverUser
     */
    'govId': string;
    /**
     * 
     * @type {string}
     * @memberof DriverUser
     */
    'fullName': string;
    /**
     * 
     * @type {string}
     * @memberof DriverUser
     */
    'licenseNumber': string;
    /**
     * 
     * @type {string}
     * @memberof DriverUser
     */
    'licenseExpireDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverUser
     */
    'licenseIssueDate'?: string;
    /**
     * 
     * @type {Array<DriverLicenseLevel>}
     * @memberof DriverUser
     */
    'licenseLevels'?: Array<DriverLicenseLevel>;
    /**
     * 
     * @type {Array<DriverLicenseCertificate>}
     * @memberof DriverUser
     */
    'licenseCertificates'?: Array<DriverLicenseCertificate>;
    /**
     * 
     * @type {string}
     * @memberof DriverUser
     */
    'birthday'?: string;
    /**
     * 
     * @type {Address}
     * @memberof DriverUser
     */
    'address'?: Address;
    /**
     * 
     * @type {DriverType}
     * @memberof DriverUser
     */
    'driverType': DriverType;
    /**
     * 
     * @type {string}
     * @memberof DriverUser
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof DriverUser
     */
    'phoneCountryCode': string;
    /**
     * 
     * @type {string}
     * @memberof DriverUser
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DriverUser
     */
    'isOnboardingCompleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DriverUser
     */
    'workEmployeeNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverUser
     */
    'workStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverUser
     */
    'workBranch'?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverUser
     */
    'workVehicleType'?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverUser
     */
    'workJobTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverUser
     */
    'workJobDevision'?: string;
}


/**
 * 
 * @export
 * @interface DriverUserDocuments
 */
export interface DriverUserDocuments {
    /**
     * 
     * @type {string}
     * @memberof DriverUserDocuments
     */
    'drivingLicenseFront'?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverUserDocuments
     */
    'drivingLicenseBack'?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverUserDocuments
     */
    'signature'?: string;
}
/**
 * 
 * @export
 * @interface ExpiredVehicleHeader
 */
export interface ExpiredVehicleHeader {
    /**
     * 
     * @type {string}
     * @memberof ExpiredVehicleHeader
     */
    'licenseNumber': string;
    /**
     * 
     * @type {boolean}
     * @memberof ExpiredVehicleHeader
     */
    'isArchived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExpiredVehicleHeader
     */
    'makeId': string;
    /**
     * 
     * @type {string}
     * @memberof ExpiredVehicleHeader
     */
    'modelId': string;
    /**
     * 
     * @type {number}
     * @memberof ExpiredVehicleHeader
     */
    'buildYear': number;
    /**
     * 
     * @type {VehicleType}
     * @memberof ExpiredVehicleHeader
     */
    'type': VehicleType;
    /**
     * 
     * @type {VehicleOwnerType}
     * @memberof ExpiredVehicleHeader
     */
    'ownerType'?: VehicleOwnerType;
    /**
     * 
     * @type {number}
     * @memberof ExpiredVehicleHeader
     */
    'lastOdometer'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExpiredVehicleHeader
     */
    'lastReportId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpiredVehicleHeader
     */
    'relatedClientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpiredVehicleHeader
     */
    'relatedClientName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpiredVehicleHeader
     */
    'relatedUserId'?: string;
}


/**
 * 
 * @export
 * @interface GetCourseDocumentQueryProps
 */
export interface GetCourseDocumentQueryProps {
    /**
     * 
     * @type {string}
     * @memberof GetCourseDocumentQueryProps
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof GetCourseDocumentQueryProps
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof GetCourseDocumentQueryProps
     */
    'documentId': string;
}
/**
 * 
 * @export
 * @interface GetCourseDocumentsQueryProps
 */
export interface GetCourseDocumentsQueryProps {
    /**
     * 
     * @type {string}
     * @memberof GetCourseDocumentsQueryProps
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof GetCourseDocumentsQueryProps
     */
    'courseId': string;
    /**
     * 
     * @type {CourseDocumentCategory}
     * @memberof GetCourseDocumentsQueryProps
     */
    'documentCategory'?: CourseDocumentCategory;
}


/**
 * 
 * @export
 * @interface GetCoursesSummeryProps
 */
export interface GetCoursesSummeryProps {
    /**
     * 
     * @type {string}
     * @memberof GetCoursesSummeryProps
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof GetCoursesSummeryProps
     */
    'courseType'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCoursesSummeryProps
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCoursesSummeryProps
     */
    'to'?: string;
}
/**
 * 
 * @export
 * @interface IncidentBase
 */
export interface IncidentBase {
    /**
     * 
     * @type {string}
     * @memberof IncidentBase
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentBase
     */
    'dateTime'?: string;
    /**
     * 
     * @type {Address}
     * @memberof IncidentBase
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof IncidentBase
     */
    'relatedVehicleLicensePlate'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentBase
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentBase
     */
    'reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentBase
     */
    'conclusion'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentBase
     */
    'policeNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentBase
     */
    'notes'?: string;
    /**
     * 
     * @type {Array<IncidentInvolvedParty>}
     * @memberof IncidentBase
     */
    'involvedParties'?: Array<IncidentInvolvedParty>;
}
/**
 * 
 * @export
 * @interface IncidentEntry
 */
export interface IncidentEntry {
    /**
     * 
     * @type {string}
     * @memberof IncidentEntry
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentEntry
     */
    'dateTime'?: string;
    /**
     * 
     * @type {Address}
     * @memberof IncidentEntry
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof IncidentEntry
     */
    'relatedVehicleLicensePlate'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentEntry
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentEntry
     */
    'reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentEntry
     */
    'conclusion'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentEntry
     */
    'policeNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentEntry
     */
    'notes'?: string;
    /**
     * 
     * @type {Array<IncidentInvolvedParty>}
     * @memberof IncidentEntry
     */
    'involvedParties'?: Array<IncidentInvolvedParty>;
    /**
     * 
     * @type {string}
     * @memberof IncidentEntry
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentEntry
     */
    'createdAt'?: string;
    /**
     * 
     * @type {DriverUser}
     * @memberof IncidentEntry
     */
    'relatedDriver'?: DriverUser;
    /**
     * 
     * @type {Array<string>}
     * @memberof IncidentEntry
     */
    'photosIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface IncidentInvolvedParty
 */
export interface IncidentInvolvedParty {
    /**
     * 
     * @type {IncidentInvolvedPartyType}
     * @memberof IncidentInvolvedParty
     */
    'type'?: IncidentInvolvedPartyType;
    /**
     * 
     * @type {string}
     * @memberof IncidentInvolvedParty
     */
    'personName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentInvolvedParty
     */
    'personGovId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentInvolvedParty
     */
    'personlicenseNumber'?: string;
    /**
     * 
     * @type {Address}
     * @memberof IncidentInvolvedParty
     */
    'personAddress'?: Address;
    /**
     * 
     * @type {string}
     * @memberof IncidentInvolvedParty
     */
    'personPhoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentInvolvedParty
     */
    'personEmail'?: string;
    /**
     * 
     * @type {IncidentInvolvedPartyVehicle}
     * @memberof IncidentInvolvedParty
     */
    'vehicle'?: IncidentInvolvedPartyVehicle;
    /**
     * 
     * @type {string}
     * @memberof IncidentInvolvedParty
     */
    'insuranceCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentInvolvedParty
     */
    'insurancePolicyNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentInvolvedParty
     */
    'insuranceAgentName'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const IncidentInvolvedPartyType = {
    DRIVER: 'DRIVER',
    PASSENGER: 'PASSENGER',
    PEDESTRIAN: 'PEDESTRIAN',
    WITNESS: 'WITNESS',
    OTHER: 'OTHER'
} as const;

export type IncidentInvolvedPartyType = typeof IncidentInvolvedPartyType[keyof typeof IncidentInvolvedPartyType];


/**
 * 
 * @export
 * @interface IncidentInvolvedPartyVehicle
 */
export interface IncidentInvolvedPartyVehicle {
    /**
     * 
     * @type {string}
     * @memberof IncidentInvolvedPartyVehicle
     */
    'vehicleLicenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentInvolvedPartyVehicle
     */
    'vehicleMake'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentInvolvedPartyVehicle
     */
    'vehicleModel'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentInvolvedPartyVehicle
     */
    'vehicleYear'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof IncidentInvolvedPartyVehicle
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof IncidentInvolvedPartyVehicle
     */
    'vehicleOwnerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentInvolvedPartyVehicle
     */
    'vehicleOwnerId'?: string;
    /**
     * 
     * @type {Address}
     * @memberof IncidentInvolvedPartyVehicle
     */
    'vehicleOwnerAddress'?: Address;
    /**
     * 
     * @type {string}
     * @memberof IncidentInvolvedPartyVehicle
     */
    'vehicleOwnerPhoneNumber'?: string;
}


/**
 * 
 * @export
 * @interface KInvoice
 */
export interface KInvoice {
    /**
     * 
     * @type {string}
     * @memberof KInvoice
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KInvoice
     */
    'invoiceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof KInvoice
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof KInvoice
     */
    'issuedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof KInvoice
     */
    'dueDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof KInvoice
     */
    'taxes'?: number;
    /**
     * 
     * @type {KInvoiceStatus}
     * @memberof KInvoice
     */
    'status'?: KInvoiceStatus;
    /**
     * 
     * @type {KInvoiceType}
     * @memberof KInvoice
     */
    'type'?: KInvoiceType;
    /**
     * 
     * @type {number}
     * @memberof KInvoice
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof KInvoice
     */
    'totalAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof KInvoice
     */
    'shipping'?: number;
    /**
     * 
     * @type {number}
     * @memberof KInvoice
     */
    'discount'?: number;
    /**
     * 
     * @type {Array<KInvoiceItem>}
     * @memberof KInvoice
     */
    'items'?: Array<KInvoiceItem>;
    /**
     * 
     * @type {string}
     * @memberof KInvoice
     */
    'invoiceFromName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KInvoice
     */
    'invoiceFromAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof KInvoice
     */
    'invoiceFromPhone'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof KInvoice
     */
    'includedScan'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KInvoice
     */
    'fileUrl'?: string;
}


/**
 * 
 * @export
 * @interface KInvoiceItem
 */
export interface KInvoiceItem {
    /**
     * 
     * @type {string}
     * @memberof KInvoiceItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KInvoiceItem
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof KInvoiceItem
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof KInvoiceItem
     */
    'price'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const KInvoiceStatus = {
    PAID: 'PAID',
    NOT_PAID: 'NOT_PAID',
    REFUNDED: 'REFUNDED',
    CANCELED: 'CANCELED'
} as const;

export type KInvoiceStatus = typeof KInvoiceStatus[keyof typeof KInvoiceStatus];


/**
 * 
 * @export
 * @interface KInvoiceTableFilters
 */
export interface KInvoiceTableFilters {
    /**
     * 
     * @type {string}
     * @memberof KInvoiceTableFilters
     */
    'name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof KInvoiceTableFilters
     */
    'service'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof KInvoiceTableFilters
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof KInvoiceTableFilters
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof KInvoiceTableFilters
     */
    'endDate'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const KInvoiceType = {
    SERVICE: 'SERVICE',
    PARKING: 'PARKING',
    RECHARGE_ELECTRIC: 'RECHARGE_ELECTRIC',
    FUEL_CARD: 'FUEL_CARD',
    TEST: 'TEST',
    INSURENCE: 'INSURENCE',
    RENTAL: 'RENTAL',
    LEASING: 'LEASING',
    LOAN: 'LOAN',
    ROAD_6: 'ROAD_6',
    ROAD_6_NORTH: 'ROAD_6_NORTH',
    ROAD_CARMEL: 'ROAD_CARMEL',
    OTHERS: 'OTHERS'
} as const;

export type KInvoiceType = typeof KInvoiceType[keyof typeof KInvoiceType];


/**
 * 
 * @export
 * @interface MainUserInfo
 */
export interface MainUserInfo {
    /**
     * 
     * @type {string}
     * @memberof MainUserInfo
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof MainUserInfo
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MainUserInfo
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MainUserInfo
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MainUserInfo
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof MainUserInfo
     */
    'profilePictureUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof MainUserInfo
     */
    'companyLogoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof MainUserInfo
     */
    'signaturePicUrl'?: string;
    /**
     * 
     * @type {Address}
     * @memberof MainUserInfo
     */
    'address'?: Address;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PushNotificationChannels = {
    VEHICLE_DOCUMENTS: 'VEHICLE_DOCUMENTS',
    DRIVER_DOCUMENT: 'DRIVER_DOCUMENT',
    DRIVER_LICENSE: 'DRIVER_LICENSE'
} as const;

export type PushNotificationChannels = typeof PushNotificationChannels[keyof typeof PushNotificationChannels];


/**
 * 
 * @export
 * @interface RegisterNewUserReq
 */
export interface RegisterNewUserReq {
    /**
     * 
     * @type {string}
     * @memberof RegisterNewUserReq
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewUserReq
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewUserReq
     */
    'stripeProductId': string;
    /**
     * 
     * @type {StripePaymentMethod}
     * @memberof RegisterNewUserReq
     */
    'paymentMethod': StripePaymentMethod;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewUserReq
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewUserReq
     */
    'companyID': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewUserReq
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewUserReq
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewUserReq
     */
    'phoneNumber': string;
}


/**
 * 
 * @export
 * @interface RegisterNewUserRes
 */
export interface RegisterNewUserRes {
    /**
     * 
     * @type {string}
     * @memberof RegisterNewUserRes
     */
    'stripeClientSecret': string;
}
/**
 * 
 * @export
 * @interface SendFeedbackReq
 */
export interface SendFeedbackReq {
    /**
     * 
     * @type {string}
     * @memberof SendFeedbackReq
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof SendFeedbackReq
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SendFeedbackReq
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof SendFeedbackReq
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SendFeedbackReq
     */
    'subject'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const StripePaymentMethod = {
    CARD: 'CARD',
    PAYPAL: 'PAYPAL'
} as const;

export type StripePaymentMethod = typeof StripePaymentMethod[keyof typeof StripePaymentMethod];


/**
 * 
 * @export
 * @interface UpdateAttendeeStatusBody
 */
export interface UpdateAttendeeStatusBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateAttendeeStatusBody
     */
    'status': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAttendeeStatusBody
     */
    'isSingedByDriver'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateCourseDataBody
 */
export interface UpdateCourseDataBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateCourseDataBody
     */
    'courseType'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCourseDataBody
     */
    'status'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateCourseDataBody
     */
    'atendeesIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<DocumentInputResult>}
     * @memberof UpdateCourseDataBody
     */
    'documentInputResults'?: Array<DocumentInputResult>;
}
/**
 * 
 * @export
 * @interface UpdateVehicleReportRequest
 */
export interface UpdateVehicleReportRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateVehicleReportRequest
     */
    'id': string;
    /**
     * 
     * @type {VehicleReportStatus}
     * @memberof UpdateVehicleReportRequest
     */
    'reportStatus': VehicleReportStatus;
    /**
     * 
     * @type {string}
     * @memberof UpdateVehicleReportRequest
     */
    'createdAt': string;
    /**
     * 
     * @type {VehicleReportType}
     * @memberof UpdateVehicleReportRequest
     */
    'reportType': VehicleReportType;
    /**
     * 
     * @type {Vehicle}
     * @memberof UpdateVehicleReportRequest
     */
    'vehicle': Vehicle;
    /**
     * 
     * @type {ClientHeader}
     * @memberof UpdateVehicleReportRequest
     */
    'clientHeader': ClientHeader;
    /**
     * 
     * @type {Array<VehicleTestResult>}
     * @memberof UpdateVehicleReportRequest
     */
    'results': Array<VehicleTestResult>;
    /**
     * 
     * @type {string}
     * @memberof UpdateVehicleReportRequest
     */
    'notes'?: string;
    /**
     * 
     * @type {Array<DocumentInputResult>}
     * @memberof UpdateVehicleReportRequest
     */
    'documentInputResults'?: Array<DocumentInputResult>;
    /**
     * 
     * @type {DriverUser}
     * @memberof UpdateVehicleReportRequest
     */
    'driver'?: DriverUser;
}


/**
 * 
 * @export
 * @interface UpdateVehicleTachograph
 */
export interface UpdateVehicleTachograph {
    /**
     * 
     * @type {number}
     * @memberof UpdateVehicleTachograph
     */
    'odometerStart'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateVehicleTachograph
     */
    'odometerEnd'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateVehicleTachograph
     */
    'isOverspeeding'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateVehicleTachograph
     */
    'maxSpeed'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateVehicleTachograph
     */
    'isOvertime'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateVehicleTachograph
     */
    'totalHours'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateVehicleTachograph
     */
    'notes'?: string;
}
/**
 * 
 * @export
 * @interface UploadDriverLicenseReq
 */
export interface UploadDriverLicenseReq {
    /**
     * 
     * @type {string}
     * @memberof UploadDriverLicenseReq
     */
    'driverId': string;
    /**
     * 
     * @type {string}
     * @memberof UploadDriverLicenseReq
     */
    'fileBase64': string;
    /**
     * 
     * @type {boolean}
     * @memberof UploadDriverLicenseReq
     */
    'isFront': boolean;
}
/**
 * 
 * @export
 * @interface UserDevice
 */
export interface UserDevice {
    /**
     * 
     * @type {UserDeviceType}
     * @memberof UserDevice
     */
    'type': UserDeviceType;
    /**
     * 
     * @type {string}
     * @memberof UserDevice
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserDevice
     */
    'osVersion': string;
    /**
     * 
     * @type {string}
     * @memberof UserDevice
     */
    'fcmToken': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const UserDeviceType = {
    ANDROID: 'ANDROID',
    IOS: 'IOS',
    WEB: 'WEB',
    DESKTOP: 'DESKTOP',
    UNKNOWN: 'UNKNOWN'
} as const;

export type UserDeviceType = typeof UserDeviceType[keyof typeof UserDeviceType];


/**
 * 
 * @export
 * @enum {string}
 */

export const UserRole = {
    SUPER_USER: 'SUPER_USER',
    ADMIN: 'ADMIN',
    USER_MAIN: 'USER_MAIN',
    USER_MAIN_CHILD: 'USER_MAIN_CHILD',
    USER_DRIVER: 'USER_DRIVER',
    VISITOR: 'VISITOR'
} as const;

export type UserRole = typeof UserRole[keyof typeof UserRole];


/**
 * 
 * @export
 * @interface Vehicle
 */
export interface Vehicle {
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    'licenseNumber': string;
    /**
     * 
     * @type {boolean}
     * @memberof Vehicle
     */
    'isArchived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    'makeId': string;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    'modelId': string;
    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    'buildYear': number;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    'vin': string;
    /**
     * 
     * @type {VehicleType}
     * @memberof Vehicle
     */
    'type': VehicleType;
    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    'lastOdometer'?: number;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    'internalId'?: string;
    /**
     * 
     * @type {VehicleOwnerType}
     * @memberof Vehicle
     */
    'ownerType'?: VehicleOwnerType;
    /**
     * 
     * @type {ClientHeader}
     * @memberof Vehicle
     */
    'relatedClientHeader'?: ClientHeader;
    /**
     * 
     * @type {Array<string>}
     * @memberof Vehicle
     */
    'relatedDrivers'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    'lastReportId'?: string;
}


/**
 * 
 * @export
 * @interface VehicleConfig
 */
export interface VehicleConfig {
    /**
     * 
     * @type {VehicleConfigVehicleManufacturers}
     * @memberof VehicleConfig
     */
    'vehicleManufacturers': VehicleConfigVehicleManufacturers;
    /**
     * 
     * @type {VehicleConfigVehicleModels}
     * @memberof VehicleConfig
     */
    'vehicleModels': VehicleConfigVehicleModels;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof VehicleConfig
     */
    'vehicleTypes': Array<VehicleType>;
}
/**
 * 
 * @export
 * @interface VehicleConfigVehicleManufacturers
 */
export interface VehicleConfigVehicleManufacturers {
    /**
     * 
     * @type {Array<VehicleManufacturer>}
     * @memberof VehicleConfigVehicleManufacturers
     */
    'cars': Array<VehicleManufacturer>;
    /**
     * 
     * @type {Array<VehicleManufacturer>}
     * @memberof VehicleConfigVehicleManufacturers
     */
    'commercial': Array<VehicleManufacturer>;
    /**
     * 
     * @type {Array<VehicleManufacturer>}
     * @memberof VehicleConfigVehicleManufacturers
     */
    'trucks': Array<VehicleManufacturer>;
    /**
     * 
     * @type {Array<VehicleManufacturer>}
     * @memberof VehicleConfigVehicleManufacturers
     */
    'bus': Array<VehicleManufacturer>;
    /**
     * 
     * @type {VehicleManufacturer}
     * @memberof VehicleConfigVehicleManufacturers
     */
    'other': VehicleManufacturer;
}
/**
 * 
 * @export
 * @interface VehicleConfigVehicleModels
 */
export interface VehicleConfigVehicleModels {
    /**
     * 
     * @type {Array<VehiclesDataModel>}
     * @memberof VehicleConfigVehicleModels
     */
    'cars': Array<VehiclesDataModel>;
    /**
     * 
     * @type {Array<VehiclesDataModel>}
     * @memberof VehicleConfigVehicleModels
     */
    'commercial': Array<VehiclesDataModel>;
}
/**
 * 
 * @export
 * @interface VehicleDocument
 */
export interface VehicleDocument {
    /**
     * 
     * @type {string}
     * @memberof VehicleDocument
     */
    'id': string;
    /**
     * 
     * @type {VehicleDocumentType}
     * @memberof VehicleDocument
     */
    'documentType'?: VehicleDocumentType;
    /**
     * 
     * @type {string}
     * @memberof VehicleDocument
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDocument
     */
    'expirationDate': string;
    /**
     * 
     * @type {number}
     * @memberof VehicleDocument
     */
    'expirationDateTimestamp'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleDocument
     */
    'isExpired'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VehicleDocument
     */
    'fileUrl'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const VehicleDocumentType = {
    VEHICLE_LICENSE: 'VEHICLE_LICENSE',
    VEHICLE_LICENSE_APPENDIX: 'VEHICLE_LICENSE_APPENDIX',
    COMPULSORY_INSURANCE: 'COMPULSORY_INSURANCE',
    BRAKES_CERTIFICATE: 'BRAKES_CERTIFICATE',
    WINTER_EXAMINATION: 'WINTER_EXAMINATION',
    SEASONAL_CHECK: 'SEASONAL_CHECK',
    OPERATING_LICENSE: 'OPERATING_LICENSE',
    BUS_TRANSPORT_CERTIFICATE: 'BUS_TRANSPORT_CERTIFICATE',
    BUS_CHILDREN_FORGETTING_CERTIFICATE: 'BUS_CHILDREN_FORGETTING_CERTIFICATE',
    TACHOGRAPH_CERTIFICATE: 'TACHOGRAPH_CERTIFICATE',
    TRUCK_LIFT_RAMP_CERTIFICATE: 'TRUCK_LIFT_RAMP_CERTIFICATE',
    TRUCK_TRANSFORTER_CERTIFICATE: 'TRUCK_TRANSFORTER_CERTIFICATE',
    COOLING_BOX_CERTIFICATE: 'COOLING_BOX_CERTIFICATE',
    OTHER: 'OTHER'
} as const;

export type VehicleDocumentType = typeof VehicleDocumentType[keyof typeof VehicleDocumentType];


/**
 * 
 * @export
 * @interface VehicleHeader
 */
export interface VehicleHeader {
    /**
     * 
     * @type {string}
     * @memberof VehicleHeader
     */
    'licenseNumber': string;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleHeader
     */
    'isArchived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VehicleHeader
     */
    'makeId': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleHeader
     */
    'modelId': string;
    /**
     * 
     * @type {number}
     * @memberof VehicleHeader
     */
    'buildYear': number;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleHeader
     */
    'type': VehicleType;
    /**
     * 
     * @type {VehicleOwnerType}
     * @memberof VehicleHeader
     */
    'ownerType'?: VehicleOwnerType;
    /**
     * 
     * @type {number}
     * @memberof VehicleHeader
     */
    'lastOdometer'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleHeader
     */
    'lastReportId'?: string;
}


/**
 * 
 * @export
 * @interface VehicleIncident
 */
export interface VehicleIncident {
    /**
     * 
     * @type {string}
     * @memberof VehicleIncident
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleIncident
     */
    'dateTime'?: string;
    /**
     * 
     * @type {Address}
     * @memberof VehicleIncident
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof VehicleIncident
     */
    'relatedVehicleLicensePlate'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleIncident
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleIncident
     */
    'reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleIncident
     */
    'conclusion'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleIncident
     */
    'policeNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleIncident
     */
    'notes'?: string;
    /**
     * 
     * @type {Array<IncidentInvolvedParty>}
     * @memberof VehicleIncident
     */
    'involvedParties'?: Array<IncidentInvolvedParty>;
    /**
     * 
     * @type {string}
     * @memberof VehicleIncident
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleIncident
     */
    'createdAt'?: string;
    /**
     * 
     * @type {DriverUser}
     * @memberof VehicleIncident
     */
    'relatedDriver'?: DriverUser;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleIncident
     */
    'photosIds'?: Array<string>;
    /**
     * 
     * @type {Vehicle}
     * @memberof VehicleIncident
     */
    'vehicle'?: Vehicle;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleIncident
     */
    'photosUrls'?: Array<string>;
}
/**
 * 
 * @export
 * @interface VehicleIncidentHeader
 */
export interface VehicleIncidentHeader {
    /**
     * 
     * @type {string}
     * @memberof VehicleIncidentHeader
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleIncidentHeader
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleIncidentHeader
     */
    'dateTime'?: string;
    /**
     * 
     * @type {Address}
     * @memberof VehicleIncidentHeader
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof VehicleIncidentHeader
     */
    'relatedDriver'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleIncidentHeader
     */
    'relatedVehicleLicensePlate'?: string;
}
/**
 * 
 * @export
 * @interface VehicleManufacturer
 */
export interface VehicleManufacturer {
    /**
     * 
     * @type {string}
     * @memberof VehicleManufacturer
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleManufacturer
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface VehicleModel
 */
export interface VehicleModel {
    /**
     * 
     * @type {string}
     * @memberof VehicleModel
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleModel
     */
    'value': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const VehicleOwnerType = {
    COMPANY: 'COMPANY',
    PRIVATE: 'PRIVATE',
    LEASING: 'LEASING',
    RENTAL: 'RENTAL',
    OTHER: 'OTHER'
} as const;

export type VehicleOwnerType = typeof VehicleOwnerType[keyof typeof VehicleOwnerType];


/**
 * 
 * @export
 * @interface VehicleReportConfig
 */
export interface VehicleReportConfig {
    /**
     * 
     * @type {VehicleReportType}
     * @memberof VehicleReportConfig
     */
    'reportType': VehicleReportType;
    /**
     * 
     * @type {Array<DocumentInputConfig>}
     * @memberof VehicleReportConfig
     */
    'enabled_test_inputs': Array<DocumentInputConfig>;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleReportConfig
     */
    'enabled_test_questions': Array<string>;
}


/**
 * 
 * @export
 * @interface VehicleReportHeader
 */
export interface VehicleReportHeader {
    /**
     * 
     * @type {string}
     * @memberof VehicleReportHeader
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleReportHeader
     */
    'vehicleLicensePlate': string;
    /**
     * 
     * @type {VehicleReportStatus}
     * @memberof VehicleReportHeader
     */
    'reportStatus': VehicleReportStatus;
    /**
     * 
     * @type {string}
     * @memberof VehicleReportHeader
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleReportHeader
     */
    'reportType': string;
    /**
     * 
     * @type {Address}
     * @memberof VehicleReportHeader
     */
    'testLocation'?: Address;
}


/**
 * 
 * @export
 * @interface VehicleReportResponse
 */
export interface VehicleReportResponse {
    /**
     * 
     * @type {string}
     * @memberof VehicleReportResponse
     */
    'id': string;
    /**
     * 
     * @type {VehicleReportStatus}
     * @memberof VehicleReportResponse
     */
    'reportStatus': VehicleReportStatus;
    /**
     * 
     * @type {string}
     * @memberof VehicleReportResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {VehicleReportType}
     * @memberof VehicleReportResponse
     */
    'reportType': VehicleReportType;
    /**
     * 
     * @type {Vehicle}
     * @memberof VehicleReportResponse
     */
    'vehicle': Vehicle;
    /**
     * 
     * @type {ClientHeader}
     * @memberof VehicleReportResponse
     */
    'clientHeader': ClientHeader;
    /**
     * 
     * @type {Array<VehicleTestResult>}
     * @memberof VehicleReportResponse
     */
    'results': Array<VehicleTestResult>;
    /**
     * 
     * @type {string}
     * @memberof VehicleReportResponse
     */
    'notes'?: string;
    /**
     * 
     * @type {Array<DocumentInputResult>}
     * @memberof VehicleReportResponse
     */
    'documentInputResults'?: Array<DocumentInputResult>;
    /**
     * 
     * @type {DriverUser}
     * @memberof VehicleReportResponse
     */
    'driver'?: DriverUser;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const VehicleReportStatus = {
    OPEN: 'OPEN',
    CLOSED: 'CLOSED'
} as const;

export type VehicleReportStatus = typeof VehicleReportStatus[keyof typeof VehicleReportStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const VehicleReportType = {
    CAR_MONTHLY: 'CAR_MONTHLY',
    CAR_QUARTERLY: 'CAR_QUARTERLY',
    CAR_HALF_YEARLY: 'CAR_HALF_YEARLY',
    COMMERCIAL_MONTHLY: 'COMMERCIAL_MONTHLY',
    COMMERCIAL_QUARTERLY: 'COMMERCIAL_QUARTERLY',
    COMMERCIAL_HALF_YEARLY: 'COMMERCIAL_HALF_YEARLY',
    TAXI_MONTHLY: 'TAXI_MONTHLY',
    BUS_MONTHLY: 'BUS_MONTHLY',
    MINIBUS_MONTHLY: 'MINIBUS_MONTHLY',
    TRUCK_MONTHLY: 'TRUCK_MONTHLY',
    TEAYOULIT_MONTHLY: 'TEAYOULIT_MONTHLY',
    SMALL_TRUCK_MONTHLY: 'SMALL_TRUCK_MONTHLY',
    FORK_LIFT_MONTHLY: 'FORK_LIFT_MONTHLY',
    TRAILER_MONTHLY: 'TRAILER_MONTHLY',
    MOTOR_HOME_INSPECTION: 'MOTOR_HOME_INSPECTION'
} as const;

export type VehicleReportType = typeof VehicleReportType[keyof typeof VehicleReportType];


/**
 * 
 * @export
 * @interface VehicleTachograph
 */
export interface VehicleTachograph {
    /**
     * 
     * @type {number}
     * @memberof VehicleTachograph
     */
    'odometerStart'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleTachograph
     */
    'odometerEnd'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleTachograph
     */
    'isOverspeeding'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VehicleTachograph
     */
    'maxSpeed'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleTachograph
     */
    'isOvertime'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VehicleTachograph
     */
    'totalHours'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleTachograph
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTachograph
     */
    'startDateTime': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTachograph
     */
    'endDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTachograph
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTachograph
     */
    'relatedDriverId': string;
    /**
     * 
     * @type {DriverUser}
     * @memberof VehicleTachograph
     */
    'relatedDriver'?: DriverUser;
    /**
     * 
     * @type {string}
     * @memberof VehicleTachograph
     */
    'photoUrl'?: string;
}
/**
 * 
 * @export
 * @interface VehicleTachographBase
 */
export interface VehicleTachographBase {
    /**
     * 
     * @type {number}
     * @memberof VehicleTachographBase
     */
    'odometerStart'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleTachographBase
     */
    'odometerEnd'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleTachographBase
     */
    'isOverspeeding'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VehicleTachographBase
     */
    'maxSpeed'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleTachographBase
     */
    'isOvertime'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VehicleTachographBase
     */
    'totalHours'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleTachographBase
     */
    'notes'?: string;
}
/**
 * 
 * @export
 * @interface VehicleTachographBaseWithDate
 */
export interface VehicleTachographBaseWithDate {
    /**
     * 
     * @type {number}
     * @memberof VehicleTachographBaseWithDate
     */
    'odometerStart'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleTachographBaseWithDate
     */
    'odometerEnd'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleTachographBaseWithDate
     */
    'isOverspeeding'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VehicleTachographBaseWithDate
     */
    'maxSpeed'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleTachographBaseWithDate
     */
    'isOvertime'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VehicleTachographBaseWithDate
     */
    'totalHours'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleTachographBaseWithDate
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTachographBaseWithDate
     */
    'startDateTime': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTachographBaseWithDate
     */
    'endDateTime'?: string;
}
/**
 * 
 * @export
 * @interface VehicleTachographEntry
 */
export interface VehicleTachographEntry {
    /**
     * 
     * @type {number}
     * @memberof VehicleTachographEntry
     */
    'odometerStart'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleTachographEntry
     */
    'odometerEnd'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleTachographEntry
     */
    'isOverspeeding'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VehicleTachographEntry
     */
    'maxSpeed'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleTachographEntry
     */
    'isOvertime'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VehicleTachographEntry
     */
    'totalHours'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleTachographEntry
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTachographEntry
     */
    'startDateTime': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTachographEntry
     */
    'endDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTachographEntry
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTachographEntry
     */
    'relatedDriverId': string;
    /**
     * 
     * @type {DriverUser}
     * @memberof VehicleTachographEntry
     */
    'relatedDriver'?: DriverUser;
}
/**
 * 
 * @export
 * @interface VehicleTachographHeader
 */
export interface VehicleTachographHeader {
    /**
     * 
     * @type {string}
     * @memberof VehicleTachographHeader
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTachographHeader
     */
    'startDateTime': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTachographHeader
     */
    'endDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTachographHeader
     */
    'relatedDriverName': string;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleTachographHeader
     */
    'isOverspeeding'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleTachographHeader
     */
    'isOvertime'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VehicleTachographHeader
     */
    'totalHours'?: number;
}
/**
 * 
 * @export
 * @interface VehicleTestResult
 */
export interface VehicleTestResult {
    /**
     * 
     * @type {string}
     * @memberof VehicleTestResult
     */
    'testId': string;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleTestResult
     */
    'result'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VehicleTestResult
     */
    'notes'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleTestResult
     */
    'attachedFiles'?: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const VehicleType = {
    CAR: 'CAR',
    COMMERCIAL: 'COMMERCIAL',
    TAXI: 'TAXI',
    TRUCK: 'TRUCK',
    TRUCK_SMALL: 'TRUCK_SMALL',
    TEAYOULIT: 'TEAYOULIT',
    BUS: 'BUS',
    MINIBUS: 'MINIBUS',
    TRAILER: 'TRAILER',
    FORK_LIFT: 'FORK_LIFT',
    MOTOR_HOME: 'MOTOR_HOME',
    OTHER: 'OTHER'
} as const;

export type VehicleType = typeof VehicleType[keyof typeof VehicleType];


/**
 * 
 * @export
 * @interface VehiclesDataModel
 */
export interface VehiclesDataModel {
    /**
     * 
     * @type {string}
     * @memberof VehiclesDataModel
     */
    'manufacturer_id': string;
    /**
     * 
     * @type {Array<VehicleModel>}
     * @memberof VehiclesDataModel
     */
    'models': Array<VehicleModel>;
}
/**
 * 
 * @export
 * @interface WebAppConfig
 */
export interface WebAppConfig {
    /**
     * List of disabled features.
     * @type {Array<WebAppFeature>}
     * @memberof WebAppConfig
     */
    'disabledFeatures': Array<WebAppFeature>;
    /**
     * Number of days before alerts.
     * @type {number}
     * @memberof WebAppConfig
     */
    'daysBeforeAlerts'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const WebAppFeature = {
    MULTI_CLIENTS: 'MULTI_CLIENTS',
    VEHICLE_DOCUMENTS: 'VEHICLE_DOCUMENTS',
    VEHICLE_INVOICES: 'VEHICLE_INVOICES',
    VEHICLE_INCIDENTS: 'VEHICLE_INCIDENTS',
    DRIVERS_MANAGEMENT: 'DRIVERS_MANAGEMENT',
    COURSES: 'COURSES'
} as const;

export type WebAppFeature = typeof WebAppFeature[keyof typeof WebAppFeature];


/**
 * 
 * @export
 * @interface WebsiteProduct
 */
export interface WebsiteProduct {
    /**
     * 
     * @type {string}
     * @memberof WebsiteProduct
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteProduct
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteProduct
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof WebsiteProduct
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof WebsiteProduct
     */
    'currency': string;
    /**
     * 
     * @type {WebsiteProductType}
     * @memberof WebsiteProduct
     */
    'type': WebsiteProductType;
    /**
     * 
     * @type {Array<WebsiteProductFeature>}
     * @memberof WebsiteProduct
     */
    'featuresList': Array<WebsiteProductFeature>;
}


/**
 * 
 * @export
 * @interface WebsiteProductFeature
 */
export interface WebsiteProductFeature {
    /**
     * 
     * @type {string}
     * @memberof WebsiteProductFeature
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteProductFeature
     */
    'included': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const WebsiteProductType = {
    PAID_BAISC: 'PAID_BAISC',
    PAID_PLUS: 'PAID_PLUS',
    PAID_PREMIUM: 'PAID_PREMIUM',
    UNKNOW: 'UNKNOW'
} as const;

export type WebsiteProductType = typeof WebsiteProductType[keyof typeof WebsiteProductType];



/**
 * AlertsApi - axios parameter creator
 * @export
 */
export const AlertsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all alerts.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertsAllGet: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('alertsAllGet', 'clientId', clientId)
            const localVarPath = `/alerts/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate new alerts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertsGenerateGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/alerts/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get alert by ID.
         * @param {string} clientId ID of the client
         * @param {string} alertId ID of the alert
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertsGetAlertGet: async (clientId: string, alertId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('alertsGetAlertGet', 'clientId', clientId)
            // verify required parameter 'alertId' is not null or undefined
            assertParamExists('alertsGetAlertGet', 'alertId', alertId)
            const localVarPath = `/alerts/get_alert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (alertId !== undefined) {
                localVarQueryParameter['alertId'] = alertId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all alerts.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertsUnreadGet: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('alertsUnreadGet', 'clientId', clientId)
            const localVarPath = `/alerts/unread`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update alert as read.
         * @param {string} clientId ID of the client
         * @param {string} alertId ID of the alert
         * @param {boolean} isRead Is the alert read
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertsUpdateGet: async (clientId: string, alertId: string, isRead: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('alertsUpdateGet', 'clientId', clientId)
            // verify required parameter 'alertId' is not null or undefined
            assertParamExists('alertsUpdateGet', 'alertId', alertId)
            // verify required parameter 'isRead' is not null or undefined
            assertParamExists('alertsUpdateGet', 'isRead', isRead)
            const localVarPath = `/alerts/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (alertId !== undefined) {
                localVarQueryParameter['alertId'] = alertId;
            }

            if (isRead !== undefined) {
                localVarQueryParameter['isRead'] = isRead;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlertsApi - functional programming interface
 * @export
 */
export const AlertsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AlertsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all alerts.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async alertsAllGet(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Alert>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.alertsAllGet(clientId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AlertsApi.alertsAllGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Generate new alerts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async alertsGenerateGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.alertsGenerateGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AlertsApi.alertsGenerateGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get alert by ID.
         * @param {string} clientId ID of the client
         * @param {string} alertId ID of the alert
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async alertsGetAlertGet(clientId: string, alertId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Alert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.alertsGetAlertGet(clientId, alertId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AlertsApi.alertsGetAlertGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get all alerts.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async alertsUnreadGet(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Alert>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.alertsUnreadGet(clientId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AlertsApi.alertsUnreadGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update alert as read.
         * @param {string} clientId ID of the client
         * @param {string} alertId ID of the alert
         * @param {boolean} isRead Is the alert read
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async alertsUpdateGet(clientId: string, alertId: string, isRead: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Alert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.alertsUpdateGet(clientId, alertId, isRead, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AlertsApi.alertsUpdateGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AlertsApi - factory interface
 * @export
 */
export const AlertsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AlertsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all alerts.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertsAllGet(clientId: string, options?: any): AxiosPromise<Array<Alert>> {
            return localVarFp.alertsAllGet(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate new alerts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertsGenerateGet(options?: any): AxiosPromise<boolean> {
            return localVarFp.alertsGenerateGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get alert by ID.
         * @param {string} clientId ID of the client
         * @param {string} alertId ID of the alert
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertsGetAlertGet(clientId: string, alertId: string, options?: any): AxiosPromise<Alert> {
            return localVarFp.alertsGetAlertGet(clientId, alertId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all alerts.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertsUnreadGet(clientId: string, options?: any): AxiosPromise<Array<Alert>> {
            return localVarFp.alertsUnreadGet(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update alert as read.
         * @param {string} clientId ID of the client
         * @param {string} alertId ID of the alert
         * @param {boolean} isRead Is the alert read
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertsUpdateGet(clientId: string, alertId: string, isRead: boolean, options?: any): AxiosPromise<Alert> {
            return localVarFp.alertsUpdateGet(clientId, alertId, isRead, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AlertsApi - object-oriented interface
 * @export
 * @class AlertsApi
 * @extends {BaseAPI}
 */
export class AlertsApi extends BaseAPI {
    /**
     * 
     * @summary Get all alerts.
     * @param {string} clientId ID of the client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertsApi
     */
    public alertsAllGet(clientId: string, options?: AxiosRequestConfig) {
        return AlertsApiFp(this.configuration).alertsAllGet(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate new alerts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertsApi
     */
    public alertsGenerateGet(options?: AxiosRequestConfig) {
        return AlertsApiFp(this.configuration).alertsGenerateGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get alert by ID.
     * @param {string} clientId ID of the client
     * @param {string} alertId ID of the alert
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertsApi
     */
    public alertsGetAlertGet(clientId: string, alertId: string, options?: AxiosRequestConfig) {
        return AlertsApiFp(this.configuration).alertsGetAlertGet(clientId, alertId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all alerts.
     * @param {string} clientId ID of the client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertsApi
     */
    public alertsUnreadGet(clientId: string, options?: AxiosRequestConfig) {
        return AlertsApiFp(this.configuration).alertsUnreadGet(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update alert as read.
     * @param {string} clientId ID of the client
     * @param {string} alertId ID of the alert
     * @param {boolean} isRead Is the alert read
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertsApi
     */
    public alertsUpdateGet(clientId: string, alertId: string, isRead: boolean, options?: AxiosRequestConfig) {
        return AlertsApiFp(this.configuration).alertsUpdateGet(clientId, alertId, isRead, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AppApi - axios parameter creator
 * @export
 */
export const AppApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send email.
         * @param {string} sendTo Email address to send to.
         * @param {string} subject Email subject.
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appSendEmailPost: async (sendTo: string, subject: string, body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendTo' is not null or undefined
            assertParamExists('appSendEmailPost', 'sendTo', sendTo)
            // verify required parameter 'subject' is not null or undefined
            assertParamExists('appSendEmailPost', 'subject', subject)
            const localVarPath = `/app/send_email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sendTo !== undefined) {
                localVarQueryParameter['sendTo'] = sendTo;
            }

            if (subject !== undefined) {
                localVarQueryParameter['subject'] = subject;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/text';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send feedback.
         * @param {SendFeedbackReq} [sendFeedbackReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appSendFeedbackPost: async (sendFeedbackReq?: SendFeedbackReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/app/send_feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendFeedbackReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get vehicle configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appVehicleConfigGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/app/vehicle_config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppApi - functional programming interface
 * @export
 */
export const AppApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send email.
         * @param {string} sendTo Email address to send to.
         * @param {string} subject Email subject.
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appSendEmailPost(sendTo: string, subject: string, body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appSendEmailPost(sendTo, subject, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppApi.appSendEmailPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send feedback.
         * @param {SendFeedbackReq} [sendFeedbackReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appSendFeedbackPost(sendFeedbackReq?: SendFeedbackReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appSendFeedbackPost(sendFeedbackReq, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppApi.appSendFeedbackPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get vehicle configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appVehicleConfigGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appVehicleConfigGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppApi.appVehicleConfigGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AppApi - factory interface
 * @export
 */
export const AppApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppApiFp(configuration)
    return {
        /**
         * 
         * @summary Send email.
         * @param {string} sendTo Email address to send to.
         * @param {string} subject Email subject.
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appSendEmailPost(sendTo: string, subject: string, body?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.appSendEmailPost(sendTo, subject, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send feedback.
         * @param {SendFeedbackReq} [sendFeedbackReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appSendFeedbackPost(sendFeedbackReq?: SendFeedbackReq, options?: any): AxiosPromise<boolean> {
            return localVarFp.appSendFeedbackPost(sendFeedbackReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get vehicle configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appVehicleConfigGet(options?: any): AxiosPromise<VehicleConfig> {
            return localVarFp.appVehicleConfigGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppApi - object-oriented interface
 * @export
 * @class AppApi
 * @extends {BaseAPI}
 */
export class AppApi extends BaseAPI {
    /**
     * 
     * @summary Send email.
     * @param {string} sendTo Email address to send to.
     * @param {string} subject Email subject.
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public appSendEmailPost(sendTo: string, subject: string, body?: string, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).appSendEmailPost(sendTo, subject, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send feedback.
     * @param {SendFeedbackReq} [sendFeedbackReq] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public appSendFeedbackPost(sendFeedbackReq?: SendFeedbackReq, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).appSendFeedbackPost(sendFeedbackReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get vehicle configuration.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public appVehicleConfigGet(options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).appVehicleConfigGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AppConfigApi - axios parameter creator
 * @export
 */
export const AppConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get available report types.
         * @param {string} langCode Language code
         * @param {VehicleType} vehicleType Type of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigAvailableReportTypesGet: async (langCode: string, vehicleType: VehicleType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'langCode' is not null or undefined
            assertParamExists('appConfigAvailableReportTypesGet', 'langCode', langCode)
            // verify required parameter 'vehicleType' is not null or undefined
            assertParamExists('appConfigAvailableReportTypesGet', 'vehicleType', vehicleType)
            const localVarPath = `/app_config/available_report_types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }

            if (vehicleType !== undefined) {
                localVarQueryParameter['vehicleType'] = vehicleType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add or update user push device token.
         * @param {string} deviceId deviceId of the client device
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigDeleteDeviceDelete: async (deviceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('appConfigDeleteDeviceDelete', 'deviceId', deviceId)
            const localVarPath = `/app_config/delete_device`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get document configuration.
         * @param {string} langCode Language code
         * @param {VehicleReportType} reportType Type of the vehicle test report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigDocumentConfigGet: async (langCode: string, reportType: VehicleReportType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'langCode' is not null or undefined
            assertParamExists('appConfigDocumentConfigGet', 'langCode', langCode)
            // verify required parameter 'reportType' is not null or undefined
            assertParamExists('appConfigDocumentConfigGet', 'reportType', reportType)
            const localVarPath = `/app_config/document_config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }

            if (reportType !== undefined) {
                localVarQueryParameter['reportType'] = reportType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add or update user push device token.
         * @param {string} deviceId deviceId of the client device
         * @param {UserDevice} [userDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigRegisterDevicePost: async (deviceId: string, userDevice?: UserDevice, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('appConfigRegisterDevicePost', 'deviceId', deviceId)
            const localVarPath = `/app_config/register_device`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDevice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set the number of days before sending an alert.
         * @param {number} numberOfDays 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigSetDaysBeforeAlertsPost: async (numberOfDays: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'numberOfDays' is not null or undefined
            assertParamExists('appConfigSetDaysBeforeAlertsPost', 'numberOfDays', numberOfDays)
            const localVarPath = `/app_config/set_days_before_alerts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (numberOfDays !== undefined) {
                localVarQueryParameter['numberOfDays'] = numberOfDays;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get static document configuration.
         * @param {string} langCode Language code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigStaticDocumentConfigGet: async (langCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'langCode' is not null or undefined
            assertParamExists('appConfigStaticDocumentConfigGet', 'langCode', langCode)
            const localVarPath = `/app_config/static_document_config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get static test categories and questions.
         * @param {VehicleReportType} reportType Type of the vehicle test report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigStaticTestCategoriesQuestionsGet: async (reportType: VehicleReportType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportType' is not null or undefined
            assertParamExists('appConfigStaticTestCategoriesQuestionsGet', 'reportType', reportType)
            const localVarPath = `/app_config/static_test_categories_questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reportType !== undefined) {
                localVarQueryParameter['reportType'] = reportType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} langCode 
         * @param {string} vehicleId 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigStaticVehicleDocumentConfigGet: async (langCode: string, vehicleId: string, clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'langCode' is not null or undefined
            assertParamExists('appConfigStaticVehicleDocumentConfigGet', 'langCode', langCode)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('appConfigStaticVehicleDocumentConfigGet', 'vehicleId', vehicleId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('appConfigStaticVehicleDocumentConfigGet', 'clientId', clientId)
            const localVarPath = `/app_config/static_vehicle_document_config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }

            if (vehicleId !== undefined) {
                localVarQueryParameter['vehicleId'] = vehicleId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get document configuration with vehicle data.
         * @param {string} langCode Language code
         * @param {string} vehicleId 
         * @param {string} clientId 
         * @param {VehicleReportType} reportType Type of the vehicle test report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigUserSettingsDocumentConfigGet: async (langCode: string, vehicleId: string, clientId: string, reportType: VehicleReportType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'langCode' is not null or undefined
            assertParamExists('appConfigUserSettingsDocumentConfigGet', 'langCode', langCode)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('appConfigUserSettingsDocumentConfigGet', 'vehicleId', vehicleId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('appConfigUserSettingsDocumentConfigGet', 'clientId', clientId)
            // verify required parameter 'reportType' is not null or undefined
            assertParamExists('appConfigUserSettingsDocumentConfigGet', 'reportType', reportType)
            const localVarPath = `/app_config/user_settings_document_config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }

            if (vehicleId !== undefined) {
                localVarQueryParameter['vehicleId'] = vehicleId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (reportType !== undefined) {
                localVarQueryParameter['reportType'] = reportType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete vehicle test configuration.
         * @param {VehicleReportType} reportType Type of the vehicle test report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigVehicleTestsConfigDelete: async (reportType: VehicleReportType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportType' is not null or undefined
            assertParamExists('appConfigVehicleTestsConfigDelete', 'reportType', reportType)
            const localVarPath = `/app_config/vehicle_tests_config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reportType !== undefined) {
                localVarQueryParameter['reportType'] = reportType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get vehicle test configuration.
         * @param {VehicleReportType} reportType Type of the vehicle test report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigVehicleTestsConfigGet: async (reportType: VehicleReportType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportType' is not null or undefined
            assertParamExists('appConfigVehicleTestsConfigGet', 'reportType', reportType)
            const localVarPath = `/app_config/vehicle_tests_config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reportType !== undefined) {
                localVarQueryParameter['reportType'] = reportType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update vehicle test configuration.
         * @param {VehicleReportType} reportType Type of the vehicle test report
         * @param {VehicleReportConfig} [vehicleReportConfig] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigVehicleTestsConfigPatch: async (reportType: VehicleReportType, vehicleReportConfig?: VehicleReportConfig, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportType' is not null or undefined
            assertParamExists('appConfigVehicleTestsConfigPatch', 'reportType', reportType)
            const localVarPath = `/app_config/vehicle_tests_config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reportType !== undefined) {
                localVarQueryParameter['reportType'] = reportType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleReportConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set vehicle test configuration.
         * @param {VehicleReportType} reportType Type of the vehicle test report
         * @param {VehicleReportConfig} [vehicleReportConfig] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigVehicleTestsConfigPost: async (reportType: VehicleReportType, vehicleReportConfig?: VehicleReportConfig, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportType' is not null or undefined
            assertParamExists('appConfigVehicleTestsConfigPost', 'reportType', reportType)
            const localVarPath = `/app_config/vehicle_tests_config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reportType !== undefined) {
                localVarQueryParameter['reportType'] = reportType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleReportConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get web app configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigWebAppConfigGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/app_config/web_app_config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppConfigApi - functional programming interface
 * @export
 */
export const AppConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get available report types.
         * @param {string} langCode Language code
         * @param {VehicleType} vehicleType Type of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appConfigAvailableReportTypesGet(langCode: string, vehicleType: VehicleType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentConfigOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appConfigAvailableReportTypesGet(langCode, vehicleType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppConfigApi.appConfigAvailableReportTypesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Add or update user push device token.
         * @param {string} deviceId deviceId of the client device
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appConfigDeleteDeviceDelete(deviceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appConfigDeleteDeviceDelete(deviceId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppConfigApi.appConfigDeleteDeviceDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get document configuration.
         * @param {string} langCode Language code
         * @param {VehicleReportType} reportType Type of the vehicle test report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appConfigDocumentConfigGet(langCode: string, reportType: VehicleReportType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appConfigDocumentConfigGet(langCode, reportType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppConfigApi.appConfigDocumentConfigGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Add or update user push device token.
         * @param {string} deviceId deviceId of the client device
         * @param {UserDevice} [userDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appConfigRegisterDevicePost(deviceId: string, userDevice?: UserDevice, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appConfigRegisterDevicePost(deviceId, userDevice, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppConfigApi.appConfigRegisterDevicePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Set the number of days before sending an alert.
         * @param {number} numberOfDays 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appConfigSetDaysBeforeAlertsPost(numberOfDays: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appConfigSetDaysBeforeAlertsPost(numberOfDays, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppConfigApi.appConfigSetDaysBeforeAlertsPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get static document configuration.
         * @param {string} langCode Language code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appConfigStaticDocumentConfigGet(langCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentInput>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appConfigStaticDocumentConfigGet(langCode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppConfigApi.appConfigStaticDocumentConfigGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get static test categories and questions.
         * @param {VehicleReportType} reportType Type of the vehicle test report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appConfigStaticTestCategoriesQuestionsGet(reportType: VehicleReportType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appConfigStaticTestCategoriesQuestionsGet(reportType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppConfigApi.appConfigStaticTestCategoriesQuestionsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} langCode 
         * @param {string} vehicleId 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appConfigStaticVehicleDocumentConfigGet(langCode: string, vehicleId: string, clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentInput>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appConfigStaticVehicleDocumentConfigGet(langCode, vehicleId, clientId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppConfigApi.appConfigStaticVehicleDocumentConfigGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get document configuration with vehicle data.
         * @param {string} langCode Language code
         * @param {string} vehicleId 
         * @param {string} clientId 
         * @param {VehicleReportType} reportType Type of the vehicle test report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appConfigUserSettingsDocumentConfigGet(langCode: string, vehicleId: string, clientId: string, reportType: VehicleReportType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appConfigUserSettingsDocumentConfigGet(langCode, vehicleId, clientId, reportType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppConfigApi.appConfigUserSettingsDocumentConfigGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete vehicle test configuration.
         * @param {VehicleReportType} reportType Type of the vehicle test report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appConfigVehicleTestsConfigDelete(reportType: VehicleReportType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appConfigVehicleTestsConfigDelete(reportType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppConfigApi.appConfigVehicleTestsConfigDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get vehicle test configuration.
         * @param {VehicleReportType} reportType Type of the vehicle test report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appConfigVehicleTestsConfigGet(reportType: VehicleReportType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleReportConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appConfigVehicleTestsConfigGet(reportType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppConfigApi.appConfigVehicleTestsConfigGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update vehicle test configuration.
         * @param {VehicleReportType} reportType Type of the vehicle test report
         * @param {VehicleReportConfig} [vehicleReportConfig] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appConfigVehicleTestsConfigPatch(reportType: VehicleReportType, vehicleReportConfig?: VehicleReportConfig, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appConfigVehicleTestsConfigPatch(reportType, vehicleReportConfig, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppConfigApi.appConfigVehicleTestsConfigPatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Set vehicle test configuration.
         * @param {VehicleReportType} reportType Type of the vehicle test report
         * @param {VehicleReportConfig} [vehicleReportConfig] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appConfigVehicleTestsConfigPost(reportType: VehicleReportType, vehicleReportConfig?: VehicleReportConfig, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appConfigVehicleTestsConfigPost(reportType, vehicleReportConfig, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppConfigApi.appConfigVehicleTestsConfigPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get web app configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appConfigWebAppConfigGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebAppConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appConfigWebAppConfigGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppConfigApi.appConfigWebAppConfigGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AppConfigApi - factory interface
 * @export
 */
export const AppConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppConfigApiFp(configuration)
    return {
        /**
         * 
         * @summary Get available report types.
         * @param {string} langCode Language code
         * @param {VehicleType} vehicleType Type of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigAvailableReportTypesGet(langCode: string, vehicleType: VehicleType, options?: any): AxiosPromise<Array<DocumentConfigOption>> {
            return localVarFp.appConfigAvailableReportTypesGet(langCode, vehicleType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add or update user push device token.
         * @param {string} deviceId deviceId of the client device
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigDeleteDeviceDelete(deviceId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.appConfigDeleteDeviceDelete(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get document configuration.
         * @param {string} langCode Language code
         * @param {VehicleReportType} reportType Type of the vehicle test report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigDocumentConfigGet(langCode: string, reportType: VehicleReportType, options?: any): AxiosPromise<DocumentConfig> {
            return localVarFp.appConfigDocumentConfigGet(langCode, reportType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add or update user push device token.
         * @param {string} deviceId deviceId of the client device
         * @param {UserDevice} [userDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigRegisterDevicePost(deviceId: string, userDevice?: UserDevice, options?: any): AxiosPromise<boolean> {
            return localVarFp.appConfigRegisterDevicePost(deviceId, userDevice, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set the number of days before sending an alert.
         * @param {number} numberOfDays 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigSetDaysBeforeAlertsPost(numberOfDays: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.appConfigSetDaysBeforeAlertsPost(numberOfDays, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get static document configuration.
         * @param {string} langCode Language code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigStaticDocumentConfigGet(langCode: string, options?: any): AxiosPromise<Array<DocumentInput>> {
            return localVarFp.appConfigStaticDocumentConfigGet(langCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get static test categories and questions.
         * @param {VehicleReportType} reportType Type of the vehicle test report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigStaticTestCategoriesQuestionsGet(reportType: VehicleReportType, options?: any): AxiosPromise<Array<DocumentCategory>> {
            return localVarFp.appConfigStaticTestCategoriesQuestionsGet(reportType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} langCode 
         * @param {string} vehicleId 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigStaticVehicleDocumentConfigGet(langCode: string, vehicleId: string, clientId: string, options?: any): AxiosPromise<Array<DocumentInput>> {
            return localVarFp.appConfigStaticVehicleDocumentConfigGet(langCode, vehicleId, clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get document configuration with vehicle data.
         * @param {string} langCode Language code
         * @param {string} vehicleId 
         * @param {string} clientId 
         * @param {VehicleReportType} reportType Type of the vehicle test report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigUserSettingsDocumentConfigGet(langCode: string, vehicleId: string, clientId: string, reportType: VehicleReportType, options?: any): AxiosPromise<DocumentConfig> {
            return localVarFp.appConfigUserSettingsDocumentConfigGet(langCode, vehicleId, clientId, reportType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete vehicle test configuration.
         * @param {VehicleReportType} reportType Type of the vehicle test report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigVehicleTestsConfigDelete(reportType: VehicleReportType, options?: any): AxiosPromise<void> {
            return localVarFp.appConfigVehicleTestsConfigDelete(reportType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get vehicle test configuration.
         * @param {VehicleReportType} reportType Type of the vehicle test report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigVehicleTestsConfigGet(reportType: VehicleReportType, options?: any): AxiosPromise<VehicleReportConfig> {
            return localVarFp.appConfigVehicleTestsConfigGet(reportType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update vehicle test configuration.
         * @param {VehicleReportType} reportType Type of the vehicle test report
         * @param {VehicleReportConfig} [vehicleReportConfig] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigVehicleTestsConfigPatch(reportType: VehicleReportType, vehicleReportConfig?: VehicleReportConfig, options?: any): AxiosPromise<boolean> {
            return localVarFp.appConfigVehicleTestsConfigPatch(reportType, vehicleReportConfig, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set vehicle test configuration.
         * @param {VehicleReportType} reportType Type of the vehicle test report
         * @param {VehicleReportConfig} [vehicleReportConfig] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigVehicleTestsConfigPost(reportType: VehicleReportType, vehicleReportConfig?: VehicleReportConfig, options?: any): AxiosPromise<boolean> {
            return localVarFp.appConfigVehicleTestsConfigPost(reportType, vehicleReportConfig, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get web app configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appConfigWebAppConfigGet(options?: any): AxiosPromise<WebAppConfig> {
            return localVarFp.appConfigWebAppConfigGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppConfigApi - object-oriented interface
 * @export
 * @class AppConfigApi
 * @extends {BaseAPI}
 */
export class AppConfigApi extends BaseAPI {
    /**
     * 
     * @summary Get available report types.
     * @param {string} langCode Language code
     * @param {VehicleType} vehicleType Type of the vehicle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppConfigApi
     */
    public appConfigAvailableReportTypesGet(langCode: string, vehicleType: VehicleType, options?: AxiosRequestConfig) {
        return AppConfigApiFp(this.configuration).appConfigAvailableReportTypesGet(langCode, vehicleType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add or update user push device token.
     * @param {string} deviceId deviceId of the client device
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppConfigApi
     */
    public appConfigDeleteDeviceDelete(deviceId: string, options?: AxiosRequestConfig) {
        return AppConfigApiFp(this.configuration).appConfigDeleteDeviceDelete(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get document configuration.
     * @param {string} langCode Language code
     * @param {VehicleReportType} reportType Type of the vehicle test report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppConfigApi
     */
    public appConfigDocumentConfigGet(langCode: string, reportType: VehicleReportType, options?: AxiosRequestConfig) {
        return AppConfigApiFp(this.configuration).appConfigDocumentConfigGet(langCode, reportType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add or update user push device token.
     * @param {string} deviceId deviceId of the client device
     * @param {UserDevice} [userDevice] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppConfigApi
     */
    public appConfigRegisterDevicePost(deviceId: string, userDevice?: UserDevice, options?: AxiosRequestConfig) {
        return AppConfigApiFp(this.configuration).appConfigRegisterDevicePost(deviceId, userDevice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set the number of days before sending an alert.
     * @param {number} numberOfDays 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppConfigApi
     */
    public appConfigSetDaysBeforeAlertsPost(numberOfDays: number, options?: AxiosRequestConfig) {
        return AppConfigApiFp(this.configuration).appConfigSetDaysBeforeAlertsPost(numberOfDays, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get static document configuration.
     * @param {string} langCode Language code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppConfigApi
     */
    public appConfigStaticDocumentConfigGet(langCode: string, options?: AxiosRequestConfig) {
        return AppConfigApiFp(this.configuration).appConfigStaticDocumentConfigGet(langCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get static test categories and questions.
     * @param {VehicleReportType} reportType Type of the vehicle test report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppConfigApi
     */
    public appConfigStaticTestCategoriesQuestionsGet(reportType: VehicleReportType, options?: AxiosRequestConfig) {
        return AppConfigApiFp(this.configuration).appConfigStaticTestCategoriesQuestionsGet(reportType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} langCode 
     * @param {string} vehicleId 
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppConfigApi
     */
    public appConfigStaticVehicleDocumentConfigGet(langCode: string, vehicleId: string, clientId: string, options?: AxiosRequestConfig) {
        return AppConfigApiFp(this.configuration).appConfigStaticVehicleDocumentConfigGet(langCode, vehicleId, clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get document configuration with vehicle data.
     * @param {string} langCode Language code
     * @param {string} vehicleId 
     * @param {string} clientId 
     * @param {VehicleReportType} reportType Type of the vehicle test report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppConfigApi
     */
    public appConfigUserSettingsDocumentConfigGet(langCode: string, vehicleId: string, clientId: string, reportType: VehicleReportType, options?: AxiosRequestConfig) {
        return AppConfigApiFp(this.configuration).appConfigUserSettingsDocumentConfigGet(langCode, vehicleId, clientId, reportType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete vehicle test configuration.
     * @param {VehicleReportType} reportType Type of the vehicle test report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppConfigApi
     */
    public appConfigVehicleTestsConfigDelete(reportType: VehicleReportType, options?: AxiosRequestConfig) {
        return AppConfigApiFp(this.configuration).appConfigVehicleTestsConfigDelete(reportType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get vehicle test configuration.
     * @param {VehicleReportType} reportType Type of the vehicle test report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppConfigApi
     */
    public appConfigVehicleTestsConfigGet(reportType: VehicleReportType, options?: AxiosRequestConfig) {
        return AppConfigApiFp(this.configuration).appConfigVehicleTestsConfigGet(reportType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update vehicle test configuration.
     * @param {VehicleReportType} reportType Type of the vehicle test report
     * @param {VehicleReportConfig} [vehicleReportConfig] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppConfigApi
     */
    public appConfigVehicleTestsConfigPatch(reportType: VehicleReportType, vehicleReportConfig?: VehicleReportConfig, options?: AxiosRequestConfig) {
        return AppConfigApiFp(this.configuration).appConfigVehicleTestsConfigPatch(reportType, vehicleReportConfig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set vehicle test configuration.
     * @param {VehicleReportType} reportType Type of the vehicle test report
     * @param {VehicleReportConfig} [vehicleReportConfig] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppConfigApi
     */
    public appConfigVehicleTestsConfigPost(reportType: VehicleReportType, vehicleReportConfig?: VehicleReportConfig, options?: AxiosRequestConfig) {
        return AppConfigApiFp(this.configuration).appConfigVehicleTestsConfigPost(reportType, vehicleReportConfig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get web app configuration.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppConfigApi
     */
    public appConfigWebAppConfigGet(options?: AxiosRequestConfig) {
        return AppConfigApiFp(this.configuration).appConfigWebAppConfigGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ClientApi - axios parameter creator
 * @export
 */
export const ClientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AlertAddEmailContactProps} [alertAddEmailContactProps] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAddAlertEmailContactPost: async (alertAddEmailContactProps?: AlertAddEmailContactProps, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/client/add_alert_email_contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(alertAddEmailContactProps, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {AlertType} alertType 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDeleteAlertEmailContactDelete: async (clientId: string, alertType: AlertType, email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('clientDeleteAlertEmailContactDelete', 'clientId', clientId)
            // verify required parameter 'alertType' is not null or undefined
            assertParamExists('clientDeleteAlertEmailContactDelete', 'alertType', alertType)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('clientDeleteAlertEmailContactDelete', 'email', email)
            const localVarPath = `/client/delete_alert_email_contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (alertType !== undefined) {
                localVarQueryParameter['alertType'] = alertType;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get client data.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientGet: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('clientGet', 'clientId', clientId)
            const localVarPath = `/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get client data.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientGetClientWebConfigGet: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('clientGetClientWebConfigGet', 'clientId', clientId)
            const localVarPath = `/client/get_client_web_config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get client data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientGetDefaultSingleClientGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/client/get_default_single_client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update client data.
         * @param {Client} [client] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPatch: async (client?: Client, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(client, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a new client.
         * @param {Client} [client] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPost: async (client?: Client, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(client, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update client data.
         * @param {string} clientId ID of the client
         * @param {{ [key: string]: boolean; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientUpdateClientWebConfigPatch: async (clientId: string, requestBody?: { [key: string]: boolean; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('clientUpdateClientWebConfigPatch', 'clientId', clientId)
            const localVarPath = `/client/update_client_web_config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientApi - functional programming interface
 * @export
 */
export const ClientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AlertAddEmailContactProps} [alertAddEmailContactProps] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientAddAlertEmailContactPost(alertAddEmailContactProps?: AlertAddEmailContactProps, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientAddAlertEmailContactPost(alertAddEmailContactProps, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientApi.clientAddAlertEmailContactPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {AlertType} alertType 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientDeleteAlertEmailContactDelete(clientId: string, alertType: AlertType, email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientDeleteAlertEmailContactDelete(clientId, alertType, email, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientApi.clientDeleteAlertEmailContactDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get client data.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientGet(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientGet(clientId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientApi.clientGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get client data.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientGetClientWebConfigGet(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientWebAppConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientGetClientWebConfigGet(clientId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientApi.clientGetClientWebConfigGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get client data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientGetDefaultSingleClientGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientGetDefaultSingleClientGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientApi.clientGetDefaultSingleClientGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update client data.
         * @param {Client} [client] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientPatch(client?: Client, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientPatch(client, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientApi.clientPatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Add a new client.
         * @param {Client} [client] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientPost(client?: Client, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientPost(client, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientApi.clientPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update client data.
         * @param {string} clientId ID of the client
         * @param {{ [key: string]: boolean; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientUpdateClientWebConfigPatch(clientId: string, requestBody?: { [key: string]: boolean; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientWebAppConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientUpdateClientWebConfigPatch(clientId, requestBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientApi.clientUpdateClientWebConfigPatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ClientApi - factory interface
 * @export
 */
export const ClientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientApiFp(configuration)
    return {
        /**
         * 
         * @param {AlertAddEmailContactProps} [alertAddEmailContactProps] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAddAlertEmailContactPost(alertAddEmailContactProps?: AlertAddEmailContactProps, options?: any): AxiosPromise<boolean> {
            return localVarFp.clientAddAlertEmailContactPost(alertAddEmailContactProps, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId 
         * @param {AlertType} alertType 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDeleteAlertEmailContactDelete(clientId: string, alertType: AlertType, email: string, options?: any): AxiosPromise<void> {
            return localVarFp.clientDeleteAlertEmailContactDelete(clientId, alertType, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get client data.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientGet(clientId: string, options?: any): AxiosPromise<Client> {
            return localVarFp.clientGet(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get client data.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientGetClientWebConfigGet(clientId: string, options?: any): AxiosPromise<ClientWebAppConfig> {
            return localVarFp.clientGetClientWebConfigGet(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get client data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientGetDefaultSingleClientGet(options?: any): AxiosPromise<Client> {
            return localVarFp.clientGetDefaultSingleClientGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update client data.
         * @param {Client} [client] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPatch(client?: Client, options?: any): AxiosPromise<Client> {
            return localVarFp.clientPatch(client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a new client.
         * @param {Client} [client] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPost(client?: Client, options?: any): AxiosPromise<Client> {
            return localVarFp.clientPost(client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update client data.
         * @param {string} clientId ID of the client
         * @param {{ [key: string]: boolean; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientUpdateClientWebConfigPatch(clientId: string, requestBody?: { [key: string]: boolean; }, options?: any): AxiosPromise<ClientWebAppConfig> {
            return localVarFp.clientUpdateClientWebConfigPatch(clientId, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientApi - object-oriented interface
 * @export
 * @class ClientApi
 * @extends {BaseAPI}
 */
export class ClientApi extends BaseAPI {
    /**
     * 
     * @param {AlertAddEmailContactProps} [alertAddEmailContactProps] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public clientAddAlertEmailContactPost(alertAddEmailContactProps?: AlertAddEmailContactProps, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).clientAddAlertEmailContactPost(alertAddEmailContactProps, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId 
     * @param {AlertType} alertType 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public clientDeleteAlertEmailContactDelete(clientId: string, alertType: AlertType, email: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).clientDeleteAlertEmailContactDelete(clientId, alertType, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get client data.
     * @param {string} clientId ID of the client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public clientGet(clientId: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).clientGet(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get client data.
     * @param {string} clientId ID of the client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public clientGetClientWebConfigGet(clientId: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).clientGetClientWebConfigGet(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get client data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public clientGetDefaultSingleClientGet(options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).clientGetDefaultSingleClientGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update client data.
     * @param {Client} [client] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public clientPatch(client?: Client, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).clientPatch(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a new client.
     * @param {Client} [client] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public clientPost(client?: Client, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).clientPost(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update client data.
     * @param {string} clientId ID of the client
     * @param {{ [key: string]: boolean; }} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public clientUpdateClientWebConfigPatch(clientId: string, requestBody?: { [key: string]: boolean; }, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).clientUpdateClientWebConfigPatch(clientId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CoursesApi - axios parameter creator
 * @export
 */
export const CoursesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add course document image.
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {string} title 
         * @param {boolean} showToAttendees 
         * @param {string} documentCategory 
         * @param {Array<string>} pagesDataBase64 
         * @param {string} [description] 
         * @param {string} [externalUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesAddCourseDocumentImgPost: async (clientId: string, courseId: string, title: string, showToAttendees: boolean, documentCategory: string, pagesDataBase64: Array<string>, description?: string, externalUrl?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('coursesAddCourseDocumentImgPost', 'clientId', clientId)
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesAddCourseDocumentImgPost', 'courseId', courseId)
            // verify required parameter 'title' is not null or undefined
            assertParamExists('coursesAddCourseDocumentImgPost', 'title', title)
            // verify required parameter 'showToAttendees' is not null or undefined
            assertParamExists('coursesAddCourseDocumentImgPost', 'showToAttendees', showToAttendees)
            // verify required parameter 'documentCategory' is not null or undefined
            assertParamExists('coursesAddCourseDocumentImgPost', 'documentCategory', documentCategory)
            // verify required parameter 'pagesDataBase64' is not null or undefined
            assertParamExists('coursesAddCourseDocumentImgPost', 'pagesDataBase64', pagesDataBase64)
            const localVarPath = `/courses/add_course_document_img`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (showToAttendees !== undefined) {
                localVarQueryParameter['showToAttendees'] = showToAttendees;
            }

            if (documentCategory !== undefined) {
                localVarQueryParameter['documentCategory'] = documentCategory;
            }

            if (externalUrl !== undefined) {
                localVarQueryParameter['externalUrl'] = externalUrl;
            }

            if (pagesDataBase64) {
                localVarFormParams.append('pagesDataBase64', pagesDataBase64.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {string} title 
         * @param {boolean} showToAttendees 
         * @param {string} documentCategory 
         * @param {string} [description] 
         * @param {string} [externalUrl] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesAddCourseDocumentPdfPost: async (clientId: string, courseId: string, title: string, showToAttendees: boolean, documentCategory: string, description?: string, externalUrl?: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('coursesAddCourseDocumentPdfPost', 'clientId', clientId)
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesAddCourseDocumentPdfPost', 'courseId', courseId)
            // verify required parameter 'title' is not null or undefined
            assertParamExists('coursesAddCourseDocumentPdfPost', 'title', title)
            // verify required parameter 'showToAttendees' is not null or undefined
            assertParamExists('coursesAddCourseDocumentPdfPost', 'showToAttendees', showToAttendees)
            // verify required parameter 'documentCategory' is not null or undefined
            assertParamExists('coursesAddCourseDocumentPdfPost', 'documentCategory', documentCategory)
            const localVarPath = `/courses/add_course_document_pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (showToAttendees !== undefined) {
                localVarQueryParameter['showToAttendees'] = showToAttendees;
            }

            if (documentCategory !== undefined) {
                localVarQueryParameter['documentCategory'] = documentCategory;
            }

            if (externalUrl !== undefined) {
                localVarQueryParameter['externalUrl'] = externalUrl;
            }


            if (file !== undefined) { 
                localVarFormParams.set('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add course document preset.
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {Array<string>} pagesDataBase64 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesAddCourseDocumentPresetPost: async (clientId: string, courseId: string, pagesDataBase64: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('coursesAddCourseDocumentPresetPost', 'clientId', clientId)
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesAddCourseDocumentPresetPost', 'courseId', courseId)
            // verify required parameter 'pagesDataBase64' is not null or undefined
            assertParamExists('coursesAddCourseDocumentPresetPost', 'pagesDataBase64', pagesDataBase64)
            const localVarPath = `/courses/add_course_document_preset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }

            if (pagesDataBase64) {
                localVarFormParams.append('pagesDataBase64', pagesDataBase64.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a new course.
         * @param {string} clientId ID of the client
         * @param {CreateNewCourseBody} [createNewCourseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesCreatePost: async (clientId: string, createNewCourseBody?: CreateNewCourseBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('coursesCreatePost', 'clientId', clientId)
            const localVarPath = `/courses/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNewCourseBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete course document.
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesDeleteCourseFileDelete: async (clientId: string, courseId: string, documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('coursesDeleteCourseFileDelete', 'clientId', clientId)
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesDeleteCourseFileDelete', 'courseId', courseId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('coursesDeleteCourseFileDelete', 'documentId', documentId)
            const localVarPath = `/courses/delete_course_file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }

            if (documentId !== undefined) {
                localVarQueryParameter['documentId'] = documentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} [from] 
         * @param {string} [courseType] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetAllCourseSummeryDocumentPdfGet: async (clientId: string, from?: string, courseType?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('coursesGetAllCourseSummeryDocumentPdfGet', 'clientId', clientId)
            const localVarPath = `/courses/get_all_course_summery_document_pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (courseType !== undefined) {
                localVarQueryParameter['courseType'] = courseType;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all courses headers.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetAllHeadersGet: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('coursesGetAllHeadersGet', 'clientId', clientId)
            const localVarPath = `/courses/get_all_headers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId ID of the client
         * @param {string} courseId 
         * @param {string} atendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetAttendeeDataGet: async (clientId: string, courseId: string, atendeeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('coursesGetAttendeeDataGet', 'clientId', clientId)
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesGetAttendeeDataGet', 'courseId', courseId)
            // verify required parameter 'atendeeId' is not null or undefined
            assertParamExists('coursesGetAttendeeDataGet', 'atendeeId', atendeeId)
            const localVarPath = `/courses/get_attendee_data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }

            if (atendeeId !== undefined) {
                localVarQueryParameter['atendeeId'] = atendeeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all courses headers.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetAvailableAttendeesGet: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('coursesGetAvailableAttendeesGet', 'clientId', clientId)
            const localVarPath = `/courses/get_available_attendees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetCourseConfigOptionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/courses/get_course_config_options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get course document url.
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetCourseDocumentUrlGet: async (clientId: string, courseId: string, documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('coursesGetCourseDocumentUrlGet', 'clientId', clientId)
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesGetCourseDocumentUrlGet', 'courseId', courseId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('coursesGetCourseDocumentUrlGet', 'documentId', documentId)
            const localVarPath = `/courses/get_course_document_url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }

            if (documentId !== undefined) {
                localVarQueryParameter['documentId'] = documentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all course documents headers.
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {CourseDocumentCategory} [documentCategory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetCourseDocumentsHeadersGet: async (clientId: string, courseId: string, documentCategory?: CourseDocumentCategory, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('coursesGetCourseDocumentsHeadersGet', 'clientId', clientId)
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesGetCourseDocumentsHeadersGet', 'courseId', courseId)
            const localVarPath = `/courses/get_course_documents_headers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }

            if (documentCategory !== undefined) {
                localVarQueryParameter['documentCategory'] = documentCategory;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetCourseOrDocConfigGet: async (documentType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('coursesGetCourseOrDocConfigGet', 'documentType', documentType)
            const localVarPath = `/courses/get_course_or_doc_config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get headers of driver documents.
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {string} driverId 
         * @param {string} [documentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetCoursePresetDocumentPdfGet: async (clientId: string, courseId: string, driverId: string, documentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('coursesGetCoursePresetDocumentPdfGet', 'clientId', clientId)
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesGetCoursePresetDocumentPdfGet', 'courseId', courseId)
            // verify required parameter 'driverId' is not null or undefined
            assertParamExists('coursesGetCoursePresetDocumentPdfGet', 'driverId', driverId)
            const localVarPath = `/courses/get_course_preset_document_pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }

            if (documentId !== undefined) {
                localVarQueryParameter['documentId'] = documentId;
            }

            if (driverId !== undefined) {
                localVarQueryParameter['driverId'] = driverId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetCourseSummeryDocumentPdfGet: async (clientId: string, courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('coursesGetCourseSummeryDocumentPdfGet', 'clientId', clientId)
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesGetCourseSummeryDocumentPdfGet', 'courseId', courseId)
            const localVarPath = `/courses/get_course_summery_document_pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Data.
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetDataGet: async (clientId: string, courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('coursesGetDataGet', 'clientId', clientId)
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesGetDataGet', 'courseId', courseId)
            const localVarPath = `/courses/get_data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentType 
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetDocPreviewGet: async (documentType: string, driverId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('coursesGetDocPreviewGet', 'documentType', documentType)
            const localVarPath = `/courses/get_doc_preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }

            if (driverId !== undefined) {
                localVarQueryParameter['driverId'] = driverId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentType 
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetDocPreviewPdfGet: async (documentType: string, driverId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('coursesGetDocPreviewPdfGet', 'documentType', documentType)
            const localVarPath = `/courses/get_doc_preview_pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }

            if (driverId !== undefined) {
                localVarQueryParameter['driverId'] = driverId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Data.
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesNotifyAttendeesGet: async (clientId: string, courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('coursesNotifyAttendeesGet', 'clientId', clientId)
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesNotifyAttendeesGet', 'courseId', courseId)
            const localVarPath = `/courses/notify_attendees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId ID of the client
         * @param {string} courseId 
         * @param {string} atendeeId 
         * @param {UpdateAttendeeStatusBody} [updateAttendeeStatusBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesUpdateAttendeeStatusPatch: async (clientId: string, courseId: string, atendeeId: string, updateAttendeeStatusBody?: UpdateAttendeeStatusBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('coursesUpdateAttendeeStatusPatch', 'clientId', clientId)
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesUpdateAttendeeStatusPatch', 'courseId', courseId)
            // verify required parameter 'atendeeId' is not null or undefined
            assertParamExists('coursesUpdateAttendeeStatusPatch', 'atendeeId', atendeeId)
            const localVarPath = `/courses/update_attendee_status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }

            if (atendeeId !== undefined) {
                localVarQueryParameter['atendeeId'] = atendeeId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAttendeeStatusBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId ID of the client
         * @param {string} courseId 
         * @param {UpdateCourseDataBody} [updateCourseDataBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesUpdatePatch: async (clientId: string, courseId: string, updateCourseDataBody?: UpdateCourseDataBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('coursesUpdatePatch', 'clientId', clientId)
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesUpdatePatch', 'courseId', courseId)
            const localVarPath = `/courses/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCourseDataBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoursesApi - functional programming interface
 * @export
 */
export const CoursesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CoursesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add course document image.
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {string} title 
         * @param {boolean} showToAttendees 
         * @param {string} documentCategory 
         * @param {Array<string>} pagesDataBase64 
         * @param {string} [description] 
         * @param {string} [externalUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesAddCourseDocumentImgPost(clientId: string, courseId: string, title: string, showToAttendees: boolean, documentCategory: string, pagesDataBase64: Array<string>, description?: string, externalUrl?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDocumentBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesAddCourseDocumentImgPost(clientId, courseId, title, showToAttendees, documentCategory, pagesDataBase64, description, externalUrl, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CoursesApi.coursesAddCourseDocumentImgPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {string} title 
         * @param {boolean} showToAttendees 
         * @param {string} documentCategory 
         * @param {string} [description] 
         * @param {string} [externalUrl] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesAddCourseDocumentPdfPost(clientId: string, courseId: string, title: string, showToAttendees: boolean, documentCategory: string, description?: string, externalUrl?: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDocumentBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesAddCourseDocumentPdfPost(clientId, courseId, title, showToAttendees, documentCategory, description, externalUrl, file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CoursesApi.coursesAddCourseDocumentPdfPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Add course document preset.
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {Array<string>} pagesDataBase64 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesAddCourseDocumentPresetPost(clientId: string, courseId: string, pagesDataBase64: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDocumentBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesAddCourseDocumentPresetPost(clientId, courseId, pagesDataBase64, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CoursesApi.coursesAddCourseDocumentPresetPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Add a new course.
         * @param {string} clientId ID of the client
         * @param {CreateNewCourseBody} [createNewCourseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesCreatePost(clientId: string, createNewCourseBody?: CreateNewCourseBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesCreatePost(clientId, createNewCourseBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CoursesApi.coursesCreatePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete course document.
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesDeleteCourseFileDelete(clientId: string, courseId: string, documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesDeleteCourseFileDelete(clientId, courseId, documentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CoursesApi.coursesDeleteCourseFileDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} [from] 
         * @param {string} [courseType] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesGetAllCourseSummeryDocumentPdfGet(clientId: string, from?: string, courseType?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesGetAllCourseSummeryDocumentPdfGet(clientId, from, courseType, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CoursesApi.coursesGetAllCourseSummeryDocumentPdfGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get all courses headers.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesGetAllHeadersGet(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseHeader>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesGetAllHeadersGet(clientId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CoursesApi.coursesGetAllHeadersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId ID of the client
         * @param {string} courseId 
         * @param {string} atendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesGetAttendeeDataGet(clientId: string, courseId: string, atendeeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseAtendee>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesGetAttendeeDataGet(clientId, courseId, atendeeId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CoursesApi.coursesGetAttendeeDataGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get all courses headers.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesGetAvailableAttendeesGet(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseAtendee>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesGetAvailableAttendeesGet(clientId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CoursesApi.coursesGetAvailableAttendeesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesGetCourseConfigOptionsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentConfigOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesGetCourseConfigOptionsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CoursesApi.coursesGetCourseConfigOptionsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get course document url.
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesGetCourseDocumentUrlGet(clientId: string, courseId: string, documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesGetCourseDocumentUrlGet(clientId, courseId, documentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CoursesApi.coursesGetCourseDocumentUrlGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get all course documents headers.
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {CourseDocumentCategory} [documentCategory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesGetCourseDocumentsHeadersGet(clientId: string, courseId: string, documentCategory?: CourseDocumentCategory, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseDocumentBase>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesGetCourseDocumentsHeadersGet(clientId, courseId, documentCategory, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CoursesApi.coursesGetCourseDocumentsHeadersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} documentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesGetCourseOrDocConfigGet(documentType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesGetCourseOrDocConfigGet(documentType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CoursesApi.coursesGetCourseOrDocConfigGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get headers of driver documents.
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {string} driverId 
         * @param {string} [documentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesGetCoursePresetDocumentPdfGet(clientId: string, courseId: string, driverId: string, documentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesGetCoursePresetDocumentPdfGet(clientId, courseId, driverId, documentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CoursesApi.coursesGetCoursePresetDocumentPdfGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesGetCourseSummeryDocumentPdfGet(clientId: string, courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesGetCourseSummeryDocumentPdfGet(clientId, courseId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CoursesApi.coursesGetCourseSummeryDocumentPdfGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Data.
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesGetDataGet(clientId: string, courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesGetDataGet(clientId, courseId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CoursesApi.coursesGetDataGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} documentType 
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesGetDocPreviewGet(documentType: string, driverId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesGetDocPreviewGet(documentType, driverId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CoursesApi.coursesGetDocPreviewGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} documentType 
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesGetDocPreviewPdfGet(documentType: string, driverId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesGetDocPreviewPdfGet(documentType, driverId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CoursesApi.coursesGetDocPreviewPdfGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Data.
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesNotifyAttendeesGet(clientId: string, courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesNotifyAttendeesGet(clientId, courseId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CoursesApi.coursesNotifyAttendeesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId ID of the client
         * @param {string} courseId 
         * @param {string} atendeeId 
         * @param {UpdateAttendeeStatusBody} [updateAttendeeStatusBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesUpdateAttendeeStatusPatch(clientId: string, courseId: string, atendeeId: string, updateAttendeeStatusBody?: UpdateAttendeeStatusBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesUpdateAttendeeStatusPatch(clientId, courseId, atendeeId, updateAttendeeStatusBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CoursesApi.coursesUpdateAttendeeStatusPatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId ID of the client
         * @param {string} courseId 
         * @param {UpdateCourseDataBody} [updateCourseDataBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesUpdatePatch(clientId: string, courseId: string, updateCourseDataBody?: UpdateCourseDataBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesUpdatePatch(clientId, courseId, updateCourseDataBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CoursesApi.coursesUpdatePatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CoursesApi - factory interface
 * @export
 */
export const CoursesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CoursesApiFp(configuration)
    return {
        /**
         * 
         * @summary Add course document image.
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {string} title 
         * @param {boolean} showToAttendees 
         * @param {string} documentCategory 
         * @param {Array<string>} pagesDataBase64 
         * @param {string} [description] 
         * @param {string} [externalUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesAddCourseDocumentImgPost(clientId: string, courseId: string, title: string, showToAttendees: boolean, documentCategory: string, pagesDataBase64: Array<string>, description?: string, externalUrl?: string, options?: any): AxiosPromise<CourseDocumentBase> {
            return localVarFp.coursesAddCourseDocumentImgPost(clientId, courseId, title, showToAttendees, documentCategory, pagesDataBase64, description, externalUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {string} title 
         * @param {boolean} showToAttendees 
         * @param {string} documentCategory 
         * @param {string} [description] 
         * @param {string} [externalUrl] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesAddCourseDocumentPdfPost(clientId: string, courseId: string, title: string, showToAttendees: boolean, documentCategory: string, description?: string, externalUrl?: string, file?: File, options?: any): AxiosPromise<CourseDocumentBase> {
            return localVarFp.coursesAddCourseDocumentPdfPost(clientId, courseId, title, showToAttendees, documentCategory, description, externalUrl, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add course document preset.
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {Array<string>} pagesDataBase64 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesAddCourseDocumentPresetPost(clientId: string, courseId: string, pagesDataBase64: Array<string>, options?: any): AxiosPromise<CourseDocumentBase> {
            return localVarFp.coursesAddCourseDocumentPresetPost(clientId, courseId, pagesDataBase64, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a new course.
         * @param {string} clientId ID of the client
         * @param {CreateNewCourseBody} [createNewCourseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesCreatePost(clientId: string, createNewCourseBody?: CreateNewCourseBody, options?: any): AxiosPromise<CourseData> {
            return localVarFp.coursesCreatePost(clientId, createNewCourseBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete course document.
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesDeleteCourseFileDelete(clientId: string, courseId: string, documentId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.coursesDeleteCourseFileDelete(clientId, courseId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} [from] 
         * @param {string} [courseType] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetAllCourseSummeryDocumentPdfGet(clientId: string, from?: string, courseType?: string, to?: string, options?: any): AxiosPromise<File> {
            return localVarFp.coursesGetAllCourseSummeryDocumentPdfGet(clientId, from, courseType, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all courses headers.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetAllHeadersGet(clientId: string, options?: any): AxiosPromise<Array<CourseHeader>> {
            return localVarFp.coursesGetAllHeadersGet(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId ID of the client
         * @param {string} courseId 
         * @param {string} atendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetAttendeeDataGet(clientId: string, courseId: string, atendeeId: string, options?: any): AxiosPromise<CourseAtendee> {
            return localVarFp.coursesGetAttendeeDataGet(clientId, courseId, atendeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all courses headers.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetAvailableAttendeesGet(clientId: string, options?: any): AxiosPromise<Array<CourseAtendee>> {
            return localVarFp.coursesGetAvailableAttendeesGet(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetCourseConfigOptionsGet(options?: any): AxiosPromise<Array<DocumentConfigOption>> {
            return localVarFp.coursesGetCourseConfigOptionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get course document url.
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetCourseDocumentUrlGet(clientId: string, courseId: string, documentId: string, options?: any): AxiosPromise<string> {
            return localVarFp.coursesGetCourseDocumentUrlGet(clientId, courseId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all course documents headers.
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {CourseDocumentCategory} [documentCategory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetCourseDocumentsHeadersGet(clientId: string, courseId: string, documentCategory?: CourseDocumentCategory, options?: any): AxiosPromise<Array<CourseDocumentBase>> {
            return localVarFp.coursesGetCourseDocumentsHeadersGet(clientId, courseId, documentCategory, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetCourseOrDocConfigGet(documentType: string, options?: any): AxiosPromise<DocumentConfig> {
            return localVarFp.coursesGetCourseOrDocConfigGet(documentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get headers of driver documents.
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {string} driverId 
         * @param {string} [documentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetCoursePresetDocumentPdfGet(clientId: string, courseId: string, driverId: string, documentId?: string, options?: any): AxiosPromise<File> {
            return localVarFp.coursesGetCoursePresetDocumentPdfGet(clientId, courseId, driverId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetCourseSummeryDocumentPdfGet(clientId: string, courseId: string, options?: any): AxiosPromise<File> {
            return localVarFp.coursesGetCourseSummeryDocumentPdfGet(clientId, courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Data.
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetDataGet(clientId: string, courseId: string, options?: any): AxiosPromise<CourseData> {
            return localVarFp.coursesGetDataGet(clientId, courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentType 
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetDocPreviewGet(documentType: string, driverId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.coursesGetDocPreviewGet(documentType, driverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentType 
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetDocPreviewPdfGet(documentType: string, driverId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.coursesGetDocPreviewPdfGet(documentType, driverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Data.
         * @param {string} clientId 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesNotifyAttendeesGet(clientId: string, courseId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.coursesNotifyAttendeesGet(clientId, courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId ID of the client
         * @param {string} courseId 
         * @param {string} atendeeId 
         * @param {UpdateAttendeeStatusBody} [updateAttendeeStatusBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesUpdateAttendeeStatusPatch(clientId: string, courseId: string, atendeeId: string, updateAttendeeStatusBody?: UpdateAttendeeStatusBody, options?: any): AxiosPromise<boolean> {
            return localVarFp.coursesUpdateAttendeeStatusPatch(clientId, courseId, atendeeId, updateAttendeeStatusBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId ID of the client
         * @param {string} courseId 
         * @param {UpdateCourseDataBody} [updateCourseDataBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesUpdatePatch(clientId: string, courseId: string, updateCourseDataBody?: UpdateCourseDataBody, options?: any): AxiosPromise<boolean> {
            return localVarFp.coursesUpdatePatch(clientId, courseId, updateCourseDataBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CoursesApi - object-oriented interface
 * @export
 * @class CoursesApi
 * @extends {BaseAPI}
 */
export class CoursesApi extends BaseAPI {
    /**
     * 
     * @summary Add course document image.
     * @param {string} clientId 
     * @param {string} courseId 
     * @param {string} title 
     * @param {boolean} showToAttendees 
     * @param {string} documentCategory 
     * @param {Array<string>} pagesDataBase64 
     * @param {string} [description] 
     * @param {string} [externalUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesAddCourseDocumentImgPost(clientId: string, courseId: string, title: string, showToAttendees: boolean, documentCategory: string, pagesDataBase64: Array<string>, description?: string, externalUrl?: string, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesAddCourseDocumentImgPost(clientId, courseId, title, showToAttendees, documentCategory, pagesDataBase64, description, externalUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId 
     * @param {string} courseId 
     * @param {string} title 
     * @param {boolean} showToAttendees 
     * @param {string} documentCategory 
     * @param {string} [description] 
     * @param {string} [externalUrl] 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesAddCourseDocumentPdfPost(clientId: string, courseId: string, title: string, showToAttendees: boolean, documentCategory: string, description?: string, externalUrl?: string, file?: File, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesAddCourseDocumentPdfPost(clientId, courseId, title, showToAttendees, documentCategory, description, externalUrl, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add course document preset.
     * @param {string} clientId 
     * @param {string} courseId 
     * @param {Array<string>} pagesDataBase64 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesAddCourseDocumentPresetPost(clientId: string, courseId: string, pagesDataBase64: Array<string>, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesAddCourseDocumentPresetPost(clientId, courseId, pagesDataBase64, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a new course.
     * @param {string} clientId ID of the client
     * @param {CreateNewCourseBody} [createNewCourseBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesCreatePost(clientId: string, createNewCourseBody?: CreateNewCourseBody, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesCreatePost(clientId, createNewCourseBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete course document.
     * @param {string} clientId 
     * @param {string} courseId 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesDeleteCourseFileDelete(clientId: string, courseId: string, documentId: string, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesDeleteCourseFileDelete(clientId, courseId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId 
     * @param {string} [from] 
     * @param {string} [courseType] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesGetAllCourseSummeryDocumentPdfGet(clientId: string, from?: string, courseType?: string, to?: string, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesGetAllCourseSummeryDocumentPdfGet(clientId, from, courseType, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all courses headers.
     * @param {string} clientId ID of the client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesGetAllHeadersGet(clientId: string, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesGetAllHeadersGet(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId ID of the client
     * @param {string} courseId 
     * @param {string} atendeeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesGetAttendeeDataGet(clientId: string, courseId: string, atendeeId: string, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesGetAttendeeDataGet(clientId, courseId, atendeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all courses headers.
     * @param {string} clientId ID of the client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesGetAvailableAttendeesGet(clientId: string, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesGetAvailableAttendeesGet(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesGetCourseConfigOptionsGet(options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesGetCourseConfigOptionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get course document url.
     * @param {string} clientId 
     * @param {string} courseId 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesGetCourseDocumentUrlGet(clientId: string, courseId: string, documentId: string, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesGetCourseDocumentUrlGet(clientId, courseId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all course documents headers.
     * @param {string} clientId 
     * @param {string} courseId 
     * @param {CourseDocumentCategory} [documentCategory] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesGetCourseDocumentsHeadersGet(clientId: string, courseId: string, documentCategory?: CourseDocumentCategory, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesGetCourseDocumentsHeadersGet(clientId, courseId, documentCategory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} documentType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesGetCourseOrDocConfigGet(documentType: string, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesGetCourseOrDocConfigGet(documentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get headers of driver documents.
     * @param {string} clientId 
     * @param {string} courseId 
     * @param {string} driverId 
     * @param {string} [documentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesGetCoursePresetDocumentPdfGet(clientId: string, courseId: string, driverId: string, documentId?: string, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesGetCoursePresetDocumentPdfGet(clientId, courseId, driverId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesGetCourseSummeryDocumentPdfGet(clientId: string, courseId: string, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesGetCourseSummeryDocumentPdfGet(clientId, courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Data.
     * @param {string} clientId 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesGetDataGet(clientId: string, courseId: string, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesGetDataGet(clientId, courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} documentType 
     * @param {string} [driverId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesGetDocPreviewGet(documentType: string, driverId?: string, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesGetDocPreviewGet(documentType, driverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} documentType 
     * @param {string} [driverId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesGetDocPreviewPdfGet(documentType: string, driverId?: string, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesGetDocPreviewPdfGet(documentType, driverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Data.
     * @param {string} clientId 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesNotifyAttendeesGet(clientId: string, courseId: string, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesNotifyAttendeesGet(clientId, courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId ID of the client
     * @param {string} courseId 
     * @param {string} atendeeId 
     * @param {UpdateAttendeeStatusBody} [updateAttendeeStatusBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesUpdateAttendeeStatusPatch(clientId: string, courseId: string, atendeeId: string, updateAttendeeStatusBody?: UpdateAttendeeStatusBody, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesUpdateAttendeeStatusPatch(clientId, courseId, atendeeId, updateAttendeeStatusBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId ID of the client
     * @param {string} courseId 
     * @param {UpdateCourseDataBody} [updateCourseDataBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesUpdatePatch(clientId: string, courseId: string, updateCourseDataBody?: UpdateCourseDataBody, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesUpdatePatch(clientId, courseId, updateCourseDataBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all clients\' headers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardClientsHeadersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/clients_headers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get dashboard data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardDataGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardExpiredReportVehiclesHeadersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/expired_report_vehicles_headers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardGetAllUnreadAlertsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/get_all_unread_alerts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardGetAppOfflineDataGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/get_app_offline_data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all clients\' headers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardClientsHeadersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientHeader>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardClientsHeadersGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.dashboardClientsHeadersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get dashboard data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardDataGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardDataGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.dashboardDataGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardExpiredReportVehiclesHeadersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExpiredVehicleHeader>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardExpiredReportVehiclesHeadersGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.dashboardExpiredReportVehiclesHeadersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardGetAllUnreadAlertsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AlertDashbaord>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardGetAllUnreadAlertsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.dashboardGetAllUnreadAlertsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardGetAppOfflineDataGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppOfflineData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardGetAppOfflineDataGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.dashboardGetAppOfflineDataGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all clients\' headers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardClientsHeadersGet(options?: any): AxiosPromise<Array<ClientHeader>> {
            return localVarFp.dashboardClientsHeadersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get dashboard data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardDataGet(options?: any): AxiosPromise<DashboardData> {
            return localVarFp.dashboardDataGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardExpiredReportVehiclesHeadersGet(options?: any): AxiosPromise<Array<ExpiredVehicleHeader>> {
            return localVarFp.dashboardExpiredReportVehiclesHeadersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardGetAllUnreadAlertsGet(options?: any): AxiosPromise<Array<AlertDashbaord>> {
            return localVarFp.dashboardGetAllUnreadAlertsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardGetAppOfflineDataGet(options?: any): AxiosPromise<AppOfflineData> {
            return localVarFp.dashboardGetAppOfflineDataGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @summary Get all clients\' headers.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardClientsHeadersGet(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardClientsHeadersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get dashboard data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardDataGet(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardDataGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardExpiredReportVehiclesHeadersGet(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardExpiredReportVehiclesHeadersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardGetAllUnreadAlertsGet(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardGetAllUnreadAlertsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardGetAppOfflineDataGet(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardGetAppOfflineDataGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DriverApi - axios parameter creator
 * @export
 */
export const DriverApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all drivers.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverAllForMainUserAndClientGet: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('driverAllForMainUserAndClientGet', 'clientId', clientId)
            const localVarPath = `/driver/all_for_main_user_and_client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all drivers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverAllForMainUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver/all_for_main_user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [driverId] 
         * @param {string} [courseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverCompleteCourseGet: async (driverId?: string, courseId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver/complete_course`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (driverId !== undefined) {
                localVarQueryParameter['driverId'] = driverId;
            }

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete driver onboarding.
         * @param {CompleteDriverOnboardingReq} [completeDriverOnboardingReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverCompleteOnboardingPost: async (completeDriverOnboardingReq?: CompleteDriverOnboardingReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver/complete_onboarding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeDriverOnboardingReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a driver.
         * @param {string} driverId ID of the driver
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDelete: async (driverId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverId' is not null or undefined
            assertParamExists('driverDelete', 'driverId', driverId)
            const localVarPath = `/driver`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (driverId !== undefined) {
                localVarQueryParameter['driverId'] = driverId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download drive license copy.
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDownloadDriverLicenseCopyPdfGet: async (driverId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver/download_driver_license_copy_pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (driverId !== undefined) {
                localVarQueryParameter['driverId'] = driverId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate a new driver document.
         * @param {DriverDocumentCreateReq} [driverDocumentCreateReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverGenerateNewDriverDocumentPost: async (driverDocumentCreateReq?: DriverDocumentCreateReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver/generate_new_driver_document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(driverDocumentCreateReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [driverId] 
         * @param {string} [courseId] 
         * @param {string} [documentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverGetCourseDocumentUrlGet: async (driverId?: string, courseId?: string, documentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver/get_course_document_url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (driverId !== undefined) {
                localVarQueryParameter['driverId'] = driverId;
            }

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }

            if (documentId !== undefined) {
                localVarQueryParameter['documentId'] = documentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [driverId] 
         * @param {string} [courseId] 
         * @param {string} [documentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverGetCoursePresetDocuemntGet: async (driverId?: string, courseId?: string, documentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver/get_course_preset_docuemnt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (driverId !== undefined) {
                localVarQueryParameter['driverId'] = driverId;
            }

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }

            if (documentId !== undefined) {
                localVarQueryParameter['documentId'] = documentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [driverId] 
         * @param {string} [courseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverGetDriverCourseDataGet: async (driverId?: string, courseId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver/get_driver_course_data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (driverId !== undefined) {
                localVarQueryParameter['driverId'] = driverId;
            }

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get driver data.
         * @param {string} [driverId] 
         * @param {boolean} [includeCompletedCourses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverGetDriverCoursesHeadersGet: async (driverId?: string, includeCompletedCourses?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver/get_driver_courses_headers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (driverId !== undefined) {
                localVarQueryParameter['driverId'] = driverId;
            }

            if (includeCompletedCourses !== undefined) {
                localVarQueryParameter['includeCompletedCourses'] = includeCompletedCourses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get driver data.
         * @param {string} [driverId] ID of the driver
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverGetDriverDataForOnboardingGet: async (driverId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver/get_driver_data_for_onboarding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (driverId !== undefined) {
                localVarQueryParameter['driverId'] = driverId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get driver data.
         * @param {string} [driverId] ID of the driver
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverGetDriverDataGet: async (driverId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver/get_driver_data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (driverId !== undefined) {
                localVarQueryParameter['driverId'] = driverId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get headers of driver documents.
         * @param {string} driverId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverGetDriverDocumentPdfGet: async (driverId: string, documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverId' is not null or undefined
            assertParamExists('driverGetDriverDocumentPdfGet', 'driverId', driverId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('driverGetDriverDocumentPdfGet', 'documentId', documentId)
            const localVarPath = `/driver/get_driver_document_pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (driverId !== undefined) {
                localVarQueryParameter['driverId'] = driverId;
            }

            if (documentId !== undefined) {
                localVarQueryParameter['documentId'] = documentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get headers of driver documents.
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverGetDriverDocumentsHeadersGet: async (driverId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver/get_driver_documents_headers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (driverId !== undefined) {
                localVarQueryParameter['driverId'] = driverId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get driver data.
         * @param {string} [driverId] ID of the driver
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverGetDriverLicenseGet: async (driverId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver/get_driver_license`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (driverId !== undefined) {
                localVarQueryParameter['driverId'] = driverId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a driver.
         * @param {string} driverId ID of the driver
         * @param {DriverUser} [driverUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverPatch: async (driverId: string, driverUser?: DriverUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverId' is not null or undefined
            assertParamExists('driverPatch', 'driverId', driverId)
            const localVarPath = `/driver`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (driverId !== undefined) {
                localVarQueryParameter['driverId'] = driverId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(driverUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a new driver.
         * @param {string} relatedClientId ID of the client
         * @param {boolean} shouldNotifyDriver 
         * @param {DriverUser} [driverUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverPost: async (relatedClientId: string, shouldNotifyDriver: boolean, driverUser?: DriverUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'relatedClientId' is not null or undefined
            assertParamExists('driverPost', 'relatedClientId', relatedClientId)
            // verify required parameter 'shouldNotifyDriver' is not null or undefined
            assertParamExists('driverPost', 'shouldNotifyDriver', shouldNotifyDriver)
            const localVarPath = `/driver`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (relatedClientId !== undefined) {
                localVarQueryParameter['relatedClientId'] = relatedClientId;
            }

            if (shouldNotifyDriver !== undefined) {
                localVarQueryParameter['shouldNotifyDriver'] = shouldNotifyDriver;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(driverUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get headers of driver documents.
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverSendDriverOnboardingNotificationGet: async (driverId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver/send_driver_onboarding_notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (driverId !== undefined) {
                localVarQueryParameter['driverId'] = driverId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload driver license.
         * @param {UploadDriverLicenseReq} [uploadDriverLicenseReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverUploadDriverLicensePost: async (uploadDriverLicenseReq?: UploadDriverLicenseReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver/upload_driver_license`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadDriverLicenseReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DriverApi - functional programming interface
 * @export
 */
export const DriverApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DriverApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all drivers.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverAllForMainUserAndClientGet(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DriverUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverAllForMainUserAndClientGet(clientId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverApi.driverAllForMainUserAndClientGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get all drivers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverAllForMainUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DriverUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverAllForMainUserGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverApi.driverAllForMainUserGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [driverId] 
         * @param {string} [courseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverCompleteCourseGet(driverId?: string, courseId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverCompleteCourseGet(driverId, courseId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverApi.driverCompleteCourseGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Complete driver onboarding.
         * @param {CompleteDriverOnboardingReq} [completeDriverOnboardingReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverCompleteOnboardingPost(completeDriverOnboardingReq?: CompleteDriverOnboardingReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverCompleteOnboardingPost(completeDriverOnboardingReq, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverApi.driverCompleteOnboardingPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a driver.
         * @param {string} driverId ID of the driver
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverDelete(driverId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverDelete(driverId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverApi.driverDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Download drive license copy.
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverDownloadDriverLicenseCopyPdfGet(driverId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverDownloadDriverLicenseCopyPdfGet(driverId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverApi.driverDownloadDriverLicenseCopyPdfGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Generate a new driver document.
         * @param {DriverDocumentCreateReq} [driverDocumentCreateReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverGenerateNewDriverDocumentPost(driverDocumentCreateReq?: DriverDocumentCreateReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DriverDocumentHeader>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverGenerateNewDriverDocumentPost(driverDocumentCreateReq, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverApi.driverGenerateNewDriverDocumentPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [driverId] 
         * @param {string} [courseId] 
         * @param {string} [documentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverGetCourseDocumentUrlGet(driverId?: string, courseId?: string, documentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverGetCourseDocumentUrlGet(driverId, courseId, documentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverApi.driverGetCourseDocumentUrlGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [driverId] 
         * @param {string} [courseId] 
         * @param {string} [documentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverGetCoursePresetDocuemntGet(driverId?: string, courseId?: string, documentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverGetCoursePresetDocuemntGet(driverId, courseId, documentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverApi.driverGetCoursePresetDocuemntGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [driverId] 
         * @param {string} [courseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverGetDriverCourseDataGet(driverId?: string, courseId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DriverCourseData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverGetDriverCourseDataGet(driverId, courseId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverApi.driverGetDriverCourseDataGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get driver data.
         * @param {string} [driverId] 
         * @param {boolean} [includeCompletedCourses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverGetDriverCoursesHeadersGet(driverId?: string, includeCompletedCourses?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DriverCourseHeader>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverGetDriverCoursesHeadersGet(driverId, includeCompletedCourses, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverApi.driverGetDriverCoursesHeadersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get driver data.
         * @param {string} [driverId] ID of the driver
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverGetDriverDataForOnboardingGet(driverId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DriverUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverGetDriverDataForOnboardingGet(driverId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverApi.driverGetDriverDataForOnboardingGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get driver data.
         * @param {string} [driverId] ID of the driver
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverGetDriverDataGet(driverId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DriverUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverGetDriverDataGet(driverId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverApi.driverGetDriverDataGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get headers of driver documents.
         * @param {string} driverId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverGetDriverDocumentPdfGet(driverId: string, documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverGetDriverDocumentPdfGet(driverId, documentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverApi.driverGetDriverDocumentPdfGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get headers of driver documents.
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverGetDriverDocumentsHeadersGet(driverId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DriverDocumentHeader>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverGetDriverDocumentsHeadersGet(driverId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverApi.driverGetDriverDocumentsHeadersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get driver data.
         * @param {string} [driverId] ID of the driver
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverGetDriverLicenseGet(driverId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DriverLicenseRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverGetDriverLicenseGet(driverId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverApi.driverGetDriverLicenseGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update a driver.
         * @param {string} driverId ID of the driver
         * @param {DriverUser} [driverUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverPatch(driverId: string, driverUser?: DriverUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DriverUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverPatch(driverId, driverUser, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverApi.driverPatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Add a new driver.
         * @param {string} relatedClientId ID of the client
         * @param {boolean} shouldNotifyDriver 
         * @param {DriverUser} [driverUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverPost(relatedClientId: string, shouldNotifyDriver: boolean, driverUser?: DriverUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DriverUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverPost(relatedClientId, shouldNotifyDriver, driverUser, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverApi.driverPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get headers of driver documents.
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverSendDriverOnboardingNotificationGet(driverId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverSendDriverOnboardingNotificationGet(driverId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverApi.driverSendDriverOnboardingNotificationGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Upload driver license.
         * @param {UploadDriverLicenseReq} [uploadDriverLicenseReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverUploadDriverLicensePost(uploadDriverLicenseReq?: UploadDriverLicenseReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverUploadDriverLicensePost(uploadDriverLicenseReq, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverApi.driverUploadDriverLicensePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DriverApi - factory interface
 * @export
 */
export const DriverApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DriverApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all drivers.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverAllForMainUserAndClientGet(clientId: string, options?: any): AxiosPromise<Array<DriverUser>> {
            return localVarFp.driverAllForMainUserAndClientGet(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all drivers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverAllForMainUserGet(options?: any): AxiosPromise<Array<DriverUser>> {
            return localVarFp.driverAllForMainUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [driverId] 
         * @param {string} [courseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverCompleteCourseGet(driverId?: string, courseId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.driverCompleteCourseGet(driverId, courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete driver onboarding.
         * @param {CompleteDriverOnboardingReq} [completeDriverOnboardingReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverCompleteOnboardingPost(completeDriverOnboardingReq?: CompleteDriverOnboardingReq, options?: any): AxiosPromise<boolean> {
            return localVarFp.driverCompleteOnboardingPost(completeDriverOnboardingReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a driver.
         * @param {string} driverId ID of the driver
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDelete(driverId: string, options?: any): AxiosPromise<string> {
            return localVarFp.driverDelete(driverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download drive license copy.
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDownloadDriverLicenseCopyPdfGet(driverId?: string, options?: any): AxiosPromise<File> {
            return localVarFp.driverDownloadDriverLicenseCopyPdfGet(driverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate a new driver document.
         * @param {DriverDocumentCreateReq} [driverDocumentCreateReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverGenerateNewDriverDocumentPost(driverDocumentCreateReq?: DriverDocumentCreateReq, options?: any): AxiosPromise<DriverDocumentHeader> {
            return localVarFp.driverGenerateNewDriverDocumentPost(driverDocumentCreateReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [driverId] 
         * @param {string} [courseId] 
         * @param {string} [documentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverGetCourseDocumentUrlGet(driverId?: string, courseId?: string, documentId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.driverGetCourseDocumentUrlGet(driverId, courseId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [driverId] 
         * @param {string} [courseId] 
         * @param {string} [documentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverGetCoursePresetDocuemntGet(driverId?: string, courseId?: string, documentId?: string, options?: any): AxiosPromise<File> {
            return localVarFp.driverGetCoursePresetDocuemntGet(driverId, courseId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [driverId] 
         * @param {string} [courseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverGetDriverCourseDataGet(driverId?: string, courseId?: string, options?: any): AxiosPromise<DriverCourseData> {
            return localVarFp.driverGetDriverCourseDataGet(driverId, courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get driver data.
         * @param {string} [driverId] 
         * @param {boolean} [includeCompletedCourses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverGetDriverCoursesHeadersGet(driverId?: string, includeCompletedCourses?: boolean, options?: any): AxiosPromise<Array<DriverCourseHeader>> {
            return localVarFp.driverGetDriverCoursesHeadersGet(driverId, includeCompletedCourses, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get driver data.
         * @param {string} [driverId] ID of the driver
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverGetDriverDataForOnboardingGet(driverId?: string, options?: any): AxiosPromise<DriverUser> {
            return localVarFp.driverGetDriverDataForOnboardingGet(driverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get driver data.
         * @param {string} [driverId] ID of the driver
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverGetDriverDataGet(driverId?: string, options?: any): AxiosPromise<DriverUser> {
            return localVarFp.driverGetDriverDataGet(driverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get headers of driver documents.
         * @param {string} driverId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverGetDriverDocumentPdfGet(driverId: string, documentId: string, options?: any): AxiosPromise<File> {
            return localVarFp.driverGetDriverDocumentPdfGet(driverId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get headers of driver documents.
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverGetDriverDocumentsHeadersGet(driverId?: string, options?: any): AxiosPromise<Array<DriverDocumentHeader>> {
            return localVarFp.driverGetDriverDocumentsHeadersGet(driverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get driver data.
         * @param {string} [driverId] ID of the driver
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverGetDriverLicenseGet(driverId?: string, options?: any): AxiosPromise<DriverLicenseRes> {
            return localVarFp.driverGetDriverLicenseGet(driverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a driver.
         * @param {string} driverId ID of the driver
         * @param {DriverUser} [driverUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverPatch(driverId: string, driverUser?: DriverUser, options?: any): AxiosPromise<DriverUser> {
            return localVarFp.driverPatch(driverId, driverUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a new driver.
         * @param {string} relatedClientId ID of the client
         * @param {boolean} shouldNotifyDriver 
         * @param {DriverUser} [driverUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverPost(relatedClientId: string, shouldNotifyDriver: boolean, driverUser?: DriverUser, options?: any): AxiosPromise<DriverUser> {
            return localVarFp.driverPost(relatedClientId, shouldNotifyDriver, driverUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get headers of driver documents.
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverSendDriverOnboardingNotificationGet(driverId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.driverSendDriverOnboardingNotificationGet(driverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload driver license.
         * @param {UploadDriverLicenseReq} [uploadDriverLicenseReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverUploadDriverLicensePost(uploadDriverLicenseReq?: UploadDriverLicenseReq, options?: any): AxiosPromise<boolean> {
            return localVarFp.driverUploadDriverLicensePost(uploadDriverLicenseReq, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DriverApi - object-oriented interface
 * @export
 * @class DriverApi
 * @extends {BaseAPI}
 */
export class DriverApi extends BaseAPI {
    /**
     * 
     * @summary Get all drivers.
     * @param {string} clientId ID of the client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverAllForMainUserAndClientGet(clientId: string, options?: AxiosRequestConfig) {
        return DriverApiFp(this.configuration).driverAllForMainUserAndClientGet(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all drivers.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverAllForMainUserGet(options?: AxiosRequestConfig) {
        return DriverApiFp(this.configuration).driverAllForMainUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [driverId] 
     * @param {string} [courseId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverCompleteCourseGet(driverId?: string, courseId?: string, options?: AxiosRequestConfig) {
        return DriverApiFp(this.configuration).driverCompleteCourseGet(driverId, courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete driver onboarding.
     * @param {CompleteDriverOnboardingReq} [completeDriverOnboardingReq] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverCompleteOnboardingPost(completeDriverOnboardingReq?: CompleteDriverOnboardingReq, options?: AxiosRequestConfig) {
        return DriverApiFp(this.configuration).driverCompleteOnboardingPost(completeDriverOnboardingReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a driver.
     * @param {string} driverId ID of the driver
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverDelete(driverId: string, options?: AxiosRequestConfig) {
        return DriverApiFp(this.configuration).driverDelete(driverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download drive license copy.
     * @param {string} [driverId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverDownloadDriverLicenseCopyPdfGet(driverId?: string, options?: AxiosRequestConfig) {
        return DriverApiFp(this.configuration).driverDownloadDriverLicenseCopyPdfGet(driverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate a new driver document.
     * @param {DriverDocumentCreateReq} [driverDocumentCreateReq] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverGenerateNewDriverDocumentPost(driverDocumentCreateReq?: DriverDocumentCreateReq, options?: AxiosRequestConfig) {
        return DriverApiFp(this.configuration).driverGenerateNewDriverDocumentPost(driverDocumentCreateReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [driverId] 
     * @param {string} [courseId] 
     * @param {string} [documentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverGetCourseDocumentUrlGet(driverId?: string, courseId?: string, documentId?: string, options?: AxiosRequestConfig) {
        return DriverApiFp(this.configuration).driverGetCourseDocumentUrlGet(driverId, courseId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [driverId] 
     * @param {string} [courseId] 
     * @param {string} [documentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverGetCoursePresetDocuemntGet(driverId?: string, courseId?: string, documentId?: string, options?: AxiosRequestConfig) {
        return DriverApiFp(this.configuration).driverGetCoursePresetDocuemntGet(driverId, courseId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [driverId] 
     * @param {string} [courseId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverGetDriverCourseDataGet(driverId?: string, courseId?: string, options?: AxiosRequestConfig) {
        return DriverApiFp(this.configuration).driverGetDriverCourseDataGet(driverId, courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get driver data.
     * @param {string} [driverId] 
     * @param {boolean} [includeCompletedCourses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverGetDriverCoursesHeadersGet(driverId?: string, includeCompletedCourses?: boolean, options?: AxiosRequestConfig) {
        return DriverApiFp(this.configuration).driverGetDriverCoursesHeadersGet(driverId, includeCompletedCourses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get driver data.
     * @param {string} [driverId] ID of the driver
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverGetDriverDataForOnboardingGet(driverId?: string, options?: AxiosRequestConfig) {
        return DriverApiFp(this.configuration).driverGetDriverDataForOnboardingGet(driverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get driver data.
     * @param {string} [driverId] ID of the driver
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverGetDriverDataGet(driverId?: string, options?: AxiosRequestConfig) {
        return DriverApiFp(this.configuration).driverGetDriverDataGet(driverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get headers of driver documents.
     * @param {string} driverId 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverGetDriverDocumentPdfGet(driverId: string, documentId: string, options?: AxiosRequestConfig) {
        return DriverApiFp(this.configuration).driverGetDriverDocumentPdfGet(driverId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get headers of driver documents.
     * @param {string} [driverId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverGetDriverDocumentsHeadersGet(driverId?: string, options?: AxiosRequestConfig) {
        return DriverApiFp(this.configuration).driverGetDriverDocumentsHeadersGet(driverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get driver data.
     * @param {string} [driverId] ID of the driver
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverGetDriverLicenseGet(driverId?: string, options?: AxiosRequestConfig) {
        return DriverApiFp(this.configuration).driverGetDriverLicenseGet(driverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a driver.
     * @param {string} driverId ID of the driver
     * @param {DriverUser} [driverUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverPatch(driverId: string, driverUser?: DriverUser, options?: AxiosRequestConfig) {
        return DriverApiFp(this.configuration).driverPatch(driverId, driverUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a new driver.
     * @param {string} relatedClientId ID of the client
     * @param {boolean} shouldNotifyDriver 
     * @param {DriverUser} [driverUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverPost(relatedClientId: string, shouldNotifyDriver: boolean, driverUser?: DriverUser, options?: AxiosRequestConfig) {
        return DriverApiFp(this.configuration).driverPost(relatedClientId, shouldNotifyDriver, driverUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get headers of driver documents.
     * @param {string} [driverId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverSendDriverOnboardingNotificationGet(driverId?: string, options?: AxiosRequestConfig) {
        return DriverApiFp(this.configuration).driverSendDriverOnboardingNotificationGet(driverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload driver license.
     * @param {UploadDriverLicenseReq} [uploadDriverLicenseReq] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverUploadDriverLicensePost(uploadDriverLicenseReq?: UploadDriverLicenseReq, options?: AxiosRequestConfig) {
        return DriverApiFp(this.configuration).driverUploadDriverLicensePost(uploadDriverLicenseReq, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DriverDocumentsApi - axios parameter creator
 * @export
 */
export const DriverDocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DriverDocumentCreateReqV1} [driverDocumentCreateReqV1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDocumentsAddNewDriverDocumentPost: async (driverDocumentCreateReqV1?: DriverDocumentCreateReqV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver/documents/add_new_driver_document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(driverDocumentCreateReqV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete driver document.
         * @param {string} driverUserId 
         * @param {DriverDocumentUploadType} documentType Type of the document
         * @param {string} [documentId] ID of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDocumentsDeleteDelete: async (driverUserId: string, documentType: DriverDocumentUploadType, documentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverUserId' is not null or undefined
            assertParamExists('driverDocumentsDeleteDelete', 'driverUserId', driverUserId)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('driverDocumentsDeleteDelete', 'documentType', documentType)
            const localVarPath = `/driver/documents/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (driverUserId !== undefined) {
                localVarQueryParameter['driverUserId'] = driverUserId;
            }

            if (documentId !== undefined) {
                localVarQueryParameter['documentId'] = documentId;
            }

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get driver documents.
         * @param {string} driverUserId 
         * @param {DriverDocumentUploadType} [documentType] Type of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDocumentsGetAllGet: async (driverUserId: string, documentType?: DriverDocumentUploadType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverUserId' is not null or undefined
            assertParamExists('driverDocumentsGetAllGet', 'driverUserId', driverUserId)
            const localVarPath = `/driver/documents/get_all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (driverUserId !== undefined) {
                localVarQueryParameter['driverUserId'] = driverUserId;
            }

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDocumentsGetAllHeadersGet: async (driverId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver/documents/get_all_headers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (driverId !== undefined) {
                localVarQueryParameter['driverId'] = driverId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentType 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDocumentsGetDocConfigGet: async (documentType: string, clientId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('driverDocumentsGetDocConfigGet', 'documentType', documentType)
            const localVarPath = `/driver/documents/get_doc_config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} driverId 
         * @param {string} documentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDocumentsGetDocPreviewGet: async (driverId: string, documentType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverId' is not null or undefined
            assertParamExists('driverDocumentsGetDocPreviewGet', 'driverId', driverId)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('driverDocumentsGetDocPreviewGet', 'documentType', documentType)
            const localVarPath = `/driver/documents/get_doc_preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (driverId !== undefined) {
                localVarQueryParameter['driverId'] = driverId;
            }

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDocumentsGetDocsConfigOptionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver/documents/get_docs_config_options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get headers of driver documents.
         * @param {string} driverId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDocumentsGetDriverDocumentPdfGet: async (driverId: string, documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverId' is not null or undefined
            assertParamExists('driverDocumentsGetDriverDocumentPdfGet', 'driverId', driverId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('driverDocumentsGetDriverDocumentPdfGet', 'documentId', documentId)
            const localVarPath = `/driver/documents/get_driver_document_pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (driverId !== undefined) {
                localVarQueryParameter['driverId'] = driverId;
            }

            if (documentId !== undefined) {
                localVarQueryParameter['documentId'] = documentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get driver document.
         * @param {string} driverUserId 
         * @param {DriverDocumentUploadType} documentType Type of the document
         * @param {string} [documentId] ID of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDocumentsGetGet: async (driverUserId: string, documentType: DriverDocumentUploadType, documentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverUserId' is not null or undefined
            assertParamExists('driverDocumentsGetGet', 'driverUserId', driverUserId)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('driverDocumentsGetGet', 'documentType', documentType)
            const localVarPath = `/driver/documents/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (driverUserId !== undefined) {
                localVarQueryParameter['driverUserId'] = driverUserId;
            }

            if (documentId !== undefined) {
                localVarQueryParameter['documentId'] = documentId;
            }

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDocumentsGetUploadedDocsConfigOptionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver/documents/get_uploaded_docs_config_options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update driver document date.
         * @param {string} driverUserId 
         * @param {string} documentId 
         * @param {DriverDocumentUploadType} documentType 
         * @param {string} expirationDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDocumentsUpdateExpirationDatePatch: async (driverUserId: string, documentId: string, documentType: DriverDocumentUploadType, expirationDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverUserId' is not null or undefined
            assertParamExists('driverDocumentsUpdateExpirationDatePatch', 'driverUserId', driverUserId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('driverDocumentsUpdateExpirationDatePatch', 'documentId', documentId)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('driverDocumentsUpdateExpirationDatePatch', 'documentType', documentType)
            // verify required parameter 'expirationDate' is not null or undefined
            assertParamExists('driverDocumentsUpdateExpirationDatePatch', 'expirationDate', expirationDate)
            const localVarPath = `/driver/documents/update_expiration_date`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (driverUserId !== undefined) {
                localVarQueryParameter['driverUserId'] = driverUserId;
            }

            if (documentId !== undefined) {
                localVarQueryParameter['documentId'] = documentId;
            }

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }

            if (expirationDate !== undefined) {
                localVarQueryParameter['expirationDate'] = (expirationDate as any instanceof Date) ?
                    (expirationDate as any).toISOString().substring(0,10) :
                    expirationDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload driver document.
         * @param {string} driverUserId 
         * @param {DriverDocumentUploadType} documentType Type of the document
         * @param {string} [title] Title of the document
         * @param {boolean} [isHidden] 
         * @param {string} [expirationDate] Expiration date of the document
         * @param {DocumentUploadImgRequestBody} [documentUploadImgRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDocumentsUploadImagesPost: async (driverUserId: string, documentType: DriverDocumentUploadType, title?: string, isHidden?: boolean, expirationDate?: string, documentUploadImgRequestBody?: DocumentUploadImgRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverUserId' is not null or undefined
            assertParamExists('driverDocumentsUploadImagesPost', 'driverUserId', driverUserId)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('driverDocumentsUploadImagesPost', 'documentType', documentType)
            const localVarPath = `/driver/documents/upload_images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (driverUserId !== undefined) {
                localVarQueryParameter['driverUserId'] = driverUserId;
            }

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (isHidden !== undefined) {
                localVarQueryParameter['isHidden'] = isHidden;
            }

            if (expirationDate !== undefined) {
                localVarQueryParameter['expirationDate'] = (expirationDate as any instanceof Date) ?
                    (expirationDate as any).toISOString().substring(0,10) :
                    expirationDate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentUploadImgRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload driver document.
         * @param {string} driverUserId 
         * @param {DriverDocumentUploadType} documentType Type of the document
         * @param {string} [title] Title of the document
         * @param {boolean} [isHidden] 
         * @param {string} [expirationDate] Expiration date of the document
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDocumentsUploadPdfPost: async (driverUserId: string, documentType: DriverDocumentUploadType, title?: string, isHidden?: boolean, expirationDate?: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverUserId' is not null or undefined
            assertParamExists('driverDocumentsUploadPdfPost', 'driverUserId', driverUserId)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('driverDocumentsUploadPdfPost', 'documentType', documentType)
            const localVarPath = `/driver/documents/upload_pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (driverUserId !== undefined) {
                localVarQueryParameter['driverUserId'] = driverUserId;
            }

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (isHidden !== undefined) {
                localVarQueryParameter['isHidden'] = isHidden;
            }

            if (expirationDate !== undefined) {
                localVarQueryParameter['expirationDate'] = (expirationDate as any instanceof Date) ?
                    (expirationDate as any).toISOString().substring(0,10) :
                    expirationDate;
            }


            if (file !== undefined) { 
                localVarFormParams.set('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DriverDocumentsApi - functional programming interface
 * @export
 */
export const DriverDocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DriverDocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DriverDocumentCreateReqV1} [driverDocumentCreateReqV1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverDocumentsAddNewDriverDocumentPost(driverDocumentCreateReqV1?: DriverDocumentCreateReqV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DriverDocumentHeader>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverDocumentsAddNewDriverDocumentPost(driverDocumentCreateReqV1, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverDocumentsApi.driverDocumentsAddNewDriverDocumentPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete driver document.
         * @param {string} driverUserId 
         * @param {DriverDocumentUploadType} documentType Type of the document
         * @param {string} [documentId] ID of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverDocumentsDeleteDelete(driverUserId: string, documentType: DriverDocumentUploadType, documentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverDocumentsDeleteDelete(driverUserId, documentType, documentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverDocumentsApi.driverDocumentsDeleteDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get driver documents.
         * @param {string} driverUserId 
         * @param {DriverDocumentUploadType} [documentType] Type of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverDocumentsGetAllGet(driverUserId: string, documentType?: DriverDocumentUploadType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DriverDocumentUpload>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverDocumentsGetAllGet(driverUserId, documentType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverDocumentsApi.driverDocumentsGetAllGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverDocumentsGetAllHeadersGet(driverId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DriverDocumentHeader>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverDocumentsGetAllHeadersGet(driverId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverDocumentsApi.driverDocumentsGetAllHeadersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} documentType 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverDocumentsGetDocConfigGet(documentType: string, clientId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverDocumentsGetDocConfigGet(documentType, clientId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverDocumentsApi.driverDocumentsGetDocConfigGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} driverId 
         * @param {string} documentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverDocumentsGetDocPreviewGet(driverId: string, documentType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverDocumentsGetDocPreviewGet(driverId, documentType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverDocumentsApi.driverDocumentsGetDocPreviewGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverDocumentsGetDocsConfigOptionsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentConfigOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverDocumentsGetDocsConfigOptionsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverDocumentsApi.driverDocumentsGetDocsConfigOptionsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get headers of driver documents.
         * @param {string} driverId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverDocumentsGetDriverDocumentPdfGet(driverId: string, documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverDocumentsGetDriverDocumentPdfGet(driverId, documentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverDocumentsApi.driverDocumentsGetDriverDocumentPdfGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get driver document.
         * @param {string} driverUserId 
         * @param {DriverDocumentUploadType} documentType Type of the document
         * @param {string} [documentId] ID of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverDocumentsGetGet(driverUserId: string, documentType: DriverDocumentUploadType, documentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DriverDocumentUpload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverDocumentsGetGet(driverUserId, documentType, documentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverDocumentsApi.driverDocumentsGetGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverDocumentsGetUploadedDocsConfigOptionsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentConfigOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverDocumentsGetUploadedDocsConfigOptionsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverDocumentsApi.driverDocumentsGetUploadedDocsConfigOptionsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update driver document date.
         * @param {string} driverUserId 
         * @param {string} documentId 
         * @param {DriverDocumentUploadType} documentType 
         * @param {string} expirationDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverDocumentsUpdateExpirationDatePatch(driverUserId: string, documentId: string, documentType: DriverDocumentUploadType, expirationDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverDocumentsUpdateExpirationDatePatch(driverUserId, documentId, documentType, expirationDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverDocumentsApi.driverDocumentsUpdateExpirationDatePatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Upload driver document.
         * @param {string} driverUserId 
         * @param {DriverDocumentUploadType} documentType Type of the document
         * @param {string} [title] Title of the document
         * @param {boolean} [isHidden] 
         * @param {string} [expirationDate] Expiration date of the document
         * @param {DocumentUploadImgRequestBody} [documentUploadImgRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverDocumentsUploadImagesPost(driverUserId: string, documentType: DriverDocumentUploadType, title?: string, isHidden?: boolean, expirationDate?: string, documentUploadImgRequestBody?: DocumentUploadImgRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverDocumentsUploadImagesPost(driverUserId, documentType, title, isHidden, expirationDate, documentUploadImgRequestBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverDocumentsApi.driverDocumentsUploadImagesPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Upload driver document.
         * @param {string} driverUserId 
         * @param {DriverDocumentUploadType} documentType Type of the document
         * @param {string} [title] Title of the document
         * @param {boolean} [isHidden] 
         * @param {string} [expirationDate] Expiration date of the document
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverDocumentsUploadPdfPost(driverUserId: string, documentType: DriverDocumentUploadType, title?: string, isHidden?: boolean, expirationDate?: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverDocumentsUploadPdfPost(driverUserId, documentType, title, isHidden, expirationDate, file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DriverDocumentsApi.driverDocumentsUploadPdfPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DriverDocumentsApi - factory interface
 * @export
 */
export const DriverDocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DriverDocumentsApiFp(configuration)
    return {
        /**
         * 
         * @param {DriverDocumentCreateReqV1} [driverDocumentCreateReqV1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDocumentsAddNewDriverDocumentPost(driverDocumentCreateReqV1?: DriverDocumentCreateReqV1, options?: any): AxiosPromise<DriverDocumentHeader> {
            return localVarFp.driverDocumentsAddNewDriverDocumentPost(driverDocumentCreateReqV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete driver document.
         * @param {string} driverUserId 
         * @param {DriverDocumentUploadType} documentType Type of the document
         * @param {string} [documentId] ID of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDocumentsDeleteDelete(driverUserId: string, documentType: DriverDocumentUploadType, documentId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.driverDocumentsDeleteDelete(driverUserId, documentType, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get driver documents.
         * @param {string} driverUserId 
         * @param {DriverDocumentUploadType} [documentType] Type of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDocumentsGetAllGet(driverUserId: string, documentType?: DriverDocumentUploadType, options?: any): AxiosPromise<Array<DriverDocumentUpload>> {
            return localVarFp.driverDocumentsGetAllGet(driverUserId, documentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDocumentsGetAllHeadersGet(driverId?: string, options?: any): AxiosPromise<Array<DriverDocumentHeader>> {
            return localVarFp.driverDocumentsGetAllHeadersGet(driverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentType 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDocumentsGetDocConfigGet(documentType: string, clientId?: string, options?: any): AxiosPromise<DocumentConfig> {
            return localVarFp.driverDocumentsGetDocConfigGet(documentType, clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} driverId 
         * @param {string} documentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDocumentsGetDocPreviewGet(driverId: string, documentType: string, options?: any): AxiosPromise<string> {
            return localVarFp.driverDocumentsGetDocPreviewGet(driverId, documentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDocumentsGetDocsConfigOptionsGet(options?: any): AxiosPromise<Array<DocumentConfigOption>> {
            return localVarFp.driverDocumentsGetDocsConfigOptionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get headers of driver documents.
         * @param {string} driverId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDocumentsGetDriverDocumentPdfGet(driverId: string, documentId: string, options?: any): AxiosPromise<File> {
            return localVarFp.driverDocumentsGetDriverDocumentPdfGet(driverId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get driver document.
         * @param {string} driverUserId 
         * @param {DriverDocumentUploadType} documentType Type of the document
         * @param {string} [documentId] ID of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDocumentsGetGet(driverUserId: string, documentType: DriverDocumentUploadType, documentId?: string, options?: any): AxiosPromise<DriverDocumentUpload> {
            return localVarFp.driverDocumentsGetGet(driverUserId, documentType, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDocumentsGetUploadedDocsConfigOptionsGet(options?: any): AxiosPromise<Array<DocumentConfigOption>> {
            return localVarFp.driverDocumentsGetUploadedDocsConfigOptionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update driver document date.
         * @param {string} driverUserId 
         * @param {string} documentId 
         * @param {DriverDocumentUploadType} documentType 
         * @param {string} expirationDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDocumentsUpdateExpirationDatePatch(driverUserId: string, documentId: string, documentType: DriverDocumentUploadType, expirationDate: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.driverDocumentsUpdateExpirationDatePatch(driverUserId, documentId, documentType, expirationDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload driver document.
         * @param {string} driverUserId 
         * @param {DriverDocumentUploadType} documentType Type of the document
         * @param {string} [title] Title of the document
         * @param {boolean} [isHidden] 
         * @param {string} [expirationDate] Expiration date of the document
         * @param {DocumentUploadImgRequestBody} [documentUploadImgRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDocumentsUploadImagesPost(driverUserId: string, documentType: DriverDocumentUploadType, title?: string, isHidden?: boolean, expirationDate?: string, documentUploadImgRequestBody?: DocumentUploadImgRequestBody, options?: any): AxiosPromise<string> {
            return localVarFp.driverDocumentsUploadImagesPost(driverUserId, documentType, title, isHidden, expirationDate, documentUploadImgRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload driver document.
         * @param {string} driverUserId 
         * @param {DriverDocumentUploadType} documentType Type of the document
         * @param {string} [title] Title of the document
         * @param {boolean} [isHidden] 
         * @param {string} [expirationDate] Expiration date of the document
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverDocumentsUploadPdfPost(driverUserId: string, documentType: DriverDocumentUploadType, title?: string, isHidden?: boolean, expirationDate?: string, file?: File, options?: any): AxiosPromise<string> {
            return localVarFp.driverDocumentsUploadPdfPost(driverUserId, documentType, title, isHidden, expirationDate, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DriverDocumentsApi - object-oriented interface
 * @export
 * @class DriverDocumentsApi
 * @extends {BaseAPI}
 */
export class DriverDocumentsApi extends BaseAPI {
    /**
     * 
     * @param {DriverDocumentCreateReqV1} [driverDocumentCreateReqV1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDocumentsApi
     */
    public driverDocumentsAddNewDriverDocumentPost(driverDocumentCreateReqV1?: DriverDocumentCreateReqV1, options?: AxiosRequestConfig) {
        return DriverDocumentsApiFp(this.configuration).driverDocumentsAddNewDriverDocumentPost(driverDocumentCreateReqV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete driver document.
     * @param {string} driverUserId 
     * @param {DriverDocumentUploadType} documentType Type of the document
     * @param {string} [documentId] ID of the document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDocumentsApi
     */
    public driverDocumentsDeleteDelete(driverUserId: string, documentType: DriverDocumentUploadType, documentId?: string, options?: AxiosRequestConfig) {
        return DriverDocumentsApiFp(this.configuration).driverDocumentsDeleteDelete(driverUserId, documentType, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get driver documents.
     * @param {string} driverUserId 
     * @param {DriverDocumentUploadType} [documentType] Type of the document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDocumentsApi
     */
    public driverDocumentsGetAllGet(driverUserId: string, documentType?: DriverDocumentUploadType, options?: AxiosRequestConfig) {
        return DriverDocumentsApiFp(this.configuration).driverDocumentsGetAllGet(driverUserId, documentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [driverId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDocumentsApi
     */
    public driverDocumentsGetAllHeadersGet(driverId?: string, options?: AxiosRequestConfig) {
        return DriverDocumentsApiFp(this.configuration).driverDocumentsGetAllHeadersGet(driverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} documentType 
     * @param {string} [clientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDocumentsApi
     */
    public driverDocumentsGetDocConfigGet(documentType: string, clientId?: string, options?: AxiosRequestConfig) {
        return DriverDocumentsApiFp(this.configuration).driverDocumentsGetDocConfigGet(documentType, clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} driverId 
     * @param {string} documentType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDocumentsApi
     */
    public driverDocumentsGetDocPreviewGet(driverId: string, documentType: string, options?: AxiosRequestConfig) {
        return DriverDocumentsApiFp(this.configuration).driverDocumentsGetDocPreviewGet(driverId, documentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDocumentsApi
     */
    public driverDocumentsGetDocsConfigOptionsGet(options?: AxiosRequestConfig) {
        return DriverDocumentsApiFp(this.configuration).driverDocumentsGetDocsConfigOptionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get headers of driver documents.
     * @param {string} driverId 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDocumentsApi
     */
    public driverDocumentsGetDriverDocumentPdfGet(driverId: string, documentId: string, options?: AxiosRequestConfig) {
        return DriverDocumentsApiFp(this.configuration).driverDocumentsGetDriverDocumentPdfGet(driverId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get driver document.
     * @param {string} driverUserId 
     * @param {DriverDocumentUploadType} documentType Type of the document
     * @param {string} [documentId] ID of the document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDocumentsApi
     */
    public driverDocumentsGetGet(driverUserId: string, documentType: DriverDocumentUploadType, documentId?: string, options?: AxiosRequestConfig) {
        return DriverDocumentsApiFp(this.configuration).driverDocumentsGetGet(driverUserId, documentType, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDocumentsApi
     */
    public driverDocumentsGetUploadedDocsConfigOptionsGet(options?: AxiosRequestConfig) {
        return DriverDocumentsApiFp(this.configuration).driverDocumentsGetUploadedDocsConfigOptionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update driver document date.
     * @param {string} driverUserId 
     * @param {string} documentId 
     * @param {DriverDocumentUploadType} documentType 
     * @param {string} expirationDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDocumentsApi
     */
    public driverDocumentsUpdateExpirationDatePatch(driverUserId: string, documentId: string, documentType: DriverDocumentUploadType, expirationDate: string, options?: AxiosRequestConfig) {
        return DriverDocumentsApiFp(this.configuration).driverDocumentsUpdateExpirationDatePatch(driverUserId, documentId, documentType, expirationDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload driver document.
     * @param {string} driverUserId 
     * @param {DriverDocumentUploadType} documentType Type of the document
     * @param {string} [title] Title of the document
     * @param {boolean} [isHidden] 
     * @param {string} [expirationDate] Expiration date of the document
     * @param {DocumentUploadImgRequestBody} [documentUploadImgRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDocumentsApi
     */
    public driverDocumentsUploadImagesPost(driverUserId: string, documentType: DriverDocumentUploadType, title?: string, isHidden?: boolean, expirationDate?: string, documentUploadImgRequestBody?: DocumentUploadImgRequestBody, options?: AxiosRequestConfig) {
        return DriverDocumentsApiFp(this.configuration).driverDocumentsUploadImagesPost(driverUserId, documentType, title, isHidden, expirationDate, documentUploadImgRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload driver document.
     * @param {string} driverUserId 
     * @param {DriverDocumentUploadType} documentType Type of the document
     * @param {string} [title] Title of the document
     * @param {boolean} [isHidden] 
     * @param {string} [expirationDate] Expiration date of the document
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverDocumentsApi
     */
    public driverDocumentsUploadPdfPost(driverUserId: string, documentType: DriverDocumentUploadType, title?: string, isHidden?: boolean, expirationDate?: string, file?: File, options?: AxiosRequestConfig) {
        return DriverDocumentsApiFp(this.configuration).driverDocumentsUploadPdfPost(driverUserId, documentType, title, isHidden, expirationDate, file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PDFSafetyReportApi - axios parameter creator
 * @export
 */
export const PDFSafetyReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Email vehicle safety report in PDF format.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {string} langCode Language code
         * @param {string} [emailAddress] Email address to send the report
         * @param {string} [subject] Subject of the email
         * @param {string} [message] Body of the email
         * @param {boolean} [includeNotes] Include notes in the report (true/false)
         * @param {boolean} [includePictures] Include pictures in the report (true/false)
         * @param {boolean} [includeDriverDetails] Include driver details in the report (true/false)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pdfEmailVehicleSafetyReportPost: async (clientId: string, licenseNumber: string, reportId: string, langCode: string, emailAddress?: string, subject?: string, message?: string, includeNotes?: boolean, includePictures?: boolean, includeDriverDetails?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('pdfEmailVehicleSafetyReportPost', 'clientId', clientId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('pdfEmailVehicleSafetyReportPost', 'licenseNumber', licenseNumber)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('pdfEmailVehicleSafetyReportPost', 'reportId', reportId)
            // verify required parameter 'langCode' is not null or undefined
            assertParamExists('pdfEmailVehicleSafetyReportPost', 'langCode', langCode)
            const localVarPath = `/pdf/email_vehicle_safety_report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }

            if (reportId !== undefined) {
                localVarQueryParameter['reportId'] = reportId;
            }

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }

            if (subject !== undefined) {
                localVarQueryParameter['subject'] = subject;
            }

            if (message !== undefined) {
                localVarQueryParameter['message'] = message;
            }

            if (includeNotes !== undefined) {
                localVarQueryParameter['includeNotes'] = includeNotes;
            }

            if (includePictures !== undefined) {
                localVarQueryParameter['includePictures'] = includePictures;
            }

            if (includeDriverDetails !== undefined) {
                localVarQueryParameter['includeDriverDetails'] = includeDriverDetails;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate vehicle safety report in PDF format.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {string} langCode Language code
         * @param {boolean} [includeNotes] Include notes in the report (true/false)
         * @param {boolean} [includePictures] Include pictures in the report (true/false)
         * @param {boolean} [includeDriverDetails] Include driver details in the report (true/false)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pdfGenerateVehicleSafetyReportGet: async (clientId: string, licenseNumber: string, reportId: string, langCode: string, includeNotes?: boolean, includePictures?: boolean, includeDriverDetails?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('pdfGenerateVehicleSafetyReportGet', 'clientId', clientId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('pdfGenerateVehicleSafetyReportGet', 'licenseNumber', licenseNumber)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('pdfGenerateVehicleSafetyReportGet', 'reportId', reportId)
            // verify required parameter 'langCode' is not null or undefined
            assertParamExists('pdfGenerateVehicleSafetyReportGet', 'langCode', langCode)
            const localVarPath = `/pdf/generate_vehicle_safety_report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }

            if (reportId !== undefined) {
                localVarQueryParameter['reportId'] = reportId;
            }

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }

            if (includeNotes !== undefined) {
                localVarQueryParameter['includeNotes'] = includeNotes;
            }

            if (includePictures !== undefined) {
                localVarQueryParameter['includePictures'] = includePictures;
            }

            if (includeDriverDetails !== undefined) {
                localVarQueryParameter['includeDriverDetails'] = includeDriverDetails;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PDFSafetyReportApi - functional programming interface
 * @export
 */
export const PDFSafetyReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PDFSafetyReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Email vehicle safety report in PDF format.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {string} langCode Language code
         * @param {string} [emailAddress] Email address to send the report
         * @param {string} [subject] Subject of the email
         * @param {string} [message] Body of the email
         * @param {boolean} [includeNotes] Include notes in the report (true/false)
         * @param {boolean} [includePictures] Include pictures in the report (true/false)
         * @param {boolean} [includeDriverDetails] Include driver details in the report (true/false)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pdfEmailVehicleSafetyReportPost(clientId: string, licenseNumber: string, reportId: string, langCode: string, emailAddress?: string, subject?: string, message?: string, includeNotes?: boolean, includePictures?: boolean, includeDriverDetails?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pdfEmailVehicleSafetyReportPost(clientId, licenseNumber, reportId, langCode, emailAddress, subject, message, includeNotes, includePictures, includeDriverDetails, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PDFSafetyReportApi.pdfEmailVehicleSafetyReportPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Generate vehicle safety report in PDF format.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {string} langCode Language code
         * @param {boolean} [includeNotes] Include notes in the report (true/false)
         * @param {boolean} [includePictures] Include pictures in the report (true/false)
         * @param {boolean} [includeDriverDetails] Include driver details in the report (true/false)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pdfGenerateVehicleSafetyReportGet(clientId: string, licenseNumber: string, reportId: string, langCode: string, includeNotes?: boolean, includePictures?: boolean, includeDriverDetails?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pdfGenerateVehicleSafetyReportGet(clientId, licenseNumber, reportId, langCode, includeNotes, includePictures, includeDriverDetails, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PDFSafetyReportApi.pdfGenerateVehicleSafetyReportGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PDFSafetyReportApi - factory interface
 * @export
 */
export const PDFSafetyReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PDFSafetyReportApiFp(configuration)
    return {
        /**
         * 
         * @summary Email vehicle safety report in PDF format.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {string} langCode Language code
         * @param {string} [emailAddress] Email address to send the report
         * @param {string} [subject] Subject of the email
         * @param {string} [message] Body of the email
         * @param {boolean} [includeNotes] Include notes in the report (true/false)
         * @param {boolean} [includePictures] Include pictures in the report (true/false)
         * @param {boolean} [includeDriverDetails] Include driver details in the report (true/false)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pdfEmailVehicleSafetyReportPost(clientId: string, licenseNumber: string, reportId: string, langCode: string, emailAddress?: string, subject?: string, message?: string, includeNotes?: boolean, includePictures?: boolean, includeDriverDetails?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.pdfEmailVehicleSafetyReportPost(clientId, licenseNumber, reportId, langCode, emailAddress, subject, message, includeNotes, includePictures, includeDriverDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate vehicle safety report in PDF format.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {string} langCode Language code
         * @param {boolean} [includeNotes] Include notes in the report (true/false)
         * @param {boolean} [includePictures] Include pictures in the report (true/false)
         * @param {boolean} [includeDriverDetails] Include driver details in the report (true/false)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pdfGenerateVehicleSafetyReportGet(clientId: string, licenseNumber: string, reportId: string, langCode: string, includeNotes?: boolean, includePictures?: boolean, includeDriverDetails?: boolean, options?: any): AxiosPromise<File> {
            return localVarFp.pdfGenerateVehicleSafetyReportGet(clientId, licenseNumber, reportId, langCode, includeNotes, includePictures, includeDriverDetails, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PDFSafetyReportApi - object-oriented interface
 * @export
 * @class PDFSafetyReportApi
 * @extends {BaseAPI}
 */
export class PDFSafetyReportApi extends BaseAPI {
    /**
     * 
     * @summary Email vehicle safety report in PDF format.
     * @param {string} clientId ID of the client
     * @param {string} licenseNumber License number of the vehicle
     * @param {string} reportId ID of the report
     * @param {string} langCode Language code
     * @param {string} [emailAddress] Email address to send the report
     * @param {string} [subject] Subject of the email
     * @param {string} [message] Body of the email
     * @param {boolean} [includeNotes] Include notes in the report (true/false)
     * @param {boolean} [includePictures] Include pictures in the report (true/false)
     * @param {boolean} [includeDriverDetails] Include driver details in the report (true/false)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PDFSafetyReportApi
     */
    public pdfEmailVehicleSafetyReportPost(clientId: string, licenseNumber: string, reportId: string, langCode: string, emailAddress?: string, subject?: string, message?: string, includeNotes?: boolean, includePictures?: boolean, includeDriverDetails?: boolean, options?: AxiosRequestConfig) {
        return PDFSafetyReportApiFp(this.configuration).pdfEmailVehicleSafetyReportPost(clientId, licenseNumber, reportId, langCode, emailAddress, subject, message, includeNotes, includePictures, includeDriverDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate vehicle safety report in PDF format.
     * @param {string} clientId ID of the client
     * @param {string} licenseNumber License number of the vehicle
     * @param {string} reportId ID of the report
     * @param {string} langCode Language code
     * @param {boolean} [includeNotes] Include notes in the report (true/false)
     * @param {boolean} [includePictures] Include pictures in the report (true/false)
     * @param {boolean} [includeDriverDetails] Include driver details in the report (true/false)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PDFSafetyReportApi
     */
    public pdfGenerateVehicleSafetyReportGet(clientId: string, licenseNumber: string, reportId: string, langCode: string, includeNotes?: boolean, includePictures?: boolean, includeDriverDetails?: boolean, options?: AxiosRequestConfig) {
        return PDFSafetyReportApiFp(this.configuration).pdfGenerateVehicleSafetyReportGet(clientId, licenseNumber, reportId, langCode, includeNotes, includePictures, includeDriverDetails, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get user data for the authenticated user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user data for the authenticated user.
         * @param {MainUserInfo} [mainUserInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mePatch: async (mainUserInfo?: MainUserInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mainUserInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload user\'s signature.
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meUploadSignaturePost: async (body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/me/upload_signature`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'image/jpg';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get user data for the authenticated user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MainUserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.meGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update user data for the authenticated user.
         * @param {MainUserInfo} [mainUserInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mePatch(mainUserInfo?: MainUserInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MainUserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mePatch(mainUserInfo, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.mePatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Upload user\'s signature.
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meUploadSignaturePost(body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meUploadSignaturePost(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.meUploadSignaturePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Get user data for the authenticated user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meGet(options?: any): AxiosPromise<MainUserInfo> {
            return localVarFp.meGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user data for the authenticated user.
         * @param {MainUserInfo} [mainUserInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mePatch(mainUserInfo?: MainUserInfo, options?: any): AxiosPromise<MainUserInfo> {
            return localVarFp.mePatch(mainUserInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload user\'s signature.
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meUploadSignaturePost(body?: string, options?: any): AxiosPromise<string> {
            return localVarFp.meUploadSignaturePost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Get user data for the authenticated user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public meGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).meGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user data for the authenticated user.
     * @param {MainUserInfo} [mainUserInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public mePatch(mainUserInfo?: MainUserInfo, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).mePatch(mainUserInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload user\'s signature.
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public meUploadSignaturePost(body?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).meUploadSignaturePost(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VehicleApi - axios parameter creator
 * @export
 */
export const VehicleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get headers for all vehicles associated with a client.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleArchiveHeadersGet: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleArchiveHeadersGet', 'clientId', clientId)
            const localVarPath = `/vehicle/archive_headers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get headers for all vehicles associated with a client.
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleExpiredHeadersGet: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleExpiredHeadersGet', 'clientId', clientId)
            const localVarPath = `/vehicle/expired_headers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get data for a specific vehicle.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleGet: async (clientId: string, licenseNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleGet', 'clientId', clientId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('vehicleGet', 'licenseNumber', licenseNumber)
            const localVarPath = `/vehicle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get headers for all vehicles associated with a client.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleHeadersGet: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleHeadersGet', 'clientId', clientId)
            const localVarPath = `/vehicle/headers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Link a driver to a specific vehicle.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} driverId ID of the driver to be linked
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleLinkDriverPut: async (clientId: string, licenseNumber: string, driverId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleLinkDriverPut', 'clientId', clientId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('vehicleLinkDriverPut', 'licenseNumber', licenseNumber)
            // verify required parameter 'driverId' is not null or undefined
            assertParamExists('vehicleLinkDriverPut', 'driverId', driverId)
            const localVarPath = `/vehicle/link_driver`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }

            if (driverId !== undefined) {
                localVarQueryParameter['driverId'] = driverId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update data for a specific vehicle.
         * @param {string} clientId ID of the client
         * @param {Vehicle} [vehicle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclePatch: async (clientId: string, vehicle?: Vehicle, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehiclePatch', 'clientId', clientId)
            const localVarPath = `/vehicle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicle, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a new vehicle for a client.
         * @param {string} clientId ID of the client
         * @param {Vehicle} [vehicle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclePost: async (clientId: string, vehicle?: Vehicle, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehiclePost', 'clientId', clientId)
            const localVarPath = `/vehicle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicle, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a driver from a specific vehicle.
         * @param {string} clientId ID of the client
         * @param {string} driverId ID of the driver to be removed
         * @param {string} licenseNumber License number of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleRemoveDriverDelete: async (clientId: string, driverId: string, licenseNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleRemoveDriverDelete', 'clientId', clientId)
            // verify required parameter 'driverId' is not null or undefined
            assertParamExists('vehicleRemoveDriverDelete', 'driverId', driverId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('vehicleRemoveDriverDelete', 'licenseNumber', licenseNumber)
            const localVarPath = `/vehicle/remove_driver`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (driverId !== undefined) {
                localVarQueryParameter['driverId'] = driverId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleApi - functional programming interface
 * @export
 */
export const VehicleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get headers for all vehicles associated with a client.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleArchiveHeadersGet(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VehicleHeader>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleArchiveHeadersGet(clientId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleApi.vehicleArchiveHeadersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get headers for all vehicles associated with a client.
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleExpiredHeadersGet(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VehicleHeader>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleExpiredHeadersGet(clientId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleApi.vehicleExpiredHeadersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get data for a specific vehicle.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleGet(clientId: string, licenseNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Vehicle>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleGet(clientId, licenseNumber, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleApi.vehicleGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get headers for all vehicles associated with a client.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleHeadersGet(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VehicleHeader>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleHeadersGet(clientId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleApi.vehicleHeadersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Link a driver to a specific vehicle.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} driverId ID of the driver to be linked
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleLinkDriverPut(clientId: string, licenseNumber: string, driverId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleLinkDriverPut(clientId, licenseNumber, driverId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleApi.vehicleLinkDriverPut']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update data for a specific vehicle.
         * @param {string} clientId ID of the client
         * @param {Vehicle} [vehicle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehiclePatch(clientId: string, vehicle?: Vehicle, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Vehicle>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehiclePatch(clientId, vehicle, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleApi.vehiclePatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Add a new vehicle for a client.
         * @param {string} clientId ID of the client
         * @param {Vehicle} [vehicle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehiclePost(clientId: string, vehicle?: Vehicle, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Vehicle>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehiclePost(clientId, vehicle, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleApi.vehiclePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Remove a driver from a specific vehicle.
         * @param {string} clientId ID of the client
         * @param {string} driverId ID of the driver to be removed
         * @param {string} licenseNumber License number of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleRemoveDriverDelete(clientId: string, driverId: string, licenseNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleRemoveDriverDelete(clientId, driverId, licenseNumber, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleApi.vehicleRemoveDriverDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * VehicleApi - factory interface
 * @export
 */
export const VehicleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleApiFp(configuration)
    return {
        /**
         * 
         * @summary Get headers for all vehicles associated with a client.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleArchiveHeadersGet(clientId: string, options?: any): AxiosPromise<Array<VehicleHeader>> {
            return localVarFp.vehicleArchiveHeadersGet(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get headers for all vehicles associated with a client.
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleExpiredHeadersGet(clientId: string, options?: any): AxiosPromise<Array<VehicleHeader>> {
            return localVarFp.vehicleExpiredHeadersGet(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get data for a specific vehicle.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleGet(clientId: string, licenseNumber: string, options?: any): AxiosPromise<Vehicle> {
            return localVarFp.vehicleGet(clientId, licenseNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get headers for all vehicles associated with a client.
         * @param {string} clientId ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleHeadersGet(clientId: string, options?: any): AxiosPromise<Array<VehicleHeader>> {
            return localVarFp.vehicleHeadersGet(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Link a driver to a specific vehicle.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} driverId ID of the driver to be linked
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleLinkDriverPut(clientId: string, licenseNumber: string, driverId: string, options?: any): AxiosPromise<string> {
            return localVarFp.vehicleLinkDriverPut(clientId, licenseNumber, driverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update data for a specific vehicle.
         * @param {string} clientId ID of the client
         * @param {Vehicle} [vehicle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclePatch(clientId: string, vehicle?: Vehicle, options?: any): AxiosPromise<Vehicle> {
            return localVarFp.vehiclePatch(clientId, vehicle, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a new vehicle for a client.
         * @param {string} clientId ID of the client
         * @param {Vehicle} [vehicle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclePost(clientId: string, vehicle?: Vehicle, options?: any): AxiosPromise<Vehicle> {
            return localVarFp.vehiclePost(clientId, vehicle, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a driver from a specific vehicle.
         * @param {string} clientId ID of the client
         * @param {string} driverId ID of the driver to be removed
         * @param {string} licenseNumber License number of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleRemoveDriverDelete(clientId: string, driverId: string, licenseNumber: string, options?: any): AxiosPromise<string> {
            return localVarFp.vehicleRemoveDriverDelete(clientId, driverId, licenseNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VehicleApi - object-oriented interface
 * @export
 * @class VehicleApi
 * @extends {BaseAPI}
 */
export class VehicleApi extends BaseAPI {
    /**
     * 
     * @summary Get headers for all vehicles associated with a client.
     * @param {string} clientId ID of the client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApi
     */
    public vehicleArchiveHeadersGet(clientId: string, options?: AxiosRequestConfig) {
        return VehicleApiFp(this.configuration).vehicleArchiveHeadersGet(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get headers for all vehicles associated with a client.
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApi
     */
    public vehicleExpiredHeadersGet(clientId: string, options?: AxiosRequestConfig) {
        return VehicleApiFp(this.configuration).vehicleExpiredHeadersGet(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get data for a specific vehicle.
     * @param {string} clientId ID of the client
     * @param {string} licenseNumber License number of the vehicle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApi
     */
    public vehicleGet(clientId: string, licenseNumber: string, options?: AxiosRequestConfig) {
        return VehicleApiFp(this.configuration).vehicleGet(clientId, licenseNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get headers for all vehicles associated with a client.
     * @param {string} clientId ID of the client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApi
     */
    public vehicleHeadersGet(clientId: string, options?: AxiosRequestConfig) {
        return VehicleApiFp(this.configuration).vehicleHeadersGet(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Link a driver to a specific vehicle.
     * @param {string} clientId ID of the client
     * @param {string} licenseNumber License number of the vehicle
     * @param {string} driverId ID of the driver to be linked
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApi
     */
    public vehicleLinkDriverPut(clientId: string, licenseNumber: string, driverId: string, options?: AxiosRequestConfig) {
        return VehicleApiFp(this.configuration).vehicleLinkDriverPut(clientId, licenseNumber, driverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update data for a specific vehicle.
     * @param {string} clientId ID of the client
     * @param {Vehicle} [vehicle] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApi
     */
    public vehiclePatch(clientId: string, vehicle?: Vehicle, options?: AxiosRequestConfig) {
        return VehicleApiFp(this.configuration).vehiclePatch(clientId, vehicle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a new vehicle for a client.
     * @param {string} clientId ID of the client
     * @param {Vehicle} [vehicle] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApi
     */
    public vehiclePost(clientId: string, vehicle?: Vehicle, options?: AxiosRequestConfig) {
        return VehicleApiFp(this.configuration).vehiclePost(clientId, vehicle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a driver from a specific vehicle.
     * @param {string} clientId ID of the client
     * @param {string} driverId ID of the driver to be removed
     * @param {string} licenseNumber License number of the vehicle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApi
     */
    public vehicleRemoveDriverDelete(clientId: string, driverId: string, licenseNumber: string, options?: AxiosRequestConfig) {
        return VehicleApiFp(this.configuration).vehicleRemoveDriverDelete(clientId, driverId, licenseNumber, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VehicleDocumentsApi - axios parameter creator
 * @export
 */
export const VehicleDocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete vehicle document.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {VehicleDocumentType} documentType Type of the document
         * @param {string} [documentId] ID of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleDocumentsDeleteDelete: async (clientId: string, licenseNumber: string, documentType: VehicleDocumentType, documentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleDocumentsDeleteDelete', 'clientId', clientId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('vehicleDocumentsDeleteDelete', 'licenseNumber', licenseNumber)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('vehicleDocumentsDeleteDelete', 'documentType', documentType)
            const localVarPath = `/vehicle/documents/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }

            if (documentId !== undefined) {
                localVarQueryParameter['documentId'] = documentId;
            }

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get vehicle documents.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {VehicleDocumentType} [documentType] Type of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleDocumentsGetAllGet: async (clientId: string, licenseNumber: string, documentType?: VehicleDocumentType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleDocumentsGetAllGet', 'clientId', clientId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('vehicleDocumentsGetAllGet', 'licenseNumber', licenseNumber)
            const localVarPath = `/vehicle/documents/get_all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get vehicle document.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {VehicleDocumentType} documentType Type of the document
         * @param {string} [documentId] ID of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleDocumentsGetGet: async (clientId: string, licenseNumber: string, documentType: VehicleDocumentType, documentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleDocumentsGetGet', 'clientId', clientId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('vehicleDocumentsGetGet', 'licenseNumber', licenseNumber)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('vehicleDocumentsGetGet', 'documentType', documentType)
            const localVarPath = `/vehicle/documents/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }

            if (documentId !== undefined) {
                localVarQueryParameter['documentId'] = documentId;
            }

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update vehicle document date.
         * @param {string} clientId 
         * @param {string} licenseNumber 
         * @param {string} documentId 
         * @param {VehicleDocumentType} documentType 
         * @param {string} expirationDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleDocumentsUpdateExpirationDatePatch: async (clientId: string, licenseNumber: string, documentId: string, documentType: VehicleDocumentType, expirationDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleDocumentsUpdateExpirationDatePatch', 'clientId', clientId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('vehicleDocumentsUpdateExpirationDatePatch', 'licenseNumber', licenseNumber)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('vehicleDocumentsUpdateExpirationDatePatch', 'documentId', documentId)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('vehicleDocumentsUpdateExpirationDatePatch', 'documentType', documentType)
            // verify required parameter 'expirationDate' is not null or undefined
            assertParamExists('vehicleDocumentsUpdateExpirationDatePatch', 'expirationDate', expirationDate)
            const localVarPath = `/vehicle/documents/update_expiration_date`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }

            if (documentId !== undefined) {
                localVarQueryParameter['documentId'] = documentId;
            }

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }

            if (expirationDate !== undefined) {
                localVarQueryParameter['expirationDate'] = (expirationDate as any instanceof Date) ?
                    (expirationDate as any).toISOString().substring(0,10) :
                    expirationDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload vehicle document.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {VehicleDocumentType} documentType Type of the document
         * @param {string} expirationDate Expiration date of the document
         * @param {string} [title] Title of the document
         * @param {DocumentUploadImgRequestBody} [documentUploadImgRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleDocumentsUploadImagesPost: async (clientId: string, licenseNumber: string, documentType: VehicleDocumentType, expirationDate: string, title?: string, documentUploadImgRequestBody?: DocumentUploadImgRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleDocumentsUploadImagesPost', 'clientId', clientId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('vehicleDocumentsUploadImagesPost', 'licenseNumber', licenseNumber)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('vehicleDocumentsUploadImagesPost', 'documentType', documentType)
            // verify required parameter 'expirationDate' is not null or undefined
            assertParamExists('vehicleDocumentsUploadImagesPost', 'expirationDate', expirationDate)
            const localVarPath = `/vehicle/documents/upload_images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (expirationDate !== undefined) {
                localVarQueryParameter['expirationDate'] = (expirationDate as any instanceof Date) ?
                    (expirationDate as any).toISOString().substring(0,10) :
                    expirationDate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentUploadImgRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload vehicle document.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {VehicleDocumentType} documentType Type of the document
         * @param {string} expirationDate Expiration date of the document
         * @param {string} [title] Title of the document
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleDocumentsUploadPdfPost: async (clientId: string, licenseNumber: string, documentType: VehicleDocumentType, expirationDate: string, title?: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleDocumentsUploadPdfPost', 'clientId', clientId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('vehicleDocumentsUploadPdfPost', 'licenseNumber', licenseNumber)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('vehicleDocumentsUploadPdfPost', 'documentType', documentType)
            // verify required parameter 'expirationDate' is not null or undefined
            assertParamExists('vehicleDocumentsUploadPdfPost', 'expirationDate', expirationDate)
            const localVarPath = `/vehicle/documents/upload_pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (expirationDate !== undefined) {
                localVarQueryParameter['expirationDate'] = (expirationDate as any instanceof Date) ?
                    (expirationDate as any).toISOString().substring(0,10) :
                    expirationDate;
            }


            if (file !== undefined) { 
                localVarFormParams.set('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleDocumentsApi - functional programming interface
 * @export
 */
export const VehicleDocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleDocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete vehicle document.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {VehicleDocumentType} documentType Type of the document
         * @param {string} [documentId] ID of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleDocumentsDeleteDelete(clientId: string, licenseNumber: string, documentType: VehicleDocumentType, documentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleDocumentsDeleteDelete(clientId, licenseNumber, documentType, documentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleDocumentsApi.vehicleDocumentsDeleteDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get vehicle documents.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {VehicleDocumentType} [documentType] Type of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleDocumentsGetAllGet(clientId: string, licenseNumber: string, documentType?: VehicleDocumentType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VehicleDocument>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleDocumentsGetAllGet(clientId, licenseNumber, documentType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleDocumentsApi.vehicleDocumentsGetAllGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get vehicle document.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {VehicleDocumentType} documentType Type of the document
         * @param {string} [documentId] ID of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleDocumentsGetGet(clientId: string, licenseNumber: string, documentType: VehicleDocumentType, documentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleDocumentsGetGet(clientId, licenseNumber, documentType, documentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleDocumentsApi.vehicleDocumentsGetGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update vehicle document date.
         * @param {string} clientId 
         * @param {string} licenseNumber 
         * @param {string} documentId 
         * @param {VehicleDocumentType} documentType 
         * @param {string} expirationDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleDocumentsUpdateExpirationDatePatch(clientId: string, licenseNumber: string, documentId: string, documentType: VehicleDocumentType, expirationDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleDocumentsUpdateExpirationDatePatch(clientId, licenseNumber, documentId, documentType, expirationDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleDocumentsApi.vehicleDocumentsUpdateExpirationDatePatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Upload vehicle document.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {VehicleDocumentType} documentType Type of the document
         * @param {string} expirationDate Expiration date of the document
         * @param {string} [title] Title of the document
         * @param {DocumentUploadImgRequestBody} [documentUploadImgRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleDocumentsUploadImagesPost(clientId: string, licenseNumber: string, documentType: VehicleDocumentType, expirationDate: string, title?: string, documentUploadImgRequestBody?: DocumentUploadImgRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleDocumentsUploadImagesPost(clientId, licenseNumber, documentType, expirationDate, title, documentUploadImgRequestBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleDocumentsApi.vehicleDocumentsUploadImagesPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Upload vehicle document.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {VehicleDocumentType} documentType Type of the document
         * @param {string} expirationDate Expiration date of the document
         * @param {string} [title] Title of the document
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleDocumentsUploadPdfPost(clientId: string, licenseNumber: string, documentType: VehicleDocumentType, expirationDate: string, title?: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleDocumentsUploadPdfPost(clientId, licenseNumber, documentType, expirationDate, title, file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleDocumentsApi.vehicleDocumentsUploadPdfPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * VehicleDocumentsApi - factory interface
 * @export
 */
export const VehicleDocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleDocumentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete vehicle document.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {VehicleDocumentType} documentType Type of the document
         * @param {string} [documentId] ID of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleDocumentsDeleteDelete(clientId: string, licenseNumber: string, documentType: VehicleDocumentType, documentId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.vehicleDocumentsDeleteDelete(clientId, licenseNumber, documentType, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get vehicle documents.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {VehicleDocumentType} [documentType] Type of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleDocumentsGetAllGet(clientId: string, licenseNumber: string, documentType?: VehicleDocumentType, options?: any): AxiosPromise<Array<VehicleDocument>> {
            return localVarFp.vehicleDocumentsGetAllGet(clientId, licenseNumber, documentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get vehicle document.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {VehicleDocumentType} documentType Type of the document
         * @param {string} [documentId] ID of the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleDocumentsGetGet(clientId: string, licenseNumber: string, documentType: VehicleDocumentType, documentId?: string, options?: any): AxiosPromise<VehicleDocument> {
            return localVarFp.vehicleDocumentsGetGet(clientId, licenseNumber, documentType, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update vehicle document date.
         * @param {string} clientId 
         * @param {string} licenseNumber 
         * @param {string} documentId 
         * @param {VehicleDocumentType} documentType 
         * @param {string} expirationDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleDocumentsUpdateExpirationDatePatch(clientId: string, licenseNumber: string, documentId: string, documentType: VehicleDocumentType, expirationDate: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.vehicleDocumentsUpdateExpirationDatePatch(clientId, licenseNumber, documentId, documentType, expirationDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload vehicle document.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {VehicleDocumentType} documentType Type of the document
         * @param {string} expirationDate Expiration date of the document
         * @param {string} [title] Title of the document
         * @param {DocumentUploadImgRequestBody} [documentUploadImgRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleDocumentsUploadImagesPost(clientId: string, licenseNumber: string, documentType: VehicleDocumentType, expirationDate: string, title?: string, documentUploadImgRequestBody?: DocumentUploadImgRequestBody, options?: any): AxiosPromise<string> {
            return localVarFp.vehicleDocumentsUploadImagesPost(clientId, licenseNumber, documentType, expirationDate, title, documentUploadImgRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload vehicle document.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {VehicleDocumentType} documentType Type of the document
         * @param {string} expirationDate Expiration date of the document
         * @param {string} [title] Title of the document
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleDocumentsUploadPdfPost(clientId: string, licenseNumber: string, documentType: VehicleDocumentType, expirationDate: string, title?: string, file?: File, options?: any): AxiosPromise<string> {
            return localVarFp.vehicleDocumentsUploadPdfPost(clientId, licenseNumber, documentType, expirationDate, title, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VehicleDocumentsApi - object-oriented interface
 * @export
 * @class VehicleDocumentsApi
 * @extends {BaseAPI}
 */
export class VehicleDocumentsApi extends BaseAPI {
    /**
     * 
     * @summary Delete vehicle document.
     * @param {string} clientId ID of the client
     * @param {string} licenseNumber License number of the vehicle
     * @param {VehicleDocumentType} documentType Type of the document
     * @param {string} [documentId] ID of the document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDocumentsApi
     */
    public vehicleDocumentsDeleteDelete(clientId: string, licenseNumber: string, documentType: VehicleDocumentType, documentId?: string, options?: AxiosRequestConfig) {
        return VehicleDocumentsApiFp(this.configuration).vehicleDocumentsDeleteDelete(clientId, licenseNumber, documentType, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get vehicle documents.
     * @param {string} clientId ID of the client
     * @param {string} licenseNumber License number of the vehicle
     * @param {VehicleDocumentType} [documentType] Type of the document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDocumentsApi
     */
    public vehicleDocumentsGetAllGet(clientId: string, licenseNumber: string, documentType?: VehicleDocumentType, options?: AxiosRequestConfig) {
        return VehicleDocumentsApiFp(this.configuration).vehicleDocumentsGetAllGet(clientId, licenseNumber, documentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get vehicle document.
     * @param {string} clientId ID of the client
     * @param {string} licenseNumber License number of the vehicle
     * @param {VehicleDocumentType} documentType Type of the document
     * @param {string} [documentId] ID of the document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDocumentsApi
     */
    public vehicleDocumentsGetGet(clientId: string, licenseNumber: string, documentType: VehicleDocumentType, documentId?: string, options?: AxiosRequestConfig) {
        return VehicleDocumentsApiFp(this.configuration).vehicleDocumentsGetGet(clientId, licenseNumber, documentType, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update vehicle document date.
     * @param {string} clientId 
     * @param {string} licenseNumber 
     * @param {string} documentId 
     * @param {VehicleDocumentType} documentType 
     * @param {string} expirationDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDocumentsApi
     */
    public vehicleDocumentsUpdateExpirationDatePatch(clientId: string, licenseNumber: string, documentId: string, documentType: VehicleDocumentType, expirationDate: string, options?: AxiosRequestConfig) {
        return VehicleDocumentsApiFp(this.configuration).vehicleDocumentsUpdateExpirationDatePatch(clientId, licenseNumber, documentId, documentType, expirationDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload vehicle document.
     * @param {string} clientId ID of the client
     * @param {string} licenseNumber License number of the vehicle
     * @param {VehicleDocumentType} documentType Type of the document
     * @param {string} expirationDate Expiration date of the document
     * @param {string} [title] Title of the document
     * @param {DocumentUploadImgRequestBody} [documentUploadImgRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDocumentsApi
     */
    public vehicleDocumentsUploadImagesPost(clientId: string, licenseNumber: string, documentType: VehicleDocumentType, expirationDate: string, title?: string, documentUploadImgRequestBody?: DocumentUploadImgRequestBody, options?: AxiosRequestConfig) {
        return VehicleDocumentsApiFp(this.configuration).vehicleDocumentsUploadImagesPost(clientId, licenseNumber, documentType, expirationDate, title, documentUploadImgRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload vehicle document.
     * @param {string} clientId ID of the client
     * @param {string} licenseNumber License number of the vehicle
     * @param {VehicleDocumentType} documentType Type of the document
     * @param {string} expirationDate Expiration date of the document
     * @param {string} [title] Title of the document
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDocumentsApi
     */
    public vehicleDocumentsUploadPdfPost(clientId: string, licenseNumber: string, documentType: VehicleDocumentType, expirationDate: string, title?: string, file?: File, options?: AxiosRequestConfig) {
        return VehicleDocumentsApiFp(this.configuration).vehicleDocumentsUploadPdfPost(clientId, licenseNumber, documentType, expirationDate, title, file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VehicleIncidentsApi - axios parameter creator
 * @export
 */
export const VehicleIncidentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} incidentId 
         * @param {AddVehicleIncidentImgsRequestBody} [addVehicleIncidentImgsRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleIncidentsAddIncidentPhotosPost: async (clientId: string, vehicleId: string, incidentId: string, addVehicleIncidentImgsRequestBody?: AddVehicleIncidentImgsRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleIncidentsAddIncidentPhotosPost', 'clientId', clientId)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('vehicleIncidentsAddIncidentPhotosPost', 'vehicleId', vehicleId)
            // verify required parameter 'incidentId' is not null or undefined
            assertParamExists('vehicleIncidentsAddIncidentPhotosPost', 'incidentId', incidentId)
            const localVarPath = `/vehicle_incidents/add_incident_photos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (vehicleId !== undefined) {
                localVarQueryParameter['vehicleId'] = vehicleId;
            }

            if (incidentId !== undefined) {
                localVarQueryParameter['incidentId'] = incidentId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addVehicleIncidentImgsRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} relatedDriverId 
         * @param {CreateOrUpdateVehicleIncident} [createOrUpdateVehicleIncident] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleIncidentsCreateIncidentPost: async (clientId: string, vehicleId: string, relatedDriverId: string, createOrUpdateVehicleIncident?: CreateOrUpdateVehicleIncident, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleIncidentsCreateIncidentPost', 'clientId', clientId)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('vehicleIncidentsCreateIncidentPost', 'vehicleId', vehicleId)
            // verify required parameter 'relatedDriverId' is not null or undefined
            assertParamExists('vehicleIncidentsCreateIncidentPost', 'relatedDriverId', relatedDriverId)
            const localVarPath = `/vehicle_incidents/create_incident`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (vehicleId !== undefined) {
                localVarQueryParameter['vehicleId'] = vehicleId;
            }

            if (relatedDriverId !== undefined) {
                localVarQueryParameter['relatedDriverId'] = relatedDriverId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateVehicleIncident, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} incidentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleIncidentsGetIncidentGet: async (clientId: string, vehicleId: string, incidentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleIncidentsGetIncidentGet', 'clientId', clientId)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('vehicleIncidentsGetIncidentGet', 'vehicleId', vehicleId)
            // verify required parameter 'incidentId' is not null or undefined
            assertParamExists('vehicleIncidentsGetIncidentGet', 'incidentId', incidentId)
            const localVarPath = `/vehicle_incidents/get_incident`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (vehicleId !== undefined) {
                localVarQueryParameter['vehicleId'] = vehicleId;
            }

            if (incidentId !== undefined) {
                localVarQueryParameter['incidentId'] = incidentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleIncidentsGetIncidentsHeadersGet: async (clientId: string, vehicleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleIncidentsGetIncidentsHeadersGet', 'clientId', clientId)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('vehicleIncidentsGetIncidentsHeadersGet', 'vehicleId', vehicleId)
            const localVarPath = `/vehicle_incidents/get_incidents_headers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (vehicleId !== undefined) {
                localVarQueryParameter['vehicleId'] = vehicleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} incidentId 
         * @param {CreateOrUpdateVehicleIncident} [createOrUpdateVehicleIncident] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleIncidentsUpdateIncidentPatch: async (clientId: string, vehicleId: string, incidentId: string, createOrUpdateVehicleIncident?: CreateOrUpdateVehicleIncident, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleIncidentsUpdateIncidentPatch', 'clientId', clientId)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('vehicleIncidentsUpdateIncidentPatch', 'vehicleId', vehicleId)
            // verify required parameter 'incidentId' is not null or undefined
            assertParamExists('vehicleIncidentsUpdateIncidentPatch', 'incidentId', incidentId)
            const localVarPath = `/vehicle_incidents/update_incident`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (vehicleId !== undefined) {
                localVarQueryParameter['vehicleId'] = vehicleId;
            }

            if (incidentId !== undefined) {
                localVarQueryParameter['incidentId'] = incidentId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateVehicleIncident, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleIncidentsApi - functional programming interface
 * @export
 */
export const VehicleIncidentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleIncidentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} incidentId 
         * @param {AddVehicleIncidentImgsRequestBody} [addVehicleIncidentImgsRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleIncidentsAddIncidentPhotosPost(clientId: string, vehicleId: string, incidentId: string, addVehicleIncidentImgsRequestBody?: AddVehicleIncidentImgsRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleIncidentsAddIncidentPhotosPost(clientId, vehicleId, incidentId, addVehicleIncidentImgsRequestBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleIncidentsApi.vehicleIncidentsAddIncidentPhotosPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} relatedDriverId 
         * @param {CreateOrUpdateVehicleIncident} [createOrUpdateVehicleIncident] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleIncidentsCreateIncidentPost(clientId: string, vehicleId: string, relatedDriverId: string, createOrUpdateVehicleIncident?: CreateOrUpdateVehicleIncident, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleIncidentHeader>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleIncidentsCreateIncidentPost(clientId, vehicleId, relatedDriverId, createOrUpdateVehicleIncident, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleIncidentsApi.vehicleIncidentsCreateIncidentPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} incidentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleIncidentsGetIncidentGet(clientId: string, vehicleId: string, incidentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleIncident>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleIncidentsGetIncidentGet(clientId, vehicleId, incidentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleIncidentsApi.vehicleIncidentsGetIncidentGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleIncidentsGetIncidentsHeadersGet(clientId: string, vehicleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VehicleIncidentHeader>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleIncidentsGetIncidentsHeadersGet(clientId, vehicleId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleIncidentsApi.vehicleIncidentsGetIncidentsHeadersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} incidentId 
         * @param {CreateOrUpdateVehicleIncident} [createOrUpdateVehicleIncident] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleIncidentsUpdateIncidentPatch(clientId: string, vehicleId: string, incidentId: string, createOrUpdateVehicleIncident?: CreateOrUpdateVehicleIncident, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncidentEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleIncidentsUpdateIncidentPatch(clientId, vehicleId, incidentId, createOrUpdateVehicleIncident, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleIncidentsApi.vehicleIncidentsUpdateIncidentPatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * VehicleIncidentsApi - factory interface
 * @export
 */
export const VehicleIncidentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleIncidentsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} incidentId 
         * @param {AddVehicleIncidentImgsRequestBody} [addVehicleIncidentImgsRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleIncidentsAddIncidentPhotosPost(clientId: string, vehicleId: string, incidentId: string, addVehicleIncidentImgsRequestBody?: AddVehicleIncidentImgsRequestBody, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.vehicleIncidentsAddIncidentPhotosPost(clientId, vehicleId, incidentId, addVehicleIncidentImgsRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} relatedDriverId 
         * @param {CreateOrUpdateVehicleIncident} [createOrUpdateVehicleIncident] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleIncidentsCreateIncidentPost(clientId: string, vehicleId: string, relatedDriverId: string, createOrUpdateVehicleIncident?: CreateOrUpdateVehicleIncident, options?: any): AxiosPromise<VehicleIncidentHeader> {
            return localVarFp.vehicleIncidentsCreateIncidentPost(clientId, vehicleId, relatedDriverId, createOrUpdateVehicleIncident, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} incidentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleIncidentsGetIncidentGet(clientId: string, vehicleId: string, incidentId: string, options?: any): AxiosPromise<VehicleIncident> {
            return localVarFp.vehicleIncidentsGetIncidentGet(clientId, vehicleId, incidentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleIncidentsGetIncidentsHeadersGet(clientId: string, vehicleId: string, options?: any): AxiosPromise<Array<VehicleIncidentHeader>> {
            return localVarFp.vehicleIncidentsGetIncidentsHeadersGet(clientId, vehicleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} incidentId 
         * @param {CreateOrUpdateVehicleIncident} [createOrUpdateVehicleIncident] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleIncidentsUpdateIncidentPatch(clientId: string, vehicleId: string, incidentId: string, createOrUpdateVehicleIncident?: CreateOrUpdateVehicleIncident, options?: any): AxiosPromise<IncidentEntry> {
            return localVarFp.vehicleIncidentsUpdateIncidentPatch(clientId, vehicleId, incidentId, createOrUpdateVehicleIncident, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VehicleIncidentsApi - object-oriented interface
 * @export
 * @class VehicleIncidentsApi
 * @extends {BaseAPI}
 */
export class VehicleIncidentsApi extends BaseAPI {
    /**
     * 
     * @param {string} clientId 
     * @param {string} vehicleId 
     * @param {string} incidentId 
     * @param {AddVehicleIncidentImgsRequestBody} [addVehicleIncidentImgsRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleIncidentsApi
     */
    public vehicleIncidentsAddIncidentPhotosPost(clientId: string, vehicleId: string, incidentId: string, addVehicleIncidentImgsRequestBody?: AddVehicleIncidentImgsRequestBody, options?: AxiosRequestConfig) {
        return VehicleIncidentsApiFp(this.configuration).vehicleIncidentsAddIncidentPhotosPost(clientId, vehicleId, incidentId, addVehicleIncidentImgsRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId 
     * @param {string} vehicleId 
     * @param {string} relatedDriverId 
     * @param {CreateOrUpdateVehicleIncident} [createOrUpdateVehicleIncident] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleIncidentsApi
     */
    public vehicleIncidentsCreateIncidentPost(clientId: string, vehicleId: string, relatedDriverId: string, createOrUpdateVehicleIncident?: CreateOrUpdateVehicleIncident, options?: AxiosRequestConfig) {
        return VehicleIncidentsApiFp(this.configuration).vehicleIncidentsCreateIncidentPost(clientId, vehicleId, relatedDriverId, createOrUpdateVehicleIncident, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId 
     * @param {string} vehicleId 
     * @param {string} incidentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleIncidentsApi
     */
    public vehicleIncidentsGetIncidentGet(clientId: string, vehicleId: string, incidentId: string, options?: AxiosRequestConfig) {
        return VehicleIncidentsApiFp(this.configuration).vehicleIncidentsGetIncidentGet(clientId, vehicleId, incidentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId 
     * @param {string} vehicleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleIncidentsApi
     */
    public vehicleIncidentsGetIncidentsHeadersGet(clientId: string, vehicleId: string, options?: AxiosRequestConfig) {
        return VehicleIncidentsApiFp(this.configuration).vehicleIncidentsGetIncidentsHeadersGet(clientId, vehicleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId 
     * @param {string} vehicleId 
     * @param {string} incidentId 
     * @param {CreateOrUpdateVehicleIncident} [createOrUpdateVehicleIncident] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleIncidentsApi
     */
    public vehicleIncidentsUpdateIncidentPatch(clientId: string, vehicleId: string, incidentId: string, createOrUpdateVehicleIncident?: CreateOrUpdateVehicleIncident, options?: AxiosRequestConfig) {
        return VehicleIncidentsApiFp(this.configuration).vehicleIncidentsUpdateIncidentPatch(clientId, vehicleId, incidentId, createOrUpdateVehicleIncident, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VehicleInvoicesApi - axios parameter creator
 * @export
 */
export const VehicleInvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Upload vehicle invoice.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {KInvoice} [kInvoice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleInvoicesAddPost: async (clientId: string, licenseNumber: string, kInvoice?: KInvoice, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleInvoicesAddPost', 'clientId', clientId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('vehicleInvoicesAddPost', 'licenseNumber', licenseNumber)
            const localVarPath = `/vehicle/invoices/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kInvoice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get vehicle invoices.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleInvoicesGetAllGet: async (clientId: string, licenseNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleInvoicesGetAllGet', 'clientId', clientId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('vehicleInvoicesGetAllGet', 'licenseNumber', licenseNumber)
            const localVarPath = `/vehicle/invoices/get_all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get vehicle invoice.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} [invoiceId] ID of the invoice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleInvoicesGetGet: async (clientId: string, licenseNumber: string, invoiceId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleInvoicesGetGet', 'clientId', clientId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('vehicleInvoicesGetGet', 'licenseNumber', licenseNumber)
            const localVarPath = `/vehicle/invoices/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update vehicle invoice.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} [invoiceId] ID of the invoice
         * @param {AddVehicleInvoiceReqBody} [addVehicleInvoiceReqBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleInvoicesUpdatePatch: async (clientId: string, licenseNumber: string, invoiceId?: string, addVehicleInvoiceReqBody?: AddVehicleInvoiceReqBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleInvoicesUpdatePatch', 'clientId', clientId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('vehicleInvoicesUpdatePatch', 'licenseNumber', licenseNumber)
            const localVarPath = `/vehicle/invoices/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addVehicleInvoiceReqBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload vehicle invoice.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {AddVehicleInvoiceReqBody} [addVehicleInvoiceReqBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleInvoicesUploadImagesPost: async (clientId: string, licenseNumber: string, addVehicleInvoiceReqBody?: AddVehicleInvoiceReqBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleInvoicesUploadImagesPost', 'clientId', clientId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('vehicleInvoicesUploadImagesPost', 'licenseNumber', licenseNumber)
            const localVarPath = `/vehicle/invoices/upload_images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addVehicleInvoiceReqBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload vehicle invoice.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {KInvoice} invoiceData 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleInvoicesUploadPdfPost: async (clientId: string, licenseNumber: string, invoiceData: KInvoice, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleInvoicesUploadPdfPost', 'clientId', clientId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('vehicleInvoicesUploadPdfPost', 'licenseNumber', licenseNumber)
            // verify required parameter 'invoiceData' is not null or undefined
            assertParamExists('vehicleInvoicesUploadPdfPost', 'invoiceData', invoiceData)
            const localVarPath = `/vehicle/invoices/upload_pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }

            if (invoiceData !== undefined) {
                for (const [key, value] of Object.entries(invoiceData)) {
                    localVarQueryParameter[key] = value;
                }
            }


            if (file !== undefined) { 
                localVarFormParams.set('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleInvoicesApi - functional programming interface
 * @export
 */
export const VehicleInvoicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleInvoicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Upload vehicle invoice.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {KInvoice} [kInvoice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleInvoicesAddPost(clientId: string, licenseNumber: string, kInvoice?: KInvoice, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KInvoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleInvoicesAddPost(clientId, licenseNumber, kInvoice, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleInvoicesApi.vehicleInvoicesAddPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get vehicle invoices.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleInvoicesGetAllGet(clientId: string, licenseNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KInvoice>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleInvoicesGetAllGet(clientId, licenseNumber, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleInvoicesApi.vehicleInvoicesGetAllGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get vehicle invoice.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} [invoiceId] ID of the invoice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleInvoicesGetGet(clientId: string, licenseNumber: string, invoiceId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KInvoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleInvoicesGetGet(clientId, licenseNumber, invoiceId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleInvoicesApi.vehicleInvoicesGetGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update vehicle invoice.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} [invoiceId] ID of the invoice
         * @param {AddVehicleInvoiceReqBody} [addVehicleInvoiceReqBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleInvoicesUpdatePatch(clientId: string, licenseNumber: string, invoiceId?: string, addVehicleInvoiceReqBody?: AddVehicleInvoiceReqBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KInvoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleInvoicesUpdatePatch(clientId, licenseNumber, invoiceId, addVehicleInvoiceReqBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleInvoicesApi.vehicleInvoicesUpdatePatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Upload vehicle invoice.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {AddVehicleInvoiceReqBody} [addVehicleInvoiceReqBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleInvoicesUploadImagesPost(clientId: string, licenseNumber: string, addVehicleInvoiceReqBody?: AddVehicleInvoiceReqBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KInvoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleInvoicesUploadImagesPost(clientId, licenseNumber, addVehicleInvoiceReqBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleInvoicesApi.vehicleInvoicesUploadImagesPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Upload vehicle invoice.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {KInvoice} invoiceData 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleInvoicesUploadPdfPost(clientId: string, licenseNumber: string, invoiceData: KInvoice, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KInvoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleInvoicesUploadPdfPost(clientId, licenseNumber, invoiceData, file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleInvoicesApi.vehicleInvoicesUploadPdfPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * VehicleInvoicesApi - factory interface
 * @export
 */
export const VehicleInvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleInvoicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Upload vehicle invoice.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {KInvoice} [kInvoice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleInvoicesAddPost(clientId: string, licenseNumber: string, kInvoice?: KInvoice, options?: any): AxiosPromise<KInvoice> {
            return localVarFp.vehicleInvoicesAddPost(clientId, licenseNumber, kInvoice, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get vehicle invoices.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleInvoicesGetAllGet(clientId: string, licenseNumber: string, options?: any): AxiosPromise<Array<KInvoice>> {
            return localVarFp.vehicleInvoicesGetAllGet(clientId, licenseNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get vehicle invoice.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} [invoiceId] ID of the invoice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleInvoicesGetGet(clientId: string, licenseNumber: string, invoiceId?: string, options?: any): AxiosPromise<KInvoice> {
            return localVarFp.vehicleInvoicesGetGet(clientId, licenseNumber, invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update vehicle invoice.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} [invoiceId] ID of the invoice
         * @param {AddVehicleInvoiceReqBody} [addVehicleInvoiceReqBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleInvoicesUpdatePatch(clientId: string, licenseNumber: string, invoiceId?: string, addVehicleInvoiceReqBody?: AddVehicleInvoiceReqBody, options?: any): AxiosPromise<KInvoice> {
            return localVarFp.vehicleInvoicesUpdatePatch(clientId, licenseNumber, invoiceId, addVehicleInvoiceReqBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload vehicle invoice.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {AddVehicleInvoiceReqBody} [addVehicleInvoiceReqBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleInvoicesUploadImagesPost(clientId: string, licenseNumber: string, addVehicleInvoiceReqBody?: AddVehicleInvoiceReqBody, options?: any): AxiosPromise<KInvoice> {
            return localVarFp.vehicleInvoicesUploadImagesPost(clientId, licenseNumber, addVehicleInvoiceReqBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload vehicle invoice.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {KInvoice} invoiceData 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleInvoicesUploadPdfPost(clientId: string, licenseNumber: string, invoiceData: KInvoice, file?: File, options?: any): AxiosPromise<KInvoice> {
            return localVarFp.vehicleInvoicesUploadPdfPost(clientId, licenseNumber, invoiceData, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VehicleInvoicesApi - object-oriented interface
 * @export
 * @class VehicleInvoicesApi
 * @extends {BaseAPI}
 */
export class VehicleInvoicesApi extends BaseAPI {
    /**
     * 
     * @summary Upload vehicle invoice.
     * @param {string} clientId ID of the client
     * @param {string} licenseNumber License number of the vehicle
     * @param {KInvoice} [kInvoice] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleInvoicesApi
     */
    public vehicleInvoicesAddPost(clientId: string, licenseNumber: string, kInvoice?: KInvoice, options?: AxiosRequestConfig) {
        return VehicleInvoicesApiFp(this.configuration).vehicleInvoicesAddPost(clientId, licenseNumber, kInvoice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get vehicle invoices.
     * @param {string} clientId ID of the client
     * @param {string} licenseNumber License number of the vehicle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleInvoicesApi
     */
    public vehicleInvoicesGetAllGet(clientId: string, licenseNumber: string, options?: AxiosRequestConfig) {
        return VehicleInvoicesApiFp(this.configuration).vehicleInvoicesGetAllGet(clientId, licenseNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get vehicle invoice.
     * @param {string} clientId ID of the client
     * @param {string} licenseNumber License number of the vehicle
     * @param {string} [invoiceId] ID of the invoice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleInvoicesApi
     */
    public vehicleInvoicesGetGet(clientId: string, licenseNumber: string, invoiceId?: string, options?: AxiosRequestConfig) {
        return VehicleInvoicesApiFp(this.configuration).vehicleInvoicesGetGet(clientId, licenseNumber, invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update vehicle invoice.
     * @param {string} clientId ID of the client
     * @param {string} licenseNumber License number of the vehicle
     * @param {string} [invoiceId] ID of the invoice
     * @param {AddVehicleInvoiceReqBody} [addVehicleInvoiceReqBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleInvoicesApi
     */
    public vehicleInvoicesUpdatePatch(clientId: string, licenseNumber: string, invoiceId?: string, addVehicleInvoiceReqBody?: AddVehicleInvoiceReqBody, options?: AxiosRequestConfig) {
        return VehicleInvoicesApiFp(this.configuration).vehicleInvoicesUpdatePatch(clientId, licenseNumber, invoiceId, addVehicleInvoiceReqBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload vehicle invoice.
     * @param {string} clientId ID of the client
     * @param {string} licenseNumber License number of the vehicle
     * @param {AddVehicleInvoiceReqBody} [addVehicleInvoiceReqBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleInvoicesApi
     */
    public vehicleInvoicesUploadImagesPost(clientId: string, licenseNumber: string, addVehicleInvoiceReqBody?: AddVehicleInvoiceReqBody, options?: AxiosRequestConfig) {
        return VehicleInvoicesApiFp(this.configuration).vehicleInvoicesUploadImagesPost(clientId, licenseNumber, addVehicleInvoiceReqBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload vehicle invoice.
     * @param {string} clientId ID of the client
     * @param {string} licenseNumber License number of the vehicle
     * @param {KInvoice} invoiceData 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleInvoicesApi
     */
    public vehicleInvoicesUploadPdfPost(clientId: string, licenseNumber: string, invoiceData: KInvoice, file?: File, options?: AxiosRequestConfig) {
        return VehicleInvoicesApiFp(this.configuration).vehicleInvoicesUploadPdfPost(clientId, licenseNumber, invoiceData, file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VehicleReportApi - axios parameter creator
 * @export
 */
export const VehicleReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a test photo to a vehicle report.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {string} testId ID of the test
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsAddTestPhotoPost: async (clientId: string, licenseNumber: string, reportId: string, testId: string, body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('reportsAddTestPhotoPost', 'clientId', clientId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('reportsAddTestPhotoPost', 'licenseNumber', licenseNumber)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportsAddTestPhotoPost', 'reportId', reportId)
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('reportsAddTestPhotoPost', 'testId', testId)
            const localVarPath = `/reports/add_test_photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }

            if (reportId !== undefined) {
                localVarQueryParameter['reportId'] = reportId;
            }

            if (testId !== undefined) {
                localVarQueryParameter['testId'] = testId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'image/jpg';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get headers for all vehicle reports.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsAllHeadersGet: async (clientId: string, licenseNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('reportsAllHeadersGet', 'clientId', clientId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('reportsAllHeadersGet', 'licenseNumber', licenseNumber)
            const localVarPath = `/reports/all_headers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update vehicle report data.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsCloseReportPatch: async (clientId: string, licenseNumber: string, reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('reportsCloseReportPatch', 'clientId', clientId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('reportsCloseReportPatch', 'licenseNumber', licenseNumber)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportsCloseReportPatch', 'reportId', reportId)
            const localVarPath = `/reports/close_report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }

            if (reportId !== undefined) {
                localVarQueryParameter['reportId'] = reportId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new vehicle report.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportType Type of the report
         * @param {string} [previousReportId] ID of the previous report (optional)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsCreatePost: async (clientId: string, licenseNumber: string, reportType: string, previousReportId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('reportsCreatePost', 'clientId', clientId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('reportsCreatePost', 'licenseNumber', licenseNumber)
            // verify required parameter 'reportType' is not null or undefined
            assertParamExists('reportsCreatePost', 'reportType', reportType)
            const localVarPath = `/reports/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }

            if (reportType !== undefined) {
                localVarQueryParameter['reportType'] = reportType;
            }

            if (previousReportId !== undefined) {
                localVarQueryParameter['previousReportId'] = previousReportId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get full data for a vehicle report.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGetDataGet: async (clientId: string, licenseNumber: string, reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('reportsGetDataGet', 'clientId', clientId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('reportsGetDataGet', 'licenseNumber', licenseNumber)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportsGetDataGet', 'reportId', reportId)
            const localVarPath = `/reports/get_data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }

            if (reportId !== undefined) {
                localVarQueryParameter['reportId'] = reportId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update vehicle report data.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {UpdateVehicleReportRequest} [updateVehicleReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsUpdateDataNewPatch: async (clientId: string, licenseNumber: string, reportId: string, updateVehicleReportRequest?: UpdateVehicleReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('reportsUpdateDataNewPatch', 'clientId', clientId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('reportsUpdateDataNewPatch', 'licenseNumber', licenseNumber)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportsUpdateDataNewPatch', 'reportId', reportId)
            const localVarPath = `/reports/update_data_new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }

            if (reportId !== undefined) {
                localVarQueryParameter['reportId'] = reportId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVehicleReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update vehicle report data.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsUpdateDataPatch: async (clientId: string, licenseNumber: string, reportId: string, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('reportsUpdateDataPatch', 'clientId', clientId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('reportsUpdateDataPatch', 'licenseNumber', licenseNumber)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportsUpdateDataPatch', 'reportId', reportId)
            const localVarPath = `/reports/update_data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }

            if (reportId !== undefined) {
                localVarQueryParameter['reportId'] = reportId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update document input result for a vehicle report.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {DocumentInputResult} [documentInputResult] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsUpdateDocumentInputResultPatch: async (clientId: string, licenseNumber: string, reportId: string, documentInputResult?: DocumentInputResult, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('reportsUpdateDocumentInputResultPatch', 'clientId', clientId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('reportsUpdateDocumentInputResultPatch', 'licenseNumber', licenseNumber)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportsUpdateDocumentInputResultPatch', 'reportId', reportId)
            const localVarPath = `/reports/update_document_input_result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }

            if (reportId !== undefined) {
                localVarQueryParameter['reportId'] = reportId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentInputResult, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update test notes for a vehicle report.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {string} testId ID of the test
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsUpdateTestNotesPatch: async (clientId: string, licenseNumber: string, reportId: string, testId: string, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('reportsUpdateTestNotesPatch', 'clientId', clientId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('reportsUpdateTestNotesPatch', 'licenseNumber', licenseNumber)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportsUpdateTestNotesPatch', 'reportId', reportId)
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('reportsUpdateTestNotesPatch', 'testId', testId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('reportsUpdateTestNotesPatch', 'body', body)
            const localVarPath = `/reports/update_test_notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }

            if (reportId !== undefined) {
                localVarQueryParameter['reportId'] = reportId;
            }

            if (testId !== undefined) {
                localVarQueryParameter['testId'] = testId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'text/plain';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update test result for a vehicle report.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {string} testId ID of the test
         * @param {boolean} testResult Test result (boolean)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsUpdateTestResultPatch: async (clientId: string, licenseNumber: string, reportId: string, testId: string, testResult: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('reportsUpdateTestResultPatch', 'clientId', clientId)
            // verify required parameter 'licenseNumber' is not null or undefined
            assertParamExists('reportsUpdateTestResultPatch', 'licenseNumber', licenseNumber)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportsUpdateTestResultPatch', 'reportId', reportId)
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('reportsUpdateTestResultPatch', 'testId', testId)
            // verify required parameter 'testResult' is not null or undefined
            assertParamExists('reportsUpdateTestResultPatch', 'testResult', testResult)
            const localVarPath = `/reports/update_test_result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }

            if (reportId !== undefined) {
                localVarQueryParameter['reportId'] = reportId;
            }

            if (testId !== undefined) {
                localVarQueryParameter['testId'] = testId;
            }

            if (testResult !== undefined) {
                localVarQueryParameter['testResult'] = testResult;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleReportApi - functional programming interface
 * @export
 */
export const VehicleReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a test photo to a vehicle report.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {string} testId ID of the test
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsAddTestPhotoPost(clientId: string, licenseNumber: string, reportId: string, testId: string, body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsAddTestPhotoPost(clientId, licenseNumber, reportId, testId, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleReportApi.reportsAddTestPhotoPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get headers for all vehicle reports.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsAllHeadersGet(clientId: string, licenseNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VehicleReportHeader>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsAllHeadersGet(clientId, licenseNumber, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleReportApi.reportsAllHeadersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update vehicle report data.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsCloseReportPatch(clientId: string, licenseNumber: string, reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsCloseReportPatch(clientId, licenseNumber, reportId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleReportApi.reportsCloseReportPatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create a new vehicle report.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportType Type of the report
         * @param {string} [previousReportId] ID of the previous report (optional)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsCreatePost(clientId: string, licenseNumber: string, reportType: string, previousReportId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleReportHeader>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsCreatePost(clientId, licenseNumber, reportType, previousReportId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleReportApi.reportsCreatePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get full data for a vehicle report.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsGetDataGet(clientId: string, licenseNumber: string, reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsGetDataGet(clientId, licenseNumber, reportId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleReportApi.reportsGetDataGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update vehicle report data.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {UpdateVehicleReportRequest} [updateVehicleReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsUpdateDataNewPatch(clientId: string, licenseNumber: string, reportId: string, updateVehicleReportRequest?: UpdateVehicleReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsUpdateDataNewPatch(clientId, licenseNumber, reportId, updateVehicleReportRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleReportApi.reportsUpdateDataNewPatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update vehicle report data.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsUpdateDataPatch(clientId: string, licenseNumber: string, reportId: string, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsUpdateDataPatch(clientId, licenseNumber, reportId, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleReportApi.reportsUpdateDataPatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update document input result for a vehicle report.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {DocumentInputResult} [documentInputResult] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsUpdateDocumentInputResultPatch(clientId: string, licenseNumber: string, reportId: string, documentInputResult?: DocumentInputResult, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentInputResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsUpdateDocumentInputResultPatch(clientId, licenseNumber, reportId, documentInputResult, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleReportApi.reportsUpdateDocumentInputResultPatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update test notes for a vehicle report.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {string} testId ID of the test
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsUpdateTestNotesPatch(clientId: string, licenseNumber: string, reportId: string, testId: string, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsUpdateTestNotesPatch(clientId, licenseNumber, reportId, testId, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleReportApi.reportsUpdateTestNotesPatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update test result for a vehicle report.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {string} testId ID of the test
         * @param {boolean} testResult Test result (boolean)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsUpdateTestResultPatch(clientId: string, licenseNumber: string, reportId: string, testId: string, testResult: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsUpdateTestResultPatch(clientId, licenseNumber, reportId, testId, testResult, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleReportApi.reportsUpdateTestResultPatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * VehicleReportApi - factory interface
 * @export
 */
export const VehicleReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleReportApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a test photo to a vehicle report.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {string} testId ID of the test
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsAddTestPhotoPost(clientId: string, licenseNumber: string, reportId: string, testId: string, body?: string, options?: any): AxiosPromise<string> {
            return localVarFp.reportsAddTestPhotoPost(clientId, licenseNumber, reportId, testId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get headers for all vehicle reports.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsAllHeadersGet(clientId: string, licenseNumber: string, options?: any): AxiosPromise<Array<VehicleReportHeader>> {
            return localVarFp.reportsAllHeadersGet(clientId, licenseNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update vehicle report data.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsCloseReportPatch(clientId: string, licenseNumber: string, reportId: string, options?: any): AxiosPromise<VehicleReportResponse> {
            return localVarFp.reportsCloseReportPatch(clientId, licenseNumber, reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new vehicle report.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportType Type of the report
         * @param {string} [previousReportId] ID of the previous report (optional)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsCreatePost(clientId: string, licenseNumber: string, reportType: string, previousReportId?: string, options?: any): AxiosPromise<VehicleReportHeader> {
            return localVarFp.reportsCreatePost(clientId, licenseNumber, reportType, previousReportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get full data for a vehicle report.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGetDataGet(clientId: string, licenseNumber: string, reportId: string, options?: any): AxiosPromise<VehicleReportResponse> {
            return localVarFp.reportsGetDataGet(clientId, licenseNumber, reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update vehicle report data.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {UpdateVehicleReportRequest} [updateVehicleReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsUpdateDataNewPatch(clientId: string, licenseNumber: string, reportId: string, updateVehicleReportRequest?: UpdateVehicleReportRequest, options?: any): AxiosPromise<VehicleReportResponse> {
            return localVarFp.reportsUpdateDataNewPatch(clientId, licenseNumber, reportId, updateVehicleReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update vehicle report data.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsUpdateDataPatch(clientId: string, licenseNumber: string, reportId: string, body?: object, options?: any): AxiosPromise<VehicleReportResponse> {
            return localVarFp.reportsUpdateDataPatch(clientId, licenseNumber, reportId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update document input result for a vehicle report.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {DocumentInputResult} [documentInputResult] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsUpdateDocumentInputResultPatch(clientId: string, licenseNumber: string, reportId: string, documentInputResult?: DocumentInputResult, options?: any): AxiosPromise<DocumentInputResult> {
            return localVarFp.reportsUpdateDocumentInputResultPatch(clientId, licenseNumber, reportId, documentInputResult, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update test notes for a vehicle report.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {string} testId ID of the test
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsUpdateTestNotesPatch(clientId: string, licenseNumber: string, reportId: string, testId: string, body: string, options?: any): AxiosPromise<void> {
            return localVarFp.reportsUpdateTestNotesPatch(clientId, licenseNumber, reportId, testId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update test result for a vehicle report.
         * @param {string} clientId ID of the client
         * @param {string} licenseNumber License number of the vehicle
         * @param {string} reportId ID of the report
         * @param {string} testId ID of the test
         * @param {boolean} testResult Test result (boolean)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsUpdateTestResultPatch(clientId: string, licenseNumber: string, reportId: string, testId: string, testResult: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.reportsUpdateTestResultPatch(clientId, licenseNumber, reportId, testId, testResult, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VehicleReportApi - object-oriented interface
 * @export
 * @class VehicleReportApi
 * @extends {BaseAPI}
 */
export class VehicleReportApi extends BaseAPI {
    /**
     * 
     * @summary Add a test photo to a vehicle report.
     * @param {string} clientId ID of the client
     * @param {string} licenseNumber License number of the vehicle
     * @param {string} reportId ID of the report
     * @param {string} testId ID of the test
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleReportApi
     */
    public reportsAddTestPhotoPost(clientId: string, licenseNumber: string, reportId: string, testId: string, body?: string, options?: AxiosRequestConfig) {
        return VehicleReportApiFp(this.configuration).reportsAddTestPhotoPost(clientId, licenseNumber, reportId, testId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get headers for all vehicle reports.
     * @param {string} clientId ID of the client
     * @param {string} licenseNumber License number of the vehicle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleReportApi
     */
    public reportsAllHeadersGet(clientId: string, licenseNumber: string, options?: AxiosRequestConfig) {
        return VehicleReportApiFp(this.configuration).reportsAllHeadersGet(clientId, licenseNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update vehicle report data.
     * @param {string} clientId ID of the client
     * @param {string} licenseNumber License number of the vehicle
     * @param {string} reportId ID of the report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleReportApi
     */
    public reportsCloseReportPatch(clientId: string, licenseNumber: string, reportId: string, options?: AxiosRequestConfig) {
        return VehicleReportApiFp(this.configuration).reportsCloseReportPatch(clientId, licenseNumber, reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new vehicle report.
     * @param {string} clientId ID of the client
     * @param {string} licenseNumber License number of the vehicle
     * @param {string} reportType Type of the report
     * @param {string} [previousReportId] ID of the previous report (optional)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleReportApi
     */
    public reportsCreatePost(clientId: string, licenseNumber: string, reportType: string, previousReportId?: string, options?: AxiosRequestConfig) {
        return VehicleReportApiFp(this.configuration).reportsCreatePost(clientId, licenseNumber, reportType, previousReportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get full data for a vehicle report.
     * @param {string} clientId ID of the client
     * @param {string} licenseNumber License number of the vehicle
     * @param {string} reportId ID of the report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleReportApi
     */
    public reportsGetDataGet(clientId: string, licenseNumber: string, reportId: string, options?: AxiosRequestConfig) {
        return VehicleReportApiFp(this.configuration).reportsGetDataGet(clientId, licenseNumber, reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update vehicle report data.
     * @param {string} clientId ID of the client
     * @param {string} licenseNumber License number of the vehicle
     * @param {string} reportId ID of the report
     * @param {UpdateVehicleReportRequest} [updateVehicleReportRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleReportApi
     */
    public reportsUpdateDataNewPatch(clientId: string, licenseNumber: string, reportId: string, updateVehicleReportRequest?: UpdateVehicleReportRequest, options?: AxiosRequestConfig) {
        return VehicleReportApiFp(this.configuration).reportsUpdateDataNewPatch(clientId, licenseNumber, reportId, updateVehicleReportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update vehicle report data.
     * @param {string} clientId ID of the client
     * @param {string} licenseNumber License number of the vehicle
     * @param {string} reportId ID of the report
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleReportApi
     */
    public reportsUpdateDataPatch(clientId: string, licenseNumber: string, reportId: string, body?: object, options?: AxiosRequestConfig) {
        return VehicleReportApiFp(this.configuration).reportsUpdateDataPatch(clientId, licenseNumber, reportId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update document input result for a vehicle report.
     * @param {string} clientId ID of the client
     * @param {string} licenseNumber License number of the vehicle
     * @param {string} reportId ID of the report
     * @param {DocumentInputResult} [documentInputResult] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleReportApi
     */
    public reportsUpdateDocumentInputResultPatch(clientId: string, licenseNumber: string, reportId: string, documentInputResult?: DocumentInputResult, options?: AxiosRequestConfig) {
        return VehicleReportApiFp(this.configuration).reportsUpdateDocumentInputResultPatch(clientId, licenseNumber, reportId, documentInputResult, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update test notes for a vehicle report.
     * @param {string} clientId ID of the client
     * @param {string} licenseNumber License number of the vehicle
     * @param {string} reportId ID of the report
     * @param {string} testId ID of the test
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleReportApi
     */
    public reportsUpdateTestNotesPatch(clientId: string, licenseNumber: string, reportId: string, testId: string, body: string, options?: AxiosRequestConfig) {
        return VehicleReportApiFp(this.configuration).reportsUpdateTestNotesPatch(clientId, licenseNumber, reportId, testId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update test result for a vehicle report.
     * @param {string} clientId ID of the client
     * @param {string} licenseNumber License number of the vehicle
     * @param {string} reportId ID of the report
     * @param {string} testId ID of the test
     * @param {boolean} testResult Test result (boolean)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleReportApi
     */
    public reportsUpdateTestResultPatch(clientId: string, licenseNumber: string, reportId: string, testId: string, testResult: boolean, options?: AxiosRequestConfig) {
        return VehicleReportApiFp(this.configuration).reportsUpdateTestResultPatch(clientId, licenseNumber, reportId, testId, testResult, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VehicleTachographApi - axios parameter creator
 * @export
 */
export const VehicleTachographApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} relatedDriverId 
         * @param {CreateVehicleTachograph} [createVehicleTachograph] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleTachographCreateRecordPost: async (clientId: string, vehicleId: string, relatedDriverId: string, createVehicleTachograph?: CreateVehicleTachograph, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleTachographCreateRecordPost', 'clientId', clientId)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('vehicleTachographCreateRecordPost', 'vehicleId', vehicleId)
            // verify required parameter 'relatedDriverId' is not null or undefined
            assertParamExists('vehicleTachographCreateRecordPost', 'relatedDriverId', relatedDriverId)
            const localVarPath = `/vehicle_tachograph/create_record`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (vehicleId !== undefined) {
                localVarQueryParameter['vehicleId'] = vehicleId;
            }

            if (relatedDriverId !== undefined) {
                localVarQueryParameter['relatedDriverId'] = relatedDriverId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createVehicleTachograph, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} date 
         * @param {string} tachographId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleTachographDeleteDelete: async (clientId: string, vehicleId: string, date: string, tachographId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleTachographDeleteDelete', 'clientId', clientId)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('vehicleTachographDeleteDelete', 'vehicleId', vehicleId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('vehicleTachographDeleteDelete', 'date', date)
            // verify required parameter 'tachographId' is not null or undefined
            assertParamExists('vehicleTachographDeleteDelete', 'tachographId', tachographId)
            const localVarPath = `/vehicle_tachograph/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (vehicleId !== undefined) {
                localVarQueryParameter['vehicleId'] = vehicleId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (tachographId !== undefined) {
                localVarQueryParameter['tachographId'] = tachographId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} [relatedDriverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleTachographDownloadSummeryPdfGet: async (clientId: string, vehicleId: string, fromDate: string, toDate: string, relatedDriverId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleTachographDownloadSummeryPdfGet', 'clientId', clientId)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('vehicleTachographDownloadSummeryPdfGet', 'vehicleId', vehicleId)
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('vehicleTachographDownloadSummeryPdfGet', 'fromDate', fromDate)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('vehicleTachographDownloadSummeryPdfGet', 'toDate', toDate)
            const localVarPath = `/vehicle_tachograph/download_summery_pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (vehicleId !== undefined) {
                localVarQueryParameter['vehicleId'] = vehicleId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = toDate;
            }

            if (relatedDriverId !== undefined) {
                localVarQueryParameter['relatedDriverId'] = relatedDriverId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} date 
         * @param {string} tachographId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleTachographGetDataGet: async (clientId: string, vehicleId: string, date: string, tachographId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleTachographGetDataGet', 'clientId', clientId)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('vehicleTachographGetDataGet', 'vehicleId', vehicleId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('vehicleTachographGetDataGet', 'date', date)
            // verify required parameter 'tachographId' is not null or undefined
            assertParamExists('vehicleTachographGetDataGet', 'tachographId', tachographId)
            const localVarPath = `/vehicle_tachograph/get_data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (vehicleId !== undefined) {
                localVarQueryParameter['vehicleId'] = vehicleId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (tachographId !== undefined) {
                localVarQueryParameter['tachographId'] = tachographId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} [relatedDriverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleTachographGetHeadersGet: async (clientId: string, vehicleId: string, fromDate: string, toDate: string, relatedDriverId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleTachographGetHeadersGet', 'clientId', clientId)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('vehicleTachographGetHeadersGet', 'vehicleId', vehicleId)
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('vehicleTachographGetHeadersGet', 'fromDate', fromDate)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('vehicleTachographGetHeadersGet', 'toDate', toDate)
            const localVarPath = `/vehicle_tachograph/get_headers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (vehicleId !== undefined) {
                localVarQueryParameter['vehicleId'] = vehicleId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = toDate;
            }

            if (relatedDriverId !== undefined) {
                localVarQueryParameter['relatedDriverId'] = relatedDriverId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} date 
         * @param {string} tachographId 
         * @param {string} [relatedDriverId] 
         * @param {UpdateVehicleTachograph} [updateVehicleTachograph] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleTachographUpdateRecordPatch: async (clientId: string, vehicleId: string, date: string, tachographId: string, relatedDriverId?: string, updateVehicleTachograph?: UpdateVehicleTachograph, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('vehicleTachographUpdateRecordPatch', 'clientId', clientId)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('vehicleTachographUpdateRecordPatch', 'vehicleId', vehicleId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('vehicleTachographUpdateRecordPatch', 'date', date)
            // verify required parameter 'tachographId' is not null or undefined
            assertParamExists('vehicleTachographUpdateRecordPatch', 'tachographId', tachographId)
            const localVarPath = `/vehicle_tachograph/update_record`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (vehicleId !== undefined) {
                localVarQueryParameter['vehicleId'] = vehicleId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (tachographId !== undefined) {
                localVarQueryParameter['tachographId'] = tachographId;
            }

            if (relatedDriverId !== undefined) {
                localVarQueryParameter['relatedDriverId'] = relatedDriverId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVehicleTachograph, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleTachographApi - functional programming interface
 * @export
 */
export const VehicleTachographApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleTachographApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} relatedDriverId 
         * @param {CreateVehicleTachograph} [createVehicleTachograph] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleTachographCreateRecordPost(clientId: string, vehicleId: string, relatedDriverId: string, createVehicleTachograph?: CreateVehicleTachograph, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleTachographHeader>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleTachographCreateRecordPost(clientId, vehicleId, relatedDriverId, createVehicleTachograph, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleTachographApi.vehicleTachographCreateRecordPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} date 
         * @param {string} tachographId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleTachographDeleteDelete(clientId: string, vehicleId: string, date: string, tachographId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleTachographDeleteDelete(clientId, vehicleId, date, tachographId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleTachographApi.vehicleTachographDeleteDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} [relatedDriverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleTachographDownloadSummeryPdfGet(clientId: string, vehicleId: string, fromDate: string, toDate: string, relatedDriverId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleTachographDownloadSummeryPdfGet(clientId, vehicleId, fromDate, toDate, relatedDriverId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleTachographApi.vehicleTachographDownloadSummeryPdfGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} date 
         * @param {string} tachographId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleTachographGetDataGet(clientId: string, vehicleId: string, date: string, tachographId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleTachograph>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleTachographGetDataGet(clientId, vehicleId, date, tachographId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleTachographApi.vehicleTachographGetDataGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} [relatedDriverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleTachographGetHeadersGet(clientId: string, vehicleId: string, fromDate: string, toDate: string, relatedDriverId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VehicleTachographHeader>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleTachographGetHeadersGet(clientId, vehicleId, fromDate, toDate, relatedDriverId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleTachographApi.vehicleTachographGetHeadersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} date 
         * @param {string} tachographId 
         * @param {string} [relatedDriverId] 
         * @param {UpdateVehicleTachograph} [updateVehicleTachograph] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleTachographUpdateRecordPatch(clientId: string, vehicleId: string, date: string, tachographId: string, relatedDriverId?: string, updateVehicleTachograph?: UpdateVehicleTachograph, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleTachograph>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleTachographUpdateRecordPatch(clientId, vehicleId, date, tachographId, relatedDriverId, updateVehicleTachograph, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehicleTachographApi.vehicleTachographUpdateRecordPatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * VehicleTachographApi - factory interface
 * @export
 */
export const VehicleTachographApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleTachographApiFp(configuration)
    return {
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} relatedDriverId 
         * @param {CreateVehicleTachograph} [createVehicleTachograph] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleTachographCreateRecordPost(clientId: string, vehicleId: string, relatedDriverId: string, createVehicleTachograph?: CreateVehicleTachograph, options?: any): AxiosPromise<VehicleTachographHeader> {
            return localVarFp.vehicleTachographCreateRecordPost(clientId, vehicleId, relatedDriverId, createVehicleTachograph, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} date 
         * @param {string} tachographId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleTachographDeleteDelete(clientId: string, vehicleId: string, date: string, tachographId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.vehicleTachographDeleteDelete(clientId, vehicleId, date, tachographId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} [relatedDriverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleTachographDownloadSummeryPdfGet(clientId: string, vehicleId: string, fromDate: string, toDate: string, relatedDriverId?: string, options?: any): AxiosPromise<File> {
            return localVarFp.vehicleTachographDownloadSummeryPdfGet(clientId, vehicleId, fromDate, toDate, relatedDriverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} date 
         * @param {string} tachographId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleTachographGetDataGet(clientId: string, vehicleId: string, date: string, tachographId: string, options?: any): AxiosPromise<VehicleTachograph> {
            return localVarFp.vehicleTachographGetDataGet(clientId, vehicleId, date, tachographId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} [relatedDriverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleTachographGetHeadersGet(clientId: string, vehicleId: string, fromDate: string, toDate: string, relatedDriverId?: string, options?: any): AxiosPromise<Array<VehicleTachographHeader>> {
            return localVarFp.vehicleTachographGetHeadersGet(clientId, vehicleId, fromDate, toDate, relatedDriverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} vehicleId 
         * @param {string} date 
         * @param {string} tachographId 
         * @param {string} [relatedDriverId] 
         * @param {UpdateVehicleTachograph} [updateVehicleTachograph] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleTachographUpdateRecordPatch(clientId: string, vehicleId: string, date: string, tachographId: string, relatedDriverId?: string, updateVehicleTachograph?: UpdateVehicleTachograph, options?: any): AxiosPromise<VehicleTachograph> {
            return localVarFp.vehicleTachographUpdateRecordPatch(clientId, vehicleId, date, tachographId, relatedDriverId, updateVehicleTachograph, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VehicleTachographApi - object-oriented interface
 * @export
 * @class VehicleTachographApi
 * @extends {BaseAPI}
 */
export class VehicleTachographApi extends BaseAPI {
    /**
     * 
     * @param {string} clientId 
     * @param {string} vehicleId 
     * @param {string} relatedDriverId 
     * @param {CreateVehicleTachograph} [createVehicleTachograph] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleTachographApi
     */
    public vehicleTachographCreateRecordPost(clientId: string, vehicleId: string, relatedDriverId: string, createVehicleTachograph?: CreateVehicleTachograph, options?: AxiosRequestConfig) {
        return VehicleTachographApiFp(this.configuration).vehicleTachographCreateRecordPost(clientId, vehicleId, relatedDriverId, createVehicleTachograph, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId 
     * @param {string} vehicleId 
     * @param {string} date 
     * @param {string} tachographId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleTachographApi
     */
    public vehicleTachographDeleteDelete(clientId: string, vehicleId: string, date: string, tachographId: string, options?: AxiosRequestConfig) {
        return VehicleTachographApiFp(this.configuration).vehicleTachographDeleteDelete(clientId, vehicleId, date, tachographId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId 
     * @param {string} vehicleId 
     * @param {string} fromDate 
     * @param {string} toDate 
     * @param {string} [relatedDriverId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleTachographApi
     */
    public vehicleTachographDownloadSummeryPdfGet(clientId: string, vehicleId: string, fromDate: string, toDate: string, relatedDriverId?: string, options?: AxiosRequestConfig) {
        return VehicleTachographApiFp(this.configuration).vehicleTachographDownloadSummeryPdfGet(clientId, vehicleId, fromDate, toDate, relatedDriverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId 
     * @param {string} vehicleId 
     * @param {string} date 
     * @param {string} tachographId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleTachographApi
     */
    public vehicleTachographGetDataGet(clientId: string, vehicleId: string, date: string, tachographId: string, options?: AxiosRequestConfig) {
        return VehicleTachographApiFp(this.configuration).vehicleTachographGetDataGet(clientId, vehicleId, date, tachographId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId 
     * @param {string} vehicleId 
     * @param {string} fromDate 
     * @param {string} toDate 
     * @param {string} [relatedDriverId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleTachographApi
     */
    public vehicleTachographGetHeadersGet(clientId: string, vehicleId: string, fromDate: string, toDate: string, relatedDriverId?: string, options?: AxiosRequestConfig) {
        return VehicleTachographApiFp(this.configuration).vehicleTachographGetHeadersGet(clientId, vehicleId, fromDate, toDate, relatedDriverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId 
     * @param {string} vehicleId 
     * @param {string} date 
     * @param {string} tachographId 
     * @param {string} [relatedDriverId] 
     * @param {UpdateVehicleTachograph} [updateVehicleTachograph] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleTachographApi
     */
    public vehicleTachographUpdateRecordPatch(clientId: string, vehicleId: string, date: string, tachographId: string, relatedDriverId?: string, updateVehicleTachograph?: UpdateVehicleTachograph, options?: AxiosRequestConfig) {
        return VehicleTachographApiFp(this.configuration).vehicleTachographUpdateRecordPatch(clientId, vehicleId, date, tachographId, relatedDriverId, updateVehicleTachograph, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WebsiteApi - axios parameter creator
 * @export
 */
export const WebsiteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all pricing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        websitePricingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/website/pricing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebsiteApi - functional programming interface
 * @export
 */
export const WebsiteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebsiteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all pricing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async websitePricingGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WebsiteProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.websitePricingGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebsiteApi.websitePricingGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * WebsiteApi - factory interface
 * @export
 */
export const WebsiteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebsiteApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all pricing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        websitePricingGet(options?: any): AxiosPromise<Array<WebsiteProduct>> {
            return localVarFp.websitePricingGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebsiteApi - object-oriented interface
 * @export
 * @class WebsiteApi
 * @extends {BaseAPI}
 */
export class WebsiteApi extends BaseAPI {
    /**
     * 
     * @summary Get all pricing.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebsiteApi
     */
    public websitePricingGet(options?: AxiosRequestConfig) {
        return WebsiteApiFp(this.configuration).websitePricingGet(options).then((request) => request(this.axios, this.basePath));
    }
}



