import {
  Checkbox,
  CircularProgress,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import { CourseAtendee, CourseAtendeeStatus } from "../../../../api";

import Label from "../../../../global/components/label/Label";
import CustomPopover, { usePopover } from "../../../components/custom-popover";
import { fDate } from "../../../utils/format/formatTime";

export const getCourseAttendeeStatusColor = (
  attendeeStatus?: CourseAtendeeStatus
) => {
  switch (attendeeStatus) {
    case CourseAtendeeStatus.REGISTERED:
      return "primary";
    case CourseAtendeeStatus.PASSED:
      return "success";
    case CourseAtendeeStatus.FAILED:
      return "error";
    default:
      return "default";
  }
};

type AtendeesListRowProps = {
  row: CourseAtendee;
  onRowClick?: (_row: CourseAtendee) => void;
  onUpdateStatus?: (
    _row: CourseAtendee,
    _newStatus: CourseAtendeeStatus
  ) => void;
  translate: Function;
  isSelected?: boolean;
  showCheckbox?: boolean;
  showStatus?: boolean;
  showEmail?: boolean;
  disabled?: boolean;
  loadingAtendeesStatusIds?: string[];
};

export function AtendeesListRow({
  row,
  onRowClick,
  onUpdateStatus,
  translate,
  isSelected,
  showCheckbox,
  showStatus,
  showEmail,
  disabled,
  loadingAtendeesStatusIds,
}: AtendeesListRowProps) {
  const popover = usePopover();

  const globalNoData = `${translate("global_no_data")}`;
  const textColor = disabled ? "text.disabled" : "text.primary";

  const {
    govId,
    name,
    email,
    phoneNumner,
    status,
    singedComplete,
    singedCompletedAt,
  } = row;

  const handleRowClick = () => {
    if (!disabled && onRowClick) {
      onRowClick(row);
    }
  };

  return (
    <>
      <TableRow hover onClick={handleRowClick} sx={{ cursor: "pointer" }}>
        {/* Checkbox if needed */}
        {showCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox
              checked={isSelected === true}
              onChange={() => {}}
              sx={{ padding: 0 }}
              disabled={disabled}
            />
          </TableCell>
        )}

        {/* Name */}
        <TableCell>
          <Typography variant="subtitle2" noWrap color={textColor}>
            {name}
          </Typography>
        </TableCell>

        {/* govId */}
        <TableCell align="center">
          <Typography variant="body2" noWrap color={textColor}>
            {govId}
          </Typography>
        </TableCell>

        {/* phoneNumner */}
        <TableCell align="center">
          <Typography variant="body2" noWrap color={textColor}>
            {`${phoneNumner}`}
          </Typography>
        </TableCell>

        {/* email */}
        {showEmail && (
          <TableCell align="center">
            <Typography variant="body2" noWrap color={textColor}>
              {email && email?.length > 0 ? email : globalNoData}
            </Typography>
          </TableCell>
        )}

        {/* singedComplete */}
        {showStatus && (
          <TableCell align="center">
            <Typography variant="body2" noWrap color={textColor}>
              {singedComplete && singedCompletedAt
                ? `${translate("global_yes")} | ${fDate(singedCompletedAt)}`
                : translate("global_no")}
            </Typography>
          </TableCell>
        )}

        {/* Status */}
        {showStatus && (
          <TableCell align="center">
            {loadingAtendeesStatusIds?.includes(row.id) ? (
              <CircularProgress color="primary" size={18} thickness={5} />
            ) : (
              <Label
                sx={{ cursor: "pointer" }}
                variant="soft"
                color={getCourseAttendeeStatusColor(status)}
                // onClick={popover.onOpen}
              >
                {status
                  ? translate(
                      `course_atendee_status_title_${status.toLowerCase()}`
                    )
                  : globalNoData}
              </Label>
            )}
          </TableCell>
        )}
      </TableRow>

      {/* Popover */}
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        {Object.values(CourseAtendeeStatus).map((atendeeStatus) => (
          <MenuItem
            key={atendeeStatus}
            onClick={() => {
              popover.onClose();
              if (onUpdateStatus) {
                onUpdateStatus(row, atendeeStatus);
              }
            }}
          >
            <Label
              variant="soft"
              color={getCourseAttendeeStatusColor(atendeeStatus)}
              onClick={popover.onOpen}
            >
              {translate(
                `course_atendee_status_title_${atendeeStatus.toLowerCase()}`
              )}
            </Label>
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
}
