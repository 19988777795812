import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Add, Upload } from "@mui/icons-material";

import {
  DriverUser,
  VehicleHeader,
} from "../../../../api";
import { CustomTableHeadProps } from "../../common/views/tableView/SearchableTable";
import AddDriverDocuments from "./AddDriverDocuments";
import { fDate } from "../../../utils/format/formatTime";
import ViewGenericDataTable, {
  ViewGenericDataTableData,
} from "../../common/views/tableView/ViewGenericDataTable";
import GenericMessageView from "../../common/views/GenericMessageView";
import {
  useDashboardAppDispatch,
  useDashboardAppSelector,
} from "../../../hooks/useRedux";
import {
  AddDriverUploadDocumentParams,
  clearCurrentDocuments,
  DriverDocumentTableItem,
  getAllDriverDocuments,
  getDriverAvailableDocumentConfigOptions,
  getDriverAvailableUploadDocumentConfigOptions,
  uploadVehicleDocument,
} from "../driverPageSlice";
import { DashboardRootState } from "../../../app/dashboardAppStore";
import {
  DocumentConfigOption,
  DriverDocumentHeader,
  DriverDocumentUpload,
} from "../../../../api/api";
import UploadDriverDocuments, {
  DriverDocumentUploadData,
} from "./UploadDriverDocuments";

type DriverDocumentsListRowProps = {
  docsOptions: DocumentConfigOption[];
  documentItem: DriverDocumentTableItem;
  onRowClick?: Function;
  translate: Function;
};

function DriverDocumentsListRow({
  docsOptions,
  documentItem,
  onRowClick,
  translate,
}: DriverDocumentsListRowProps) {
  const handleRowClick = () => {
    if (onRowClick) {
      onRowClick(documentItem);
    }
  };

  const docTitle = docsOptions?.find(
    (opt) => opt.type === documentItem.documentType
  )?.label;

  return (
    <>
      <TableRow hover onClick={handleRowClick} sx={{ cursor: "pointer" }}>
        {/* documentType */}
        <TableCell>
          <Typography variant="subtitle2" noWrap color={"text"}>
            {`${docTitle}`}
          </Typography>
        </TableCell>

        {/* createdAt or expirationDate */}
        <TableCell align="center">
          <Typography
            variant="subtitle2"
            noWrap
            sx={{
              color: (documentItem.raw as DriverDocumentUpload)?.isExpired
                ? "error.main"
                : "text.primary",
            }}
          >
            {`${
              (documentItem.raw as DriverDocumentHeader)?.createdAt
                ? fDate((documentItem.raw as DriverDocumentHeader)?.createdAt)
                : (documentItem.raw as DriverDocumentUpload)?.expirationDate
                  ? fDate(
                      (documentItem.raw as DriverDocumentUpload)?.expirationDate
                    )
                  : translate("global_no_data")
            } ${
              (documentItem.raw as DriverDocumentUpload)?.isExpired
                ? `(${translate("driver_document_expired_title")})`
                : ``
            }`}
          </Typography>
        </TableCell>

        {/* title */}
        <TableCell align="center">
          {documentItem.title || translate("global_no_data")}
        </TableCell>
      </TableRow>
    </>
  );
}

type Props = {
  isLoading: boolean;
  clientId?: string;
  driver?: DriverUser | null;
  clientVehicles?: VehicleHeader[] | null;
  documentTypes?: string[] | null;
  translate: Function;
  onDocListRowClicked: (_documentItem: DriverDocumentTableItem) => void;
};

export default function DriverDocumentsPage({
  isLoading,
  clientId,
  driver,
  translate,
  onDocListRowClicked,
}: Props) {
  const dispatch = useDashboardAppDispatch();

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const handleClose = () => {
    setIsAddDialogOpen(false);
  };

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const handleUploadClose = () => {
    setIsUploadDialogOpen(false);
  };

  const documentGeneratedConfigOptions = useDashboardAppSelector(
    (state: DashboardRootState) => state.driverPageSlice.documentConfigOptions
  );

  const documentUploadedConfigOptions = useDashboardAppSelector(
    (state: DashboardRootState) =>
      state.driverPageSlice.documentUploadedConfigOptions
  );

  const currentDocuments = useDashboardAppSelector(
    (state: DashboardRootState) => state.driverPageSlice.currentDocuments
  );

  const loadingData = useDashboardAppSelector(
    (state: DashboardRootState) => state.driverPageSlice.isLoadingData
  );

  const getData = () => {
    if (driver?.id) {
      dispatch(getAllDriverDocuments({ driverUserId: driver?.id }));
    }
  };

  const handleUploadDoc = async (
    documentData: DriverDocumentUploadData
  ): Promise<string | null> => {
    if (driver?.id && clientId && documentData.selectedFile !== null) {
      return new Promise((resolve) => {
        dispatch(
          uploadVehicleDocument({
            driverUserId: driver?.id!,
            documentType: documentData.type,
            expirationDate: documentData.expireDate,
            title:
              documentData.title ??
              `${translate(
                `driver_document_upload_title_${documentData.type.toLowerCase()}`
              )}`,
            file: documentData.selectedFile,
            isHidden: documentData.isHiddenFile,
            onRespose: (isSuccess: boolean) => {
              if (isSuccess) {
                dispatch(getAllDriverDocuments({ driverUserId: driver?.id! }));
                resolve(null);
                handleUploadClose();
              } else {
                resolve(
                  `${translate("driver_documents_page_error_adding_document")}`
                );
              }
            },
          } as AddDriverUploadDocumentParams)
        );
      });
    }
    return null;
  };

  // Get the data:
  useEffect(() => {
    if (!currentDocuments && driver?.id) {
      getData();
    }

    if (!documentGeneratedConfigOptions || !documentUploadedConfigOptions) {
      dispatch(getDriverAvailableDocumentConfigOptions());
      dispatch(getDriverAvailableUploadDocumentConfigOptions());
    }

    return () => {
      // Cleanup
      dispatch(clearCurrentDocuments());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driver]);

  const renderRow = (documentItem: DriverDocumentTableItem) => (
    <DriverDocumentsListRow
      docsOptions={[
        ...(documentGeneratedConfigOptions || []),
        ...(documentUploadedConfigOptions || []),
      ]}
      key={documentItem.id}
      documentItem={documentItem}
      onRowClick={onDocListRowClicked}
      translate={translate}
    />
  );

  const TABLE_HEAD: CustomTableHeadProps[] = [
    {
      id: "documentType",
      label: `${translate("driver_documents_type")}`,
      align: "left",
    },
    {
      id: "createdAt",
      label: `${translate("driver_document_create_date")}`,
      align: "center",
    },
    {
      id: "title",
      label: `${translate("driver_documents_upload_name_title")}`,
      align: "center",
    },
  ];

  const genericDataTableViewProps: ViewGenericDataTableData<DriverDocumentTableItem> =
    {
      isLoading,
      translate,
      titleKey: "driver_documents_list_title",
      data: currentDocuments || [],
      renderRow,
      tableHead: TABLE_HEAD,
      searchable: true,
      searchLabelKey: "driver_documents_search_documents",
      overrideActionButtons: (
        <>
          <Stack spacing={1} direction={"row"}>
            <Button
              onClick={() => setIsUploadDialogOpen(true)}
              variant="contained"
              startIcon={<Upload />}
            >
              {`${translate("driver_documents_upload_new_document")}`}
            </Button>

            <Button
              onClick={() => setIsAddDialogOpen(true)}
              variant="contained"
              color="success"
              startIcon={<Add />}
            >
              {`${translate("driver_documents_generate_new_document")}`}
            </Button>
          </Stack>
        </>
      ),
      emptyStateComponent: (
        <GenericMessageView
          title={`${translate("driver_documents_list_empty_title")}`}
          subtitle={``}
          buttonTitle={`${translate("driver_documents_generate_new_document")}`}
          onAction={() => setIsAddDialogOpen(true)}
        />
      ),
    };

  // ------------------- Main view -------------------
  return (
    <>
      <ViewGenericDataTable {...genericDataTableViewProps} />

      <Dialog
        fullWidth
        maxWidth="md"
        scroll="paper"
        open={isAddDialogOpen}
        onClose={handleClose}
      >
        <DialogContent dividers>
          <AddDriverDocuments
            clientId={clientId}
            driver={driver}
            translate={translate}
            onComplete={(isSuccess: Boolean) => {
              if (isSuccess) {
                handleClose();
              }
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={isUploadDialogOpen} onClose={handleUploadClose}>
        <UploadDriverDocuments
          driver={driver}
          translate={translate}
          isLoading={loadingData.uploadDocument}
          handleUploadDoc={(data: any) => {
            handleUploadDoc(data);
          }}
        />
      </Dialog>
    </>
  );
}
