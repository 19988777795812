import { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import { Add } from "@mui/icons-material";
import {
  Address,
  DocumentInputResult,
  DocumentInputType,
  DriverDocumentCreateReqV1,
  DriverDocumentHeader,
  DriverDocumentInputOptionKeys,
  DriverUser,
} from "../../../../api";
import FullWithDataView from "../../common/views/FullWithDataView";
import {
  useDashboardAppDispatch,
  useDashboardAppSelector,
} from "../../../hooks/useRedux";
import { DashboardRootState } from "../../../app/dashboardAppStore";
import {
  addNewDriverDocument,
  clearDocumentConfig,
  clearSelectedDocumentHtmlPreview,
  downloadDriverDocument,
  getDriverDocumentConfig,
  getGeneratedDriverDocumentHtmlPreview,
} from "../driverPageSlice";
import FormProvider, {
  RHFTextField,
} from "../../../../global/components/hook-form";
import LoadingView from "../../../components/loading-view";
import { getRHFComponentByType } from "../../../../global/components/hook-form/RHFUtils";
import { getAddressInputFields } from "../../../utils/formsUtils";
import { genericRequiredAnySchema } from "../../../../global/utils/formValidators";
import { DocumentTypeSelect } from "../../common/views/DocumentTypeSelect";

type Props = {
  clientId?: string;
  driver?: DriverUser | null;
  translate: Function;
  onComplete: (_isSuccess: Boolean) => void;
};

export default function AddDriverDocuments({
  clientId,
  driver,
  translate,
  onComplete,
}: Props) {
  const dispatch = useDashboardAppDispatch();

  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);

  const [selectedDocumentType, setSelectedDocumentType] = useState<
    string | null
  >();

  const documentConfigOptions = useDashboardAppSelector(
    (state: DashboardRootState) =>
      state.driverPageSlice.documentConfigOptions || []
  );

  const isLoadingData = useDashboardAppSelector(
    (state: DashboardRootState) => state.driverPageSlice.isLoadingData
  );

  const selectedDocumentConfig = useDashboardAppSelector(
    (state: DashboardRootState) => state.driverPageSlice.selectedDocumentConfig
  );

  const selectedDocumentHtmlPreview = useDashboardAppSelector(
    (state: DashboardRootState) =>
      state.driverPageSlice.selectedDocumentHtmlPreview
  );

  useEffect(() => {
    if (clientId && selectedDocumentType && selectedDocumentType.length > 0) {
      dispatch(
        getDriverDocumentConfig({
          documentType: selectedDocumentType,
          clientId: clientId!,
        })
      );
    }

    return () => {
      dispatch(clearDocumentConfig());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocumentType]);

  const onPreviewClick = () => {
    dispatch(
      getGeneratedDriverDocumentHtmlPreview({
        driverUserId: driver?.id!,
        documentType: selectedDocumentType!,
        onComplete: (isSuccess) => {
          setIsPreviewDialogOpen(isSuccess);
        },
      })
    );
  };

  const handleClosePreviewDialog = () => {
    setIsPreviewDialogOpen(false);
    dispatch(clearSelectedDocumentHtmlPreview());
  };

  const handleGenerateDoc = async (request: DriverDocumentCreateReqV1) => {
    const result = (await dispatch(
      addNewDriverDocument({ request })
    )) as PayloadAction<DriverDocumentHeader>;
    if (result?.payload.id !== null) {
      dispatch(
        downloadDriverDocument({
          driverId: driver?.id!,
          documentId: result?.payload.id!,
        })
      );
    }
    onComplete(result !== undefined);
  };

  const NewDriverSchema = Yup.object().shape(
    selectedDocumentConfig?.documentInputs?.reduce((acc, docInput) => {
      if (docInput.isMandatory) {
        acc[docInput.dbKey] = genericRequiredAnySchema(translate);
      }
      return acc;
    }, {} as any)
  );

  const methods = useForm({
    resolver: yupResolver(NewDriverSchema),
  });

  const { reset, watch, handleSubmit } = methods;

  const includeSendToAddress = watch(
    DriverDocumentInputOptionKeys.INCLUDE_SEND_TO_ADDRESS
  );

  const onSubmit = async (formData: any) => {
    let sendToAddress: Address | null = null;
    if (includeSendToAddress) {
      sendToAddress = {
        street: formData.street,
        houseNumber: formData.houseNumber,
        city: formData.city,
        zipCode: formData.zipCode,
      } as Address;
    }

    const documentResults: DocumentInputResult[] = [];
    Object.keys(formData).forEach((key) => {
      documentResults.push({
        dbKey: key,
        value: formData[key],
      });
    });

    const createDocumentRequest = {
      documentType: selectedDocumentType,
      driverId: driver?.id,
      relatedClientId: clientId,
      documentInputResults: documentResults,
      sendToAddress,
      sendToName: formData[DriverDocumentInputOptionKeys.SEND_TO_ADDRESS_NAME],
    } as DriverDocumentCreateReqV1;

    handleGenerateDoc(createDocumentRequest);
  };

  const handleOnTypeChange = (value: string) => {
    setSelectedDocumentType(value);
    reset();
  };

  const itemsPerRow = 2;
  const ScreenContent = () => (
    <Stack spacing={2}>
      {/** Fixed Slected Document Type */}
      <DocumentTypeSelect
        translate={translate}
        id="driver_documents_generate_title_type"
        labelKey="driver_documents_generate_title_type"
        currentValue={selectedDocumentType || ""}
        options={documentConfigOptions}
        onSelect={handleOnTypeChange}
      />

      <LoadingButton
        variant="contained"
        color="primary"
        onClick={onPreviewClick}
        disabled={!selectedDocumentType || selectedDocumentType.length === 0}
        loading={isLoadingData.documentPreview}
      >
        {`${translate("global_preview")}`}
      </LoadingButton>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {isLoadingData.documentConfig ? (
          <LoadingView />
        ) : (
          selectedDocumentConfig && (
            <Stack spacing={4} alignContent={"center"} alignItems={"center"}>
              <Grid container rowSpacing={2} columnSpacing={2} sx={{ pr: 2 }}>
                {/** Dynamic form fileds */}
                {selectedDocumentConfig?.documentInputs?.map(
                  (docConfig, index) => (
                    <Grid item xs={12 / itemsPerRow} key={index}>
                      {getRHFComponentByType({
                        type: docConfig.type,
                        name: docConfig.dbKey,
                        label: docConfig.label,
                        options: docConfig.options,
                      })}
                    </Grid>
                  )
                )}
              </Grid>

              {/** Send to address */}
              {includeSendToAddress && (
                <Grid container rowSpacing={2} columnSpacing={2} sx={{ pr: 2 }}>
                  <Grid
                    item
                    xs={12 / itemsPerRow}
                    key={DriverDocumentInputOptionKeys.SEND_TO_ADDRESS_NAME}
                  >
                    {getRHFComponentByType({
                      type: DocumentInputType.TEXT,
                      name: DriverDocumentInputOptionKeys.SEND_TO_ADDRESS_NAME,
                      label: `${translate("driver_documents_generate_send_to_name")}`,
                    })}
                  </Grid>
                  {getAddressInputFields.map((field) => (
                    <Grid item xs={12 / itemsPerRow} key={field.name}>
                      <RHFTextField
                        key={field.name}
                        name={field.name}
                        label={`${translate(field.lablel_key)}`}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}

              <LoadingButton
                type="submit"
                sx={{ width: "50%" }}
                variant="contained"
                color="success"
                loading={isLoadingData.uploadDocument}
                startIcon={<Add />}
              >
                {`${translate("driver_documents_generate_new_document")}`}
              </LoadingButton>
            </Stack>
          )
        )}
      </FormProvider>
    </Stack>
  );

  return (
    <>
      {driver && (
        <FullWithDataView
          title={`${translate("driver_documents_generate_title")}`}
          subheader={`${translate("driver_documents_subtitle_completed")}`}
          itemsPerRow={2}
          translate={translate}
          overrideContent={<ScreenContent />}
          elevation={0}
        />
      )}

      {isPreviewDialogOpen && (
        <Dialog
          fullWidth
          maxWidth="md"
          scroll="paper"
          open={isPreviewDialogOpen}
          onClose={handleClosePreviewDialog}
        >
          <DialogTitle id="scroll-dialog-title">{`${translate("driver_documents_preivew_title")}`}</DialogTitle>
          <DialogContent dividers>
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(selectedDocumentHtmlPreview || "", {
                  FORCE_BODY: true,
                  ALLOWED_ATTR: ["style", "class", "type"],
                }),
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClosePreviewDialog}
            >{`${translate("global_close")}`}</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
