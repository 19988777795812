import { Typography } from "@mui/material";

import {
  RHFCheckbox,
  RHFDatePicker,
  RHFDateTimePicker,
  RHFRadioGroup,
  RHFSelect,
  RHFTextField,
} from "./index";
import { DocumentInputOption, DocumentInputType } from "../../../api";
import { Upload } from "../upload";

// Get RHF Component by type:
export function getRHFComponentByType({
  type,
  name,
  label,
  options,
}: {
  type: DocumentInputType;
  name: string;
  label: string;
  options?: DocumentInputOption[];
}): JSX.Element | null {
  switch (type) {
    case DocumentInputType.LABEL:
      return (
        <Typography variant="body2" color="textSecondary">
          {label}
        </Typography>
      );
    case DocumentInputType.TEXT:
      return <RHFTextField key={name} id={name} name={name} label={label} type="text" />;
    case DocumentInputType.NUMBER:
      return <RHFTextField key={name} id={name} name={name} label={label} type="number" />;
    case DocumentInputType.DATE:
      return <RHFDatePicker key={name} name={name} label={label} />;
    case DocumentInputType.TIME:
      return <RHFDateTimePicker key={name} name={name} label={label} />;
    case DocumentInputType.SELECT:
      return (
        <RHFSelect key={name} native name={name} label={label}>
          <option key={""} value={""} />
          {options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </RHFSelect>
      );
    case DocumentInputType.CHECKBOX:
      return <RHFCheckbox key={name} id={name} name={name} label={label} />;
    case DocumentInputType.RADIO:
      return (
        <RHFRadioGroup key={name} id={name} name={name} label={label} options={options || []} />
      );
    case DocumentInputType.FILE:
      return <Upload key={name} />;

    default:
      return (
        <Typography variant="subtitle2" color="textSecondary">
          Error - Not implemented
        </Typography>
      );
  }
}
