// @mui
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";

import { CourseHeader, DocumentConfigOption } from "../../../../api";
import { getCourseStatusColor } from "./CoursesListView";

import Label from "../../../../global/components/label";
import { fDate } from "../../../utils/format/formatTime";
import { getCourseTypeLabel } from "../clientCoursesUtils";

// ----------------------------------------------------------------------

type Props = {
  translate: Function;
  row: CourseHeader;
  onViewRow: VoidFunction;
  courseOrDocumentConfigOptions?: DocumentConfigOption[];
};

export default function CoursesListViewRowItem({
  translate,
  row,
  onViewRow,
  courseOrDocumentConfigOptions,
}: Props) {
  const { title, courseType, status, startedAt } = row;

  const globalNoData = `${translate("global_no_data")}`;

  return (
    <>
      <TableRow hover onClick={onViewRow} sx={{ cursor: "pointer" }}>
        {/** title */}
        <TableCell>
          <ListItemText
            primary={
              <Typography variant="body2">{title ?? globalNoData}</Typography>
            }
          />
        </TableCell>

        {/** courseType */}
        <TableCell>
          <ListItemText
            primary={
              <Typography variant="body2">
                {
                  getCourseTypeLabel({
                    translate,
                    courseType,
                    options: courseOrDocumentConfigOptions,
                  })
                }
              </Typography>
            }
          />
        </TableCell>

        {/** status */}
        <TableCell>
          <Label variant="soft" color={getCourseStatusColor(status)}>
            {status
              ? translate(`course_status_title_${status.toLowerCase()}`)
              : globalNoData}
          </Label>
        </TableCell>

        {/** started at */}
        <TableCell>
          <ListItemText
            primary={
              <Typography variant="body2">
                {startedAt ? fDate(startedAt) : globalNoData}
              </Typography>
            }
          />
        </TableCell>
      </TableRow>
    </>
  );
}
