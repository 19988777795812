/* eslint-disable no-unused-vars */
import {
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from "react";
import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  logEvent,
  setUserId,
  EventParams,
} from "firebase/analytics";
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  onIdTokenChanged,
  User,
  signInAnonymously,
} from "firebase/auth";

// config
import axiosInstance from "../../global/repositories/RepositoryBase";
import { FIREBASE_API } from "../../config-global";
//
import {
  ActionMapType,
  AuthStateType,
  AuthUserType,
  FirebaseAnalyticsContextType,
  FirebaseContextType,
} from "./types";
import { PATH_AUTH } from "../../dashboard/routes/paths";

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = "INITIAL",
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    isAnonymous: boolean;
    authToken?: string;
    user: AuthUserType;
  };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  isAnonymous: true,
  authToken: "",
  user: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      isAnonymous: action.payload.isAnonymous,
      authToken: action.payload.authToken,
      user: action.payload.user,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);
const analytics = getAnalytics(firebaseApp);

// ----------------------------------------------------------------------
// Firebase: Auth Provider
const AUTH = getAuth(firebaseApp);
AUTH.languageCode = "he";

type AuthProviderProps = {
  children: React.ReactNode;
};

export const AuthContext = createContext<FirebaseContextType | null>(null);

// ----------------------------------------------------------------------
// set a middleware to axiosInstance check for 403 error to refresh token and try again:
axiosInstance.interceptors.response.use((response) => 
  response,
  async (error) => {
    const originalRequest = error.config;
    if ((error.response?.status === 403 || error.response?.status === 401) && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const user = AUTH.currentUser;
        if (user) {
          const newAccessToken = await user.getIdToken(true);
          if (newAccessToken) {
            localStorage.setItem("accessToken", newAccessToken);
            axiosInstance.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          }
        } else {
          window.location.href = PATH_AUTH.login;
        }
      } catch (_error) {
        window.location.href = PATH_AUTH.login;
      }
      return originalRequest;
    }
    return Promise.reject(error);
  }
);

function setSession(accessToken: string | null) {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);

    // Apply for all requests
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    localStorage.clear();
    delete axiosInstance.defaults.headers.common.Authorization;
  }
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  // ----------------------------------------------------------------------
  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async (user: any) => {
        const firebaseUser = user as User;
        if (firebaseUser) {
          const accessToken = await firebaseUser.getIdToken();
          const { firebaseRole, stripeRole } = (
            await firebaseUser.getIdTokenResult()
          ).claims;

          if (accessToken) {
            setSession(accessToken);
          }

          if (!firebaseUser.isAnonymous) {
            dispatch({
              type: Types.INITIAL,
              payload: {
                isAuthenticated: true,
                authToken: accessToken,
                isAnonymous: firebaseUser.isAnonymous,
                user: {
                  uid: firebaseUser.uid,
                  email: firebaseUser.email,
                  displayName: firebaseUser.displayName,
                  photoURL: firebaseUser.photoURL,
                  firebaseRole,
                  stripeRole,
                },
              },
            });
          }
        } else {
          setSession(null);
          dispatch({
            type: Types.INITIAL,
            payload: {
              isAuthenticated: false,
              isAnonymous: true,
              authToken: undefined,
              user: null,
            },
          });
        }
      });

      onIdTokenChanged(AUTH, async (user: any) => {
        if (user) {
          const newAccessToken = await user.getIdToken();
          if (newAccessToken) {
            setSession(newAccessToken);
          }
        } else {
          setSession(null);
        }
      });
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(
    async (email: string, password: string, handleError: VoidFunction) => {
      try {
        const result = await signInWithEmailAndPassword(AUTH, email, password);
        const accessToken = await result.user.getIdToken();
        if (accessToken) {
          localStorage.setItem("accessToken", accessToken);
          setSession(accessToken);
          setUserId(analytics, result.user.uid);
        }
      } catch (error) {
        handleError();
        console.error(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    await signOut(AUTH);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      isAnonymous: state.isAnonymous,
      user: state.user,
      authToken: state.authToken,
      method: "firebase",
      authInstance: AUTH,
      login,
      logout,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.isAnonymous,
      state.user,
      state.authToken,
      login,
      logout,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}

export const forcedLogout = () => {
  localStorage.clear();
  delete axiosInstance.defaults.headers.common.Authorization;
  signOut(AUTH);
};

export const registerAanonymous = async (): Promise<string | undefined> => {
  const result = await signInAnonymously(AUTH);
  const accessToken = await result.user.getIdToken();
  if (accessToken) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }
  return accessToken;
};

export const deleteAanonymous = async () => {
  await signOut(AUTH);
  axiosInstance.defaults.headers.common.Authorization = ``;
};

// ----------------------------------------------------------------------
// Firebase: Analytics
export const AnalyticsContext =
  createContext<FirebaseAnalyticsContextType | null>(null);
export function AnalyticsProvider({ children }: AuthProviderProps) {
  const logEventForAnalytics = useCallback(
    (eventName: string, eventParams?: EventParams) => {
      logEvent(analytics, eventName, eventParams);
    },
    []
  );

  const memoizedValue = useMemo(
    () => ({
      logEvent: logEventForAnalytics,
    }),
    [logEventForAnalytics]
  );

  return (
    <AnalyticsContext.Provider value={memoizedValue}>
      {children}
    </AnalyticsContext.Provider>
  );
}
