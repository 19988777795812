import { useState } from "react";

// @mui
import { Download, Edit, Notifications } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import {
  CourseAtendee,
  CourseAtendeeStatus,
  CourseData,
  DocumentConfigOption,
} from "../../../../api";

import { useSnackbar } from "../../../../global/components/snackbar";
import FullWithDataView from "../../common/views/FullWithDataView";
import { fDate } from "../../../utils/format/formatTime";
import ViewGenericDataTable, {
  ViewGenericDataTableData,
} from "../../common/views/tableView/ViewGenericDataTable";
import GenericMessageView from "../../common/views/GenericMessageView";
import { CustomTableHeadProps } from "../../common/views/tableView/SearchableTable";
import { AtendeesListRow } from "./AtendeesListRow";
import ClientCoursesAddEdit from "../ClientCoursesAddEdit";
import {
  useDashboardAppDispatch,
  useDashboardAppSelector,
} from "../../../hooks/useRedux";
import {
  downloadCourseSummery,
  getGeneratedDocumentPdf,
  notifyAttendees,
  updateAttendeeStatus,
} from "../clientCoursesPageSlice";
import { DashboardRootState } from "../../../app/dashboardAppStore";
import ConfirmDialog from "../../../components/confirm-dialog";
import LoadingView from "../../../components/loading-view";

// ----------------------------------------------------------------------

type Props = {
  translate: Function;
  course: CourseData | undefined;
  clientId: string | undefined;
  onEditCourseComplete: Function;
  courseConfigOptions?: DocumentConfigOption[];
};

export default function CourseDetailsPage({
  translate,
  course,
  clientId,
  onEditCourseComplete,
}: Props) {
  const dispatch = useDashboardAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const globalNoData = `${translate("global_no_data")}`;

  const isLoading = useDashboardAppSelector(
    (state: DashboardRootState) => state.clientCoursesPageSlice.isLoading
  );

  // Add:
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  // Notify Atendees:
  const [isNotifyConfirmDialogOpen, setNotifyConfirmDialogOpen] =
    useState(false);

  const handleNotifyAtendees = async () => {
    if (clientId && course?.id) {
      const result = await dispatch(
        notifyAttendees({
          clientId,
          courseId: course.id,
        })
      );

      if (result) {
        enqueueSnackbar(
          `${translate("course_details_page_atendee_remider_sent")}`
        );
      } else {
        enqueueSnackbar(
          `${translate("course_details_page_atendee_remider_error")}`,
          {
            variant: "error",
          }
        );
      }

      setNotifyConfirmDialogOpen(false);
    }
  };

  const CourseDetails = () => {
    if (!course) {
      return <></>;
    }

    const data = [
      {
        title: `${translate("course_details_page_title_name")}`,
        value: course.title ?? globalNoData,
      },
      {
        title: `${translate("course_details_page_title_status")}`,
        value: `${translate(`course_status_title_${course.status.toLowerCase()}`)}`,
      },
      {
        title: `${translate("course_details_page_title_startedAt")}`,
        value: course.startedAt ? fDate(course.startedAt) : globalNoData,
      },
      {
        title: `${translate("course_details_page_title_totalRegistered")}`,
        value: course.totalRegistered
          ? course.totalRegistered.toString()
          : globalNoData,
      },
      {
        title: `${translate("course_details_page_title_totalPassed")}`,
        value: course.totalPassed
          ? course.totalPassed.toString()
          : globalNoData,
      },
      {
        title: `${translate("course_details_page_title_totalFailed")}`,
        value: course.totalFailed
          ? course.totalFailed.toString()
          : globalNoData,
      },
    ];

    const handleDownloadCoursePdf = () => {
      dispatch(
        downloadCourseSummery({
          clientId: clientId || "",
          courseId: course.id,
        })
      );
    };

    return (
      <FullWithDataView
        title={`${translate("course_details_page_title")}`}
        itemsPerRow={3}
        data={data}
        translate={translate}
        actions={[
          {
            title: `${translate("course_details_page_edit_data")}`,
            icon: <Edit />,
            color: "success",
            onClick: () => {
              setIsEditDialogOpen(true);
            },
          },
          {
            title: `${translate("course_details_page_download_pdf")}`,
            icon: !isLoading.download ? (
              <Download />
            ) : (
              <CircularProgress color={"primary"} size={16} thickness={5} />
            ),
            onClick: handleDownloadCoursePdf,
            isDisabled: isLoading.download,
          },
        ]}
      />
    );
  };

  const handleOnAtendeeStatusCheched = (
    atendee: CourseAtendee,
    newStatus: CourseAtendeeStatus
  ) => {
    if (clientId && course?.id) {
      dispatch(
        updateAttendeeStatus({
          clientId,
          courseId: course.id,
          attendeeId: atendee.id,
          status: newStatus,
        })
      );
    }
  };

  const handleAtendeeRowItemClick = (row: CourseAtendee) => {
    dispatch(
      getGeneratedDocumentPdf({
        clientId: clientId || "",
        courseId: course?.id || "",
        documentId: "",
        driverId: row.id,
      })
    );
  };

  const renderRow = (row: CourseAtendee) => (
    <AtendeesListRow
      showStatus
      showEmail
      key={row.id}
      row={row}
      onRowClick={handleAtendeeRowItemClick}
      translate={translate}
      onUpdateStatus={handleOnAtendeeStatusCheched}
      loadingAtendeesStatusIds={isLoading.atendeesStatusIds}
    />
  );

  const TABLE_HEAD: CustomTableHeadProps[] = [
    {
      id: "fullName",
      label: `${translate("course_details_page_atendee_name")}`,
      align: "left",
    },
    {
      id: "govId",
      label: `${translate("global_id_number")}`,
      align: "center",
    },
    {
      id: "phoneNumber",
      label: `${translate("global_phone_number")}`,
      align: "center",
    },
    {
      id: "email",
      label: `${translate("global_email")}`,
      align: "center",
    },
    {
      id: "singedComplete",
      label: `${translate("course_details_page_atendee_singed")}`,
      align: "center",
    },
    {
      id: "status",
      label: `${translate("course_details_page_atendee_status")}`,
      align: "center",
    },
  ];

  const atendeesListData: ViewGenericDataTableData<CourseAtendee> = {
    isLoading: false,
    translate,
    titleKey: "course_details_page_atendees",
    data: course?.atendees ?? [],
    renderRow,
    tableHead: TABLE_HEAD,
    searchable: true,
    searchLabelKey: "course_details_page_atendee_search",
    onActionButtonClicked: () => {},
    overrideActionButtons: (
      <Button
        onClick={() => {
          setNotifyConfirmDialogOpen(true);
        }}
        variant="contained"
        color="warning"
        startIcon={<Notifications />}
      >
        {`${translate("course_details_page_atendee_remider")}`}
      </Button>
    ),
    emptyStateComponent: (
      <GenericMessageView
        title={`${translate("course_details_page_atendee_list_empty_title")}`}
        subtitle={""}
      />
    ),
  };

  return course ? (
    <>
      <Stack spacing={3}>
        {/** Vehicle details */}
        <CourseDetails />

        {/** Atendees list */}
        <Card sx={{ padding: 3 }}>
          <ViewGenericDataTable {...atendeesListData} />
        </Card>
      </Stack>

      {/**  Edit Dialog  */}
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
      >
        <ClientCoursesAddEdit
          currentCourseData={course}
          clientId={clientId || ""}
          translate={translate}
          onComplete={onEditCourseComplete}
        />
      </Dialog>

      <ConfirmDialog
        open={isNotifyConfirmDialogOpen}
        onClose={() => setNotifyConfirmDialogOpen(false)}
        title={`${translate("course_details_page_atendee_notify_title")}`}
        cancelText={`${translate("global_cancel")}`}
        content={`${translate("course_details_page_atendee_notify_description")}`}
        action={
          <LoadingButton
            loading={isLoading.atendeesNotify}
            variant="contained"
            color="warning"
            onClick={() => handleNotifyAtendees()}
          >
            {`${translate("course_details_page_atendee_notify_button")}`}
          </LoadingButton>
        }
      />

      <Dialog open={isLoading.documentsDownload}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <LoadingView showTruck />
        </Box>
      </Dialog>
    </>
  ) : (
    <></>
  );
}
