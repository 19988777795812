import { DocumentConfigOption } from "../../../api";

export const getCourseTypeLabel = ({
    translate,
    courseType,
    options,
}: {
    translate: Function;
    courseType?: string;
    options?: DocumentConfigOption[];
}) => {
    const globalNoData = `${translate("global_no_data")}`;
    return options?.find(
        (item: any) => item.type === courseType
    )?.label ?? globalNoData;
};