import { Navigate } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { PATH_DRIVER } from "./paths";
import DriverLoginPage from "../features/login/DriverLoginPage";
import DriverOnboardingPage from "../features/onboarding/DriverOnboardingPage";
import { DriversApp } from "../app/DriversApp";
import { driversAppStore } from "../app/driversAppStore";
import DriverMainPage from "../features/main/DriverMainPage";
import DriverCoursesPage from "../features/courses/DriverCoursesPage";
// ----------------------------------------------------------------------

export const driversRoutes = [
  {
    path: PATH_DRIVER.root,
    element: (
      <ReduxProvider store={driversAppStore}>
        <DriversApp />
      </ReduxProvider>
    ),
    children: [
      // Home:
      {
        element: <Navigate to={PATH_DRIVER.home} replace />,
        index: true,
      },
      {
        path: PATH_DRIVER.home,
        element: <DriverMainPage />,
      },

      // Login:
      {
        path: PATH_DRIVER.login,
        element: <DriverLoginPage />,
      },

      // Onboarding:
      {
        path: PATH_DRIVER.onboarding,
        element: <DriverOnboardingPage />,
      },
      
      // Courses:
      {
        path: PATH_DRIVER.courses,
        element: <DriverCoursesPage />,
      },
    ],
  }
];
