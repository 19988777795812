// @mui
import {
  Card,
  Checkbox,
  Dialog,
  FormControlLabel,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
// components
import { Add } from "@mui/icons-material";
import { CustomTableHeadProps } from "../../common/views/tableView/SearchableTable";
import { PATH_DASHBOARD } from "../../../routes/paths";
import ClientVehiclesAddEdit from "./ClientVehiclesAddEdit";
import ViewGenericDataTable, {
  ViewGenericDataTableData,
  ViewGenericDataTableFilterData,
} from "../../common/views/tableView/ViewGenericDataTable";
import { VehicleHeader, VehicleType } from "../../../../api";
import {
  getVehicleOwnerTypeLabelKey,
  getVehicleTypeLabelKey,
} from "../../../utils/models/typesUtils";
import ManufacturerOrModelLabel from "../../common/views/ManufacturerOrModelLabel";
import GenericMessageView from "../../common/views/GenericMessageView";
import {
  useDashboardAppDispatch,
  useDashboardAppSelector,
} from "../../../hooks/useRedux";
import { DashboardRootState } from "../../../app/dashboardAppStore";
import {
  clearVehiclesData,
  getClientDriversFromApi,
  getClientVehiclesFromApi,
} from "../../clientPage/main/clientPageSlice";
import { useAnalyticsContext } from "../../../../app/firebase/useAnalyticsContext";
import ViewLicensePlate from "../../common/views/ViewLicensePlate";
import { formatKmString } from "../../../utils/format/formatUnits";
import {
  fDateFromSeconds,
  fToNow,
} from "../../../utils/format/formatTime";
import { VEHICLE_PAGE_TABS_IDS } from "../VehiclePage";

// ----------------------------------------------------------------------

type ClientVehiclesListRowProps = {
  row: VehicleHeader;
  onRowClick?: Function;
  translate: Function;
};

function ClientVehiclesListRow({
  row,
  onRowClick,
  translate,
}: ClientVehiclesListRowProps) {
  const {
    licenseNumber,
    buildYear,
    type,
    isArchived,
    ownerType,
    lastOdometer,
    lastReportId,
  } = row;

  const handleRowClick = () => {
    if (onRowClick) {
      onRowClick(licenseNumber);
    }
  };

  return (
    <>
      <TableRow hover onClick={handleRowClick} sx={{ cursor: "pointer" }}>
        {/* Name */}
        <TableCell align="left">
          <ViewLicensePlate
            size="small"
            number={licenseNumber || ""}
            backgroundColor={isArchived ? "#FAFAFA" : undefined}
          />
        </TableCell>

        {/* Make */}
        <TableCell align="left">
          <ManufacturerOrModelLabel
            labelType="manufacturer"
            makeId={row.makeId}
            modelId={row.modelId}
            type={row.type}
          />
        </TableCell>

        {/* Model */}
        <TableCell align="left">
          <ManufacturerOrModelLabel
            labelType="model"
            makeId={row.makeId}
            modelId={row.modelId}
            type={row.type}
          />
        </TableCell>

        {/* buildYear */}
        <TableCell align="left">{buildYear}</TableCell>

        {/* Vehicles type */}
        <TableCell align="left">{`${translate(
          getVehicleTypeLabelKey(type)
        )}`}</TableCell>

        {/* Vehicle owner type */}
        <TableCell align="left">{`${translate(
          getVehicleOwnerTypeLabelKey(ownerType)
        )}`}</TableCell>

        {/* Last odometer */}
        <TableCell align="left">
          {formatKmString(lastOdometer, translate)}
        </TableCell>

        {/* Last report date */}
        <TableCell align="left">
          {lastReportId ? (
            <>
              <Typography variant="body2">
                {`${fDateFromSeconds(Number(lastReportId))}`}
              </Typography>
              <Typography
                variant="subtitle2"
                color={"primary"}
              >
                {`(${fToNow(Number(lastReportId) * 1000)})`}
              </Typography>
            </>
          ) : (
            <>{translate("global_no_data")}</>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}

// ----------------------------------------------------------------------

type Props = {
  clientId: string | undefined;
  translate: Function;
  navigate: Function;
};

export default function ClientVehicles({
  clientId,
  translate,
  navigate,
}: Props) {
  const dispatch = useDashboardAppDispatch();
  const { logEvent } = useAnalyticsContext();

  const vehiclesData = useDashboardAppSelector(
    (state: DashboardRootState) => state.clientPageSlice.vehicles
  );

  const clientDrivers = useDashboardAppSelector(
    (state: DashboardRootState) => state.clientPageSlice.drivers
  );

  const isLoading = useDashboardAppSelector(
    (state: DashboardRootState) => state.clientPageSlice.isLoading
  );

  const [filteredVehiclesData, setFilteredVehiclesData] = useState<
    Array<VehicleHeader>
  >([]);

  const [showArchive, setShowArchive] = useState(false);
  const handleShowArchiveChange = () => {
    if (clientId) {
      const newValue = !showArchive;
      setShowArchive(newValue);
      dispatch(getClientVehiclesFromApi({ clientId, fromArchive: newValue }));
    }
  };

  const [
    listOfViewGenericDataTableFilterData,
    setListOfViewGenericDataTableFilterData,
  ] = useState<Array<ViewGenericDataTableFilterData> | null>(null);

  const [currentSelectedFilterKey, setCurrentSelectedFilterKey] = useState<
    string | undefined
  >(undefined);

  // Get the data:
  useEffect(() => {
    if (clientId) {
      dispatch(getClientVehiclesFromApi({ clientId, fromArchive: false }));
    }

    return () => {
      dispatch(clearVehiclesData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  useEffect(() => {
    if (listOfViewGenericDataTableFilterData === null) {
      setListOfViewGenericDataTableFilterData(
        Object.values(VehicleType).map((type) => ({
          label: `${translate(getVehicleTypeLabelKey(type))}`,
          key: type,
          payloadObject: type,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfViewGenericDataTableFilterData]);

  useEffect(() => {
    if (vehiclesData && currentSelectedFilterKey) {
      const filteredData = vehiclesData.filter(
        (vehicle: VehicleHeader) => vehicle.type === currentSelectedFilterKey
      );
      setFilteredVehiclesData(filteredData);
    } else {
      setFilteredVehiclesData(vehiclesData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehiclesData, currentSelectedFilterKey]);

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

  const handleAddClicked = () => {
    if (clientId) {
      dispatch(getClientDriversFromApi(clientId));
      setIsAddDialogOpen(true);
      logEvent("client_page_vehicle_add_clicked");
    }
  };

  const handleClose = () => {
    setIsAddDialogOpen(false);
  };

  const onAddVehicleComplete = () => {
    if (clientId) {
      dispatch(getClientVehiclesFromApi({ clientId, fromArchive: false }));
      handleClose();
    }
  };

  const handleFilterChange = (newFilter: string) => {
    setCurrentSelectedFilterKey(newFilter);
  };

  const TABLE_HEAD: CustomTableHeadProps[] = [
    {
      id: "licenseNumber",
      label: `${translate("client_page_vehicle_id")}`,
      align: "left",
    },
    {
      id: "vehicleMake",
      label: `${translate("client_page_vehicle_make")}`,
      align: "left",
    },
    {
      id: "vehicleModel",
      label: `${translate("client_page_vehicle_model")}`,
      align: "left",
    },
    {
      id: "vehicleYear",
      label: `${translate("client_page_vehicle_year")}`,
      align: "left",
    },
    {
      id: "vehicleType",
      label: `${translate("client_page_vehicle_type")}`,
      align: "left",
    },
    {
      id: "vehicleOwnerType",
      label: `${translate("client_page_vehicle_owner_type")}`,
      align: "left",
    },
    {
      id: "lastOdometer",
      label: `${translate("client_page_vehicle_last_odometer")}`,
      align: "left",
    },
    {
      id: "lastTestDate",
      label: `${translate("vehicle_exipred_tests_last_date")}`,
      align: "left",
    },
  ];

  const handleRowClick = (licenseNumber: string) => {
    if (clientId) {
      navigate(
        PATH_DASHBOARD.management.clients.vheicle.path(clientId, licenseNumber, VEHICLE_PAGE_TABS_IDS.info)
      );
    }
  };

  const renderRow = (row: VehicleHeader) => (
    <ClientVehiclesListRow
      key={row.licenseNumber}
      row={row}
      onRowClick={handleRowClick}
      translate={translate}
    />
  );

  const genericDataTableViewProps: ViewGenericDataTableData<VehicleHeader> = {
    isLoading: isLoading.vehicles,
    translate,
    titleKey: "client_page_tab_vehicles",
    data: filteredVehiclesData,
    renderRow,
    tableHead: TABLE_HEAD,
    onActionButtonClicked: handleAddClicked,
    actionButtonLabelKey: "client_page_vehicle_add",
    actionButtonIcon: <Add />,
    searchable: true,
    searchLabelKey: "client_page_search_vehicles",
    listOfFilters: listOfViewGenericDataTableFilterData,
    currentSelectedFilterKey,
    onFilterChange: handleFilterChange,
    filterTitle: `${translate("client_page_vehicle_filter_by_type")}`,
    emptyStateComponent: (
      <>
        {!showArchive ? (
          <GenericMessageView
            title={`${translate("error_vehicles_list_empty_title")}`}
            subtitle={`${translate("error_vehicles_list_empty_subtitle")}`}
            buttonTitle={`${translate("client_page_vehicle_add")}`}
            onAction={handleAddClicked}
          />
        ) : (
          <GenericMessageView
            title={`${translate("error_global_empty")}`}
            subtitle={""}
          />
        )}
      </>
    ),
  };

  return (
    <>
      <Card sx={{ p: 3 }}>
        <ViewGenericDataTable {...genericDataTableViewProps} />
      </Card>

      {/** Show Archive checkbox */}
      <FormControlLabel
        sx={{ mt: 2 }}
        control={
          <Checkbox
            checked={showArchive}
            onChange={handleShowArchiveChange}
            name="showArchive"
            color="primary"
          />
        }
        label={`${translate("client_page_show_archive")}`}
      />

      {clientId && (
        <Dialog open={isAddDialogOpen} onClose={handleClose}>
          <ClientVehiclesAddEdit
            clientId={clientId}
            translate={translate}
            drivers={clientDrivers}
            onComplete={onAddVehicleComplete}
          />
        </Dialog>
      )}
    </>
  );
}
