// @mui
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";

import { DriverCourseHeader, DocumentConfigOption } from "../../../../api";
import Label from "../../../../global/components/label/Label";
import { fDate } from "../../../utils/format/formatTime";
import { getCourseTypeLabel } from "../../courses/clientCoursesUtils";
import { getCourseAttendeeStatusColor } from "../../courses/details/AtendeesListRow";

// ----------------------------------------------------------------------

type Props = {
  translate: Function;
  row: DriverCourseHeader;
  onViewRow: VoidFunction;
  courseOrDocumentConfigOptions?: DocumentConfigOption[];
};

export default function DriverCoursesListViewRowItem({
  translate,
  row,
  onViewRow,
  courseOrDocumentConfigOptions,
}: Props) {
  const { title, courseType, status, singedComplete, singedCompletedAt } = row;

  const globalNoData = `${translate("global_no_data")}`;

  return (
    <>
      <TableRow hover onClick={onViewRow} sx={{ cursor: "pointer" }}>
        {/** title */}
        <TableCell>
          <ListItemText
            primary={
              <Typography variant="body2">{title ?? globalNoData}</Typography>
            }
          />
        </TableCell>

        {/** courseType */}
        <TableCell>
          <ListItemText
            primary={
              <Typography variant="body2">
                {getCourseTypeLabel({
                  translate,
                  courseType,
                  options: courseOrDocumentConfigOptions,
                })}
              </Typography>
            }
          />
        </TableCell>

        {/* singedComplete */}
        <TableCell align="center">
          <Typography variant="body2" noWrap color={'text.primary'}>
            {singedComplete && singedCompletedAt
              ? `${translate("global_yes")} | ${fDate(singedCompletedAt)}`
              : translate("global_no")}
          </Typography>
        </TableCell>

        {/* Status */}
        <TableCell align="center">
          <Label
            sx={{ cursor: "pointer" }}
            variant="soft"
            color={getCourseAttendeeStatusColor(status)}
            // onClick={popover.onOpen}
          >
            {status
              ? translate(`course_atendee_status_title_${status.toLowerCase()}`)
              : globalNoData}
          </Label>
        </TableCell>
      </TableRow>
    </>
  );
}
