// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { DatePicker, DateView } from "@mui/x-date-pickers";

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// ----------------------------------------------------------------------

interface Props {
  name: string;
  disabled?: boolean;
  label?: string;
  openTo?: DateView;
  views?: DateView[];
  onValueChanged?: (_newValue: any) => void;
  currentValue?: Date;
}

export default function RHFDatePicker({
  name,
  disabled,
  label,
  openTo,
  views,
  onValueChanged,
  currentValue,
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          openTo={openTo}
          views={views}
          disabled={disabled === true}
          label={label}
          value={currentValue || field.value || null}
          onChange={(newValue: any) => {
            field.onChange(newValue);
            if (onValueChanged) {
              onValueChanged(newValue);
            }
          }}
          slotProps={{
            textField: {
              fullWidth: true,
              error: !!error,
              helperText: error?.message,
            }
          }}
          slots={{
            leftArrowIcon: ChevronRightIcon,
            rightArrowIcon: ChevronLeftIcon,
          }}
        />
      )}
    />
  );
}

export function KDatePicker({
  value,
  label,
  onValueChanged,
}: {
  value: string;
  label?: string;
  onValueChanged: (_newValue: any) => void;
}) {
  return (
    <DatePicker
      sx={{ width: "100%" }}
      label={label}
      value={value || null}
      onChange={(newValue: any) => {
        onValueChanged(newValue);
      }}
      slots={{
        leftArrowIcon: ChevronRightIcon,
        rightArrowIcon: ChevronLeftIcon,
      }}
    />
  );
}
