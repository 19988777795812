import * as Yup from "yup";
import { useCallback, useEffect, useMemo } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { LoadingButton } from "@mui/lab";
import { Stack, Box, Typography } from "@mui/material";
import { useSnackbar } from "../../../../global/components/snackbar";
import {
  CreateVehicleTachograph,
  DriverUser,
  UpdateVehicleTachograph,
  VehicleTachograph,
} from "../../../../api";

import FormProvider, {
  RHFTextField,
  RHFSelect,
  RHFDateTimePicker,
  RHFSwitch,
  RHFUpload,
} from "../../../../global/components/hook-form";
import {
  genericAnySchema,
  genericDateSchema,
  genericRequiredDateSchema,
  genericRequiredStringSchema,
  genericStringSchema,
  numberOnlySchema,
} from "../../../../global/utils/formValidators";
import LoadingView from "../../../components/loading-view/LoadingView";
import { convertFileToBase64, reduceImageSize } from "../../../utils/fileUtils";
import { fDateIsoString } from "../../../utils/format/formatTime";

export type VehicleTachographAddEditProps = {
  translate: Function;
  isLoadingCreate: boolean;
  isLoadingSelected: boolean;
  isLoadingDelete: boolean;
  currentTachographData?: VehicleTachograph;
  driversList?: DriverUser[];
  preSelectedDate?: Date;
  onCreateNewTachographRecord: (
    _createObject: CreateVehicleTachograph,
    _relatedDriverId: string
  ) => void;
  onUpdateTachographRecord: (
    _createObject: UpdateVehicleTachograph,
    _relatedDriverId: string
  ) => void;
  onDeleteClicked: () => void;
};

export default function VehicleTachographAddEdit({
  translate,
  isLoadingCreate,
  isLoadingSelected,
  isLoadingDelete,
  currentTachographData,
  driversList,
  preSelectedDate,
  onCreateNewTachographRecord,
  onUpdateTachographRecord,
  onDeleteClicked,
}: VehicleTachographAddEditProps) {
  const isEdit = Boolean(currentTachographData);

  const { enqueueSnackbar } = useSnackbar();

  const NewIncidentSchema = Yup.object().shape({
    relatedDriverId: genericRequiredStringSchema(translate),
    startDateTime: genericRequiredDateSchema(translate),
    endDateTime: genericDateSchema(translate),
    odometerStart: numberOnlySchema(translate),
    odometerEnd: numberOnlySchema(translate),
    isOverspeeding: genericAnySchema(),
    maxSpeed: numberOnlySchema(translate),
    isOvertime: genericAnySchema(),
    totalHours: numberOnlySchema(translate),
    notes: genericStringSchema(),
    photoData: Yup.mixed().nullable(),
  });

  const defaultValues = useMemo(
    () => ({
      relatedDriverId: currentTachographData?.relatedDriverId || "",
      startDateTime: currentTachographData?.startDateTime ? new Date(currentTachographData.startDateTime) : preSelectedDate || new Date(),
      endDateTime: currentTachographData?.endDateTime ? new Date(currentTachographData.endDateTime) : preSelectedDate || new Date(),
      odometerStart: currentTachographData?.odometerStart || 0,
      odometerEnd: currentTachographData?.odometerEnd || 0,
      isOverspeeding: currentTachographData?.isOverspeeding || false,
      maxSpeed: currentTachographData?.maxSpeed || 0,
      isOvertime: currentTachographData?.isOvertime || false,
      totalHours: currentTachographData?.totalHours || 0,
      notes: currentTachographData?.notes || "",
      photoData: currentTachographData?.photoUrl || null,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTachographData]
  );

  const methods = useForm({
    resolver: yupResolver(NewIncidentSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (currentTachographData) {
      reset(defaultValues);
    }
  }, [currentTachographData, defaultValues, reset]);

  const onSubmit = async (data: any) => {
    try {
      if (isEdit) {
        const updateObject = {
          odometerStart: data.odometerStart,
          odometerEnd: data.odometerEnd,
          isOverspeeding: data.isOverspeeding,
          maxSpeed: data.maxSpeed,
          isOvertime: data.isOvertime,
          totalHours: data.totalHours,
          notes: data.notes,
        } as UpdateVehicleTachograph;
        onUpdateTachographRecord(updateObject, data.relatedDriverId);
      } else {
        let photoDateBase64 = null;
        if (data.photoData) {
          const localFile = data.photoData as File;
          const reducedFile = await reduceImageSize(localFile, 1080);
          const fileAsBase64 = reducedFile
            ? await convertFileToBase64(reducedFile)
            : undefined;
          const prefixToRemove = "data:image/jpeg;base64,";
          photoDateBase64 = fileAsBase64
            ? fileAsBase64.substring(prefixToRemove.length)
            : undefined;
        }
        const createObject = {
          startDateTime: fDateIsoString(data.startDateTime),
          endDateTime: fDateIsoString(data.endDateTime),
          odometerStart: data.odometerStart,
          odometerEnd: data.odometerEnd,
          isOverspeeding: data.isOverspeeding,
          maxSpeed: data.maxSpeed,
          isOvertime: data.isOvertime,
          totalHours: data.totalHours,
          notes: data.notes,
          photoDataBase64: photoDateBase64,
        } as CreateVehicleTachograph;
        onCreateNewTachographRecord(createObject, data.relatedDriverId);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(`${translate("error_tachograph_add_title")}`, {
        variant: "error",
      });
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
      if (file) {
        setValue("photoData", newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const handleRemoveFile = useCallback(() => {
    setValue("photoData", null);
  }, [setValue]);

  const renderTitleAndActions = (
    <Stack direction={"row"} justifyContent="space-between">
      <Stack>
        <Typography variant="h4">{`${translate(
          currentTachographData
            ? "vehicle_tachograph_edit"
            : "vehicle_tachograph_add"
        )}`}</Typography>
      </Stack>

      <Stack direction={"row"} spacing={1}>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={isSubmitting || isLoadingCreate}
          sx={{ minWidth: 120 }}
        >
          {!currentTachographData
            ? `${translate("global_add_new")}`
            : `${translate("global_save")}`}
        </LoadingButton>

        {isEdit && (
          <LoadingButton
            variant="contained"
            color="error"
            loading={isLoadingDelete}
            onClick={onDeleteClicked}
            sx={{ minWidth: 120 }}
          >
            {`${translate("global_delete")}`}
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );

  const renderDetails = (
    <Box
      rowGap={3}
      columnGap={2}
      display="grid"
      gridTemplateColumns={{
        xs: "repeat(2, 1fr)",
        sm: "repeat(2, 1fr)",
      }}
    >
      <RHFDateTimePicker
        disabled={isEdit}
        name="startDateTime"
        label={`${translate("vehicle_tachograph_add_edit_title_startDateTime")}`}
      />

      <RHFDateTimePicker
        disabled={isEdit}
        name="endDateTime"
        label={`${translate("vehicle_tachograph_add_edit_title_endDateTime")}`}
      />

      <RHFTextField
        name="odometerStart"
        type="number"
        label={`${translate("vehicle_tachograph_add_edit_title_odometerStart")}`}
      />

      <RHFTextField
        name="odometerEnd"
        type="number"
        label={`${translate("vehicle_tachograph_add_edit_title_odometerEnd")}`}
      />

      <RHFSwitch
        name="isOverspeeding"
        label={`${translate("vehicle_tachograph_add_edit_title_isOverspeeding")}`}
      />

      <RHFTextField
        name="maxSpeed"
        type="number"
        label={`${translate("vehicle_tachograph_add_edit_title_maxSpeed")}`}
      />

      <RHFSwitch
        name="isOvertime"
        label={`${translate("vehicle_tachograph_add_edit_title_isOvertime")}`}
      />

      <RHFTextField
        name="totalHours"
        type="number"
        label={`${translate("vehicle_tachograph_add_edit_title_totalHours")}`}
      />
    </Box>
  );

  return (
    <>
      {isLoadingSelected ? (
        <Box padding={3}>
          <LoadingView />
        </Box>
      ) : (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack padding={4} spacing={4}>
            {renderTitleAndActions}
            <Stack spacing={4}>
              <RHFSelect
                native
                name="relatedDriverId"
                label={`${translate("vehicle_tachograph_add_edit_title_relatedDriverId")}`}
              >
                <option />
                {driversList?.map(
                  (driverData) =>
                    driverData && (
                      <option key={driverData.govId} value={driverData.id}>
                        {`${driverData.fullName} | ${driverData.govId}`}
                      </option>
                    )
                )}
              </RHFSelect>

              {renderDetails}

              <RHFTextField
                name="notes"
                multiline
                rows={5}
                label={`${translate("vehicle_tachograph_add_edit_title_notes")}`}
              />

              {isEdit && !currentTachographData?.photoUrl ? (
                <></>
              ) : (
                <RHFUpload
                  name="photoData"
                  maxSize={10 * 1024 * 1024} // 10MB
                  onDrop={handleDrop}
                  onRemove={handleRemoveFile}
                />
              )}
            </Stack>
          </Stack>
        </FormProvider>
      )}
    </>
  );
}
