import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import RepositoryDriver from '../../repositories/RepositoryDriver';
import { DocumentConfig, DocumentConfigOption, DriverCourseHeader, DriverDocumentCreateReqV1, DriverDocumentHeader, DriverDocumentUpload, DriverDocumentUploadType, DriverLicenseRes, DriverUser, UploadDriverLicenseReq } from '../../../api';
import { downloadFile } from '../../../global/repositories/RepositoryBase';
import RepositoryDriverDocuments from '../../repositories/RepositoryDriverDocuments';
import RepositoryCourses from '../../repositories/RepositoryCourses';

export type DriverDocumentTableItem = {
  id: string;
  title?: string;
  createdAt?: string;
  documentType: string;
  isUploaded: boolean;
  raw: DriverDocumentHeader | DriverDocumentUpload;
};

export type DriverPageState = {
  data: DriverUser | undefined;
  driverLicense: DriverLicenseRes | undefined;
  isDownloadingFile: boolean;
  currentDocuments?: DriverDocumentTableItem[];
  documentConfigOptions?: DocumentConfigOption[] | null;
  documentUploadedConfigOptions?: DocumentConfigOption[] | null;
  selectedDocumentConfig?: DocumentConfig | null;
  selectedDocumentHtmlPreview?: string | null;
  driverCoursesHeaders?: DriverCourseHeader[] | null;
  courseConfigOptions?: DocumentConfigOption[] | null;
  isLoadingData: {
    driverData: boolean;
    driverLicense: boolean;
    driverDocuments: boolean;
    uploadDocument: boolean;
    deleteDriver: boolean;
    documentOptions: boolean;
    documentConfig: boolean;
    documentPreview: boolean;
    downloadDocument: boolean;
    coursesHeaders: boolean;
    courseDocDownload: boolean;
  };
};

const initialState: DriverPageState = {
  data: undefined,
  driverLicense: undefined,
  isDownloadingFile: false,
  currentDocuments: undefined,
  isLoadingData: {
    driverData: false,
    driverLicense: false,
    driverDocuments: false,
    uploadDocument: false,
    deleteDriver: false,
    documentOptions: false,
    documentConfig: false,
    documentPreview: false,
    downloadDocument: false,
    coursesHeaders: false,
    courseDocDownload: false,
  },
};


export const getDriverData = createAsyncThunk('driverPageSlice/getDriverData', async (
  { driverId }: { driverId: string }
) => {
  const data = await RepositoryDriver().getDriverData(driverId);
  return data;
});

// Delete driver:
export const deleteDriverFromApi = createAsyncThunk('driverPageSlice/deleteDriverFromApi', async ({ driverId }: { driverId: string, onCompleted?: (_isSuccess: boolean) => void; }) => {
  const data = await RepositoryDriver().deleteDriver(driverId);
  return data;
});

// Get driver license data:
export const getDriverLicenseData = createAsyncThunk('driverPageSlice/getDriverLicenseData', async (
  { driverId }: { driverId: string }
) => {
  const data = await RepositoryDriver().getDriverLicenseRes(driverId);
  return data;
});

// Upload driver license:
export const uploadDriverLicense = createAsyncThunk('driverPageSlice/uploadDriverLicense', async (
  { ploadDriverLicenseReq }: { ploadDriverLicenseReq: UploadDriverLicenseReq }
) => {
  const data = await RepositoryDriver().uploadDriverLicense(ploadDriverLicenseReq);
  return data;
});


// Download driver document:
export const downloadDriverDocument = createAsyncThunk('driverPageSlice/downloadDriverDocument', async (
  { driverId, documentId }: { driverId: string, documentId: string }
) => {
  const data = await RepositoryDriver().downloadDriverDocument(driverId, documentId);
  return data;
});

// Send driver onboarding notification:
export const sendDriverOnboardingNotification = createAsyncThunk('driverPageSlice/sendDriverOnboardingNotification', async (
  { driverId }: {
    driverId: string,
    onComplete: (_isSuccess?: boolean) => void
  }
) => {
  const data = await RepositoryDriver().sendDriverOnboardingNotification(driverId);
  return data;
});

// Download driver license:
export const downloadDriverLicense = createAsyncThunk('driverPageSlice/downloadDriverLicense', async (
  { driverId }: { driverId: string }
) => {
  const data = await RepositoryDriver().downloadDriverLicense(driverId);
  return data;
});


// ======== Driver Documents: ========
type baseDriverDocumentsParams = {
  driverUserId: string;
}

// Get driver documents headers:
export const getAllDriverDocuments = createAsyncThunk('driverPageSlice/getAllDriverDocuments', async ({ driverUserId }: baseDriverDocumentsParams) => {
  const docsHeaders = await RepositoryDriverDocuments().getAllDriverDocumentsHeaders({
    driverUserId
  });

  const result: DriverDocumentTableItem[] = [];
  docsHeaders?.forEach((header) => {
    result.push({
      id: header.id,
      createdAt: header.createdAt,
      isUploaded: false,
      documentType: header.documentType,
      raw: header
    } as DriverDocumentTableItem);
  });

  const docsUploaded = await RepositoryDriverDocuments().getDriverDocuments({
    driverUserId
  });

  docsUploaded?.forEach((doc) => {
    result.push({
      id: doc.id,
      title: doc.title,
      createdAt: doc.expirationDate,
      documentType: doc.documentType,
      isUploaded: true,
      raw: doc
    } as DriverDocumentTableItem);
  });

  return result;
});


// >>>>>>>>  Generated Documents  <<<<<<<<:
export const getDriverAvailableDocumentConfigOptions = createAsyncThunk('driverPageSlice/getDriverAvailableDocumentConfigOptions', async () => {
  const data = await RepositoryDriverDocuments().getDriverAvailableDocumentConfigOptions();
  return data;
});

export const getDriverAvailableUploadDocumentConfigOptions = createAsyncThunk('driverPageSlice/getDriverAvailableUploadDocumentConfigOptions', async () => {
  const data = await RepositoryDriverDocuments().getDriverAvailableUploadDocumentsOptions();
  return data;
});

export const getDriverDocumentConfig = createAsyncThunk('driverPageSlice/getDriverDocumentConfig', async ({ documentType, clientId }: { documentType: string, clientId: string }) => {
  const data = await RepositoryDriverDocuments().getDriverDocumentConfig({
    documentType,
    clientId
  });
  return data;
});

export const addNewDriverDocument = createAsyncThunk('driverPageSlice/addNewDriverDocument', async ({ request }: { request: DriverDocumentCreateReqV1 }) => {
  const data = await RepositoryDriverDocuments().addNewDriverDocument(request);
  return data;
});

export const getGeneratedDriverDocumentPdf = createAsyncThunk('driverPageSlice/getGeneratedDriverDocumentPdf', async ({ driverUserId, documentId }: { driverUserId: string, documentId: string }) => {
  const data = await RepositoryDriverDocuments().getGeneratedDriverDocumentPdf({
    driverUserId,
    documentId
  });
  return data;
});

export const getGeneratedDriverDocumentHtmlPreview = createAsyncThunk('driverPageSlice/getGeneratedDriverDocumentHtmlPreview', async ({ driverUserId, documentType }: { driverUserId: string, documentType: string, onComplete: (_isSucess: boolean) => void }) => {
  const data = await RepositoryDriverDocuments().getGeneratedDriverDocumentHtmlPreview({
    driverUserId,
    documentType
  });
  return data;
});


// >>>>>>>>  Uploaded Documents  <<<<<<<<:
type downloadDriverUploadDocumentsParams = baseDriverDocumentsParams & {
  documentType: DriverDocumentUploadType;
  documentId: string;
  onRespose: (_data: DriverDocumentUpload) => void;
}

export const downloadDriverUploadedDocument = createAsyncThunk('driverPageSlice/downloadDriverUploadedDocument', async ({
  driverUserId,
  documentType,
  documentId,
  onRespose
}: downloadDriverUploadDocumentsParams) => {
  const data = await RepositoryDriverDocuments().getDriverDocuemntById({
    driverUserId,
    documentType,
    documentId
  });
  onRespose(data);
});

export type AddDriverUploadDocumentParams = baseDriverDocumentsParams & {
  documentType: DriverDocumentUploadType;
  title: string;
  expirationDate: string | null;
  isHidden?: boolean;
  file: File;
  onRespose: (_isSuccess: boolean) => void;
};

export const uploadVehicleDocument = createAsyncThunk('vehiclePageSlice/addVehicleDocument', async ({
  driverUserId,
  documentType,
  title,
  expirationDate,
  isHidden,
  file,
  onRespose
}: AddDriverUploadDocumentParams) => {
  try {
    const isSuccess = await RepositoryDriverDocuments().addDriverDocument({
      driverUserId,
      documentType,
      expirationDate,
      title,
      isHidden,
      docuemnt: file
    });
    onRespose(isSuccess);
    return isSuccess;
  } catch (error) {
    onRespose(false);
    return false;
  }
});



// >>>>>>>>  Driver Courses  <<<<<<<<:
export const getAvailableCourseConfigOptions = createAsyncThunk('driverPageSlice/getAvailableCourseConfigOptions', async () => {
  const data = await RepositoryCourses().getCourseAvailableConfigOptions();
  return data;
});

export const getDriverCoursesHeaders = createAsyncThunk('driverPageSlice/getDriverCoursesHeaders', async ({ driverUserId, includeCompletedCourses }: { driverUserId: string, includeCompletedCourses?: boolean }) => {
  const coursesHeaders = await RepositoryDriver().getDriverCoursesHeaders(driverUserId, includeCompletedCourses);
  return coursesHeaders;
});

export const downloadCourseDocument = createAsyncThunk('driverPageSlice/downloadCourseDocument', async ({ clientId, courseId, driverId }: { clientId: string, courseId: string, driverId: string, onComplete: () => void }) => {
  const data = await RepositoryCourses().getGeneratedCourseDocumentSignedPdf({
    clientId,
    courseId,
    driverId
  });
  return data;
});



export const driverPageSlice = createSlice({
  name: 'vehiclePageSlice',
  initialState,
  reducers: {
    // Clear state
    resetDriverData: (state: DriverPageState) => {
      state.data = undefined;
      state.isLoadingData = initialState.isLoadingData;
    },

    // Clear Documents:
    clearCurrentDocuments: (state: DriverPageState) => {
      state.currentDocuments = undefined;
    },

    // Clear Document Config:
    clearDocumentConfig: (state: DriverPageState) => {
      state.isLoadingData.documentConfig = false;
      state.selectedDocumentConfig = undefined;
    },

    // Reset selectedDocumentHtmlPreview:
    clearSelectedDocumentHtmlPreview: (state: DriverPageState) => {
      state.selectedDocumentHtmlPreview = undefined;
    },

    // Clear Courses Headers:
    clearCoursesHeaders: (state: DriverPageState) => {
      state.driverCoursesHeaders = undefined;
    },

  },
  extraReducers: (builder) => {

    // Get driver data: 
    builder.addCase(getDriverData.pending, (state: DriverPageState) => {
      state.isLoadingData.driverData = true;
    });

    builder.addCase(getDriverData.fulfilled, (state: DriverPageState, action) => {
      state.data = action.payload;
      state.isLoadingData.driverData = false;
    });

    builder.addCase(getDriverData.rejected, (state: DriverPageState) => {
      state.isLoadingData.driverData = false;
    });

    // Get driver license data:
    builder.addCase(getDriverLicenseData.pending, (state: DriverPageState) => {
      state.isLoadingData.driverLicense = true;
    });

    builder.addCase(getDriverLicenseData.fulfilled, (state: DriverPageState, action) => {
      state.driverLicense = action.payload;
      state.isLoadingData.driverLicense = false;
    });

    builder.addCase(getDriverLicenseData.rejected, (state: DriverPageState) => {
      state.driverLicense = undefined;
      state.isLoadingData.driverLicense = false;
    });

    // Delete driver:
    builder.addCase(deleteDriverFromApi.pending, (state: DriverPageState, _action) => {
      state.isLoadingData.deleteDriver = true;
    });

    builder.addCase(deleteDriverFromApi.fulfilled, (state: DriverPageState, action) => {
      state.data = undefined;
      state.isLoadingData.deleteDriver = false;
      if (action.meta.arg.onCompleted) {
        action.meta.arg.onCompleted(true);
      }
    });

    builder.addCase(deleteDriverFromApi.rejected, (state: DriverPageState, action) => {
      state.isLoadingData.deleteDriver = false;
      if (action.meta.arg.onCompleted) {
        action.meta.arg.onCompleted(false);
      }
    });

    // Upload driver license:
    builder.addCase(uploadDriverLicense.pending, (state: DriverPageState, _action) => {
      state.isLoadingData.driverData = true;
    });

    builder.addCase(uploadDriverLicense.fulfilled, (state: DriverPageState, _action) => {
      state.isLoadingData.driverData = false;
    });

    builder.addCase(uploadDriverLicense.rejected, (state: DriverPageState, _action) => {
      state.isLoadingData.driverData = false;
    });

    // Get driver documents headers:
    builder.addCase(getAllDriverDocuments.pending, (state: DriverPageState, _action) => {
      state.isLoadingData.driverDocuments = true;
    });

    builder.addCase(getAllDriverDocuments.fulfilled, (state: DriverPageState, action) => {
      state.currentDocuments = action.payload;
      state.isLoadingData.driverDocuments = false;
    });

    builder.addCase(getAllDriverDocuments.rejected, (state: DriverPageState, _action) => {
      state.isLoadingData.driverDocuments = false;
    });

    // Download driver document:
    builder.addCase(downloadDriverDocument.pending, (state: DriverPageState, _action) => {
      state.isDownloadingFile = true;
    });

    builder.addCase(downloadDriverDocument.fulfilled, (state: DriverPageState, action) => {
      downloadFile(action.payload, `${action.meta.arg.documentId}`, 'application/pdf')
      state.isDownloadingFile = false;
    });

    builder.addCase(downloadDriverDocument.rejected, (state: DriverPageState, _action) => {
      state.isDownloadingFile = false;
    });

    // Send driver onboarding notification:
    builder.addCase(sendDriverOnboardingNotification.pending, (state: DriverPageState, _action) => {
      state.isLoadingData.driverData = true;
    });

    builder.addCase(sendDriverOnboardingNotification.fulfilled, (state: DriverPageState, action) => {
      state.isLoadingData.driverData = false;
      const result = action.payload;
      action.meta.arg.onComplete(result);
    });

    builder.addCase(sendDriverOnboardingNotification.rejected, (state: DriverPageState, action) => {
      state.isLoadingData.driverData = false;
      action.meta.arg.onComplete(false);
    });

    // Download driver license:
    builder.addCase(downloadDriverLicense.pending, (state: DriverPageState, _action) => {
      state.isDownloadingFile = true;
    });

    builder.addCase(downloadDriverLicense.fulfilled, (state: DriverPageState, action) => {
      downloadFile(action.payload, `driver-license`, 'application/pdf')
      state.isDownloadingFile = false;
    });

    builder.addCase(downloadDriverLicense.rejected, (state: DriverPageState, _action) => {
      state.isDownloadingFile = false;
    });

    // Download driver uploaded document:
    builder.addCase(downloadDriverUploadedDocument.pending, (state: DriverPageState, _action) => {
      state.isDownloadingFile = true;
    });

    builder.addCase(downloadDriverUploadedDocument.fulfilled, (state: DriverPageState, action) => {
      downloadFile(action.payload, `${action.meta.arg.documentId}`, 'application/pdf')
      state.isDownloadingFile = false;
    });

    builder.addCase(downloadDriverUploadedDocument.rejected, (state: DriverPageState, _action) => {
      state.isDownloadingFile = false;
    });

    // Upload driver document:
    builder.addCase(uploadVehicleDocument.pending, (state: DriverPageState, _action) => {
      state.isLoadingData.uploadDocument = true;
    });

    builder.addCase(uploadVehicleDocument.fulfilled, (state: DriverPageState, _action) => {
      state.isLoadingData.uploadDocument = false;
    });

    builder.addCase(uploadVehicleDocument.rejected, (state: DriverPageState, _action) => {
      state.isLoadingData.uploadDocument = false;
    });

    // >>>>>>>>  Generated Documents  <<<<<<<<:

    // Get driver available document config options:
    builder.addCase(getDriverAvailableDocumentConfigOptions.pending, (state: DriverPageState, _action) => {
      state.isLoadingData.documentOptions = true;
    });

    builder.addCase(getDriverAvailableDocumentConfigOptions.fulfilled, (state: DriverPageState, action) => {
      state.documentConfigOptions = action.payload;
      state.isLoadingData.documentOptions = false;
    });

    builder.addCase(getDriverAvailableDocumentConfigOptions.rejected, (state: DriverPageState, _action) => {
      state.isLoadingData.documentOptions = false;
    });

    // Get driver available upload document config options:
    builder.addCase(getDriverAvailableUploadDocumentConfigOptions.pending, (state: DriverPageState, _action) => {
      state.isLoadingData.documentOptions = true;
    });

    builder.addCase(getDriverAvailableUploadDocumentConfigOptions.fulfilled, (state: DriverPageState, action) => {
      state.documentUploadedConfigOptions = action.payload;
      state.isLoadingData.documentOptions = false;
    });

    builder.addCase(getDriverAvailableUploadDocumentConfigOptions.rejected, (state: DriverPageState, _action) => {
      state.isLoadingData.documentOptions = false;
    });

    // Get driver document config:
    builder.addCase(getDriverDocumentConfig.pending, (state: DriverPageState, _action) => {
      state.isLoadingData.documentConfig = true;
    });

    builder.addCase(getDriverDocumentConfig.fulfilled, (state: DriverPageState, action) => {
      state.selectedDocumentConfig = action.payload;
      state.isLoadingData.documentConfig = false;
    });

    builder.addCase(getDriverDocumentConfig.rejected, (state: DriverPageState, _action) => {
      state.isLoadingData.documentConfig = false;
    });

    // Add new driver document:
    builder.addCase(addNewDriverDocument.pending, (state: DriverPageState, _action) => {
      state.isLoadingData.uploadDocument = true;
    });

    builder.addCase(addNewDriverDocument.fulfilled, (state: DriverPageState, action) => {
      if (action.payload) {
        state.currentDocuments = [{
          id: action.payload.id,
          documentType: action.payload.documentType,
          createdAt: action.payload.createdAt,
          raw: action.payload,
        } as DriverDocumentTableItem, ...state.currentDocuments || []];
      }
      state.isLoadingData.uploadDocument = false;
    });

    builder.addCase(addNewDriverDocument.rejected, (state: DriverPageState, _action) => {
      state.isLoadingData.uploadDocument = false;
    });

    // Get generated driver document pdf:
    builder.addCase(getGeneratedDriverDocumentPdf.pending, (state: DriverPageState, _action) => {
      state.isLoadingData.documentPreview = true;
    });

    builder.addCase(getGeneratedDriverDocumentPdf.fulfilled, (state: DriverPageState, action) => {
      downloadFile(action.payload, 'document', 'application/pdf')
      state.isLoadingData.documentPreview = false;
    });

    builder.addCase(getGeneratedDriverDocumentPdf.rejected, (state: DriverPageState, _action) => {
      state.isLoadingData.documentPreview = false;
    });

    // Get generated driver document html preview:
    builder.addCase(getGeneratedDriverDocumentHtmlPreview.pending, (state: DriverPageState, _action) => {
      state.isLoadingData.documentPreview = true;
    });

    builder.addCase(getGeneratedDriverDocumentHtmlPreview.fulfilled, (state: DriverPageState, action) => {
      if (action.payload) {
        state.selectedDocumentHtmlPreview = action.payload;
        action.meta.arg.onComplete(true);
      } else {
        action.meta.arg.onComplete(false);
      }
      state.isLoadingData.documentPreview = false;
    });

    builder.addCase(getGeneratedDriverDocumentHtmlPreview.rejected, (state: DriverPageState, _action) => {
      state.isLoadingData.documentPreview = false;
    });

    // getAvailableCourseConfigOptions:
    builder.addCase(getAvailableCourseConfigOptions.pending, (state: DriverPageState, _action) => {
      state.isLoadingData.documentOptions = true;
    });

    builder.addCase(getAvailableCourseConfigOptions.fulfilled, (state: DriverPageState, action) => {
      state.courseConfigOptions = action.payload;
      state.isLoadingData.documentOptions = false;
    });

    builder.addCase(getAvailableCourseConfigOptions.rejected, (state: DriverPageState, _action) => {
      state.isLoadingData.documentOptions = false;
    });

    // getCoursesHeaders:
    builder.addCase(getDriverCoursesHeaders.pending, (state: DriverPageState, _action) => {
      state.isLoadingData.coursesHeaders = true;
    });

    builder.addCase(getDriverCoursesHeaders.fulfilled, (state: DriverPageState, action) => {
      state.driverCoursesHeaders = action.payload;
      state.isLoadingData.coursesHeaders = false;
    });

    builder.addCase(getDriverCoursesHeaders.rejected, (state: DriverPageState, _action) => {
      state.isLoadingData.coursesHeaders = false;
    });

    // downloadCourseDocument:
    builder.addCase(downloadCourseDocument.pending, (state: DriverPageState, _action) => {
      state.isLoadingData.courseDocDownload = true;
    });

    builder.addCase(downloadCourseDocument.fulfilled, (state: DriverPageState, action) => {
      downloadFile(action.payload, 'document', 'application/pdf')
      action.meta.arg.onComplete();
      state.isLoadingData.courseDocDownload = false;
    });

    builder.addCase(downloadCourseDocument.rejected, (state: DriverPageState, _action) => {
      state.isLoadingData.courseDocDownload = false;
    });
  },
});

// Export the actions and reducer
export default driverPageSlice.reducer;
export const { resetDriverData, clearCurrentDocuments, clearDocumentConfig, clearSelectedDocumentHtmlPreview } = driverPageSlice.actions;