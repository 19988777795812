import { useRef } from "react";
import { SnackbarProvider as NotistackProvider, SnackbarKey } from "notistack";
// @mui
import { Collapse, IconButton } from "@mui/material";
//
import { Check, Close, Error, Info, Warning } from "@mui/icons-material";
import { useSettingsContext } from "../settings";
//
import StyledNotistack from "./styles";

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function SnackbarProvider({ children }: Props) {
  const { themeDirection } = useSettingsContext();

  const isRTL = themeDirection === "rtl";

  const notistackRef = useRef<any>(null);

  const onClose = (key: SnackbarKey) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <>
      <StyledNotistack />

      <NotistackProvider
        ref={notistackRef}
        dense
        maxSnack={5}
        preventDuplicate
        autoHideDuration={3000}
        TransitionComponent={isRTL ? Collapse : undefined}
        variant="success" // Set default variant
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        iconVariant={{
          info: <Info />,
          success: <Check />,
          warning: <Warning />,
          error: <Error />,
        }}
        // With close as default
        action={(key) => (
          <IconButton
            size="small"
            onClick={onClose(key)}
            sx={{
              p: 0.5,
              color: "#FFFFFF",
              marginLeft: 1,
              marginRight: 1,
            }}
          >
            <Close />
          </IconButton>
        )}
      >
        {children}
      </NotistackProvider>
    </>
  );
}
