import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Helmet } from "react-helmet-async";
import { useLocales } from "../../../global/locales";
import Logo from "../../../global/components/logo";
import {
  useDriversAppDispatch,
  useDriversAppSelector,
} from "../../hooks/useRedux";
import {
  completeDriverCourse,
  getCourseDocumentPresetPdf,
  getCourseDocumentUrl,
  getDriverCourseData,
  getDriverCoursesHeaders,
  getDriverOnboarding,
} from "../../app/driverAppSlice";
import { DriversRootState } from "../../app/driversAppStore";
import { PATH_DRIVER } from "../../routes/paths";
import LoadingView from "../../../dashboard/components/loading-view";
import GenericMessageView from "../../../dashboard/features/common/views/GenericMessageView";
import { fDate } from "../../../dashboard/utils/format/formatTime";
// import Label from "../../../global/components/label/Label";
import { CourseDocumentCategory, DriverCourseDocument } from "../../../api";
import { useAuthContext } from "../../../app/firebase/useAuthContext";
import { enqueueSnackbar } from "../../../global/components/snackbar";

export default function DriverCoursesPage() {
  const navigate = useNavigate();

  const { translate } = useLocales();
  const dispatch = useDriversAppDispatch();
  const { logout } = useAuthContext();

  const isLoadingData = useDriversAppSelector(
    (state: DriversRootState) => state.driverAppSlice.isLoading
  );

  const driverUser = useDriversAppSelector(
    (state: DriversRootState) => state.driverAppSlice.driverOnboardingData
  );

  const coursesHeaders = useDriversAppSelector(
    (state: DriversRootState) => state.driverAppSlice.coursesHeaders
  );

  const selectedCourseData = useDriversAppSelector(
    (state: DriversRootState) => state.driverAppSlice.selectedCourseData
  );

  useEffect(() => {
    if (!driverUser) {
      dispatch(getDriverOnboarding());
    } else if (driverUser.isOnboardingCompleted !== true) {
      navigate(PATH_DRIVER.onboarding, {
        replace: true,
      });
    } else if (driverUser.id) {
      dispatch(
        getDriverCoursesHeaders({
          driverId: driverUser.id,
          includeCompletedCourses: false,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverUser]);

  useEffect(() => {
    if (driverUser?.id && coursesHeaders && coursesHeaders.length === 1) {
      dispatch(
        getDriverCourseData({
          driverId: driverUser.id,
          courseId: coursesHeaders[0].id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coursesHeaders]);

  const handleCourseHeaderClick = (courseId: string) => {
    if (driverUser?.id) {
      dispatch(
        getDriverCourseData({
          driverId: driverUser.id,
          courseId,
        })
      );
    }
  };

  const handleCourseDocumentClick = async (document: DriverCourseDocument) => {
    if (driverUser?.id && selectedCourseData?.id) {
      if (
        document.documentCategory !== CourseDocumentCategory.PRESET_MATERIAL
      ) {
        dispatch(
          getCourseDocumentUrl({
            driverId: driverUser.id,
            courseId: selectedCourseData.id,
            docId: document.id,
            onComplete: (result: string) => {
              window.open(result, "_blank");
            },
          })
        );
      } else {
        dispatch(
          getCourseDocumentPresetPdf({
            driverId: driverUser.id,
            courseId: selectedCourseData.id,
            docId: document.id,
            onComplete: (_result: any) => {},
          })
        );
      }
    }
  };

  const handleCompleteCourse = async () => {
    if (driverUser?.id && selectedCourseData?.id) {
      const completeResult = await dispatch(
        completeDriverCourse({
          driverId: driverUser.id,
          courseId: selectedCourseData.id,
        })
      );

      if (completeResult.payload) {
        dispatch(
          getDriverCoursesHeaders({
            driverId: driverUser.id,
            includeCompletedCourses: false,
          })
        );
        enqueueSnackbar(`${translate("driver_page_courses_completed_title")}`, {
          variant: "success",
        });
      }
    }
  };

  const randerCoursesList = (
    <Stack
      spacing={4}
      sx={{
        textAlign: "center",
      }}
    >
      {/** Title */}
      <Stack spacing={2}>
        <Typography variant="h3">{`${translate("driver_page_courses_title")}`}</Typography>
        <Typography variant="subtitle1">{`${translate("driver_page_courses_subtitle")}`}</Typography>
      </Stack>

      {/** Avilable courss list courses */}
      {coursesHeaders && (
        <Stack spacing={2}>
          {coursesHeaders?.map((course) => (
            <Button
              key={course.id}
              variant="outlined"
              onClick={() => handleCourseHeaderClick(course.id)}
              size="large"
            >
              {course.title}
            </Button>
          ))}
        </Stack>
      )}

      {isLoadingData.courseData && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Stack>
  );

  const randerCourseData = selectedCourseData ? (
    <Stack
      spacing={4}
      sx={{
        textAlign: "center",
      }}
    >
      <Stack spacing={0}>
        <Typography variant="h4">{selectedCourseData.title}</Typography>
        <Typography variant="subtitle2">
          {fDate(selectedCourseData.startedAt)}
        </Typography>
      </Stack>

      {/* <Label variant="soft" color="primary">
        {`${selectedCourseData.courseTypeLabel}`}
      </Label> */}

      {/** Instruction Title:  */}
      <Stack spacing={2}>
        <Typography variant="h4" color="primary">
          {`${translate("driver_page_courses_instruction_to_driver_title")}`}
        </Typography>
        <Typography variant="subtitle1">
          {`${translate("driver_page_courses_instruction_to_driver_description")}`}
        </Typography>
      </Stack>

      {/** Documents List:  */}
      <Stack spacing={2}>
        {selectedCourseData?.documents &&
        selectedCourseData?.documents.length > 0 ? (
          selectedCourseData?.documents.map((document) => (
            <Card key={document.id} sx={{ p: 2 }}>
              <Stack justifyContent="space-between" spacing={1}>
                <Stack
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h6">{document.title}</Typography>
                  <Typography variant="subtitle2">
                    {document.description}
                  </Typography>
                </Stack>
                <LoadingButton
                  variant="contained"
                  onClick={() => handleCourseDocumentClick(document)}
                  loading={isLoadingData.downloadUrl[document.id]}
                  size="medium"
                >
                  {`${translate(document.documentCategory === CourseDocumentCategory.PRESET_MATERIAL ? "driver_page_courses_download" : "driver_page_courses_click_to_view")}`}
                </LoadingButton>
              </Stack>
            </Card>
          ))
        ) : (
          <GenericMessageView
            title={`${translate("driver_page_courses_no_documents_title")}`}
            subtitle={`${translate("driver_page_courses_no_documents_subtitle")}`}
          />
        )}
      </Stack>

      {/** Legal agreement */}
      <Typography variant="h5">
        {`${translate("driver_page_courses_legal_agreement")}`}
      </Typography>

      {/** Legal agreement button */}
      <LoadingButton
        loading={isLoadingData.courseComplpete}
        variant="contained"
        color="success"
        onClick={handleCompleteCourse}
      >
        {`${translate("driver_page_courses_confirm")}`}
      </LoadingButton>
    </Stack>
  ) : (
    <GenericMessageView
      title={`${translate("driver_page_courses_no_courses_title")}`}
      subtitle={`${translate("driver_page_courses_no_courses_subtitle")}`}
    />
  );

  return (
    <>
      <Helmet>
        <title>
          {`${translate("app_name")} | ${translate("driver_page_courses_title")}`}
        </title>
      </Helmet>

      <Stack
        direction="column"
        alignItems="center"
        justifyContent={"space-between"}
        spacing={3}
        sx={{
          minHeight: "100vh",
          paddingY: 5,
          paddingX: 2,
        }}
      >
        {/** Logo */}
        <Logo disabledLink />

        {/** Contnet */}
        {isLoadingData.coursesHeaders || isLoadingData.data ? (
          <LoadingView isCircular />
        ) : (
          <>
            {!selectedCourseData && coursesHeaders ? (
              randerCoursesList
            ) : selectedCourseData ? (
              randerCourseData
            ) : (
              <GenericMessageView
                title={`${translate("driver_page_courses_no_courses_title")}`}
                subtitle={`${translate(
                  "driver_page_courses_no_courses_subtitle"
                )}`}
              />
            )}
          </>
        )}

        {/** Footer */}
        <Stack
          spacing={1}
          sx={{
            textAlign: "center",
          }}
        >
          {/** Logout button */}
          <Button
            variant="text"
            color="error"
            onClick={() => {
              logout();
            }}
          >
            {`${translate("logout_title")}`}
          </Button>

          <Typography variant="body2">
            {`${translate("driver_page_login_copyright_text")}`}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
}
