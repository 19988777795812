import { useEffect, useMemo, useState } from "react";

import * as Yup from "yup";
import DOMPurify from "dompurify";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// @mui
import { LoadingButton } from "@mui/lab";
import {
  Card,
  Stack,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { useSnackbar } from "../../../global/components/snackbar";

import FormProvider, {
  RHFCheckbox,
} from "../../../global/components/hook-form";
import {
  CourseAtendee,
  CourseData,
  CourseStatus,
  CourseTypes,
  CreateNewCourseBody,
  DocumentConfig,
  DocumentConfigOption,
  DocumentInputResult,
  UpdateCourseDataBody,
} from "../../../api/api";
import { genericRequiredAnySchema } from "../../../global/utils/formValidators";
import RepositoryCourses from "../../repositories/RepositoryCourses";
import { AtendeesListRow } from "./details/AtendeesListRow";
import { CustomTableHeadProps } from "../common/views/tableView/SearchableTable";
import ViewGenericDataTable, {
  ViewGenericDataTableData,
} from "../common/views/tableView/ViewGenericDataTable";
import {
  useDashboardAppDispatch,
  useDashboardAppSelector,
} from "../../hooks/useRedux";
import { DashboardRootState } from "../../app/dashboardAppStore";
import {
  clearCourseConfig,
  clearSelectedDocumentHtmlPreview,
  getAllClientAvilableAttendees,
  getCourseOrDocConfig,
  getDocumentHtmlPreview,
} from "./clientCoursesPageSlice";
import { DocumentTypeSelect } from "../common/views/DocumentTypeSelect";
import { getRHFComponentByType } from "../../../global/components/hook-form/RHFUtils";
import LoadingView from "../../components/loading-view";

function ClientCoursesAddForm({
  isEditMode,
  isLoading,
  translate,
  isSubmitting,
  courseOptions,
  allCoursesAtendees,
  selectedAtendeesIds,
  onAttendeeSelected,
  isAllSelected,
  handleOnTypeChange,
  selectedDocumentType,
  selectedCourseOrDocumentConfig,
  loadingDocumentConfig,
  loadingDocumentPreview,
  onPreviewClick,
}: {
  isEditMode: boolean;
  isLoading: boolean;
  translate: Function;
  isSubmitting: boolean;
  courseOptions?: DocumentConfigOption[];
  allCoursesAtendees: CourseAtendee[] | undefined;
  selectedAtendeesIds: string[] | undefined;
  onAttendeeSelected(_attendee: CourseAtendee): void;
  isAllSelected: boolean;
  handleOnTypeChange: (_selectedType: string) => void;
  selectedDocumentType: string | null;
  selectedCourseOrDocumentConfig?: DocumentConfig;
  loadingDocumentConfig?: boolean;
  loadingDocumentPreview?: boolean;
  onPreviewClick?(): void;
}) {
  const renderRow = (row: CourseAtendee) => (
    <AtendeesListRow
      showCheckbox
      key={row.id}
      row={row}
      onRowClick={onAttendeeSelected}
      translate={translate}
      isSelected={selectedAtendeesIds?.includes(row.id)}
      disabled={!isEditMode && isAllSelected}
    />
  );

  const TABLE_HEAD: CustomTableHeadProps[] = [
    {
      id: "checkbox",
      label: `${translate("global_select")}`,
      align: "center",
    },
    {
      id: "fullName",
      label: `${translate("course_details_page_atendee_name")}`,
      align: "left",
    },
    {
      id: "govId",
      label: `${translate("global_id_number")}`,
      align: "center",
    },
    {
      id: "phoneNumber",
      label: `${translate("global_phone_number")}`,
      align: "center",
    },
  ];

  const atendeesListData: ViewGenericDataTableData<CourseAtendee> = {
    isLoading,
    translate,
    titleKey: "client_page_course_ateendees_title",
    data: allCoursesAtendees || [],
    renderRow,
    tableHead: TABLE_HEAD,
    searchable: true,
    searchLabelKey: "course_details_page_atendee_search",
  };

  return (
    <Stack spacing={3}>
      {/** Title & Action Button */}
      <Stack direction={"row"} justifyContent="space-between">
        {/** Title */}
        <Stack>
          <Typography variant="h4">{`${translate(isEditMode ? "client_page_course_edit" : "client_page_course_add")}`}</Typography>
          <Typography variant="body2">{`${translate("client_page_course_subtitle")}`}</Typography>
        </Stack>

        {/** Action Button */}
        <LoadingButton
          type="submit"
          variant="contained"
          color="success"
          loading={isSubmitting}
          sx={{ minWidth: 120 }}
          startIcon={<Add />}
        >
          {`${translate(isEditMode ? "global_save" : "global_add_new")}`}
        </LoadingButton>
      </Stack>

      {/** Content */}
      <Stack direction={"row"} spacing={3} justifyContent="space-between">
        {/** Course Data */}
        <Stack spacing={1} width={"50%"} alignContent={"center"}>
          <Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>
            {`${translate("client_page_course_details")}`}
          </Typography>

          {/** Fixed Slected Document Type */}
          {!isEditMode && (
            <DocumentTypeSelect
              translate={translate}
              id="client_page_course_type"
              labelKey="client_page_course_type"
              currentValue={selectedDocumentType || ""}
              options={courseOptions || []}
              onSelect={handleOnTypeChange}
            />
          )}

          {loadingDocumentConfig ? (
            <LoadingView />
          ) : selectedCourseOrDocumentConfig?.documentInputs ? (
            <Stack spacing={1} sx={{ pt: 2 }}>
              {!isEditMode && selectedDocumentType !== CourseTypes.OTHER && (
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={onPreviewClick}
                  loading={loadingDocumentPreview}
                >
                  {`${translate("global_preview")}`}
                </LoadingButton>
              )}

              <Box
                sx={{ pt: 2 }}
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: "repeat(2, 1fr)",
                  sm: "repeat(2, 1fr)",
                }}
              >
                {selectedCourseOrDocumentConfig?.documentInputs?.map(
                  (docConfig) =>
                    getRHFComponentByType({
                      type: docConfig.type,
                      name: docConfig.dbKey,
                      label: docConfig.label,
                      options: docConfig.options,
                    })
                )}

                {!isEditMode && (
                  <RHFCheckbox
                    sx={{ marginTop: 0 }}
                    name="includeAllAtendees"
                    label={`${translate("client_page_course_add_all_ateendees")}`}
                  />
                )}
              </Box>
            </Stack>
          ) : (
            <Box
              sx={{ pt: 5 }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body1">{`${translate("client_page_course_select_type")}`}</Typography>
            </Box>
          )}
        </Stack>

        {/** Atendees List */}
        <Box width={"50%"}>
          <ViewGenericDataTable {...atendeesListData} />
        </Box>
      </Stack>
    </Stack>
  );
}

export type ClientCoursesAddEditProps = {
  currentCourseData?: CourseData;
  clientId: string;
  translate: Function;
  onComplete?: Function;
};

export default function ClientCoursesAddEdit({
  currentCourseData,
  clientId,
  translate,
  onComplete,
}: ClientCoursesAddEditProps) {
  const isEditMode = Boolean(currentCourseData);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDashboardAppDispatch();

  const courseOrDocumentConfigOptions = useDashboardAppSelector(
    (state: DashboardRootState) =>
      state.clientCoursesPageSlice.courseOrDocumentConfigOptions
  );

  const selectedCourseConfig = useDashboardAppSelector(
    (state: DashboardRootState) =>
      state.clientCoursesPageSlice.selectedCourseOrDocumentConfig
  );

  const allClientAvilableAttendees = useDashboardAppSelector(
    (state: DashboardRootState) =>
      state.clientCoursesPageSlice.allClientAvilableAttendees
  );

  const isLoading = useDashboardAppSelector(
    (state: DashboardRootState) => state.clientCoursesPageSlice.isLoading
  );

  const [selectedAtendeesIds, setSelectedAtendeesIds] = useState<string[]>(
    currentCourseData?.atendees?.map((attendee) => attendee.id) || []
  );

  const selectedDocumentHtmlPreview = useDashboardAppSelector(
    (state: DashboardRootState) =>
      state.clientCoursesPageSlice.selectedDocumentHtmlPreview
  );

  // Get the data:
  useEffect(() => {
    if (clientId) {
      dispatch(getAllClientAvilableAttendees(clientId));
    }

    if (isEditMode && currentCourseData) {
      dispatch(
        getCourseOrDocConfig({
          courseType: currentCourseData.courseType,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  const NewCourseSchema = Yup.object().shape(
    selectedCourseConfig?.documentInputs?.reduce((acc, docInput) => {
      if (docInput.isMandatory) {
        acc[docInput.dbKey] = genericRequiredAnySchema(translate);
      }
      return acc;
    }, {} as any)
  );

  const defaultValues = useMemo(
    () => ({
      courseType: currentCourseData?.courseType || "",
      courseStatus: currentCourseData?.status || CourseStatus.ACTIVE,
      ...currentCourseData?.documentInputResults?.reduce((acc, docResult) => {
        acc[docResult.dbKey] = docResult.value;
        return acc;
      }, {} as any),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewCourseSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);

  const [currentSelectedType, setSelectedType] = useState<string>("");

  const handleOnTypeChange = (newSelectedType: string) => {
    setSelectedType(newSelectedType);
    reset();
  };

  useEffect(() => {
    if (clientId && currentSelectedType && currentSelectedType.length > 0) {
      dispatch(
        getCourseOrDocConfig({
          courseType: currentSelectedType,
        })
      );
    }

    return () => {
      dispatch(clearCourseConfig());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedType]);

  const includeAllAtendees = methods.watch("includeAllAtendees");

  const onPreviewClick = () => {
    dispatch(
      getDocumentHtmlPreview({
        documentType: currentSelectedType,
        onComplete: (isSuccess) => {
          setIsPreviewDialogOpen(isSuccess);
        },
      })
    );
  };

  const handleClosePreviewDialog = () => {
    setIsPreviewDialogOpen(false);
    dispatch(clearSelectedDocumentHtmlPreview());
  };

  const onSubmit = async (data: any) => {
    const documentResults: DocumentInputResult[] = [];
    Object.keys(data).forEach((key) => {
      documentResults.push({
        dbKey: key,
        value: data[key],
      });
    });

    try {
      let result;
      if (!isEditMode) {
        const courseData: CreateNewCourseBody = {
          courseType: currentSelectedType,
          includeAllAtendees: data.includeAllAtendees,
          atendeesIds: selectedAtendeesIds,
          documentInputResults: documentResults,
        };

        result = await RepositoryCourses().createNewCourse(
          clientId,
          courseData
        );
      } else {
        // Edit mode:
        const updateCourseData: UpdateCourseDataBody = {
          courseType: currentSelectedType,
          atendeesIds: selectedAtendeesIds,
          status: data.courseStatus,
          documentInputResults: documentResults,
        };

        result = await RepositoryCourses().updateCourseData(
          clientId,
          currentCourseData?.id || "",
          updateCourseData
        );
      }

      if (result) {
        enqueueSnackbar(`${translate("form_message_success_message")}`);

        // Wait for 1 second before closing the dialog
        await new Promise((resolve) => setTimeout(resolve, 500));
        if (onComplete) {
          onComplete();
        }
      } else {
        enqueueSnackbar(`${translate("error_course_add_title")}`, {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar(`${translate("error_course_add_title")}`, {
        variant: "error",
      });
    }
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ p: 3 }}>
          <ClientCoursesAddForm
            isEditMode={isEditMode}
            isLoading={isLoading.getAllClientAvilableAttendees}
            isAllSelected={includeAllAtendees === true}
            translate={translate}
            isSubmitting={isSubmitting}
            allCoursesAtendees={allClientAvilableAttendees}
            selectedAtendeesIds={selectedAtendeesIds}
            onAttendeeSelected={(attendee: CourseAtendee) => {
              setSelectedAtendeesIds((prev) => {
                const index = prev.indexOf(attendee.id);
                if (index === -1) {
                  return [...prev, attendee.id];
                }
                return prev.filter((_, i) => i !== index);
              });
            }}
            courseOptions={courseOrDocumentConfigOptions}
            handleOnTypeChange={handleOnTypeChange}
            selectedDocumentType={currentSelectedType}
            selectedCourseOrDocumentConfig={selectedCourseConfig}
            loadingDocumentConfig={isLoading.courseOrDocumentConfig}
            loadingDocumentPreview={isLoading.documentPreview}
            onPreviewClick={onPreviewClick}
          />
        </Card>
      </FormProvider>

      <Dialog
        fullWidth
        maxWidth="md"
        scroll="paper"
        open={isPreviewDialogOpen}
        onClose={handleClosePreviewDialog}
      >
        <DialogTitle id="scroll-dialog-title">{`${translate("driver_documents_preivew_title")}`}</DialogTitle>
        <DialogContent dividers>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(selectedDocumentHtmlPreview || "", {
                FORCE_BODY: true,
                ALLOWED_ATTR: ["style", "class", "type"],
              }),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClosePreviewDialog}
          >{`${translate("global_close")}`}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
